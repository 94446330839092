import React from 'react';

import FormsLaudus from '../widgets/FormReviewLaudus';

const Index = () => {
    return(
        <>
            <FormsLaudus/>
        </>
    );
}

export default Index;