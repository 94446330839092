import React, { Fragment } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./styles.scss";
export const LoadingViewResumus = () => {
    return (
        <Fragment>
            <Skeleton height={40} className="mb-2" />
            <Skeleton height={25} className="margin__line" count={1} />

            <div className="author">
                <Skeleton width={80} height={80} circle={true} />
                <div style={{marginLeft: 15}}>
                    <Skeleton width={350} height={20} className="margin__line" count={1} />
                </div>
            </div>

            <Skeleton height={25} count={5} className="margin__line" />
            <Skeleton height={250} count={1} className="margin__line" />
            <Skeleton height={25} count={5} className="margin__line" />

            <Skeleton height={60} className="margin__options" />
            <div className="author">
                <Skeleton width={100} height={100} circle={true} />
                <div style={{marginLeft: 15}}>
                    <Skeleton width={150} height={25} className="margin__line" count={2} />
                </div>
            </div>
        </Fragment>
    )
}