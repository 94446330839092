import React, { Fragment} from 'react';
import FeedCard from './FeedCard';

const FeedContent = () => {

  return (
    <Fragment>
      <FeedCard/>
    </Fragment>
  );
};

export default FeedContent;
