import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaAngleRight, FaHome } from "react-icons/fa";

const NavFeed = () => {

    const [navgation, setNavgation] = useState([])
    const nav = useParams();

    useEffect(() => {
        const NavSlug = () => {
            const navItens = [];
            Object.keys(nav).forEach(function (item) {
                if (item !== 'id') {
                    navItens.push(nav[item]);
                }
            });
            setNavgation(navItens);
        }
        NavSlug();
    }, [])



    return (
        <Fragment>
            <nav style={{ marginBottom: 20, padding: 0 }}>
                <ul style={{ display: 'flex', padding: 0, margin: 0 }}>
                    <li
                        style={{
                            marginRight: 10,
                            outline: 'none',
                            textDecoration: 'none',
                            listStyleType: 'none'
                        }}>
                        <Link to='/dashboard' style={{ color: '#9F9F9F', fontSize: 17, alignItems: 'center', display: 'flex' }}> <FaHome style={{ marginRight: 5 }} /> Home <FaAngleRight style={{ marginLeft: 10 }} /> </Link>
                    </li>
                    {navgation.map((i, x) => {
                        return (
                            <li
                                style={{
                                    marginRight: 10,
                                    outline: 'none',
                                    textDecoration: 'none',
                                    listStyleType: 'none'
                                }}>

                                {
                                    i === 'LaudUS' ? <Link to={`/${i}/especialidades`} style={{ color: '#9F9F9F', fontSize: 17, alignItems: 'center', display: 'flex' }}>{i} <FaAngleRight style={{ marginLeft: 10 }} /> </Link> :
                                        i === 'ResumUS' ? <Link to={`/${i}/especialidades`} style={{ color: '#9F9F9F', fontSize: 17 }}>{i}<FaAngleRight style={{ marginLeft: 10 }} /></Link> :
                                            <Link style={{ color: '#9F9F9F', fontSize: 17 }}>{i} {x === navgation.length - 1 ? '' : <FaAngleRight style={{ marginLeft: 10 }} />}</Link>
                                }
                            </li>

                        );
                    })}

                </ul>
            </nav>
        </Fragment>
    )
}

export default NavFeed;