import React from 'react';

import FormsResumus from '../widgets/FormsResumus';

const IndexRusumus = () => {
    return(
        <>
            <FormsResumus/>
        </>
    );
}

export default IndexRusumus;