import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Like, User, LikeGreen } from '../../assets/img/icons/celus/index';
import { FaRegBookmark, FaShareAlt, FaBookmark } from 'react-icons/fa';
import { LoadingViewLaudus } from '../LoadingViewLaudus';

import './view_styles.scss';

import CelusApi from '../../server/celusApi';
import Cookies from 'js-cookie';

const FeedCard = ({ className }) => {

  const api = CelusApi();
  const params = useParams();
  const [values, setValues] = useState(['']);
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [images, setImagens] = useState([]);
  const [likeCheck, setLikeCheck] = useState();
  const [favoriteCheck, setFavoriteCheck] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getLaudusById();
     }, []);


  const getLaudusById = async () => {
    try {
      setLoading(true);
      const result = await api.getLaudusById({ id: params.id, clientId: Number(Cookies.get('clientId')) });
      setName(result.result.result[0].client.name);
      setAvatar(result.result.result[0].client.avatar);
      setImagens(result.result.result[0].reportImages);
      setValues(result.result.result[0]);
      setLikeCheck(result.result.likeUser[0].like);
      setFavoriteCheck(result.result.favoriteUser[0].favorite);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      console.log(error);
    }
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [toLikes, setToLikes] = useState(0);
  
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    likes();
  }, [toLikes]);

  const postLFavorite = async () => {
    try {
      const value = {
        reportId: values.id,
        clientId: Number(Cookies.get('clientId')),
      }
      await api.postFavoriteLaudus(value);
      // getLaudusById();
      setFavoriteCheck(!favoriteCheck);
    } catch (error) {
      console.log(error);
    }
  }

  const postLike = async () => {

    try {
      const value = {
        reportId: values.id,
        clientId: Cookies.get('clientId')
      }
      await api.postLikesReports(value);
      likes();
      checklikes();
    } catch (error) {
      console.log(error);
    }
  }

  const likes = async () => {
    try {
      const resultLikes = await api.getByReportIdLike(params.id);
      setToLikes(resultLikes.result);
    } catch (error) {
      console.log(error);
    }
  }

  const checklikes = async () => {
    try {

      let value = {
        reportId: values.id,
        clientId: Cookies.get('clientId')
      };

      const checks = await api.checkLike(value);
      setLikeCheck(checks);
    } catch (error) {
      console.log(error);
    }
  }

  const slides = images.map(item => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%', backgroundColor: '#DDD', borderRadius: 5 }}>
          <img style={{ height: 370, backgroundSize: '100%' }} src={item.src} alt={item.altText} className='img-fluid' />
        </div>
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  String.prototype.stripHTML = function () { return this.replace(/<.*?>/g, ''); }

  const goToEdit = () => {
    history.push(`/laudus/edit/${params.id}`);
  }

  return (

    <Card className={`${className} item-card`} style={{ cursor: 'auto' }}>
      {
        loading ? (
          <Fragment>
            <LoadingViewLaudus/>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 30 }}>
                <div style={{ width: '90%', textAlign: 'justify' }} >
                  <h3  >{values.title}</h3>
                </div>
                <div>
                  <button style={{
                    color: '#50D19A',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    outline: 'none'
                  }}
                    onClick={goToEdit}
                  >
                    Editar
                  </button>
                </div>

              </div >

              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
              </Carousel>

              <div style={{ marginBottom: '30px', marginTop: '60px' }}>
                <h5 style={{ fontSize: 19, color: '#50D19A', marginBottom: 15 }}>Descrição</h5>
                <div className="desc-img">{ReactHtmlParser(values.description)}</div>

              </div>

              <div>
                <h5 style={{ fontSize: 19, color: '#50D19A', marginBottom: 15 }}>Conclusão</h5>
                <div className="desc-img">{ReactHtmlParser(values.conclusion)}</div>
              </div>

              <div style={{ borderTop: '1px solid #EBEBEB', borderBottom: '1px solid #EBEBEB' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', padding: '20px 0' }}>

                  <div className="like">
                    <img src={likeCheck ? LikeGreen : Like} style={{ width: 23, marginRight: 5 }} onClick={() => { postLike(); }} />
                    <p style={{ color: '#000', fontSize: 14, fontWeight: 'bold', margin: 0 }}>{(toLikes)} {Number(toLikes) >= 2 ? 'curtidas' : 'curtida'}</p>
                  </div>

                  <div className="like">
                    {/* <FaCopy transform="shrink-4" className="fs-2 footer-icon" color='#344050' style={{ marginRight: 15 }} /> */}
                    {

                      !favoriteCheck ? <FaRegBookmark transform="shrink-4" className="fs-2 footer-icon" color='#344050' style={{ marginRight: 15 }} onClick={postLFavorite} />
                        :
                        <FaBookmark transform="shrink-4" className="fs-2 footer-icon" color='#31AF89' style={{ marginRight: 15 }} onClick={postLFavorite} />
                    }
                    <FaShareAlt transform="shrink-4" className="fs-2 footer-icon" color='#344050'
                      onClick={
                        () => {
                          navigator.share({
                            title: 'Share',
                            text: 'whatevs',
                            url: `/to-see/laudus/${values.id}/${values.title}`
                          }
                          )
                        }
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ padding: '25px 0' }}>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  <img style={{ width: 90, height: 90,  borderRadius: '50%', border: '2px solid #31AF89 ', objectFit: 'cover' }} src={avatar ? avatar : User} />
                  <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>
                    <h5 style={{ fontSize: 17 }}>Autor</h5>
                    <p style={{ margin: 0, width: 'auto' }}>{name ? name : '********'}</p>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )
      }

    </Card>
  );
};

FeedCard.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  user: PropTypes.object,
  className: PropTypes.string
};

export default FeedCard;
