import React, { useState, useMemo, useEffect, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';

import { Digital } from "react-activity";
import "react-activity/dist/library.css";

import * as S from './css/anatomus';
import html2canvas from 'html2canvas';
import {
    FaAngleLeft,
    FaAngleRight,
    FaRegClone,
    FaRegTimesCircle,
    FaCloudDownloadAlt,
    FaMagnet,
    FaPen,
    FaPalette,
    FaFont,
    FaExpandArrowsAlt
} from 'react-icons/fa';

import interact from 'interactjs';
import './styles.scss';
import { Up } from '../../assets/img/icons/celus';
export const AreasImagensMark = ({
    arrayImagensMark,
    setArrayImagesMark = Function(),
    arrayImagensNoMark,
    setArrayImagesNoMark = Function(),
    imageToMark,
    setImageToMark = Function(),
    idMark = String,
    classMark = String,
    labelsImagens,
    setLabelsImagens = Function(),
    labelIndex,
    setLabelIndex = Function(),
    setLabelDescription = Function(),
    labelDescription,
    setCont = Function(),
    cont,
    arrayLabels,
    setArrayLabels = Function()
}) => {
    const [collapseOne, collapseOneOpen] = React.useState(false);
    const [collapseOneLabel, collapseOneOpenLabel] = React.useState(false);
    const [collapseOneLabelList, collapseOneOpenLabeList] = React.useState(false);
    const [collapseOneLabelListCreate, collapseOneOpenLabeListCreate] = React.useState(false);

    const [indexLabelSelect, setIndexLabelSelect] = useState();
    const [valuesEdit, setValuesEdit] = useState('');

    const [imgModal, setImgModal] = useState();
    const [colorPoint, setColorPoint] = useState('#31AF89');
    const [colorFont, setColorFont] = useState('#FFF');
    const [sizeDot, setSizeDot] = useState('35');

    const pushImagesNoMark = files => {
        if (files) {
            setImageToMark(files);
            arrayImagensNoMark.push(files);
            setArrayImagesNoMark([...arrayImagensNoMark]);
        }
    };
    const [labelModalEdit, setLabelModalEdit] = useState();
    const [loadingMark, setLoadingMark] = useState(false);
    const [error, setError] = useState(false);
    const [errs, setErrs] = useState({
        title: '',
        description: '',
        specialtie: '',
        subArea: '',
        arrayImagens: '',
        arrayImagensMark: '',
        arrayImagensNoMark: ''
    });

    const [clip, setClip] = useState(false);

    const [idMarkContent, setIdMarkContent] = useState('');
    useEffect(() => {
        setIdMarkContent(idMark);
    }, [idMark]);

    const previewToMark = useMemo(() => {
        return imageToMark ? URL.createObjectURL(imageToMark) : null;
    }, [imageToMark]);

    const ModalNumberMark = () => {
        return (
            <Fragment>
                <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)}>
                    <ModalHeader>Adicionar Legenda [{labelIndex}]</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <S.Label>
                                Index <S.Required>*</S.Required>
                            </S.Label>
                            <S.Input
                                type="number"
                                placeholder="Index da Legenda"
                                onChange={e => {
                                    setIndexLabelSelect(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                collapseOneOpen(!collapseOne);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ background: '#31AF89' }}
                            onClick={() => {
                                SetLabelArray(labelIndex);
                            }}
                        >
                            Ok
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    };

    const ModalEditLabelsList = () => {
        return (
            <Fragment>
                <Modal isOpen={collapseOneLabelList} toggle={() => collapseOneOpenLabeList(!collapseOneLabelList)}>
                    <ModalHeader>
                        Editar Legenda [{indexLabelSelect + 1},{labelModalEdit + 1}]
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <S.Label>
                                Legenda <S.Required>*</S.Required>
                            </S.Label>
                            <S.Input
                                type="text"
                                placeholder="Adicionar Legenda"
                                value={valuesEdit}
                                onChange={e => {
                                    setValuesEdit(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                collapseOneOpenLabeList(!collapseOneLabelList);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button style={{ background: '#31AF89' }} onClick={editLabelFunc}>
                            Salvar
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    };

    const editLabelFunc = () => {
        labelsImagens[indexLabelSelect][labelModalEdit] = { description: valuesEdit };
        setLabelsImagens(labelsImagens);
        collapseOneOpenLabeList(!collapseOneLabelList);
    };

    const ModalEditLabelsListCreate = () => {
        return (
            <Fragment>
                <Modal
                    isOpen={collapseOneLabelListCreate}
                    toggle={() => collapseOneOpenLabeListCreate(!collapseOneLabelListCreate)}
                >
                    <ModalHeader>Editar Legenda [{indexLabelSelect + 1}]</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <S.Label>
                                Legenda <S.Required>*</S.Required>
                            </S.Label>
                            <S.Input
                                type="text"
                                placeholder="Adicionar Legenda"
                                value={valuesEdit}
                                onChange={e => {
                                    setValuesEdit(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                collapseOneOpenLabeListCreate(!collapseOneLabelListCreate);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ background: '#31AF89' }}
                            onClick={() => {
                                arrayLabels[indexLabelSelect] = { description: valuesEdit };
                                setArrayLabels([...arrayLabels]);
                                collapseOneOpenLabeListCreate(!collapseOneLabelListCreate);
                            }}
                        >
                            Salvar
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    };

    const ModalEditLabels = () => {

        const labelEditArray = labelsImagens[indexLabelSelect];
        return (
            <Fragment>
                <Modal
                    isOpen={collapseOneLabel}
                    toggle={() => {
                        collapseOneOpenLabel(!collapseOneLabel);
                        setArrayLabels([]);
                    }}
                >
                    <ModalHeader>Editar Legenda ({indexLabelSelect + 1})</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <img src={imgModal} style={{ width: 'auto', height: '100%', objectFit: 'contain', marginBottom: 10 }} />

                            <S.Label>Legenda(s)</S.Label>
                            {labelEditArray?.map((item, index) => {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                UpPositionLabelsModal(Number(index), Number(index - 1));
                                            }}
                                            disabled={index === 0}
                                        >
                                            <FaAngleLeft />
                                        </S.IButton>
                                        <p style={{ margin: 0, padding: 0, width: '100%' }}>
                                            {`[${index + 1}]`} : {item.description}
                                        </p>
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                UpPositionLabelsModal(Number(index), Number(index + 1));
                                            }}
                                            disabled={arrayLabels.length === 1 ? true : index === arrayLabels.length - 1 ? true : false}
                                        >
                                            <FaAngleRight />
                                        </S.IButton>
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                CloneLabelsModal(item, index);
                                            }}
                                        >
                                            <FaRegClone />
                                        </S.IButton>
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                RemoverLabelsModal(index);
                                            }}
                                        >
                                            <FaRegTimesCircle />
                                        </S.IButton>
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                setValuesEdit(item.description);
                                                setLabelModalEdit(index);
                                                collapseOneOpenLabeList(true);
                                            }}
                                        >
                                            <FaPen />
                                        </S.IButton>
                                    </div>
                                );
                            })}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                collapseOneOpenLabel(!collapseOneLabel);
                                setArrayLabels([]);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ background: '#31AF89' }}
                            onClick={() => {
                                arrayLabels[indexLabelSelect] = { description: valuesEdit };
                                setArrayLabels([]);
                                collapseOneOpenLabel(!collapseOneLabel);
                            }}
                        >
                            Salvar
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    };

    ///// MODAL FUNC

    const UpPositionLabelsModal = (from, to) => {
        labelsImagens[indexLabelSelect].splice(to, 0, labelsImagens[indexLabelSelect].splice(from, 1)[0]);
        setLabelsImagens([...labelsImagens]);
    };

    const CloneLabelsModal = (item, to) => {
        labelsImagens[indexLabelSelect].push(item);
        setLabelsImagens([...labelsImagens]);
    };

    const RemoverLabelsModal = from => {
        labelsImagens[indexLabelSelect].splice(from, 1);
        setLabelsImagens([...labelsImagens]);
    };

    ///////////
    const UpPositionNoMark = (from, to) => {
        if (!clip) {
            arrayImagensNoMark.splice(to, 0, arrayImagensNoMark.splice(from, 1)[0]);
            setArrayImagesNoMark([...arrayImagensNoMark]);
        } else {
            arrayImagensNoMark.splice(to, 0, arrayImagensNoMark.splice(from, 1)[0]);
            setArrayImagesNoMark([...arrayImagensNoMark]);

            arrayImagensMark.splice(to, 0, arrayImagensMark.splice(from, 1)[0]);
            setArrayImagesMark([...arrayImagensMark]);
        }
    };

    const UpPositionLabels = (from, to) => {
        arrayLabels.splice(to, 0, arrayLabels.splice(from, 1)[0]);
        setArrayLabels([...arrayLabels]);
    };

    const CloneLabels = (item, to) => {
        arrayLabels.push(item);
        arrayLabels.splice(to, 0, arrayLabels.splice(arrayLabels.length - 1, 1)[0]);
        setArrayLabels([...arrayLabels]);
    };

    const RemoverLabels = from => {
        arrayLabels.splice(from, 1);
        setArrayLabels([...arrayLabels]);
    };

    const CloneFileNoMark = (item, to) => {
        if (!clip) {
            arrayImagensNoMark.push(item);
            arrayImagensNoMark.splice(to, 0, arrayImagensNoMark.splice(arrayImagensNoMark.length - 1, 1)[0]);
            setArrayImagesNoMark([...arrayImagensNoMark]);
        } else {
            arrayImagensNoMark.push(item);
            arrayImagensNoMark.splice(to, 0, arrayImagensNoMark.splice(arrayImagensNoMark.length - 1, 1)[0]);
            setArrayImagesNoMark([...arrayImagensNoMark]);

            arrayImagensMark.push(arrayImagensMark[Number(to)]);
            arrayImagensMark.splice(to, 0, arrayImagensMark.splice(arrayImagensMark.length - 1, 1)[0]);
            setArrayImagesMark([...arrayImagensMark]);

            const arrayAux = labelsImagens[to];
            labelsImagens.push(arrayAux);
            labelsImagens.splice(to, 0, labelsImagens.splice(labelsImagens.length - 1, 1)[0]);
            setLabelsImagens(labelsImagens);
        }
    };

    const RemoverFileNoMark = from => {
        if (!clip) {
            arrayImagensNoMark.splice(from, 1);
            setArrayImagesNoMark([...arrayImagensNoMark]);
        } else {
            arrayImagensNoMark.splice(from, 1);
            setArrayImagesNoMark([...arrayImagensNoMark]);

            labelsImagens.splice(from, 1);
            setLabelsImagens(labelsImagens);

            arrayImagensMark.splice(from, 1);
            setArrayImagesMark([...arrayImagensMark]);
        }
    };

    const UpPositionMark = (from, to) => {
        if (!clip) {
            arrayImagensMark.splice(to, 0, arrayImagensMark.splice(from, 1)[0]);
            setArrayImagesMark([...arrayImagensMark]);

            labelsImagens.splice(to, 0, labelsImagens.splice(from, 1)[0]);
            setLabelsImagens(labelsImagens);
        } else {
            arrayImagensNoMark.splice(to, 0, arrayImagensNoMark.splice(from, 1)[0]);
            setArrayImagesNoMark([...arrayImagensNoMark]);

            labelsImagens.splice(to, 0, labelsImagens.splice(from, 1)[0]);
            setLabelsImagens(labelsImagens);

            arrayImagensMark.splice(to, 0, arrayImagensMark.splice(from, 1)[0]);
            setArrayImagesMark([...arrayImagensMark]);
        }
    };

    const CloneFileMark = (item, to) => {
        if (!clip) {
            arrayImagensMark.push(item);
            arrayImagensMark.splice(to, 0, arrayImagensMark.splice(arrayImagensMark.length - 1, 1)[0]);
            setArrayImagesMark([...arrayImagensMark]);

            const arrayAux = labelsImagens[to];
            labelsImagens.push(arrayAux);
            labelsImagens.splice(to, 0, labelsImagens.splice(labelsImagens.length - 1, 1)[0]);
            setLabelsImagens(labelsImagens);
        } else {
            arrayImagensNoMark.push(arrayImagensNoMark[Number(to)]);
            arrayImagensNoMark.splice(to, 0, arrayImagensNoMark.splice(arrayImagensNoMark.length - 1, 1)[0]);
            setArrayImagesNoMark([...arrayImagensNoMark]);

            const arrayAux = labelsImagens[to];
            labelsImagens.push(arrayAux);
            labelsImagens.splice(to, 0, labelsImagens.splice(labelsImagens.length - 1, 1)[0]);
            setLabelsImagens(labelsImagens);

            arrayImagensMark.push(item);
            arrayImagensMark.splice(to, 0, arrayImagensMark.splice(arrayImagensMark.length - 1, 1)[0]);
            setArrayImagesMark([...arrayImagensMark]);
        }

    };

    const RemoverFileMark = from => {
        if (!clip) {
            arrayImagensMark.splice(from, 1);
            setArrayImagesMark([...arrayImagensMark]);

            labelsImagens.splice(from, 1);
            setLabelsImagens(labelsImagens);
        } else {
            arrayImagensNoMark.splice(from, 1);
            setArrayImagesNoMark([...arrayImagensNoMark]);


            labelsImagens.splice(from, 1);
            setLabelsImagens(labelsImagens);

            arrayImagensMark.splice(from, 1);
            setArrayImagesMark([...arrayImagensMark]);
        }
    };

    const Capture = e => {
        html2canvas(document.getElementById(idMarkContent), {
            logging: true
        }).then(canvas => {
            var img = new Image();
            img.onload = () => {
                img.onload = null;
            };
            var a = document.createElement('a');
            a.href = canvas.toDataURL('image/png');
            a.download = Date.now() + '.png';
            a.click();
            setArrayImagesNoMark([...arrayImagensNoMark]);
        });
    };

    const Magnet = e => {
        html2canvas(document.getElementById(idMarkContent), {
            logging: true
        }).then(canvas => {
            var img = new Image();
            img.onload = () => {
                img.onload = null;
            };
            let fileMark = dataURLtoFile(canvas.toDataURL('image/png'), Date.now() + '.png');
            arrayImagensMark.push(fileMark);
            setArrayImagesMark([...arrayImagensMark]);
            const auxArray = [...arrayLabels];
            labelsImagens.push(auxArray);
            setLabelsImagens([...labelsImagens]);
            setArrayLabels([]);
            setImageToMark(null);
        });
    };

    interact('.' + classMark).draggable({
        inertia: true,
        modifiers: [
            interact.modifiers.restrictRect({
                restriction: 'parent',
                endOnly: true
            })
        ],
        autoScroll: true,
        listeners: {
            move: dragMoveListener
        }
    });

    function dragMoveListener(event) {
        var target = event.target;
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
    }

    window.dragMoveListener = dragMoveListener;

    const SetLabelArray = index => {
        if (indexLabelSelect) {
            const dot = document.getElementById(`mark-${index}`);
            dot.innerText = indexLabelSelect.toString();
            dot.id = `mark-${indexLabelSelect.toString()}`;
        }

        collapseOneOpen(false);

        setIndexLabelSelect(undefined);
        setLabelDescription(undefined);
    };

    const insertLabel = (event, mark) => {
        mark.innerText = document.getElementById(event.target.id).innerText
            ? document.getElementById(event.target.id).innerText
            : `${cont}`;
        setLabelIndex(Number(document.getElementById(event.target.id).innerText));
        setLabelDescription(arrayLabels[Number(document.getElementById(event.target.id).innerText) - 1]?.description);
        collapseOneOpen(true);
        setLoadingMark(false);

    };

    const removeLabel = (event, mark) => {
        if (3 === event.which) {
            if (cont === 1) {
                setCont(1);
            } else if (cont < 1) {
                setCont(cont - 1);
            }
            window.addEventListener('contextmenu', e => e.preventDefault());
            document.getElementById(event.target.id).remove();
            setLoadingMark(false);
        }
        setLoadingMark(false);
    };

    const createElementMarck = () => {

        setLoadingMark(true);
        setCont(cont + 1);
        const fatherElement = document.getElementById(idMarkContent + '');
        const mark = document.createElement('div');
        mark.classList.add(classMark);
        mark.style.cssText = `width:${sizeDot}px; height: ${sizeDot}px; position: absolute`;
        mark.style.backgroundColor = `${colorPoint}`;
        mark.style.borderRadius = ' 50% 50% 50% 0%';
        mark.style.transform = 'rotateZ(45deg)';
        mark.style.display = 'flex';
        mark.style.justifyContent = 'center';
        mark.style.alignItems = 'center';
        mark.style.color = `${colorFont}`;
        mark.style.fontWeight = 'bold';
        mark.id = `mark-${cont}`;
        fatherElement.appendChild(mark);

        mark.ondblclick = function (event) {
            insertLabel(event, mark);
        };

        mark.onmousedown = event => {
            removeLabel(event, mark);
        };
        setLoadingMark(false);
    };

    const dataURLtoFile = (dataurl, filename) => {
        if (dataurl) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
    };

    return (
        <Fragment>
            <S.FormGroup>
                <S.Label>
                    Escolha uma imagem <S.Required>*</S.Required>
                </S.Label>
                <label className="dropZone">
                    <input
                        type="file"
                        style={{ visibility: 'hidden', display: 'none' }}
                        onChange={e => {
                            pushImagesNoMark(e.target.files[0]);
                            setCont(1);
                        }}
                    />
                    <p className="dropLabel">
                        Clique aqui para adicionar a imagem na área abaixo, clique no pin e adicione as marcações
                    </p>
                    <img src={Up} height="50px" />
                </label>

                {error === true && arrayImagensMark.length <= 0 ? (
                    <S.errorMessage>{errs.arrayImagensMark}</S.errorMessage>
                ) : null}
                {error === true && arrayImagensNoMark.length <= 0 ? (
                    <S.errorMessage>{errs.arrayImagensNoMark}</S.errorMessage>
                ) : null}
            </S.FormGroup>

            {arrayImagensNoMark.length > 0 || arrayImagensMark.length > 0 ? (
                <Fragment>
                    {previewToMark ? (
                        <Fragment>
                            <S.FramesArea style={{ curso: loadingMark ? 'wait' : 'default' }}>
                                <S.ContentArea style={{ justifyContent: 'center' }}>
                                    <div
                                        id={idMarkContent + ''}
                                        style={{ width: 'auto', height: '100%', position: 'relative', cursor: 'pointer' }}
                                    >
                                        <img src={previewToMark} style={{ width: 'auto', height: '100%', objectFit: 'contain' }} />
                                    </div>
                                </S.ContentArea>

                                <S.PickerArea>
                                    <S.MarkArea>
                                        {
                                            loadingMark ? (<Digital />) : (<S.MarkPoint color={colorPoint} onClick={createElementMarck} />)
                                        }

                                    </S.MarkArea>

                                    <S.MarkArea>
                                        <label className="label-custon">
                                            <FaExpandArrowsAlt size={30} />
                                            <input
                                                type="number"
                                                value={sizeDot}
                                                max={100}
                                                min={30}
                                                onChange={e => {
                                                    setSizeDot(e.target.value > 100 ? 100 : e.target.value);
                                                }}
                                                style={{
                                                    width: '60%',
                                                    height: 25,
                                                    border: '1px solid rgba(126, 126, 126, 1)',
                                                    marginTop: 3,
                                                    padding: 5,
                                                    overflow: 'none',
                                                    outline: 'none',
                                                    borderRadius: 5
                                                }}
                                            />
                                        </label>
                                    </S.MarkArea>

                                    <S.MarkArea>
                                        <label className="label-custon">
                                            <input
                                                type="color"
                                                onChange={e => {
                                                    setColorPoint(e.target.value);
                                                }}
                                                hidden
                                            />
                                            <FaPalette size={30} />
                                            <div
                                                style={{
                                                    background: colorPoint,
                                                    width: 30,
                                                    height: 6,
                                                    border: '1px solid rgba(126, 126, 126, 1)',
                                                    marginTop: 3
                                                }}
                                            />
                                        </label>
                                    </S.MarkArea>

                                    <S.MarkArea>
                                        <label className="label-custon">
                                            <input
                                                type="color"
                                                onChange={e => {
                                                    setColorFont(e.target.value);
                                                }}
                                                hidden
                                            />
                                            <FaFont size={30} />
                                            <div
                                                style={{
                                                    background: colorFont,
                                                    width: 30,
                                                    height: 6,
                                                    border: '1px solid rgba(126, 126, 126, 1)',
                                                    marginTop: 3
                                                }}
                                            />
                                        </label>
                                    </S.MarkArea>

                                    <S.MarkArea onClick={Magnet}>
                                        <FaMagnet size={30} />
                                    </S.MarkArea>

                                    <S.MarkArea onClick={Capture}>
                                        <FaCloudDownloadAlt size={30} />
                                    </S.MarkArea>
                                </S.PickerArea>
                            </S.FramesArea>

                            <S.AreaLabels>
                                <S.HeaderLabels>
                                    <FormGroup style={{ margin: 0 }}>
                                        <S.Input
                                            style={{ margin: 0 }}
                                            type="text"
                                            placeholder="Adicionar Legenda"
                                            value={labelDescription}
                                            onChange={e => {
                                                setLabelDescription(e.target.value);
                                            }}
                                        />
                                    </FormGroup>

                                    <Button
                                        style={{ width: '120px', background: '#31AF89', marginLeft: 15 }}
                                        onClick={() => {
                                            arrayLabels.push({ description: labelDescription });
                                            setArrayLabels([...arrayLabels]);
                                            setLabelDescription('');
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                </S.HeaderLabels>
                                <S.ListLabels>
                                    {arrayLabels.map((item, index) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        UpPositionLabels(Number(index), Number(index - 1));
                                                    }}
                                                    disabled={index === 0}
                                                >
                                                    <FaAngleLeft />
                                                </S.IButton>
                                                <p style={{ margin: 0, padding: 0, width: '100%' }}>
                                                    {`[${index + 1}]`} : {item.description}
                                                </p>
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        UpPositionLabels(Number(index), Number(index + 1));
                                                    }}
                                                    disabled={arrayLabels.length === 1 ? true : index === arrayLabels.length - 1 ? true : false}
                                                >
                                                    <FaAngleRight />
                                                </S.IButton>
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        CloneLabels(item, index);
                                                    }}
                                                >
                                                    <FaRegClone />
                                                </S.IButton>
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        RemoverLabels(index);
                                                    }}
                                                >
                                                    <FaRegTimesCircle />
                                                </S.IButton>
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        setIndexLabelSelect(index);
                                                        setValuesEdit(item.description);
                                                        collapseOneOpenLabeListCreate(true);
                                                    }}
                                                >
                                                    <FaPen />
                                                </S.IButton>
                                            </div>
                                        );
                                    })}
                                </S.ListLabels>
                            </S.AreaLabels>
                        </Fragment>
                    ) : null}

                    {/* <br />

                    {clip ? (
                        <BsLockFill
                            onClick={() => {
                                setClip(!clip);
                            }}
                            size={25}
                            color="#31AF89"
                            style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <BsUnlockFill
                            onClick={() => {
                                setClip(!clip);
                            }}
                            size={25}
                            style={{ cursor: 'pointer' }}
                        />
                    )} */}

                    <S.FramesArea style={{ marginTop: 60 }}>
                        <div
                            className="scroll"
                            style={{
                                width: '81%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexDirection: 'row',
                                overflowX: 'scroll',
                                overflowY: 'hidden'
                            }}
                        >
                            {arrayImagensNoMark.map((item, index) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                minWidth: 140,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginRight: 20,
                                                marginLeft: 20
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <S.IButton
                                                    radius="left"
                                                    style={{ width: 35, height: 120 }}
                                                    onClick={() => {
                                                        UpPositionNoMark(Number(index), Number(index - 1));
                                                    }}
                                                    disabled={index === 0}
                                                >
                                                    <FaAngleLeft />
                                                </S.IButton>
                                            </div>
                                            <S.ImageList
                                                style={{ objectFit: 'cover' }}
                                                src={URL.createObjectURL(item)}
                                                onClick={() => {
                                                    setImageToMark(item);
                                                    setCont(1);

                                                }}
                                            />
                                            <div
                                                style={{
                                                    height: 120,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <S.IButton
                                                    radius="top"
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        UpPositionNoMark(Number(index), Number(index + 1));
                                                    }}
                                                    disabled={
                                                        arrayImagensNoMark.length === 1
                                                            ? true
                                                            : index === arrayImagensNoMark.length - 1
                                                                ? true
                                                                : false
                                                    }
                                                >
                                                    <FaAngleRight />
                                                </S.IButton>
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        CloneFileNoMark(item, index);
                                                    }}
                                                >
                                                    <FaRegClone />
                                                </S.IButton>
                                                <S.IButton
                                                    radius="botton"
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        RemoverFileNoMark(index);
                                                    }}
                                                >
                                                    <FaRegTimesCircle />
                                                </S.IButton>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </S.FramesArea>

                    <S.FramesArea>
                        <div
                            className="scroll"
                            style={{
                                width: '81%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexDirection: 'row',
                                overflowX: 'scroll',
                                overflowY: 'hidden'
                            }}
                        >
                            {arrayImagensMark.map((item, index) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                minWidth: 140,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginRight: 20,
                                                marginLeft: 20
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <S.IButton
                                                    radius="left"
                                                    style={{ width: 35, height: 120 }}
                                                    onClick={() => {
                                                        UpPositionMark(Number(index), Number(index - 1));
                                                    }}
                                                    disabled={index === 0}
                                                >
                                                    <FaAngleLeft />
                                                </S.IButton>
                                            </div>
                                            <S.ImageList
                                                style={{ objectFit: 'cover' }}
                                                src={URL.createObjectURL(item)}
                                                onClick={() => {
                                                    setIndexLabelSelect(index);
                                                    collapseOneOpenLabel(true);
                                                    setImgModal(URL.createObjectURL(item))

                                                }}
                                            />
                                            <div
                                                style={{
                                                    height: 120,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <S.IButton
                                                    radius="top"
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        UpPositionMark(Number(index), Number(index + 1));
                                                    }}
                                                    disabled={
                                                        arrayImagensMark.length === 1 ? true : index === arrayImagensMark.length - 1 ? true : false
                                                    }
                                                >
                                                    <FaAngleRight />
                                                </S.IButton>
                                                {/* <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        CloneFileMark(item, index);
                                                    }}
                                                >
                                                    <FaRegClone />
                                                </S.IButton> */}
                                                <S.IButton
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        RemoverFileMark(index);
                                                    }}
                                                >
                                                    <FaRegTimesCircle />
                                                </S.IButton>
                                                <S.IButton
                                                    radius="botton"
                                                    style={{ width: 35 }}
                                                    onClick={() => {
                                                        setIndexLabelSelect(index);
                                                        collapseOneOpenLabel(true);
                                                        setImgModal(URL.createObjectURL(item))
                                                    }}
                                                >
                                                    <FaPen />
                                                </S.IButton>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </S.FramesArea>
                </Fragment>
            ) : null}

            {ModalNumberMark()}
            {ModalEditLabels()}
            {ModalEditLabelsList()}
            {ModalEditLabelsListCreate()}
        </Fragment>
    );
};
