import React, { Fragment, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import './styles.scss';

import CelusApi from '../../server/celusApi';
import Cookies from 'js-cookie';

const FeedSideBar = () => {
  const api = CelusApi();
  const history = useHistory();
  const [results, setResults] = React.useState([]);

  const [colorSelect, setColorSelect] = React.useState('#50D19A');
  const [colorDisable, setColorDisable] = React.useState('#F3F3F3');

  const [catView, setCatView] = React.useState('T');
  const [refBgTitle, setRefBgTitle] = React.useState('');

  const [selectSub, setSelectSub] = React.useState('');
  const [selectRepo, setSelectRepo] = React.useState('');

  const { type } = useParams();

  useEffect(() => {
    getGroupedReporter();
     }, []);


  const getGroupedReporter = async () => {
    setCatView('T');
    var res = await api.groupedReports();
    setResults(res.result);
  }
  const getGroupedReporterUser = async () => {
    setCatView('M');
    var res = await api.groupedReportsUser(Cookies.get('clientId'));
    setResults(res);
  }


  const openCollapse = (name) => {
    if (refBgTitle === name) {
      setRefBgTitle('');
    } else {
      setRefBgTitle(name);
    }
  }

  const openReports = (name, id) => {
    if (selectSub === name) {
      setSelectSub('');
    } else {
      setSelectSub(name);

    }
  }

  const selectRepos = (name, id) => {
    if (selectRepo === name) {
      setSelectRepo('');
    } else {
      setSelectRepo(name);
    }
    history.push(`/to-see/laudus/${id}`)
  }

  return (
    <Fragment>
      <div className="content-menu">

        <div style={{ display: 'flex' }}>

          <button
            className="button-header-left"
            style={{
              color: catView === 'T' ? '#FFFFFF' : '#9F9F9F',
              background: catView === 'T' ? colorSelect : colorDisable,
              fontSize: 17
            }}
            onClick={() => {
              getGroupedReporter()
            }}
          >
            Todos
          </button>

          <button
            className="button-header-right"
            style={{
              color: catView === 'M' ? '#FFFFFF' : '#9F9F9F',
              background: catView === 'M' ? colorSelect : colorDisable,
              fontSize: 17
            }}
            onClick={() => {
              getGroupedReporterUser()
            }}
          >

            Meus LaudUS

          </button>
        </div>

        <div>
          {
            results.map((item, index) => {
              let subareas = item.subareas;
              return (
                <div key={index}>

                  <button
                    className="button-area"
                    onClick={() => {
                      openCollapse(item.name)
                    }}
                    style={{
                      fontWeight: refBgTitle === item.name ? 'bold' : 'normal',
                      background: refBgTitle === item.name ? '#EBEBEB' : '#FFFFFF',
                      borderBottom: refBgTitle === item.name ? 0 : '1px solid #EBEBEB',
                    }}
                  >
                    {item.name}
                  </button>

                  <Collapse isOpen={refBgTitle === item.name ? true : false} id={item.id}>
                    {
                      subareas.map((subItens, index) => {
                        var reports = subItens.reports;
                        return (
                          <div key={index}>
                            <button
                              onClick={() => { openReports(subItens.name, subItens.id) }}
                              className="button-subarea"
                              style={{
                                background: '#F9F9F9'
                              }}
                              id={subItens.id}
                            >
                              <div className="sub-itens">
                                <p
                                  className="p"
                                  style={{
                                    color: selectSub === subItens.name ? '#31AF89' : '#000000'
                                  }}>{subItens.name} </p>

                                <p className="length-results" >
                                  {subItens.reports.length < 10 ? '0' + subItens.reports.length : subItens.reports.length}
                                </p>
                              </div>

                            </button>

                            <Collapse
                              isOpen={selectSub === subItens.name ? true : false} id={item.id}
                              className="sd-bar"
                              style={{ overflow: subItens.reports.length > 5 ? 'scroll' : 'hidden ' }}
                            >
                              {
                                reports.map((subItens, index) => {
                                  return (
                                    <button
                                      key={index}
                                      onClick={() => { selectRepos(subItens.id, subItens.id) }}
                                      className="button-results"
                                      id={subItens.id}>
                                      <div >
                                        <div style={{ justifyContent: 'start' }} >
                                          <p
                                            className="text-results"
                                            style={{
                                              color: selectRepo === subItens.id ? '#31AF89' : '#000000'
                                            }}>{subItens.title}</p>
                                        </div>
                                      </div>
                                    </button>
                                  )
                                })
                              }
                            </Collapse>
                          </div>
                        )
                      })
                    }
                  </Collapse>
                </div>
              )
            })
          }
        </div>
      </div>
    </Fragment>
  )
};

export default FeedSideBar;
