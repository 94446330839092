import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';

import './server/firebase';
import './globals.scss';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import Reducers from './Reducers';

const store = createStore(Reducers);

ReactDOM.render(
  <Main>
    <Provider store={store}>
      <App />
    </Provider>
  </Main>,
  document.getElementById('main')
);
