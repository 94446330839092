import styled from 'styled-components';

export const Title = styled.span`
  color: #000;
  font-size: 32px;
  font-weight: bolder;
`;

export const SubTitle = styled.span`
  color: #000;
  font-size: 22px;
`;

export const DetailsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const AuthorAvatar = styled.img`
  width: 61px;
  height: 61px;
  border-radius: 100%;
  border: 1px solid #33B18A;
  margin: 0px 3px;
`;

export const AuthorName = styled.span`
  color: #33B18A;
  font-size: 14px;
  font-weight: 600;
`;

export const DefaultText = styled.span`
  color: #9F9F9F;
  font-size: 14px;
  margin: 0px 3px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterAvatar = styled.img`
  width: 89px;
  height: 89px;
  border-radius: 100%;
  border: 1px solid #33B18A;
  margin: 0px 3px;
`;

export const AuthorArea = styled.div`
  display: flex;
`;

export const FooterAuthor = styled(AuthorName)`
  font-size: 16px;
`;

export const UpButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #33B18A;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReferencesToggle = styled.div`
  width: 100%;
  padding: 10px 0;
  background: #31AF89;
  border-radius: 5px 10px 0  0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`;

export const CommentsToggle = styled(ReferencesToggle)`
`;

//Related Component
export const RelatedTitle = styled.h3`
  font-size: 19px;
  font-weight: 600;
  color: #000;
  margin-top: 20px;
`;

export const RelatedArea = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ColumnRelated = styled.div`
  display: flex;
  align-items: flex-start;
  width: 48%;
  max-width: 375px;
  max-height: 170px;
  margin-bottom: 20px;
`;

export const RelatedCard = styled.div`
  width: 100%;
  height: 170px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 5px #00000029;
  position: relative;
`;

export const TopSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BottomSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RelatedCardTitle = styled(Title)`
  font-size: 19px;
  @media screen and (max-width: 720px){
    font-size: 16px;
  }
`;

export const RelatedAuthor = styled(DefaultText)`
  margin: 0px;
`;

export const Date = styled(DefaultText)`
  margin: 0px;
`;

export const ReadingTime = styled(DefaultText)`
  margin-right: 2px;
`;

export const ThumbnailImg = styled.img`
  width: 93px;
  height: 101px;
  border-radius: 15px;
  border: 1px solid #000;
`;

export const IconsArea = styled.div`
  width: 93px;
  right: 10px;
  bottom: 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  `;

export const Icon = styled.img`
  width: 23px;
  height: auto;
`;
