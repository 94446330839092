import React from 'react';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import FeedContent from './FeedContent';
import FeedSideBarResumus from './FeedSideBarResumus';
import FeedProvider from './FeedProvider';
import NavFeed from './NavFeed';

export const FeedResumus = () => {

  return (
    <FeedProvider style={{ margin: 0 }}>
      <ContentWithAsideLayout aside={<FeedSideBarResumus />} isStickyAside={false}>
        <NavFeed />
        <FeedContent/>
      </ContentWithAsideLayout>
    </FeedProvider>
  )
};

