import React from 'react';
import { Row, Col} from 'reactstrap';
import ReviewResumus from '../feed/ReviewResumus';
import ApproveSideBarResumus from '../feedUS/ApproveSideBarResumus';

const FormReviewLaudus = () => {

  return (
    <>
      <Row>
        <Col lg={8} className="pl-lg-2">
          
          <ReviewResumus/>
        </Col>
        <Col lg={4} className="pr-lg-2 mb-3 mb-lg-0">
          <ApproveSideBarResumus />
        </Col>
      </Row>

    </>
  );
};

export default FormReviewLaudus;
