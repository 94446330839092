import React from 'react';
import { NavItem, NavLink } from 'reactstrap';

const CartNotification = () => {
  return (
    <NavItem>
      <NavLink>
      </NavLink>
    </NavItem>
  );
};

export default CartNotification;
