import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import * as Scroll from "react-scroll";
import ReactHtmlParser from "react-html-parser";
import {
  Like,
  User,
  LikeGreen,
  ArrowUp,
} from "../../assets/img/icons/celus/index";
import * as S from "./style/resumus.js";
import CommentCard from "../CommentCard/CommentCard";
import PostCommentCard from "../CommentCard/PostCommentCard";
import "./view_styles.scss";
import CelusApi from "../../server/celusApi";
import { FaBookmark, FaShareAlt, FaRegBookmark } from "react-icons/fa";
import Cookies from "js-cookie";
import { LoadingViewResumus } from "../LoadingViewResumus";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useCommentRefresh } from "../../hooks/useCommentUpdate";

const FeedCard = () => {
  const api = CelusApi();
  const doc = useParams();
  const clientId = parseInt(Cookies.get("clientId"));

  const [resumu, setResumu] = useState();
  const [valuesAuthor, setValuesAuthor] = useState([]);
  const [valuesComments, setValuesComments] = useState([]);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(true);
  const { refresh } = useCommentRefresh();

  useEffect(() => {
    GetResumusByDoc();
  }, [refresh]);

  const GetResumusByDoc = async (noLoad) => {
    if (!noLoad) setLoading(true);

    try {
      const result = await api.getResumusByDoc(doc.id);

      const meses = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];

      let auxDate = new Date(result.result.createdAt);

      let newDateFormat =
        auxDate.getDate() +
        " " +
        meses[auxDate.getMonth()] +
        " " +
        auxDate.getFullYear();

      setDate(newDateFormat);

      setValuesAuthor(result.result.author);
      setValuesComments(result.result.comments);
      setResumu(result.result);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      console.log(error);
    }
  };

  const isFavorite = resumu?.favorite?.includes(clientId) ?? false;
  const isLiked = resumu?.likes?.includes(clientId) ?? false;
  const [isReferencesOpen, setIsReferencesOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);

  const saveResumus = async () => {
    const json = {
      docId: doc.id,
      uid: clientId,
    };

    try {
      await api.postFavoriteResumus(json);
      await GetResumusByDoc(true);
    } catch (error) {
      console.log(error);
    }
  };

  const postLike = async () => {
    const json = {
      docId: doc.id,
      uid: clientId,
    };

    await api.postLike(json);
    await GetResumusByDoc(true);
  };

  const toggleReferences = () => {
    setIsReferencesOpen(!isReferencesOpen);
  };

  const toggleComments = () => {
    setIsCommentsOpen(!isCommentsOpen);
  };

  String.prototype.stripHTML = function () {
    return this.replace(/<.*?>/g, "");
  };

  return (
    <>
      <Card className={`item-card`} style={{ cursor: "default" }} id="hero">
        {loading || !resumu ? (
          <Fragment>
            <LoadingViewResumus />
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <S.Title>{resumu.title}</S.Title>
                <Link to={"/resumus/review/" + doc.id} style={{ width: 100 }}>
                  <p
                    style={{
                      color: "#50D19A",
                      fontSize: 19,
                      fontWeight: "bold",
                    }}
                  >
                    Revisar
                  </p>
                </Link>
              </div>
              <div style={{ marginBottom: 30 }}>
                <S.SubTitle>{resumu.subtitle}</S.SubTitle>
              </div>
              <S.DetailsArea>
                <S.AuthorAvatar
                  src={valuesAuthor.photo ? valuesAuthor.photo : User}
                />
                <S.DefaultText>Escrito por:</S.DefaultText>
                <S.AuthorName>{valuesAuthor.name}</S.AuthorName>
                <S.DefaultText>-</S.DefaultText>
                <S.DefaultText>{date}</S.DefaultText>
              </S.DetailsArea>

              <div style={{ marginBottom: "30px", marginTop: "60px" }}>
                {/* <h5 style={{ fontSize: 19, color: '#50D19A', marginBottom: 15 }}>Descrição</h5> */}
                <div className="desc-img">
                  {ReactHtmlParser(resumu.content)}
                </div>
              </div>

              <div
                style={{
                  borderTop: "1px solid #EBEBEB",
                  borderBottom: "1px solid #EBEBEB",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "center",
                    padding: "20px 0",
                  }}
                >
                  <div className="like">
                    <img
                      src={isLiked ? LikeGreen : Like}
                      style={{ width: 23, marginRight: 5 }}
                      onClick={() => postLike()}
                    />
                    <p
                      style={{
                        color: "#000",
                        fontSize: 14,
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      {resumu?.likes.length}{" "}
                      {Number(resumu?.likes.length) > 1
                        ? "curtidas"
                        : "curtida"}
                    </p>
                  </div>

                  <div className="like">
                    {/* <FaCopy transform="shrink-4" className="fs-2 footer-icon" color='#344050' style={{ marginRight: 15 }} /> */}

                    {!isFavorite ? (
                      <FaRegBookmark
                        transform="shrink-4"
                        className="fs-2 footer-icon"
                        color="#344050"
                        style={{ marginRight: 15 }}
                        onClick={() => saveResumus()}
                      />
                    ) : (
                      <FaBookmark
                        transform="shrink-4"
                        className="fs-2 footer-icon"
                        color="#31AF89"
                        style={{ marginRight: 15 }}
                        onClick={() => saveResumus()}
                      />
                    )}
                    <FaShareAlt
                      transform="shrink-4"
                      className="fs-2 footer-icon"
                      color="#344050"
                      onClick={() => {
                        navigator.share({
                          title: "Share",
                          text: "whatevs",
                          url: `/to-see/resumus/${doc.id}`,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <S.Footer style={{ padding: "25px 0" }}>
                <S.AuthorArea>
                  <S.FooterAvatar
                    src={valuesAuthor.avatar ? valuesAuthor.avatar : User}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                  >
                    <h5 style={{ fontSize: 17 }}>Escrito por</h5>
                    <S.FooterAuthor>{valuesAuthor.name}</S.FooterAuthor>
                  </div>
                </S.AuthorArea>
                <Scroll.Link to="hero" smooth="true" duration={500}>
                  <S.UpButton>
                    <img src={ArrowUp} />
                  </S.UpButton>
                </Scroll.Link>
              </S.Footer>
            </div>
          </Fragment>
        )}
      </Card>

      {loading ? null : (
        <Fragment>
          <div style={{ marginBottom: 20 }}>
            <S.ReferencesToggle
              onClick={() => toggleReferences()}
              style={{ marginTop: 20 }}
            >
              Referências Bibliográficas
            </S.ReferencesToggle>
            {isReferencesOpen && (
              <Fragment>
                <div className="content-ref">
                  <ul>
                    {resumu?.references?.map((item, index) => {
                      return (
                        <li>
                          <p className="ref-item">
                            {" "}
                            {`${index + 1 + " ." + item}`}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}

      <S.CommentsToggle onClick={() => toggleComments()}>
        Comentários ({valuesComments?.length})
        {!isCommentsOpen ? (
          <IoIosArrowDown size={18} style={{ marginLeft: "10px" }} />
        ) : (
          <IoIosArrowUp size={18} style={{ marginLeft: "10px" }} />
        )}
      </S.CommentsToggle>

      {isCommentsOpen && (
        <div>
          {resumu?.comments.map((item, index) => {
            return (
              <CommentCard
                key={index}
                comment={item}
                docId={doc.id}
                reloadResumu={() => GetResumusByDoc(true)}
              />
            );
          })}
          <PostCommentCard docId={doc.id} />
        </div>
      )}

      {/* <S.CommentsToggle onClick={() => toggleComments()}>
        Comments ({valuesComments.length})
      </S.CommentsToggle>

      {isCommentsOpen &&
        <div>
          {valuesComments.map((item, index) => {
            return (
              <p key={index}>{item.comments.content}</p>
            )
          })}</div>} */}

      {/* <S.RelatedTitle>Leituras relacionadas</S.RelatedTitle>
      <Divider color="#31AF89" />

      <S.RelatedArea>
        {
          relatedCardData.map((item, key) => {
            return (
              <S.ColumnRelated key={key}>
                <ResumusRelated
                  title={item.title}
                  author={item.author}
                  date={item.date}
                  readingTime={item.readingTime}
                  thumb={item.thumb}
                />
              </S.ColumnRelated>
            )
          })
        }
      </S.RelatedArea> */}
    </>
  );
};

FeedCard.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  user: PropTypes.object,
  className: PropTypes.string,
};

export default FeedCard;
