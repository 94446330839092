import { version } from "./config";

export const homeRoutes = {
  name: "Início",
  to: "/dashboard",
  exact: true,
  icon: "home",
};

export const profileRoutes = {
  name: "Usuários",
  to: "/dashboard",
  exact: true,
  icon: "user",
  children: [
    // { to: '/list/table/laudus', name: 'Todos os usuários' },
    { to: "/all/users", name: "Todos os usuários" },
    { to: "/add/user", name: "Adicionar usuário" },
    { to: "/profile/user", name: "Perfil" },
    // { to: '/list/table/laudus', name: 'Perfil' },
  ],
};

export const laudusRoutes = {
  name: "LaudUS",
  to: "/laudus",
  exact: true,
  icon: "list",
  children: [
    { to: "/especialidades/laudus", name: "LaudUS Publicados" },
    { to: "/laudus/new", name: "Criar LaudUS" },
    { to: "/list/table/laudus", name: "Todos os LaudUS" },
    { to: "/laudus/list", name: "Meus LaudUS" },
    { to: "/my-laudus/draft", name: "Meus Rascunhos" },
  ],
};

export const configRoutes = {
  name: "Configuração",
  to: "/tools",
  exact: true,
  icon: "tools",
};

export const pushNotification = {
  name: "Notificação",
  to: "/notifications/push",
  exact: true,
  icon: "push",
};

export const resumusRoutes = {
  name: "ResumUS",
  to: "/resumus",
  exact: true,
  icon: "book",
  children: [
    { to: "/especialidades/resumus", name: "ResumUS Publicados" },
    { to: "/resumus/new", name: "Criar ResumUS" },
    { to: "/list/table/resumus", name: "Todos os ResumUS" },
    { to: "/resumus/list", name: "Meus ResumUS" },
    { to: "/my-resumus/draft", name: "Meus Rascunhos" },
  ],
};

export const logoutProtoculus = {
  name: "ProtocolUS",
  to: "/protocolus",
  exact: true,
  icon: "protoculus",
  children: [
    { to: "/protocolus", name: "ProtocolUS Publicados" },
    { to: "/create/protocolus", name: "Criar ProtocolUS" },
  ],
};

export const anatomusRoutes = {
  name: "Anatomus",
  to: "/anatomus",
  exact: true,
  icon: "buffer",
  children: [
    // { to: '/resumus/new', name: 'Criar ResumUS' },
    // { to: '/list/table/resumus', name: 'Revisar ResumUS' },
    // { to: '/resumus/list', name: 'Meus ResumUS' },
    { to: "/anatomus/new", name: "Criar Anatomus" },
    { to: "/anatomus", name: "Anatomus Publicados" },
  ],
};

export const deleteTrash = {
  name: "Limpar testes",
  to: "/clear-tests",
  exact: true,
  icon: "trash",
};

export const searchesNotFound = {
  name: "Pesquisas",
  to: "/searches-not-found",
  exact: true,
  icon: "search",
};

export const filterAreasAmount = {
  name: "Areas",
  to: "/filter-areas-amount",
  exact: true,
  icon: "search",
};

export const analytics = {
  name: "Analytics",
  to: "/analytics",
  exact: true,
  icon: "chart",
};

export const logoutRoutes = {
  name: "Sair",
  to: "/authentication/logout",
  //Apenas mandando para a página de login, sem fazer o logout
  exact: true,
  icon: "logout",
};

const routesExportHml = [
  homeRoutes,
  profileRoutes,
  laudusRoutes,
  resumusRoutes,
  logoutProtoculus,
  anatomusRoutes,
  configRoutes,
  pushNotification,
  deleteTrash,
  analytics,
  logoutRoutes,
];

const routesExportProd = [
  homeRoutes,
  profileRoutes,
  laudusRoutes,
  resumusRoutes,
  logoutProtoculus,
  configRoutes,
  pushNotification,
  analytics,
  logoutRoutes,
];

export default process.env.REACT_APP_PROJECT_ID === "celus-hml"
  ? routesExportHml
  : routesExportProd;
