import React, { useState, Fragment, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Media,
  Input,
  Form,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { GiSmartphone } from "react-icons/gi";
import ReactHtmlParser from 'react-html-parser';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Dots } from 'react-activity';
import 'react-activity/dist/library.css';
import Cookies from 'js-cookie';
import ReactDevicePreview from 'react-device-preview';
import ModalImg from '../../assets/img/celus/resumus/resumus-modal-img.png';
import { toast } from 'react-toastify';

import CelusApi from '../../server/celusApi';
import FalconCardHeader from '../common/FalconCardHeader';
import ImageUploading from "react-images-uploading";
import { Editor } from '../Editor';

import './styles.scss';
import * as S from './css/components';
import './css/create-resumus.css';
import { Up } from '../../assets/img/icons/celus/index';
import { Link, useHistory } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import { ImHome2 } from 'react-icons/im';
import PreviewMobileLaudus from '../PreviewMobileLaudus';

const PostCreateLaudus = () => {

  const api = CelusApi();

  const [collapseOne, collapseOneOpen] = useState(false);
  const [content, setContent] = useState('');
  const [content1, setContent1] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);

  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [specialtie, setSpecialtie] = useState();
  const [subArea, setSubArea] = useState();
  const [title, setTitle] = useState('');
  const [images, setImages] = React.useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [errs, setErrs] = useState({
    title: '',
    description: '',
    conclusion: '',
    images: '',
    subArea: '',
    specialtie: '',
  });

  useEffect(() => {
    GetSpecialties();
     }, []);


  useEffect(() => {
    GetSubAreas();
  }, [specialtie])

  const onChange = (imageList) => {
    setImages(imageList);
  };

  const GetSpecialties = async () => {
    try {
      const res = await api.listAreas('laudus');
      setSpecialties(res);
    } catch (error) {
      console.log(error);
    }
  }

  const GetSubAreas = async () => {
    try {
      const res = await api.getListSubAreas({specialtie: specialtie, type: 'laudus'});
      setSubAreas(res);
    } catch (error) {
      console.log(error);
    }
  }

  const Description = () => {
    return (
      <Fragment>
        <FormGroup>
          <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Descrição <strong style={{ color: '#FF6766' }}>*</strong> </Label>
          <Editor setValues={setContent} values={content} />
          {(error === true && !content) ? <S.errorMessage >{errs.description}</S.errorMessage > : null}
          <Label>Descreva acima como lauda a patologia</Label>
        </FormGroup>
      </Fragment>
    )
  }

  const Conclusion = () => {
    return (
      <Fragment>
        <FormGroup>
          <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Conclusão <strong style={{ color: '#FF6766' }}>*</strong> </Label>
          <Editor setValues={setContent1} values={content1} />
          {(error === true && !content1) ? <S.errorMessage >{errs.conclusion} </S.errorMessage > : null}
        </FormGroup>
      </Fragment>
    )
  }

  const CreateModal = () => {
    return (

      // toggle={() => {
      //   setIsCreateModalOpen(!isCreateModalOpen)
      //   setLoading(false);
      // }}
      
      <Modal isOpen={isCreateModalOpen} >
        <ModalHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div>
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>LaudUS enviado com sucesso!</span>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '15px 45px',
          }}>
          <img src={ModalImg} style={{ maxWidth: '200px', height: "auto", marginBottom: '20px' }} />

          <div>
            <p style={{ width: '100%', textAlign: 'center' }}>LaudUS Publicado</p>
          </div>
        </ModalBody>
        <ModalFooter style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '15px',
          borderTop: 'none',
          paddingBottom: '20px',
        }}>
          <Button
            style={{ background: '#50D19A', border: 0, padding: '10px 15px' }}
            onClick={newLaudus}
          >
            Criar novo laudUS
          </Button>
          <Link style={{
            background: '#fff',
            border: '1px solid #50D19A',
            color: '#50D19A',
            padding: '10px 15px',
            borderRadius: '5px',
          }} to={'/laudus/list'}>
            Ver laudUS
          </Link>

        </ModalFooter>
      </Modal>
    );
  }

  const ValidationModal = () => {
    return (
      <Modal isOpen={isValidationModalOpen} toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}>
        <ModalHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div>
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>Ops! Alguns campos estão incompletos</span>
          </div>
        </ModalHeader>
        <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px' }}>
            {/* <S.ValidateModalRow>
              <span>{!errs.images ? <S.CheckIcon checked /> : <S.CheckIcon />} Imagem</span>
            </S.ValidateModalRow> */}

            <S.ValidateModalRow>
              <span>{!errs.title ? <S.CheckIcon checked /> : <S.CheckIcon />} Titulo</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.description ? <S.CheckIcon checked /> : <S.CheckIcon />} Descrição</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.conclusion ? <S.CheckIcon checked /> : <S.CheckIcon />} Conclusão</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.specialtie ? <S.CheckIcon checked /> : <S.CheckIcon />} Especialidade</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.subArea ? <S.CheckIcon checked /> : <S.CheckIcon />} Categoria</span>
            </S.ValidateModalRow>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button block className="modal-save" onClick={() =>
            setIsValidationModalOpen(!isValidationModalOpen)}
          >
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const newLaudus = () => {
    window.location.href = '/laudus/new'
  }

  const PostLaudus = async (isDraft) => {
    setLoading(true);
    if (title && content && content1 && subArea && specialtie
      //  && images.length > 0
    ) {
      
      const data = new FormData();

      data.append('title', title);
      data.append('description', content);
      data.append('conclusion', content1);
      data.append('subareaId', parseInt(subArea));
      data.append('areaId', parseInt(specialtie));
      data.append('uid', Cookies.get('clientId'));
      data.append('isDraft', isDraft);

      if (images.length > 0) {
        for (let index = 0; index < images.length; index++) {
          data.append('file', images[index].file);
        }
      }

      try {
        await api.postLaudus(data).then(() => {
          toast.success(`LaudUS Criado!`);

          if (isDraft === true) {
            toast.success(`LaudUS salvo como rascunho!`);

            setLoading(false);

            setIsDraftModalOpen(!isDraftModalOpen);
            setTimeout(() => {
              history.push('/my-laudus/draft');
            }, 500);
          } else {
            setIsCreateModalOpen(!isCreateModalOpen);
            setLoading(false);

          }

          setLoading(false);

        }).catch(() => {
          toast.error(`Opa! Não foi possivel criar o LaudUS no momento!`);
          setLoading(false);

        });


      } catch (error) {
        toast.error(`Opa! Não foi possivel criar o LaudUS no momento!`);
        console.log(error);
        setLoading(false);

      }
    } else {

      if (!title) {
        errs.title = 'O campo titulo é Obrigatorio!'
      } else {
        errs.title = null
      };
      if (!content) {
        errs.description = 'O campo Descrição é Obrigatorio!'
      } else {
        errs.description = null
      };
      if (!content1) {
        errs.conclusion = 'O campo Conclusão é Obrigatorio!'
      } else {
        errs.conclusion = null
      };
      if (!subArea) {
        errs.subArea = 'O campo Categorias é Obrigatorio!'
      } else {
        errs.subArea = null
      };
      if (!specialtie) {
        errs.specialtie = 'O campo Especialidade é Obrigatorio!'
      } else {
        errs.specialtie = null
      };
      // if (images.length ===0) {
      //   errs.images = 'Nenhuma Imagem selecionada!'
      // } else {
      //   errs.images = null
      // };

      setErrs({ ...errs });

      setLoading(false);
      setError(true);
      setIsValidationModalOpen(true);
    }
  }



  const saveAsDraft = async (status) => {
    setIsDraftModalOpen(!isDraftModalOpen);
    // setTimeout(function(){ setIsDraftModalOpen(false); }, 3000);
    setTimeout(function () {
      document.getElementById('draft-alert').classList.add('draft-alert-out');
      document.getElementById('draft-alert-span').classList.add('draft-alert-out');
    }, 4000);
    setTimeout(function () {
      setIsDraftModalOpen(false);

    }, 3000);
  }

  const cls = () => {
    window.location.href = '/laudus/new';
    collapseOneOpen(!collapseOne);
  }

  const me = () => {
    window.location.href = '/my-laudus/list';
    collapseOneOpen(!collapseOne);
  }

  const handleEditorChange = (e) => {

  }

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={
          <Fragment>
            <Media body className="ml-2" style={{ width: '100%' }}>
              <h5 className="mb-0 fs-0" style={{ fontSize: 19 }}>Criar novo LaudUS</h5>
            </Media>
          </Fragment>
        }
        titleTag={Media}
        titleClass="align-items-center"
      />

      <div style={{ padding: 30 }}>
        <p style={{ fontSize: 17, fontWeight: 'normal', color: '#3C3C3C', width: '100%' }}>
          Compartilhe conosco sua experiência, crie um formato de como lauda de uma achado
          ultrassonográfico de uma patologia.
        </p>
        <CardBody className="p-0" >
          <div
            style={{
              height: '270px',
              border: '1px dashed #739AD1',
              borderRadius: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              background: '#EDF2F9',
              margin: '15px 0',
              padding: '15px',
            }}
          >
            {images.length === 0 ?
              <>
                <img src={Up} style={{ width: '50px' }} />
                <p style={{ color: '#9F9F9F', fontSize: '17px', fontWeight: 'normal', textAlign: 'center' }} >
                  Adicione imagens para melhor exemplificar a patologia para o leitor.
                </p>
              </>
              :
              ""
            }

            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageRemove,
                onImageUpload,
                dragProps
              }) => (
                // write your building UI
                <div
                  className="upload__image-wrapper"
                  id="images_area"
                >
                  <button
                    id="upd_btn"
                    onClick={(e) => {
                      if (e.target.id === 'upd_btn') {
                        onImageUpload()
                      }
                    }}
                    style={{
                      color: '#9F9F9F',
                      fontSize: '17px',
                      fontWeight: 'normal',
                      border: '1px solid #9F9F9F',
                      padding: '5px',
                      borderRadius: '5px',
                      fontSize: '15px',
                      textAlign: 'center',
                      maxWidth: 205,
                      background: 'transparent',
                    }}
                    {...dragProps}
                  >
                    Selecionar arquivos
                  </button>
                  <div className="imgs-col" style={{ justifyContent: images.length > 3 ? 'flex-start' : 'center' }}>
                    {
                      imageList.map((image, index) => (
                        <div key={index} className="drop-images">
                          <div className="image-item__btn-wrapper">
                            {/* <button onClick={() => onImageRemove(index)}>X</button> */}
                            <div className="trash-btn" id="remove_btn" onClick={(e) => {
                              onImageRemove(index)
                            }} >
                              <AiFillDelete />
                            </div>
                          </div>
                          <img src={image.data_url} alt="" className="drop-image" />
                        </div>
                      ))
                    }
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>

          {(error === true && images.length <= 0) ? <S.errorMessage >{errs.images} </S.errorMessage > : null}
          <Form>
            <FormGroup>
              <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Título <strong style={{ color: '#FF6766' }}>*</strong> </Label>
              <Input type="text" name="name" id="exampleName" placeholder="Título" value={title} maxLength="100" onChange={e => { setTitle(e.target.value) }} disabled={loading} />
              {(error === true && !title) ? <S.errorMessage >{errs.title}</S.errorMessage > : null}
              
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                <Label>Adicione o nome da patologia</Label>
                <S.LabelForm style={{ fontSize: 13 }}>
                  <strong style={{ color: title.length > 70 && title.length !== 100 ? "#ff8c00" : title.length===100 ? '#FF6766' : '#9c9a9a' }}>Número de caracteres: [{`${title.length}/100`}]</strong>
                </S.LabelForm>
              </div>
            </FormGroup>
            <input id="my-file" type="file" name="my-file" style={{ display: "none" }} onChange="" />
            {Description()}
            {Conclusion()}

            <div 
              style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                flexDirection: 'row' 
              }}
            >
              <FormGroup style={{ width: '49%' }}>
                <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Especialidade <strong style={{ color: '#FF6766' }}>*</strong></Label>
                <Input type="select" name="select" id="exampleSelect" onChange={e => { setSpecialtie(e.target.value) }} disabled={loading}>
                  <option value="" disabled selected hidden>Especialidade</option>
                  {specialties.map((item, index) => {
                    return (
                      <option key={index} value={item.id} selected={specialtie === item.id}>{item.name}</option>
                    );
                  })}
                </Input>
                {(error === true && !specialtie) ? <S.errorMessage >{errs.specialtie} </S.errorMessage > : null}

              </FormGroup>

              <FormGroup style={{ width: '49%' }}>
                <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Categoria <strong style={{ color: '#FF6766' }}>*</strong></Label>
                <Input type="select" name="select" id="exampleSelect" onChange={e => { setSubArea(e.target.value) }} disabled={loading}>
                  <option value="" disabled selected hidden>Categoria</option>
                  {subAreas.map((item, index) => {
                    return (
                      <option key={index} value={item.id} selected={subArea === item.id}>{item.name}</option>
                    );
                  })}
                </Input>
                {(error === true && !subArea) ? <S.errorMessage >{errs.subArea}</S.errorMessage > : null}
              </FormGroup>
            </div>

            <Row noGutters className="justify-content-between" style={{ alignItems: 'center', marginBottom: '30px' }}>
              <Col className="col" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <S.DraftBtn className="d-md-inline-block" onClick={() => { PostLaudus(true) }} disabled={loading} >
                    {loading ? <Dots /> : 'Salvar como rascunho'}
                  </S.DraftBtn>

                  <S.PublishBtn className="d-md-inline-block" onClick={() => { PostLaudus(false) }} disabled={loading}>
                    {loading ? <Dots /> : 'Publicar'}
                  </S.PublishBtn>
                </div>

                <S.PreviewMobileBtn isActive={isPreviewOpen} onClick={() => setIsPreviewOpen(!isPreviewOpen)}>
                  <GiSmartphone color={'#fff'} />
                </S.PreviewMobileBtn>
              </Col>
            </Row>
            {
              isPreviewOpen && (
                <Fragment>
                  <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C', width: '100%', marginBottom: '20px' }}>Pré-visualização do App</Label>
                  <PreviewMobileLaudus 
                    images={images} 
                    title={title} 
                    description={content} 
                    conclusion={content1} 
                  />
                </Fragment>
              )
            }
          </Form>
          {
            isDraftModalOpen && (
              <div className="draft-alert" id="draft-alert" style={{
                width: '100%',
                padding: '8px 30px',
                background: '#31AF89',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '6px',
                marginTop: '30px'
              }}
              >
                <IoIosCheckmarkCircle style={{ color: '#fff', fontSize: '21px' }} />
                <span id="draft-alert-span" style={{ color: '#FFF', fontSize: '17px', fontWeight: 'lighter' }}>
                  Rascunho salvo com sucesso!
                </span>
              </div>
            )
          }
        </CardBody>
      </div>
      {CreateModal()}
      {ValidationModal()}
    </Card>
  );
};

export default PostCreateLaudus;
