import { Button, Input } from "reactstrap";
import styled from "styled-components";

export const ContentArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonArea = styled.button`
  font-size: 12px;
  width: 250px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  color: #fff;
  border: none;
  margin-left: 5px;
  background-color: ${(props) => (props.show ? "#31AF89" : "#FF6766")};
  transition: 0.5s;
  &:hover {
    opacity: 0.7;
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

export const TextInput = styled(Input)`
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d8e2ef;
  margin: 20px 0;
`;
