import React, { Fragment, useEffect, useState } from 'react';
import { Card, InputGroup, Input, CardHeader } from 'reactstrap';
import CelusApi from '../../server/celusApi';
import './styles.scss';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { Doughnut, Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import * as S from './styles.js';
import celusApi from '../../server/celusApi';
import { LoadingCard } from '../LoadingCard';
import { LoadingCardRanking } from './LoadingCardRanking';


export const ContentRanking = () => {
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [content, setContent] = useState();
    const history = useHistory();

    useEffect(() => {
        getContentRanking()
    }, [filter]);

    const getContentRanking = async () => {
        try {
            setLoading(true);
            const response = await celusApi().getContentRanking(filter);
            setContent(response.result);
         } catch (error) {
             console.log(error)
         } finally {
            setLoading(false);
         }
    }

    return (
        <Fragment>
            <Card>
                <CardHeader style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5 style={{ marginBottom: '20px' }} className="mb-0">Conteúdos com mais acessos no app</h5>
                    <InputGroup style={{ width: '15%' }}>
                        <Input type="select" placeholder="Filtrar" onChange={e => { setFilter(e.target.value.toLocaleLowerCase()) }}>
                            {/* <option className='option' value="" disabled selected hidden>Filtrar</option > */}
                            <option className='option' value="all">Todos</option>
                            <option className='option' value="1">LaudUS</option>
                            <option className='option' value="4">AnatomUS</option>
                            {/* <option className='option' value="3">CalculUS</option> */}
                            <option className='option' value="5">ProtocolUS</option>
                            <option className='option' value="6">MedUltra</option>
                        </Input>
                    </InputGroup>
                </CardHeader>


                <S.Area>
                    {
                        !loading ? (
                            content.map((item, index) => {
                                const title = () => {
                                    if(item.report){
                                        return item.report.title
                                    }else if(item.infografico){
                                        return item.infografico.contents[0].title
                                    }else if(item.SubAgeRangeContent){
                                        return item.SubAgeRangeContent.name
                                    }
                                }
    
                                const goToContent = () => {
                                    if(item.report){
                                        history.push(`to-see/Laudus/${item.report.id}`)
                                    }else if(item.infografico){
                                        history.push(`view/protocolus/${item.infografico.id}/${item.infografico.contents[0].title}`)
                                    }else{
                                        alert('Conteúdo exclusivo no app')
                                    }
                                }
    
                                return (
                                    <S.CardContent onClick={() => goToContent()}>
                                        <S.Title>{title()}</S.Title>
                                        <S.TypeTitle>{item.functionality.name}</S.TypeTitle>
                                        <S.PositionNumber position={index + 1}>{(index + 1) + '°'}</S.PositionNumber>
                                    </S.CardContent>
                                )
                            })
                        ) : (
                            <Fragment>
                                <LoadingCardRanking />
                            </Fragment>
                        )
                        
                    }
                </S.Area>

            </Card>
        </Fragment>
    )
}
