import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import CelusApi from '../../server/celusApi';
import { ProtoculusItem } from '../ProtoculusItem';
import * as S from './styled';

export const FeedProtoculus = () => {

    const api = CelusApi();
    const [protoculus, setProtoculus] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const handlerGetProtoculus = async () => {
            try {
                setLoading(true);
                const result = await api.listProtoculus();
                setProtoculus(result);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        handlerGetProtoculus();
    }, [api]);


    return (
        <Fragment>
            {
                loading ? (null) : (
                    <Fragment>
                        {
                            protoculus.map((protocolu, index) => {
                                if (protocolu?.infograficos.length > 0) {
                                    return (
                                        <Fragment key={index}>
                                            <Card style={(index > 0 && index !== protocolu.lengt) ? { borderRadius: 0 } : index === (protocolu.length - 1) ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 5 } : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>

                                                <S.Header>
                                                    <S.IconProtoculus src={protocolu.iconBlack} />
                                                    <CardTitle>{protocolu.name}</CardTitle>
                                                </S.Header>

                                                <S.Body className="scroll__protoculus">
                                                    {
                                                        protocolu.infograficos.map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <ProtoculusItem values={item} admin={item.admin} id={item.id}/>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </S.Body>

                                                <CardFooter>

                                                </CardFooter>
                                            </Card>
                                        </Fragment>
                                    )
                                }
                            })
                        }
                    </Fragment>
                )
            }

        </Fragment>
    )
};
