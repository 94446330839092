import styled from "styled-components";
import { Label, FormGroup, Input, CardHeader, Button  } from 'reactstrap';
import { ImCheckmark } from "react-icons/im";

export const P = styled.p`
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: justify;

    font-size: 16px;
`;

export const ILabel = styled(Label)`
    font-size: 16px;
    color: #3C3C3C;
`;

export const IFormGroup = styled(FormGroup)`
    margin-top: 15px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const IInput = styled(Input)`
    outline: none;
    border: ${props => props.checked ? '1px solid #d8e2ef' : '1px solid #FF6766'} ;
    :focus{
        border: ${props => props.checked ? '1px solid #31AF89' : '1px solid #FF6766'} ;
        box-shadow: 0 0 0 0;
    }
`;

export const Error = styled.div`
    width: 100%;
    padding: 10px 0;
`;

export const MsgError = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: #FF6766;
`;

export const IRequired = styled.strong`
    color: #FF6766;
    font-weight: 600;
    font-size: 19px;
`;

export const IButton = styled.button`
    width: 48%;
    height: 40px;
    background-color: #31AF89;
    color: #FFFF;
    font-weight: 600;
    font-size: 17px;

    border-radius: 5px;
    outline: none;
    border: 0 none;

    transition: 0.5s;

    :hover{
        background-color: #31AF99;
    }

    :focus{
        outline: none;
    }
`;

export const ICardHeader = styled(CardHeader)`
    font-weight: 600;
    font-size: 19px;
    border-bottom: 2px solid #EBEBEB ;
`;


export const DraftBtn = styled(Button)`
  margin-right: 15px;
  background: #EBEBEB;
  border: 1px solid #9F9F9F;
  color: #9F9F9F;

  :hover{
    background: #EBEBEB !important;
    opacity: 0.7;
    color: #9F9F9F !important;
  }
`;

export const InfoButton = styled.div`

  margin-left: 8px;
  background: #EBEBEB;
  border: 1px solid #9F9F9F;
  color: #9F9F9F;
  width: 20px;
  height: 20px;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 2px;

  :hover{
    background: #EBEBEB !important;
    opacity: 0.7;
    color: #9F9F9F !important;
  }
`;

export const PublishBtn = styled(Button)`
  background: #50D19A;
  border: 1px solid #31AF89;

  :hover{
    background: #50D19A !important;
    opacity: 0.7;
    color: #fff !important;
  }
`;

export const DeleteBtn = styled.div`
  font-size: 18px;
  color: #FF6766;
  text-decoration: underline;
  cursor: pointer; 
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const LabelForm = styled(Label)`
  font-size: 16px;
  color: #3C3C3C;
  display: flex;
  align-items: center;
`

export const ValidateModalRow = styled.div`
  height: 20px;
  display: flex;
  margin-bottom: 3px;
  justify-content: flex-start;
  align-items: center;
`;

export const CheckIcon = styled(ImCheckmark)`
  color: ${props => props.checked ? '#31AF89' : '#ccc'};
  margin-right: 5px;
`;

export const errorMessage = styled.p`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #FF6766;
  font-size: 14px;
  padding: 0;
`;

export const Mandatory = styled.samp`
  color: #FF6766;
  font-weight: 600;
`;