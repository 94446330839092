import React, { useState, Fragment, useEffect, useRef } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Media,
  Input,
  Form,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import {
  AiFillDelete,
} from "react-icons/ai";

import {
  IoIosArrowBack,
  IoIosArrowForward
} from "react-icons/io";

import Cookies from 'js-cookie';
import { useParams, useHistory } from 'react-router-dom';
import CelusApi from '../../server/celusApi';
import FalconCardHeader from '../common/FalconCardHeader';
import ImageUploading from "react-images-uploading";
import { Editor } from '../Editor';

import * as S from './css/components';

import './styles.scss';
import './css/editLaudus.css';

import { Up } from '../../assets/img/icons/celus/index';

const EditLaudus = () => {

  const api = CelusApi();

  const { id } = useParams();

  const fileField = useRef();
  const [collapseOne, collapseOneOpen] = useState(false);
  const [collapseOne1, collapseOneOpen1] = useState(false);
  const [content, setContent] = useState('');
  const [content1, setContent1] = useState('');
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const history = useHistory();

  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [specialtie, setSpecialtie] = useState();
  const [subArea, setSubArea] = useState();
  const [title, setTitle] = useState('');
  const [images, setImages] = React.useState([]);
  const [rImages, setRImages] = React.useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const [values, setValues] = useState([]);
  const [looding, setLooding] = useState(false);
  const [error, setError] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const [scrollX, setScrollX] = useState(0);

  const [errs, setErrs] = useState({
    title: '',
    description: '',
    conclusion: '',
    images: '',
    subArea: '',
    specialtie: '',
  });

  useEffect(() => {
    const handlerSpecialties = async () => {
      try {
        const res = await api.listAreas('laudus');
        setSpecialties(res);
      } catch (error) {
        console.log(error);
      }
    }
    handlerSpecialties();
  }, [api]);


  useEffect(() => {
    const handlerLaudus = async () => {
      try {
        const values = {
          id: Number(id),
          clientId: Number(Cookies.get('clientId')),
        }
        const result = await api.getLaudusByClient(values);

        setRImages(result.result[0].reportImages);
        setTitle(result.result[0].title);
        setContent(result.result[0].description);
        setContent1(result.result[0].conclusion);
        setSpecialtie(result.result[0].areaId);
        setSubArea(result.result[0].subareaId)
        setValues(result.result[0]);

      } catch (error) {
        console.log(error);
      }
    }
    handlerLaudus();
  }, [api, id]);


  useEffect(() => {

    const handlerSubAreas = async () => {
      try {
        const res = await api.getListSubAreas({ specialtie: specialtie, type: 'laudus' });
        setSubAreas(res);
      } catch (error) {
        console.log(error);
      }
    }
    handlerSubAreas();
  }, [specialtie, api])

  const onChange = (imageList) => {
    setImages(imageList);
  };



  const ModalAlert = () => {
    return (
      <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)}>
        <ModalHeader >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }} >
            <div>LaudUS salvo com sucesso!</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: 17, fontWeight: 'bold' }}>O que você gostaria de fazer agora?</p>
        </ModalBody>
        <ModalFooter style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button onClick={cls}>
            Criar novo LaudUS
          </Button>
          <Button style={{ background: '#50D19A', border: 0 }} onClick={me}>Ver meus LaudUS</Button>
        </ModalFooter>
      </Modal>
    );
  }

  const DeleteLaudusModal = () => {
    return (
      <Modal isOpen={collapseOne1} toggle={() => collapseOneOpen1(!collapseOne1)}>
        <ModalHeader >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }} >
            <div>Aviso!</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: 17, fontWeight: 'bold', width: '100%' }}>Você está prestes a excluir um documento!</p>
        </ModalBody>
        <ModalFooter style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button onClick={() => collapseOneOpen1(!collapseOne1)}>
            Cancelar
          </Button>
          <Button style={{ background: '#50D19A', border: 0 }} onClick={() => { (DeleteLaudus()) }}>Continuar</Button>
        </ModalFooter>
      </Modal>
    );
  }

  const ValidationModal = () => {
    return (
      <Modal isOpen={isValidationModalOpen} toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}>
        <ModalHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div>
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>Ops! Alguns campos estão incompletos</span>
          </div>
        </ModalHeader>
        <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px' }}>
            <S.ValidateModalRow>
              <span>{!errs.images ? <S.CheckIcon checked /> : <S.CheckIcon />} Imagem</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.title ? <S.CheckIcon checked /> : <S.CheckIcon />} Titulo</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.description ? <S.CheckIcon checked /> : <S.CheckIcon />} Descrição</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.conclusion ? <S.CheckIcon checked /> : <S.CheckIcon />} Conclusão</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.specialtie ? <S.CheckIcon checked /> : <S.CheckIcon />} Especialidade</span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>{!errs.subArea ? <S.CheckIcon checked /> : <S.CheckIcon />} Categoria</span>
            </S.ValidateModalRow>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button block className="modal-save" onClick={() =>
            setIsValidationModalOpen(!isValidationModalOpen)}
          >
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  const PostLaudus = async (isDraft) => {

    if (title && content && content1 && subArea && specialtie && images.length >= 0) {
      setLooding(!looding);
      const date = new FormData();

      date.append('title', title);
      date.append('description', content);
      date.append('conclusion', content1);
      date.append('subareaId', parseInt(subArea));
      date.append('areaId', parseInt(specialtie));
      date.append('uid', Cookies.get('clientId'));
      date.append('isDraft', isDraft);
      date.append('id', id);
      date.append('clientId', Cookies.get('clientId'));

      if (images.length > 0) {
        for (let index = 0; index < images.length; index++) {
          date.append('file', images[index].file);
        }
      }

      try {
        await api.putLaudus(date);
        collapseOneOpen(!collapseOne);
        DeleteImage();
      } catch (error) {
        console.log(error);
      }
    } else {
      if (!title) {
        errs.title = 'O campo titulo é Obrigatorio!'
      } else {
        errs.title = null
      };
      if (!content) {
        errs.description = 'O campo Descrição é Obrigatorio!'
      } else {
        errs.description = null
      };
      if (!content1) {
        errs.conclusion = 'O campo Conclusão é Obrigatorio!'
      } else {
        errs.conclusion = null
      };
      if (!subArea) {
        errs.subArea = 'O campo Categorias é Obrigatorio!'
      } else {
        errs.subArea = null
      };
      if (!specialtie) {
        errs.specialtie = 'O campo Especialidade é Obrigatorio!'
      } else {
        errs.specialtie = null
      };
      if (images.length === 0) {
        // errs.images = 'Nenhuma Imagem selecionada!'
      } else {
        errs.images = 'null'
      };

      setErrs({ ...errs });

      // setLoading(false);
      setError(true);
      setIsValidationModalOpen(true);
    }
  }


  const cls = () => {
    window.location.href = '/laudus/new';
    collapseOneOpen(!collapseOne);
  }

  const me = () => {
    history.push('/laudus/list');
    collapseOneOpen(!collapseOne);
  }




  const DeleteLaudus = async () => {
    const values = {
      id: id,
      clientId: Cookies.get('clientId'),
    }

    try {
      await api.deleteLaudus(values);
      collapseOneOpen1(!collapseOne1);
      window.location.href = '/laudus/list';
    } catch (error) {
      console.log(error);
    }
  }

  const deleteImageFromArray = (id, index) => {
    const aux = rImages;
    aux.splice(index, 1);
    setRImages(aux);
    setImagesToDelete(oldArray => [...oldArray, id]);
  }

  const DeleteImage = async () => {
    try {
      await api.deleteReportImage(imagesToDelete);
    } catch (error) {
      console.log(error);
    }
  }

  const changeImagePos = (to, id, index) => {
    if (to === 'next') {
      rImages.splice(index + 1, 0, rImages.splice(index, 1)[0]);
      setRImages([...rImages]);

    } else if (to === 'back') {
      rImages.splice(index - 1, 0, rImages.splice(index, 1)[0]);
      setRImages([...rImages]);
    }
  }

  const moveB = document.getElementById("move-back");
  const moveN = document.getElementById("move-next");
  const imgsContainer = document.getElementById('imgs-container');
  const listWidth = rImages.length * 325;
  const deleteBtn = document.getElementById('deleteBtn');

  const showImagesButtons = () => {
    moveB.classList.remove('hidden');
    moveN.classList.remove('hidden');
  }

  const CloseImagesButton = () => {
    moveB.classList.add('hidden');
    moveN.classList.add('hidden');
  }

  const showDeleteBtn = () => {
    setIsHidden(!isHidden);
  }

  const closeDeleteBtn = () => {
    setIsHidden(!isHidden);
  }

  const handleLeftArrow = () => {
    let x = scrollX + Math.round(imgsContainer.offsetWidth / 2)
    if (x > 0) {
      x = 0
    }
    setScrollX(x)
  }

  const handleRightArrow = () => {
    let x = scrollX - Math.round(imgsContainer.offsetWidth / 2);
    let listW = rImages.length * 235;
    if ((imgsContainer.offsetWidth - listW) > x) {
      x = imgsContainer.offsetWidth - listW - 60
    }
    setScrollX(x)
  }

  // useEffect(() => {
  //   GetLaudu();
  // }, [rImages])


  return (
    <Card className="mb-3">

      <FalconCardHeader
        title={
          <Fragment>
            <Media body className="ml-2" style={{ width: '100%' }}>
              <h5 className="mb-0 fs-0" style={{ fontSize: 19 }}>Editar LaudUS</h5>
            </Media>
          </Fragment>
        }
        titleTag={Media}
        titleClass="align-items-center"
      />

      <div style={{ padding: 30, overflowX: 'hidden' }}>
        <p style={{ fontSize: 17, fontWeight: 'normal', color: '#3C3C3C', width: '100%' }}>
          Compartilhe conosco sua experiência, crie um formato de como lauda de uma achado
          ultrassonográfico de uma patologia.
        </p>
        <CardBody className="p-0" style={{ overflowX: 'hidden' }}>
          <label
            style={{
              height: '270px',
              border: '1px dashed #739AD1',
              borderRadius: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              background: '#EDF2F9',
              margin: '15px 0',
              padding: '15px',
              cursor: 'pointer',
            }}
          >

            {images.length === 0 ?
              <>
                <img src={Up} style={{ width: '50px' }} />
                <p style={{ color: '#9F9F9F', fontSize: '17px', fontWeight: 'normal', textAlign: 'center' }} >
                  Adicione imagens para melhor exemplificar a patologia para o leitor.
                </p>
              </>
              :
              ""
            }

            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              dataURLKey="data_url"
              ref={fileField}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemove,
                dragProps
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <button
                    style={{
                      color: '#9F9F9F',
                      fontSize: '17px',
                      fontWeight: 'normal',
                      border: '1px solid #9F9F9F',
                      padding: '5px',
                      borderRadius: '5px',
                      fontSize: '15px',
                      textAlign: 'center',
                      maxWidth: 205,
                      backgroundColor: 'transparent'
                    }}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Selecionar arquivos
                  </button>
                  <div className="imgs-col" style={{ justifyContent: images.length > 3 ? 'flex-start' : 'center' }}>
                    {imageList.map((image, index) => (
                      <div key={index} className="drop-images">
                        <div className="image-item__btn-wrapper">
                          {/* <button  onClick={() => onImageRemove(index)}>x</button> */}
                          <div className="trash-btn" onClick={() => onImageRemove(index)} >
                            <AiFillDelete />
                          </div>

                        </div>
                        <img src={image.data_url} alt="" className="drop-image" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </label>
          {(error === true && images.length <= 0) ? <S.errorMessage >{errs.images} </S.errorMessage > : null}
          
          <div
            onMouseOver={() => showImagesButtons()}
            onMouseOut={() => CloseImagesButton()}
            id="imgs-container"
            style={{
              position: 'relative',
            }}
          >
            <div className="move-back move hidden" id="move-back" onClick={() => handleLeftArrow()}>
              <IoIosArrowBack className="icon-arrow" />
            </div>
            <div className="move-next move hidden" id="move-next" onClick={() => handleRightArrow()}>
              <IoIosArrowForward className="icon-arrow" />
            </div>
            
            <div
              style={{
                maxWidth: listWidth,
                marginBottom: 20,
                overflowX: 'hidden',
                marginLeft: scrollX,
              }}
              className="list"
            >

              {
                rImages.map((item, index) => {
                  return (

                    <div
                      className="image-item"
                      onMouseOver={() => showDeleteBtn(index)}
                      onMouseOut={() => closeDeleteBtn()}
                    >
                      {/* <div className={`left-side side ${index ===0 && 'hidden'}`} onClick={() => changeImagePos('back', item.id, index)}>
                          <AiOutlineCaretLeft color="white" fontSize="22"/>
                        </div> */}
                      <AiFillDelete className={`delete-btn ${!isHidden && 'hidden'}`} onClick={() => deleteImageFromArray(item.id, index)} />
                      <img key={index} src={item.src}
                        style={{ width: '100%', height: '100%' }}
                      />

                      {/* <div className={`right-side side ${index ===(rImages.length - 1) && 'hidden'}`} onClick={() => changeImagePos('next', item.id, index)}>
                          <AiOutlineCaretRight color="white" fontSize="22"/>
                        </div> */}
                    </div>

                  )
                })
              }
            </div>

          </div>
          <Form>
            <FormGroup>
              <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Título <strong style={{ color: '#FF6766' }}>*</strong> </Label>
              <Input type="text" name="name" id="exampleName" placeholder="Título" maxLength="100" value={title} onChange={e => { setTitle(e.target.value) }} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                <Label>Adicione o nome da patologia</Label>
                <S.LabelForm style={{ fontSize: 13 }}>
                  <strong style={{ color: title.length > 70 && title.length !== 100 ? "#ff8c00" : title.length === 100 ? '#FF6766' : '#9c9a9a' }}>Número de caracteres: [{`${title.length}/100`}]</strong>
                </S.LabelForm>
              </div>
              {(error === true && !title) ? <S.errorMessage >{errs.title}</S.errorMessage > : null}
            </FormGroup>


            <FormGroup>
              <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Descrição <strong style={{ color: '#FF6766' }}>*</strong> </Label>
              <Editor setValues={setContent} values={content} />

              {(error === true && !content) ? <S.errorMessage >{errs.description}</S.errorMessage > : null}

              <Label>Descreva acima como lauda a patologia</Label>
            </FormGroup>

            <FormGroup>
              <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Conclusão <strong style={{ color: '#FF6766' }}>*</strong> </Label>
              <Editor setValues={setContent1} values={content1} />

              {(error === true && !content1) ? <S.errorMessage >{errs.conclusion} </S.errorMessage > : null}
            </FormGroup>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FormGroup style={{ width: '49%' }}>
                <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Especialidade <strong style={{ color: '#FF6766' }}>*</strong></Label>
                <Input type="select" name="select" id="exampleSelect" onChange={e => { setSpecialtie(e.target.value) }}>
                  <option value="" disabled selected hidden>Especialidade</option>
                  {specialties.map((item, index) => {
                    return (
                      <option key={index} value={item.id} selected={values.areaId === item.id}>{item.name}</option>
                    );
                  })}
                </Input>
                {(error === true && !specialtie) ? <S.errorMessage >{errs.specialtie} </S.errorMessage > : null}
              </FormGroup>

              <FormGroup style={{ width: '49%' }}>
                <Label for="exampleName" style={{ fontSize: 16, color: '#3C3C3C' }}>Categoria <strong style={{ color: '#FF6766' }}>*</strong></Label>
                <Input type="select" name="select" id="exampleSelect" onChange={e => { setSubArea(e.target.value) }}>
                  <option value="" disabled selected hidden>Categoria</option>
                  {subAreas.map((item, index) => {
                    return (
                      <option key={index} value={item.id} selected={values.subareaId === item.id} >{item.name}</option>
                    );
                  })}
                </Input>
                {(error === true && !subArea) ? <S.errorMessage >{errs.subArea}</S.errorMessage > : null}
              </FormGroup>
            </div>
            <Row noGutters className="justify-content-between ">
              <Col className="col" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button
                  onClick={() => {
                    PostLaudus(true);
                  }}
                  className="d-md-inline-block" style={{ background: '#B8B8B8', marginRight: '20px', border: 'none' }}>
                  Salvar como rascunho
                </Button>

                <Button
                  onClick={() => {
                    PostLaudus(false);
                  }}
                  className="d-md-inline-block" style={{ background: '#50D19A', marginRight: '20px', border: 'none' }}>
                  Publicar alterações
                </Button>

                <Button
                  onClick={() => {
                    collapseOneOpen1(!collapseOne1);
                  }}
                  className="d-md-inline-block"
                  style={{ marginRight: 10, color: '#9F9F9F', background: '#FF6766', color: '#FAFAFA', border: 'none' }}>Excluir
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </div>
      {ModalAlert()}
      {DeleteLaudusModal()}
      {ValidationModal()}
    </Card>
  );
};

export default EditLaudus;
