import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import loadable from '@loadable/component';
import RouteHandler from '../components/RouteHandler/RouteHandler';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const Landing = loadable(() => import('../components/landing-copy/Landing'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
    Landing.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
  }, []);


  return (
    <Router fallback={<span />}>
      <Switch>
        <RouteHandler path="/landing-copy" exact component={Landing} />
        <RouteHandler path="/authentication" component={AuthCardRoutes} />
        <RouteHandler path="/authentication/wizard" component={WizardLayout} />
        <RouteHandler path="/errors" component={ErrorLayout} />
        <RouteHandler path="/authentication/basic" component={AuthBasicLayout} />
        <RouteHandler private component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
