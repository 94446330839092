
const initialState = {
    email: '',
    name: '',
    clientId: '',
    adminId: '',
}

export default (state = initialState, action) => {

    if(action.type )

    return state;
}