import React, { useState, Fragment } from 'react';
import { Button} from 'reactstrap';
import * as S from './css/anatomus';
import CelusApi from '../../server/celusApi';

import {
    FaAngleLeft,
    FaAngleRight,
    FaRegClone,
    FaRegTimesCircle,
    FaCommentAlt
} from 'react-icons/fa';
import './styles.scss';
import { Editor } from '../Editor';

import { toast } from 'react-toastify';
import { useParams } from 'react-router';

export const FormAnatomusEdit = ({
    title,
    arrayImagens,
    arrayImagensNew,
    setArrayImages = Function(),
    setArrayImagesNew = Function(),
    content,
    setContent = Function(),
    description,
    setDescription = Function(),
    areaImagensMark = Function(),
}) => {

    const api = CelusApi();

    const [varredura, setVarredura] = useState();
    const { anatomusId } = useParams();

    const pushImages = async files => {
        const Varreduras = new FormData();

        Object.keys(files).forEach(function (item) {
            arrayImagensNew.push(files[item]);
            setArrayImagesNew([...arrayImagensNew]);

            description.push('Descrição da Varredura');
            setDescription([...description]);
        });

        for (let index = 0; index < arrayImagensNew.length; index++) {
            Varreduras.append('files', arrayImagensNew[index]);
        }

        Varreduras.append('type', 'transversal');
        Varreduras.append('anatomusId', anatomusId);
        const resultVarreduras = await api.postNewVarreduras(Varreduras);
        resultVarreduras.result.map(item => {
            arrayImagens.push(item);
            setArrayImages([...arrayImagens]);
        });

        setArrayImagesNew([...[]]);
    };


    const UpPosition = (from, to) => {
        arrayImagens.splice(to, 0, arrayImagens.splice(from, 1)[0]);
        setArrayImagesNew([...arrayImagens]);

        description.splice(to, 0, description.splice(from, 1)[0]);
        setDescription([...description]);
    };

    const CloneFile = (item, to) => {
        arrayImagens.push(item);
        arrayImagens.splice(to, 0, arrayImagens.splice(arrayImagens.length - 1, 1)[0]);
        setArrayImages([...arrayImagens]);

        description.push(content);
        description.splice(to, 0, description.splice(description.length - 1, 1)[0]);
        setDescription([...description]);
    };

    const RemoverFile = from => {
        arrayImagens.splice(from, 1);
        setArrayImages([...arrayImagens]);

        description.splice(from, 1);
        setDescription([...description]);
    };

    return (
        <Fragment>
            <S.BorderDiv>
                <S.FormGroup>
                    <S.Label>
                        Imagens {title} <S.Required>*</S.Required>
                    </S.Label>
                    <label className="dropZone">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={e => pushImages(e.target.files)}
                            style={{ visibility: 'hidden', display: 'none' }}
                        />
                        <p className="dropLabel">Clique aqui para adicionar uma nova imagem</p>
                    </label>
                </S.FormGroup>

                <div
                    className="scroll"
                    style={{
                        width: '81%',
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'row',
                        overflowX: 'scroll',
                        overflowY: 'hidden'
                    }}
                >
                    {arrayImagens.map((item, index) => {
                        return (
                            <>
                                <div
                                    style={{
                                        minWidth: 140,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        marginRight: 20,
                                        marginLeft: 20
                                    }}
                                >
                                    <div
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                                    >
                                        <S.IButton
                                            radius="left"
                                            style={{ width: 35, height: 120 }}
                                            onClick={() => {
                                                UpPosition(Number(index), Number(index - 1));
                                            }}
                                            disabled={index === 0}
                                        >
                                            <FaAngleLeft />
                                        </S.IButton>
                                    </div>
                                    <S.ImageList
                                        style={{ objectFit: 'cover' }}
                                        src={item}
                                        onClick={() => {
                                            setVarredura(index + 1);
                                            setContent(description[index]);
                                        }}
                                    />
                                    <div
                                        style={{
                                            height: 120,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <S.IButton
                                            radius="top"
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                UpPosition(Number(index), Number(index + 1));
                                            }}
                                            disabled={arrayImagens.length === 1 ? true : index === arrayImagens.length - 1 ? true : false}
                                        >
                                            <FaAngleRight />
                                        </S.IButton>
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                CloneFile(item, index);
                                            }}
                                        >
                                            <FaRegClone />
                                        </S.IButton>
                                        <S.IButton
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                RemoverFile(index);
                                            }}
                                        >
                                            <FaRegTimesCircle />
                                        </S.IButton>
                                        <S.IButton
                                            radius="botton"
                                            style={{ width: 35 }}
                                            onClick={() => {
                                                setVarredura(index + 1);
                                                setContent(description[index]);
                                            }}
                                        >
                                            <FaCommentAlt />
                                        </S.IButton>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
                <input id="my-file" type="file" name="my-file" style={{ display: 'none' }} onChange="" />

                <S.IFormGroup style={{ marginTop: '20px' }}>
                    <S.Label>
                        Descrição da Varredura {varredura && `[${varredura}]`} <S.Required>*</S.Required>
                    </S.Label>
                    <Editor setValues={setContent} values={content} />

                </S.IFormGroup>

                <Button
                    style={{ backgroundColor: '#31AF89', marginBottom: 15, maxWidth: '81%' }}
                    onClick={() => {
                        if (!varredura) {
                            toast.error(`Opa! Nenhuma Varredura selecionada!`);
                        } else {
                            description[varredura - 1] = content;
                            setDescription([...description]);
                            toast.success(`Descrição da Varredura [${varredura}] adicionada!`);
                        }
                    }}
                >
                    Salvar Descrição
                </Button>

                <br />
                {areaImagensMark()}
            </S.BorderDiv>
        </Fragment>
    );
};
