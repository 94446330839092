import React from 'react';
import { Link } from 'react-router-dom';

import '../custon/custonDashboard.css';

const DashboardItens = ({ toRoutes, icon, title }) => {
    return (
        <>
            <Link to={toRoutes} style={{ marginBottom: 10 }}>
                <div className="p-2 mr-3 card card_view card_dash">
                    <div className="card_view">
                        <div className="content-img">
                            <img src={icon} className="icon_card" />
                        </div>
                        <p className="label_icon">{title}</p>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default DashboardItens;