import React from 'react';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import FeedContent from './FeedContent';
import FeedSideBar from './FeedSideBar';
import FeedProvider from './FeedProvider';
import NavFeed from './NavFeed';

const Feed = () => {

  return (
    <FeedProvider style={{ margin: 0 }}>
      <ContentWithAsideLayout aside={<FeedSideBar />} isStickyAside={false}>
        <NavFeed />
        <FeedContent/>
      </ContentWithAsideLayout>
    </FeedProvider>
  )
};

export default Feed;
