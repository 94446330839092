import React, { Fragment } from 'react';
import { emptyCards } from '../../assets/img/illustrations';
import { CardResumus } from '../cardResumus/CardResumus';
import { EmptyContent } from '../EmptyContent';
import { LoadingCard } from '../LoadingCard';

import './styles.scss';

const FeedCard = ({ values, loading }) => {
  return (
    <Fragment>
      {
        loading ? (
          <Fragment>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Fragment>
        ) : (values.length <= 0) ?
          <EmptyContent
            src={emptyCards}
            msg="Você ainda não tem nenhum ResumUS, crie um conteúdo para publicação!"
            goTo="/resumus/new"
            title="Clique aqui"
          />
          :
          values.map((item, index) => {
            return (
              <CardResumus key={index} values={item} to="/me-resumus/resumus" status={true} />
            )
          })
      }
    </Fragment>
  );
};

export default FeedCard;
