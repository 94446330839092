import React, { useState, Fragment, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Media,
  Input,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
  Button,
} from "reactstrap";

import { toast } from "react-toastify";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

import "./css/create-resumus.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import CelusApi from "../../server/celusApi";
import FalconCardHeader from "../common/FalconCardHeader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import "react-tagsinput/react-tagsinput.css";
import { ImgNull, Up } from "../../assets/img/icons/celus/index";
import ModalImg from "../../assets/img/celus/resumus/resumus-modal-img.png";
import * as S from "./css/components";

import "./css/createResumus.css";
import Cookies from "js-cookie";
import { useHistory } from "react-router";
import { Editor } from "../Editor";
import { Reference } from "../Reference";

export const PostCreateResumus = () => {
  const infoText = {
    title: [
      "O título deve conter as palavras-chave relacionadas ao tema do seu artigo. Idealmente o título deve conter no máximo 100 caracteres. Exemplos:",
      <br />,
      "	1. Quero criar um artigo sobre as medidas de normalidade do piloro no neonato.",
      <br />,
      "Título: Medidas de normalidade do piloro em lactentes.",
    ],

    subTitle: [
      "Esta opção não é obrigatória, porém o subtítulo tem o objetivo de especificar melhor o título para que a pessoa entenda exatamente sobre o que vai ler naquele artigo. Idealmente o subtítulo deve conter no máximo 15 palavras. Exemplos: ",
      <br />,
      "1. Quero criar um artigo sobre as medidas de normalidade do piloro no neonato.",
      <br />,
      "Título: Medidas de normalidade do piloro em lactentes.",
    ],

    description: [
      "O conteúdo deve ser direto e objetivo, tratando de pontos-chave relacionados ao título, com um máximo de 2200 caracteres. Inspire-se e crie seu artigo. Caso haja alguma palavra(s) no seu texto que você considere possível a criação de um significado para ela, você pode acrescentá-la ao dicionário da celus.",
      <br />,
      "Rememorando que o conteúdo criado é enviado para avaliação da celus, antes que seja publicado, e nele Não deve conter:",
      <br />,
      "·         Conteúdo e imagens pornográficas , as mesmas serão bloqueadas.",
      <br />,
      "·         Conteúdo plagiado- Copiar textos sem a permissão do autor é crime, pois isso configura uma forma de roubo e roubar ideias é plágio, e plágio é crime. Se for copiar algum texto completa ou parcialmente, dê os devidos créditos, isso se chama referência, e você pode adicioná-lá ao texto.",
      <br />,
      "Para mais informações consulte nossos Termos de uso.",
    ],

    images:
      "Adicione uma foto destaque ao artigo para que possamos exibir na biblioteca de artigos no aplicativo. Procure utilizar fotos que representem o tema abordado no artigo.As dimensoes da fotos devem ter a mesma proporção entre altura e largura (1080 x 1080).  Este campo deve ser preenchido obrigatoriamente.",

    tags: "Adicione tags para facilitar a busca pelo seu artigo. As tags são palavras-chave e servem como etiquetas. Quando você adiciona tags ao seu texto você os deixa mais fácil de ser encontrado e consequentemente mais visível para os usuários do aplicativo. Este campo não é obrigatório.",

    specialtie: "Selecione a especialidade desejada",

    ref: [
      "Idealmente as referências bibliográficas devem ser artigos científicos e você deve adicioná-los de acordo com o https://scholar.google.com.br/ . Entretanto, se você pesquisar algum texto inspiração e quer adicionar ao seu artigo, eles são referências, neste campo você deve adicioná-los. Deve ser adicionado:",
      <br />,
      "·         Link do site ou informação da fonte",
      <br />,
      "·         Nome do autor",
      <br />,
      "·         Edição.",
      <br />,
      "·         Cidade onde foi publicado: Editora, ano de publicação.",
    ],

    categories: "Selecione a categoria desejada",
  };

  const api = CelusApi();
  const history = useHistory();
  const [content, setContent] = useState("");
  const [specialtie, setSpecialtie] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [subArea, setSubArea] = useState("");
  const [tags, setTags] = useState([]);
  const [ref, setRef] = useState([]);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");

  const [images, setImages] = React.useState(null);

  const [error, setError] = useState(false);

  const [tooltipTitle, setTooltipTitle] = useState(false);
  const [tooltipSubTitle, setTooltipSubTitle] = useState(false);
  const [tooltipCategories, setTooltipCategories] = useState(false);
  const [tooltipSpecialities, setTooltipSpecialities] = useState(false);
  const [tooltipRef, setTooltipRef] = useState(false);
  const [tooltipImages, setTooltipImages] = useState(false);
  const [tooltipDescription, setTooltipDescription] = useState(false);
  const [tooltipTags, setTooltipTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errs, setErrs] = useState({
    title: "",
    subtitle: "",
    description: "",
    images: "",
    tags: "",
    specialtie: "",
    subArea: "",
    ref: "",
  });

  const preview = useMemo(() => {
    return images ? URL.createObjectURL(images) : null;
  }, [images]);

  useEffect(() => {
    const GetSpecialties = async () => {
      try {
        const res = await api.listAreas("resumus");
        setSpecialties(res);
      } catch (error) {
        console.log(error);
      }
    };
    GetSpecialties();
  }, [api]);

  useEffect(() => {
    const GetSubAreas = async () => {
      try {
        const res = await api.getListSubAreas({
          specialtie: specialtie,
          type: "resumus",
        });
        setSubAreas(res);
      } catch (error) {
        console.log(error);
      }
    };
    GetSubAreas();
  }, [specialtie, api]);

  const CreateModal = () => {
    return (
      //toggle={() => setIsCreateModalOpen(!isCreateModalOpen)}
      <Modal isOpen={isCreateModalOpen}>
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              ResumUS enviado com sucesso!
            </span>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "15px 45px",
          }}
        >
          <img
            src={ModalImg}
            style={{ maxWidth: "200px", height: "auto", marginBottom: "20px" }}
          />

          <div>
            <p style={{ width: "100%", textAlign: "center" }}>
              Obrigado pela contribuição! Em breve entraremos em contato! Caso
              seu resumo seja aprovado, você receberá X pontos.
            </p>
          </div>
        </ModalBody>
        <ModalFooter
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            borderTop: "none",
            paddingBottom: "20px",
          }}
        >
          <Button
            style={{
              background: "#fff",
              border: "1px solid #50D19A",
              color: "#50D19A",
            }}
            onClick={() => (window.location.href = "/resumus/list")}
          >
            Ver resumUS
          </Button>
          <Button
            style={{ background: "#50D19A", border: 0 }}
            onClick={() => (window.location.href = "/resumus/new")}
          >
            Criar novo resumUS
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const postArtigo = async (status) => {
    setLoading(true);
    if (
      title &&
      // subTitle && && images && ref
      content &&
      tags.length > 0 &&
      specialtie &&
      subAreas
    ) {
      const data = new FormData();
      data.append("areaId", specialtie);
      data.append("content", content);
      data.append("isDraft", status);
      data.append("references", JSON.stringify(ref));
      data.append("subAreaId", subArea);
      data.append("subtitle", subTitle);
      data.append("thumbnai", " ");
      data.append("title", title);
      data.append("clientId", Number(Cookies.get("clientId")));
      data.append("keywords", JSON.stringify(tags));
      data.append("file", images);

      try {
        await api
          .postResumus(data)
          .then(() => {
            if (status === true) {
              setIsDraftModalOpen(!isDraftModalOpen);
              toast.success(`ResumUS salvo como rascunho!`);
              setTimeout(() => {
                history.push("/my-resumus/draft");
              }, 500);
              setLoading(false);
            } else {
              setIsCreateModalOpen(!isCreateModalOpen);
              toast.success(`ResumUS Criado!`);
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);

            toast.error(`Opa! Não foi possivel criar o ResumUS!`);
          });
      } catch (error) {
        setLoading(false);

        toast.error(`Opa! Não foi possivel criar o ResumUS!`);
        console.log(error);
      }
    } else {
      if (!title) {
        errs.title = "O campo titulo é obrigatorio!";
      } else {
        errs.title = null;
      }
      // if (!subTitle) {
      //   errs.subtitle = 'O campo subtitulo é obrigatorio!';
      // } else {
      //   errs.subtitle = null
      // };
      if (!content) {
        errs.description = "O campo descrição é obrigatorio!";
      } else {
        errs.description = null;
      }
      // if (!images) {
      // 		errs.images = 'O campo de imagem é obrigatorio!';
      // } else {
      // 		errs.images = null
      // };
      if (tags.length <= 0) {
        errs.tags = "O campo de tags é obrigatorio!";
      } else {
        errs.tags = null;
      }
      if (!specialtie) {
        errs.specialtie = "O campo de especialidade é obrigatorio!";
      } else {
        errs.specialtie = null;
      }
      if (!subArea) {
        errs.subarea = "O campo de categoria é obrigatorio!";
      } else {
        errs.subarea = null;
      }
      // if (!ref) {
      // 		errs.ref = 'O campo de referências é obrigatorio!';
      // } else {
      // 		errs.ref = null
      // };
      setLoading(false);
      setErrs({ ...errs });
      setError(true);
      setIsValidationModalOpen(true);
    }
  };

  const saveAsDraft = async (status) => {
    setIsDraftModalOpen(!isDraftModalOpen);
    // setTimeout(function(){ setIsDraftModalOpen(false); }, 3000);
    setTimeout(function () {
      document.getElementById("draft-alert").classList.add("draft-alert-out");
      document
        .getElementById("draft-alert-span")
        .classList.add("draft-alert-out");
    }, 4000);
    setTimeout(function () {
      setIsDraftModalOpen(false);
    }, 5500);
  };

  const ValidationModal = () => {
    return (
      <Modal
        isOpen={isValidationModalOpen}
        toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}
      >
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              Ops! Alguns campos estão incompletos
            </span>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "200px",
            }}
          >
            <S.ValidateModalRow>
              <span>
                {!errs.title ? <S.CheckIcon checked /> : <S.CheckIcon />} Titulo
              </span>
            </S.ValidateModalRow>

            {/* <S.ValidateModalRow>
														<span>{!errs.subtitle ? <S.CheckIcon checked /> : <S.CheckIcon />} Subtitulo</span>
												</S.ValidateModalRow> */}

            <S.ValidateModalRow>
              <span>
                {!errs.description ? <S.CheckIcon checked /> : <S.CheckIcon />}{" "}
                Descrição
              </span>
            </S.ValidateModalRow>

            {/* <S.ValidateModalRow>
														<span>{!errs.images ? <S.CheckIcon checked /> : <S.CheckIcon />} Imagem destaque</span>
												</S.ValidateModalRow> */}

            <S.ValidateModalRow>
              <span>
                {!errs.tags ? <S.CheckIcon checked /> : <S.CheckIcon />} Tags
              </span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>
                {!errs.specialtie ? <S.CheckIcon checked /> : <S.CheckIcon />}{" "}
                Especialidade
              </span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>
                {!errs.subarea ? <S.CheckIcon checked /> : <S.CheckIcon />}{" "}
                Categoria
              </span>
            </S.ValidateModalRow>

            {/* <S.ValidateModalRow>
														<span>{!errs.ref ? <S.CheckIcon checked /> : <S.CheckIcon />} Referências</span>
												</S.ValidateModalRow> */}
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button
            block
            className="modal-save"
            onClick={() => setIsValidationModalOpen(!isValidationModalOpen)}
          >
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={
          <Fragment>
            <Media body className="ml-2">
              <h5 className="mb-0 fs-0" style={{ fontSize: 19 }}>
                Criar novo ResumUS
              </h5>
            </Media>
          </Fragment>
        }
        titleTag={Media}
        titleClass="align-items-center"
      />

      <div style={{ padding: 30 }}>
        <CardBody className="p-0">
          <Form>
            <FormGroup>
              <S.LabelForm for="name">
                Título
                <S.Mandatory>*</S.Mandatory>
                <S.InfoButton id="titleInfo" size={19} />
                <Tooltip
                  className="myTooltip"
                  placement="right"
                  isOpen={tooltipTitle}
                  target="titleInfo"
                  toggle={() => {
                    setTooltipTitle(!tooltipTitle);
                  }}
                >
                  {infoText.title}
                </Tooltip>
              </S.LabelForm>
              <Input
                type="text"
                name="name"
                id="exampleName"
                maxlength="100"
                placeholder="Título"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <S.LabelForm
                style={{ fontSize: 13, marginTop: 15, position: "relative" }}
              >
                <strong
                  style={{
                    position: "absolute",
                    right: 0,
                    color:
                      title.length > 70 && title.length !== 100
                        ? "#ff8c00"
                        : title.length === 100
                        ? "#FF6766"
                        : "#9c9a9a",
                  }}
                >
                  Número de caracteres: [{`${title.length}/100`}]
                </strong>
              </S.LabelForm>
              {error === true && !title ? (
                <S.errorMessage>{errs.title}</S.errorMessage>
              ) : null}
            </FormGroup>

            <FormGroup>
              <S.LabelForm for="subtitle">
                Subtítulo
                {/* <S.Mandatory>*</S.Mandatory> */}
                <S.InfoButton id="subtitleInfo" size={19} />
                <Tooltip
                  className="myTooltip"
                  placement="right"
                  isOpen={tooltipSubTitle}
                  target="subtitleInfo"
                  toggle={() => {
                    setTooltipSubTitle(!tooltipSubTitle);
                  }}
                >
                  {infoText.subTitle}
                </Tooltip>
              </S.LabelForm>
              <Input
                type="text"
                name="subtitle"
                placeholder="Subtítulo"
                onChange={(e) => {
                  setSubTitle(e.target.value);
                }}
                maxLength="100"
              />
              <S.LabelForm
                style={{ fontSize: 13, marginTop: 15, position: "relative" }}
              >
                <strong
                  style={{
                    position: "absolute",
                    right: 0,
                    color:
                      subTitle.length > 70 && subTitle.length !== 100
                        ? "#ff8c00"
                        : subTitle.length === 100
                        ? "#FF6766"
                        : "#9c9a9a",
                  }}
                >
                  Número de caracteres: [{`${subTitle.length}/100`}]
                </strong>
              </S.LabelForm>
              {error === true && !subTitle ? (
                <S.errorMessage>{errs.subtitle}</S.errorMessage>
              ) : null}
            </FormGroup>

            <FormGroup>
              <S.LabelForm for="exampleName">
                Descrição
                <S.Mandatory>*</S.Mandatory>
                <S.InfoButton id="desc" size={19} />
                <Tooltip
                  className="myTooltip"
                  placement="right"
                  isOpen={tooltipDescription}
                  target="desc"
                  toggle={() => {
                    setTooltipDescription(!tooltipDescription);
                  }}
                >
                  {infoText.description}
                </Tooltip>
              </S.LabelForm>
              <Editor setValues={setContent} values={content} />
              {error === true && !content ? (
                <S.errorMessage>{errs.description} </S.errorMessage>
              ) : null}
            </FormGroup>

            <FormGroup>
              <S.LabelForm for="exampleName">
                Foto destaque
                {/* <S.Mandatory>*</S.Mandatory> */}
                <S.InfoButton id="imageInfo" size={19} />
                <Tooltip
                  placement="right"
                  className="myTooltip"
                  isOpen={tooltipImages}
                  target="imageInfo"
                  toggle={() => {
                    setTooltipImages(!tooltipImages);
                  }}
                >
                  {infoText.images}
                </Tooltip>
              </S.LabelForm>

              <S.PreviwArea>
                <S.SelectImage>
                  <S.Up src={Up} />
                  {!preview ? "Escolha uma imagem" : "Atualizar imagem"}
                  <Input
                    type="file"
                    name="filefield"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => setImages(e.target.files[0])}
                  />
                </S.SelectImage>

                <S.PreviwAreaImage>
                  <S.ImagemPreviw src={preview ? preview : ImgNull} />
                </S.PreviwAreaImage>
              </S.PreviwArea>

              <S.LabelForm style={{ fontSize: 14 }}>
                Adicione uma imagem de destaque para o seu artigo.
              </S.LabelForm>
              {error === true && images === null ? (
                <S.errorMessage>{errs.images} </S.errorMessage>
              ) : null}
            </FormGroup>

            <FormGroup>
              <S.LabelForm for="exampleName">
                Tags
                <S.Mandatory>*</S.Mandatory>
                <S.InfoButton id="tagsInfo" size={19} />
                <Tooltip
                  placement="right"
                  className="myTooltip"
                  isOpen={tooltipTags}
                  target="tagsInfo"
                  toggle={() => {
                    setTooltipTags(!tooltipTags);
                  }}
                >
                  {infoText.tags}
                </Tooltip>
              </S.LabelForm>
              <ReactTagInput
                tags={tags}
                onChange={(newTags) => setTags(newTags)}
                placeholder="Tags"
              />
              <S.LabelForm style={{ fontSize: 14, marginTop: 5 }}>
                Pressione a tecla Enter a cada tag informada!
              </S.LabelForm>
              {error === true && tags.length <= 0 ? (
                <S.errorMessage>{errs.tags} </S.errorMessage>
              ) : null}
            </FormGroup>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormGroup style={{ width: "49%" }}>
                <S.LabelForm for="exampleName">
                  Especialidade
                  <S.Mandatory>*</S.Mandatory>
                  <S.InfoButton id="specialitieInfo" size={19} />
                  <Tooltip
                    placement="right"
                    isOpen={tooltipSpecialities}
                    target="specialitieInfo"
                    toggle={() => {
                      setTooltipSpecialities(!tooltipSpecialities);
                    }}
                  >
                    {infoText.specialtie}
                  </Tooltip>
                </S.LabelForm>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => {
                    setSpecialtie(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Especialidade
                  </option>
                  {specialties.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </Input>
                {error === true && !specialtie ? (
                  <S.errorMessage>{errs.specialtie} </S.errorMessage>
                ) : null}
              </FormGroup>
              <FormGroup style={{ width: "49%" }}>
                <S.LabelForm for="exampleName">
                  Categoria
                  <S.Mandatory>*</S.Mandatory>
                  <S.InfoButton id="categoriesInfo" size={19} />
                  <Tooltip
                    placement="right"
                    isOpen={tooltipCategories}
                    target="categoriesInfo"
                    toggle={() => {
                      setTooltipCategories(!tooltipCategories);
                    }}
                  >
                    {infoText.categories}
                  </Tooltip>
                </S.LabelForm>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => {
                    setSubArea(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Categoria
                  </option>
                  {subAreas.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </Input>
                {error === true && !subArea ? (
                  <S.errorMessage>{errs.subarea}</S.errorMessage>
                ) : null}
              </FormGroup>
            </div>

            <FormGroup>
              <S.LabelForm for="exampleName">
                Referências
                {/* <S.Mandatory>*</S.Mandatory> */}
                <S.InfoButton id="refInfo" size={19} />
                <Tooltip
                  placement="right"
                  className="myTooltip"
                  isOpen={tooltipRef}
                  target="refInfo"
                  toggle={() => {
                    setTooltipRef(!tooltipRef);
                  }}
                >
                  {infoText.ref}
                </Tooltip>
              </S.LabelForm>

              <Reference disabled={false} arrayRef={ref} setArreyRef={setRef} />

              {error === true && !ref ? (
                <S.errorMessage>{errs.ref} </S.errorMessage>
              ) : null}
            </FormGroup>

            <Row
              noGutters
              className="justify-content-between"
              style={{ alignItems: "center" }}
            >
              <Col
                className="col"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <S.DraftBtn
                  className="d-md-inline-block"
                  onClick={() => {
                    postArtigo(true);
                  }}
                  disabled={loading}
                >
                  {loading ? <Dots /> : "Salvar como rascunho"}
                </S.DraftBtn>
                <S.PublishBtn
                  disabled={loading}
                  className="d-md-inline-block"
                  style={{}}
                  onClick={() => {
                    postArtigo(false);
                  }}
                >
                  {loading ? <Dots /> : "Publicar"}
                </S.PublishBtn>
              </Col>
            </Row>
          </Form>
          {isDraftModalOpen && (
            <div
              className="draft-alert"
              id="draft-alert"
              style={{
                width: "100%",
                padding: "8px 30px",
                background: "#31AF89",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                marginTop: "30px",
              }}
            >
              <IoIosCheckmarkCircle
                style={{ color: "#fff", fontSize: "21px" }}
              />
              <span
                id="draft-alert-span"
                style={{
                  color: "#FFF",
                  fontSize: "17px",
                  fontWeight: "lighter",
                }}
              >
                Rascunho salvo com sucesso!
              </span>
            </div>
          )}
        </CardBody>
      </div>
      {CreateModal()}
      {ValidationModal()}
    </Card>
  );
};
