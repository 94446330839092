import React, { Fragment, useEffect } from "react";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./styles.scss";

import CelusApi from "../../server/celusApi";
import Cookies from "js-cookie";

const FeedSideBar = () => {
  const api = CelusApi();
  const history = useHistory();
  const [results, setResults] = React.useState([]);

  const colorSelect = "#50D19A";
  const colorDisable = "#F3F3F3";

  const [catView, setCatView] = React.useState("T");
  const [refBgTitle, setRefBgTitle] = React.useState("");

  const [selectSub, setSelectSub] = React.useState("");
  const [selectRepo, setSelectRepo] = React.useState("");

  useEffect(() => {
    if (catView === "T") {
      getGroupedResumus();
    } else {
      getGroupedResumusByClient();
    }
  }, [catView]);

  const getGroupedResumus = async () => {
    var res = await api.groupedResumus();

    var resum = await api.getResumusGrouped();

    res.map((item, index) => {
      res[index].subareas.map((subarea, subareaIndex) => {
        res[index].subareas[subareaIndex].resumus = [];

        resum.map((itemDoc) => {
          if (itemDoc.subAreaId === subarea.id) {
            res[index].subareas[subareaIndex].resumus.push(itemDoc);
          }
        });
      });
    });

    setResults(res);
  };

  const getGroupedResumusByClient = async () => {
    var res = await api.groupedResumus();

    const uid = Cookies.get("clientId");

    var resum = await api.getResumusByClient(uid);

    res.map((item, index) => {
      res[index].subareas.map((subarea, subareaIndex) => {
        res[index].subareas[subareaIndex].resumus = [];

        resum.map((itemDoc, resumusIndex) => {
          if (itemDoc.subAreaId === subarea.id) {
            res[index].subareas[subareaIndex].resumus.push(itemDoc);
          }
        });
      });
    });

    setResults(res);
  };

  const openCollapse = (name) => {
    if (refBgTitle === name) {
      setRefBgTitle("");
    } else {
      setRefBgTitle(name);
    }
  };

  const openReports = (name, id) => {
    if (selectSub === name) {
      setSelectSub("");
    } else {
      setSelectSub(name);
    }
  };

  const selectRepos = (name, id) => {
    if (selectRepo === name) {
      setSelectRepo("");
    } else {
      setSelectRepo(name);
    }
    history.push(`/to-see/resumus/${id}`);
  };

  return (
    <Fragment>
      <div className="content-menu">
        <div style={{ display: "flex" }}>
          <button
            className="button-header-left"
            style={{
              color: catView === "T" ? "#FFFFFF" : "#9F9F9F",
              background: catView === "T" ? colorSelect : colorDisable,
            }}
            onClick={() => setCatView("T")}
          >
            Todos
          </button>

          <button
            className="button-header-right"
            style={{
              color: catView === "M" ? "#FFFFFF" : "#9F9F9F",
              background: catView === "M" ? colorSelect : colorDisable,
            }}
            onClick={() => setCatView("M")}
          >
            Meus ResumUS
          </button>
        </div>

        <div>
          {results.map((item) => {
            let subareas = item.subareas;
            return (
              <div>
                <button
                  className="button-area"
                  onClick={() => {
                    openCollapse(item.name);
                  }}
                  style={{
                    fontWeight: refBgTitle === item.name ? "bold" : "normal",
                    background:
                      refBgTitle === item.name ? "#EBEBEB" : "#FFFFFF",
                    borderBottom:
                      refBgTitle === item.name ? 0 : "1px solid #EBEBEB",
                  }}
                >
                  {item.name}
                </button>

                <Collapse
                  isOpen={refBgTitle === item.name ? true : false}
                  id={item.id}
                >
                  {subareas.map((subItens) => {
                    var resumus = subItens.resumus;
                    return (
                      <div>
                        <button
                          onClick={() => {
                            openReports(subItens.name, subItens.id);
                          }}
                          className="button-subarea"
                          style={{
                            background: "#F9F9F9",
                          }}
                          id={subItens.id}
                        >
                          <div className="sub-itens">
                            <p
                              className="p"
                              style={{
                                color:
                                  selectSub === subItens.name
                                    ? "#31AF89"
                                    : "#000000",
                              }}
                            >
                              {subItens.name}{" "}
                            </p>

                            <p className="length-results">
                              {subItens.resumus.length < 10
                                ? "0" + subItens.resumus.length
                                : subItens.resumus.length}
                            </p>
                          </div>
                        </button>

                        <Collapse
                          isOpen={selectSub === subItens.name ? true : false}
                          id={item.id}
                          className="sd-bar"
                          style={{
                            overflow:
                              subItens.resumus.length > 5
                                ? "scroll"
                                : "hidden ",
                          }}
                        >
                          {resumus.map((resumus) => {
                            return (
                              <button
                                onClick={() => {
                                  selectRepos(resumus.title, resumus.id);
                                }}
                                className="button-results"
                                id={resumus.id}
                              >
                                <div>
                                  <div style={{ justifyContent: "start" }}>
                                    <p
                                      className="text-results"
                                      style={{
                                        color:
                                          selectRepo === resumus.title
                                            ? "#31AF89"
                                            : "#000000",
                                      }}
                                    >
                                      {resumus.title}
                                    </p>
                                  </div>
                                </div>
                              </button>
                            );
                          })}
                        </Collapse>
                      </div>
                    );
                  })}
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default FeedSideBar;
