import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, Modal, ModalBody } from 'reactstrap';
import * as S from './styled';
import CelusApi from '../../server/celusApi';
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { toast } from 'react-toastify';
import { ProtoculusText } from '../../utils';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { ProtocolusForm } from '../ProtocolusForm';
import { Dots } from 'react-activity';

export const Protoculus = () => {

    const api = CelusApi();
    const history = useHistory();
    const [specialties, setSpecialties] = useState([]);
    const [subAreas, setSubAreas] = useState([]);

    const [specialtie, setSpecialtie] = useState();
    const [subArea, setSubArea] = useState();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState();

    const [language, setLanguage] = useState(1);
    // states language pt-br
    const [titleBr, setTitleBr] = useState('');
    const [tagsBr, setTagsBr] = useState([])
    const [descriptionBr, setDescriptionBr] = useState('');
    const [sectionsBr, setSectionsBr] = useState([]);

    // states language en
    const [titleEn, setTitleEn] = useState('')
    const [tagsEn, setTagsEn] = useState([])
    const [descriptionEn, setDescriptionEn] = useState('');
    const [sectionsEn, setSectionsEn] = useState([]);

    // states modal's
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalErr, setIsOpenModalErr] = useState(false);
    const [isOpenModalInputErr, setIsOpenModalInputErr] = useState(false);


    const [languages, setLanguages] = useState([]);
    const languageSelect = useMemo(() => {
        return language === 1 ? ProtoculusText.br : ProtoculusText.en;
    }, [language]);

    useEffect(() => {
        const GetSpecialties = async () => {
            try {
                const res = await api.listAreas('laudus');
                setSpecialties(res);
            } catch (error) {
                console.log(error);
            }
        }
        GetSpecialties();
    }, [api]);

    useEffect(() => {
        const handlerFindLanguages = async () => {

            try {
                const result = await api.listLanguage();
                setLanguages(result);
            } catch (error) {
                console.log(error);
            } finally {

            }
        };

        handlerFindLanguages()
    }, [api]);

    useEffect(() => {
        const GetSubAreas = async () => {
            try {
                const res = await api.getListSubAreas({ specialtie: specialtie, type: 'protocolus' });
                setSubAreas(res);
            } catch (error) {
                console.log(error);
            }
        }
        GetSubAreas();
    }, [specialtie]);

    const inputErrorBr = [

        {
            field: 'Título',
            error: 'Campo Obrigatorio!',
            status: !titleBr,
        },
        {
            field: 'Especialidade',
            error: 'Campo Obrigatorio!',
            status: !specialtie,
        },
        {
            field: 'Categoria',
            error: 'Campo Obrigatorio!',
            status: !subArea,
        },
        {
            field: 'Descrição',
            error: 'Campo Obrigatorio!',
            status: !descriptionBr,
        },
        {
            field: 'Tags',
            error: 'Campo Obrigatorio!',
            status: !(tagsBr.length > 0),
        },
        {
            field: 'Sessão',
            error: 'Obrigatorio informar pelo menos 1 sessão!',
            status: !(sectionsBr.length > 0),
        },

    ];

    const inputErrorEn = [

        {
            field: 'Título',
            error: 'Campo Obrigatorio!',
            status: !titleEn,
        },
        {
            field: 'Especialidade',
            error: 'Campo Obrigatorio!',
            status: !specialtie,
        },
        {
            field: 'Categoria',
            error: 'Campo Obrigatorio!',
            status: !subArea,
        },
        {
            field: 'Descrição',
            error: 'Campo Obrigatorio!',
            status: !descriptionEn,
        },
        {
            field: 'Tags',
            error: 'Campo Obrigatorio!',
            status: !(tagsEn.length > 0),
        },
        {
            field: 'Sessão',
            error: 'Obrigatorio informar pelo menos 1 sessão!',
            status: !(sectionsEn.length > 0),
        }
    ];

    const handlerSubmit = async () => {
        setLoading(true);

        if (titleBr && descriptionBr && tagsBr.length > 0 && specialtie && subArea && sectionsEn.length > 0) {
            if (titleEn && descriptionEn && tagsEn.length > 0 && specialtie && subArea && sectionsEn.length > 0) {

                let data = new FormData();

                let values = {
                    areaId: specialtie,
                    subareaId: subArea,
                    adminId: Cookies.get('adminId'),
                    protocolusContent: [

                        {
                            languageId: sectionsBr[0]?.languageId,
                            title: titleBr,
                            description: descriptionBr,
                            tags: tagsBr,
                            section: sectionsBr
                        },
                        {
                            languageId: sectionsEn[0]?.languageId,
                            title: titleEn,
                            description: descriptionEn,
                            tags: tagsBr,
                            section: sectionsEn
                        },
                    ]

                }


                try {

                    data.append('data', JSON.stringify(values));

                    values.protocolusContent.map((protocolu, indexProtocolu) => {
                        protocolu.section.map((section, indexSection) => {
                            section.images.map((image, index) => {
                                data.append(
                                    section.languageId == 1 ? 'files' : 'enFiles',
                                    image,
                                    section.sectionId + `.${image.type.split('/')[1]}`
                                );
                            })
                        })
                    })

                    await api.PostProtoculus(data).then((response) => {
                        setResult(response.result)
                        setLoading(false);

                        setIsOpenModal(true);
                        toast.success('ProtocolUS cadastrado com seucesso!');

                    });

                } catch (error) {
                    console.log(error);
                    setIsOpenModalErr(!isOpenModalErr);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setIsOpenModalInputErr(true);
            }

        } else {
            setLoading(false);
            setIsOpenModalInputErr(true);
        }
    }

    const handlerModalSubmit = () => {
        return (
            <Fragment>
                <Modal isOpen={isOpenModal} toggle={() => setIsOpenModal(!isOpenModal)}>
                    <S.HeaderModal><S.HeaderTitle>ProtocolUS</S.HeaderTitle></S.HeaderModal>
                    <ModalBody>
                        <S.InfoModal>Parabéns! Sua publicação foi compartilhada com sucesso, clique para conferir!</S.InfoModal>
                    </ModalBody>
                    <S.FooterModal>
                        <S.Bottom color="#484848" onClick={() => { document.location.reload(true) }}>Novo</S.Bottom>
                        <S.Bottom bg="#31AF89" color="#f5f5f5" onClick={() => { history.push(`/view/protocolus/${result}/${titleBr}`) }}>Visualizar publicação</S.Bottom>
                    </S.FooterModal>
                </Modal>
            </Fragment>
        )
    }

    const handlerModalError = () => {
        return (
            <Fragment>
                <Modal isOpen={isOpenModalErr} toggle={() => setIsOpenModalErr(!isOpenModalErr)}>
                    <S.HeaderModal bg="#FF6766" ><S.HeaderTitle>ProtocolUS</S.HeaderTitle></S.HeaderModal>
                    <ModalBody>
                        <S.InfoModal>Houve um erro inesperado! Sua publicação não foi compartilhada, tente novamente mais tarde!</S.InfoModal>
                    </ModalBody>
                    <S.FooterModal>
                        <S.Bottom color="#FFF" bg="#FF6766" onClick={() => history.push('/')}>Sair</S.Bottom>
                        <S.Bottom color="#484848" onClick={() => setIsOpenModalErr(!isOpenModalErr)}>Tentar Denovo</S.Bottom>
                    </S.FooterModal>
                </Modal>
            </Fragment>
        )
    }

    const handlerModalInputError = () => {
        return (
            <Fragment>
                <Modal isOpen={isOpenModalInputErr} toggle={() => setIsOpenModalInputErr(!isOpenModalInputErr)}>
                    <S.HeaderModal bg="#FF6766" ><S.HeaderTitle>ProtocolUS</S.HeaderTitle></S.HeaderModal>
                    <ModalBody>
                        <S.HeaderTitle color="#484848">Campos em Português:</S.HeaderTitle>
                        <br />
                        {
                            inputErrorBr.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <S.ItemError>
                                            {item.status ? (<AiFillCloseCircle color="#FF6766" />) : (<AiFillCheckCircle color="#31AF89" />)}
                                            <span> <b>{item.field}:</b> {item.status ? item.error : ''}</span>
                                        </S.ItemError>
                                    </Fragment>
                                )
                            })
                        }
                        <hr />
                        <S.HeaderTitle color="#484848">Campos em Inglês:</S.HeaderTitle>
                        <br />

                        {
                            inputErrorEn.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <S.ItemError>
                                            {item.status ? (<AiFillCloseCircle color="#FF6766" />) : (<AiFillCheckCircle color="#31AF89" />)}
                                            <span> <b>{item.field}:</b> {item.status ? item.error : ''}</span>
                                        </S.ItemError>
                                    </Fragment>
                                )
                            })
                        }
                    </ModalBody>
                    <S.FooterModal>
                        <S.Bottom color="#FFF" bg="#FF6766" onClick={() => setIsOpenModalInputErr(!isOpenModalInputErr)}>Sair</S.Bottom>
                        <S.Bottom color="#484848" onClick={() => setIsOpenModalInputErr(!isOpenModalInputErr)}>Corrigir Erro(s)</S.Bottom>
                    </S.FooterModal>
                </Modal>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Card>
                <S.CardHeaderStyled>
                    <S.CardTitleStyled>{languageSelect.headerTitle} </S.CardTitleStyled>
                </S.CardHeaderStyled>
                <S.Headerlanguage>
                    {
                        languages.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <S.HeaderCurrentLanguage
                                        style={index === 0 ? { borderRadius: '0px 0px 10px 0px' } : index === 1 ? { borderRadius: '0px 0px 10px 10px' } : { borderRadius: '0px 0px 0px 10px' }}
                                        current={language === item.id}
                                        disabled={item.id === 3}
                                        onClick={() => { setLanguage(item.id) }}
                                    >
                                        <S.Icon src={item.icon} />
                                        {item.name}
                                        {
                                            (item.id === 1 || item.id === 2) ? (<S.Required> *</S.Required>) : null
                                        }
                                    </S.HeaderCurrentLanguage>
                                </Fragment>
                            )
                        })
                    }
                </S.Headerlanguage>

                {
                    language === 1 ? (
                        <ProtocolusForm
                            title={titleBr}
                            setTitle={setTitleBr}
                            specialties={specialties}
                            setSpecialties={setSpecialties}
                            subAreas={subAreas}
                            setSubAreas={setSubAreas}
                            specialtie={specialtie}
                            setSpecialtie={setSpecialtie}
                            subArea={subArea}
                            setSubArea={setSubArea}
                            loading={loading}
                            setLoading={setLoading}
                            tags={tagsBr}
                            setTags={setTagsBr}
                            description={descriptionBr}
                            setDescription={setDescriptionBr}
                            languageSelect={languageSelect}
                            sections={sectionsBr}
                            setSections={setSectionsBr}
                            languageId={language}
                        />
                    ) : language === 2 ? (
                        <ProtocolusForm
                            title={titleEn}
                            setTitle={setTitleEn}
                            specialties={specialties}
                            setSpecialties={setSpecialties}
                            subAreas={subAreas}
                            setSubAreas={setSubAreas}
                            specialtie={specialtie}
                            setSpecialtie={setSpecialtie}
                            subArea={subArea}
                            setSubArea={setSubArea}
                            loading={loading}
                            setLoading={setLoading}
                            tags={tagsEn}
                            setTags={setTagsEn}
                            description={descriptionEn}
                            setDescription={setDescriptionEn}
                            languageSelect={languageSelect}
                            sections={sectionsEn}
                            setSections={setSectionsEn}
                            languageId={language}
                        />
                    ) : (
                        <ProtocolusForm
                            title={titleBr}
                            setTitle={setTitleBr}
                            specialties={specialties}
                            setSpecialties={setSpecialties}
                            subAreas={subAreas}
                            setSubAreas={setSubAreas}
                            specialtie={specialtie}
                            setSpecialtie={setSpecialtie}
                            subArea={subArea}
                            setSubArea={setSubArea}
                            loading={loading}
                            setLoading={setLoading}
                            tags={tagsBr}
                            setTags={setTagsBr}
                            description={descriptionBr}
                            setDescription={setDescriptionBr}
                            languageSelect={languageSelect}
                            sections={sectionsBr}
                            setSections={setSectionsBr}
                            languageId={language}
                        />
                    )
                }

                <S.PostBtn onClick={handlerSubmit} disabled={loading} style={{ marginLeft: 20, marginBottom: 20 }}>
                    {loading ? <Dots /> : languageSelect.submit}
                </S.PostBtn>

                <S.CardFooterStyled>
                    <S.RequiredText>
                        <S.Required>*</S.Required>
                        {languageSelect.requiredInfo}
                    </S.RequiredText>
                </S.CardFooterStyled>

                {handlerModalSubmit()}
                {handlerModalError()}
                {handlerModalInputError()}

            </Card>
        </Fragment>
    )
}
