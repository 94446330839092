import Cookies from 'js-cookie';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col } from 'reactstrap';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CelusApi from '../../server/celusApi';
import * as S from './styles';
import { useHistory } from 'react-router';

export const User = () => {
    const hystory = useHistory();
    const api = CelusApi(); 
    const [email, setEmail] = useState(Cookies.get('email'));
    const [checkEmail, setCheckEmail] = useState(Cookies.get('email'));
    const [name, setName] = useState(Cookies.get('name'))
    const [password, setPassword] = useState();
    const [checkPassword, setCheckPassword] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [edit, setEdit] = useState(true);
    const [errs, setErrs] = useState({
        name: undefined,
        checkEmail: undefined,
        checkPassword: undefined,
        email: undefined,
        password: undefined,
    });

    useEffect(() => {
        CheckPassword()
    }, [password, checkPassword]);

    useEffect(() => {
        CheckEmail()
    }, [email, checkEmail]);

    const CheckEmail = () => {
        return email ===checkEmail;
    }
    const CheckPassword = () => {
        return password ===checkPassword;
    }

    const CheckName = () => {
        return name ===undefined;
    }

    const Submit = async () => {

        if (!edit) {
            if (name && password && checkPassword && password.length >= 6) {
                setLoading(true);
                try {
                    const values = {
                        name: name,
                        password: password,
                        adminId: Cookies.get('adminId'),
                        clientId: Cookies.get('clientId'),
                    }
                    await api.PutAdmin(values);
                    toast.success(`Usuário atualizado: ${email}`);
                    Cookies.set('name', name);
                    setLoading(false);
                    hystory.push('/dashboard');
                } catch (error) {
                    console.log(error);
                    toast.error(`Não foi possivel atualizar o usuário: ${email}`);
                    setLoading(false);
                }

            } else {

                if (!name) {
                    errs.name = 'O campo Nome é obrigatorio!';
                } else {
                    errs.name = null;
                }
                if (!password) {
                    errs.password = 'O campo Senha é obrigatorio!';
                } else {
                    if (password.length < 6) {
                        errs.password = 'A senha precisa ter pelo menos 6 (seis) caracteres!';
                    } else {
                        errs.password = null;
                    }
                }
                if (!checkPassword) {
                    errs.checkPassword = 'O campo Confirmar Senha é obrigatorio!';
                } else {
                    errs.checkPassword = null;
                }
                if (!email) {
                    errs.email = 'O campo E-mail é obrigatorio!';
                } else {
                    errs.email = null;
                }
                if (!checkEmail) {
                    errs.checkEmail = 'O campo Confirmar E-mail é obrigatorio!';
                } else {
                    errs.checkEmail = null;
                }


                setErrs({ ...errs });
                setError(true);
                // setIsValidationModalOpen(true);
                setLoading(false);
            }

        } else {
            toast.error(`Edição não habilitada`);
        }

    }


    const Form = () => {
        return (
            <Card className="mb-3">
                <S.ICardHeader>Meus Dados
                    <S.Edit onClick={() => { setEdit(!edit) }}>Editar</S.Edit>
                </S.ICardHeader>
                <CardBody>

                    <S.IFormGroup>
                        <S.ILabel for="exampleName">Nome Completo <S.IRequired>*</S.IRequired> </S.ILabel>
                        <S.IInput
                            disabled={edit}
                            checked={!CheckName()}
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Nome completo"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                        {(error ===true && !name) ? <S.errorMessage >{errs.name}</S.errorMessage > : null}
                    </S.IFormGroup>
                    <S.IFormGroup>
                        <S.ILabel for="exampleName">E-mail <S.IRequired>*</S.IRequired></S.ILabel>
                        <S.IInput
                            
                            disabled={true}
                            checked={CheckEmail()}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                        {(error ===true && !email) ? <S.errorMessage >{errs.email}</S.errorMessage > : null}
                    </S.IFormGroup>

                    <S.Row>
                        <S.IFormGroup style={{ width: '48%' }}>
                            <S.ILabel for="exampleName">Senha <S.IRequired>*</S.IRequired></S.ILabel>
                            <S.IInput
                                disabled={edit}
                                checked={CheckPassword()}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                            {(error ===true && !password) ? <S.errorMessage >{errs.password}</S.errorMessage > : null}
                            {(error ===true && errs.password && password) ? <S.errorMessage >{errs.password}</S.errorMessage > : null}
                        </S.IFormGroup>

                        <S.IFormGroup style={{ width: '48%' }}>
                            <S.ILabel for="exampleName">Confirmar senha <S.IRequired>*</S.IRequired></S.ILabel>
                            <S.IInput
                                disabled={edit}
                                checked={CheckPassword()}
                                type="password"
                                name="checkPassword"
                                id="checkPassword"
                                placeholder="Confirmar senha"
                                value={checkPassword}
                                onChange={(e) => { setCheckPassword(e.target.value) }}
                            />
                            {(error ===true && !checkPassword) ? <S.errorMessage >{errs.checkPassword}</S.errorMessage > : null}
                            {(error ===true && errs.password && password) ? <S.errorMessage >{errs.password}</S.errorMessage > : null}
                        </S.IFormGroup>

                    </S.Row>
                    <S.Row >
                        <S.IButton style={{ marginTop: 35 }} onClick={Submit} disabled={loading} >Salvar</S.IButton>
                    </S.Row>
                </CardBody>
            </Card>
        )
    }
    return (
        <Fragment>
            <Col lg="8">
                <ContentWithAsideLayout banner={Form()}>
                </ContentWithAsideLayout>
            </Col>
            {/* {ValidationModal()} */}
        </Fragment>
    )
}