import styled from 'styled-components';

export const CardItem = styled.div`
    max-width: 280px;
    min-width: 280px;
    min-height: 360px;
    max-height: 360px;

    cursor: pointer;

    background: transparent linear-gradient(90deg, #31AF89 0%, #50D19A 100%) 0% 0% no-repeat padding-box;

    border-radius: 5px;

    box-shadow: 0px 3px 6px #00000029;

    transition: 0.5s;
   

    margin-right: 50px;
`;

export const ImagePreview = styled.img`
    width: 100%;
    min-height: 250px;
    max-height: 250px;

    object-fit: cover;
    transition: 0.3s;
    filter: grayscale(40%);

    :hover{
        filter: grayscale(0%);
        transform: scale(1.02);  
    }


    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

export const CardInfo = styled.div`
    min-height: 110px;
    flex: 1;
    padding: 5px;
    position: relative;
`;

export const Title = styled.h5`
    font-size: 16px;
    text-transform: none;
    color: #FFFFFF;
`;

export const RowInfo = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between !important;
    align-items: center;

    bottom: 0;
    position: absolute;
    padding: 5px;
`;

export const Author = styled.h6`
    font-size: 12px;
    text-transform: none;
    margin: 0;
    padding: 0;
    color:#FFFFFF ;
`;

export const CardFunctions = styled.div`
    min-width: 60px;
    padding: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000040;

    cursor: pointer !important;

    margin-right: 5px;
    justify-content: space-between;
`;
