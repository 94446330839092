import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FaRegBell, FaBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Card, Dropdown, DropdownMenu } from 'reactstrap';
import ListGroup from 'reactstrap/es/ListGroup';
import FalconCardHeader from '../common/FalconCardHeader';
import CelusApi from '../../server/celusApi';
import Avatar from '../common/Avatar';

import { User } from '../../assets/img/icons/celus';

import './scroll.scss';
import { socket } from '../../server/socket';
const SaveLaudusResumus = () => {

  const api = CelusApi();
  const [values, setValues] = useState([]);
  const [status, setStatus] = useState(false);

  const ws = useRef();

  useEffect(() => {

    ws.current = socket();
    const stopButton1 = document.querySelector('#stopButton1');
    const c4 = 349.2;

    let context,
      oscillator,
      contextGain,
      x = 1,
      type = 'sine',
      frequency;
    
    function start() {
      context = new AudioContext();
      oscillator = context.createOscillator();
      contextGain = context.createGain();
      oscillator.frequency.value = frequency;
      oscillator.type = type;
      oscillator.connect(contextGain);
      contextGain.connect(context.destination);
      oscillator.start(0);
    }

    function stop() {
      start();
      contextGain.gain.exponentialRampToValueAtTime(
        0.00001, context.currentTime + x
      )
    }

    stopButton1.addEventListener('click', function () {
      frequency = c4;
      stop();
    });

    ws.current.on('new-content', data => {
      frequency = c4;
      stop();
      setStatus(true);
      GetNotifications();
    });
     }, []);


  useEffect(() => {
    GetNotifications();
     }, []);


  const putStatusNotification = async (values) => {
    await api.putStatusNotification(values);
  }

  const GetNotifications = async () => {
    const result = await api.getNotifications();
    setValues(result.result);

    result.result.map((item, index) => {
      if (item.status === 0) {
        setStatus(true);
      }
    });
  }

  // State
  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const DateNotification = (date) => {
    const altData = new Date();
    const Data = new Date(date);
    return Math.round((Math.abs(altData - Data) / (1000 * 3600 * 24)));
  }


  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >

      {status ? <FaBell transform="shrink-4" className="fs-2" color='#31AF89' /> : <FaRegBell transform="shrink-4" className="fs-2" color='#344050' />}

      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
          <FalconCardHeader className="card-header" title="Notificações" titleTag="h6" light={false}>
          </FalconCardHeader>
          <ListGroup flush className="font-weight-normal fs--1 scroll" style={{ maxHeight: 360, backgroundColor: '#FFF', overflow: 'scroll', overflowX: 'hidden' }}>
            <div className="list-group-title center">Novas</div>
            <div className='center__notification'>

              {
                values.map((item, index) => {

                  return (

                    <div className='item__notification' style={{ backgroundColor: item.status === 0 ? '#F5F5F5' : '#FFF' }}>
                      {
                        item.status === 0 ? (
                          <Fragment>
                            <div className='mark__notificationTrue'>
                              <div className='mark__notification' />
                            </div>
                          </Fragment>
                        ) : null
                      }

                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                        <div style={{
                          marginTop: 'auto',
                          marginBottom: 'auto'
                        }}>
                          <Avatar src={item?.client?.avatar ? item?.client?.avatar : User} />
                        </div>
                        <p
                          className='num__line'
                          style={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            marginLeft: 10,
                            marginBottom: 0

                          }} dangerouslySetInnerHTML={{ __html: item.content + " Clique para revisar." }} onClick={() => {
                            putStatusNotification(item.id)
                            window.location.href = item.notificationAdminLaudus.length > 0 ? `/laudus/review/${item.notificationAdminLaudus[0].idLaudus}` : item.notificationAdminResumus.length > 0 ? `/resumus/review/${item.notificationAdminResumus[0].idResumus}` : null
                          }} />

                      </div>
                      <p style={{ color: '#636363', fontSize: 11, fontWeight: 'bold', margin: 0 }}> {DateNotification(item.createdAt) <= 1 ? "Hoje - " : "há " + DateNotification(item.createdAt) + ` ${DateNotification(item.createdAt) === 1 ? ' dia - ' : ' dias - '} `}  {item.notificationAdminLaudus.length > 0 ? 'LaudUS' : item.notificationAdminResumus.length > 0 ? 'ResumUS' : null}</p>
                    </div>
                  )
                })
              }
            </div>

          </ListGroup>
          <div className="card-footer text-center border-top" onClick={handleToggle}>
            {/* //to="/pages/notifications" */}
            <Link style={{ color: '#000' }} className="card-link d-block" to="/admin/notifications" >
              Ver tudo
              <button id="stopButton1" hidden >C</button>
            </Link>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SaveLaudusResumus;
