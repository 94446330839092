import React, { Fragment, useState } from 'react';
import { CardBody, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Editor } from '../Editor';
import * as S from './styled';
import { AiOutlineCamera, AiOutlineClose, AiOutlinePlus, AiFillCaretLeft, AiFillCaretRight, AiFillCaretUp, AiFillCaretDown, AiOutlineExpand, AiOutlineSelect } from "react-icons/ai";
import ReactTagInput from '@pathofdev/react-tag-input';
import 'react-activity/dist/library.css';
import md5 from 'md5';
import { ModalTitle } from 'react-bootstrap';
export const ProtocolusForm = ({
    title,
    setTitle = Function,
    specialties,
    subAreas,
    specialtie,
    setSpecialtie = Function,
    subArea,
    setSubArea = Function,
    loading,
    tags,
    setTags = Function,
    description,
    setDescription = Function,
    languageSelect,
    sections,
    setSections = Function,
    languageId,

}) => {

    const [selectViewImage, setSelectViewImage] = useState(null);
    const [openModalViewImage, setOpenModalViewImage] = useState(false);

    const ModalImageView = () => {
        return(
            <Fragment>
                <Modal isOpen={openModalViewImage} toggle={() => setOpenModalViewImage(!openModalViewImage)} style={{width: '50%'}}>
                    <ModalHeader>
                        <ModalTitle>{selectViewImage?.title}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <img src={selectViewImage?.src} alt="image section select protocolus"/>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }

    const handlerSections = (events) => {
        let files = events.target.files;

        Object.keys(files).forEach(function (item) {
            sections.push({
                sectionId: md5(new Date().getTime() +  "" + files[item].name + item.toString() + files[item].lastModifiedDate),
                title: '',
                description: '',
                languageId: Number(languageId),
                images: [files[item]],
                filesUrl: [URL.createObjectURL(files[item])]
            })

            setSections([...sections]);
        });
    };

    const handlerSectionImages = (events, index) => {
        let files = events.target.files;

        Object.keys(files).forEach(function (item) {
            sections[index].images.push(files[item])
            sections[index].filesUrl.push(URL.createObjectURL(files[item]))
            setSections([...sections]);
        });
    };

    const RemoveFile = (from, index) => {
        sections[index].images.splice(from, 1);
        sections[index].filesUrl.splice(from, 1);
        setSections([...sections]);
    };

    const RemoveSection = (from) => {
        sections.splice(from, 1);
        setSections([...sections]);
    };

    const UpPositionSection = (from, to) => {
        sections.splice(to, 0, sections.splice(from, 1)[0]);
        setSections([...sections]);
    };
    const UpPositionFile = (from, to, index) => {
        sections[index].filesUrl.splice(to, 0, sections[index].filesUrl.splice(from, 1)[0]);
        sections[index].images.splice(to, 0, sections[index].images.splice(from, 1)[0]);
        setSections([...sections]);
    };
    const handlerSelectThumbnail = (from, index) => {
        sections[index].filesUrl.splice(0, 0, sections[index].filesUrl.splice(from, 1)[0]);
        sections[index].images.splice(0, 0, sections[index].images.splice(from, 1)[0]);
        setSections([...sections]);
    }

    const handlerSelectImageView = (file, title) => {
        setSelectViewImage({
            src: file,
            title: title,
        })
    }

    return (
        <Fragment>
            <CardBody>
                <S.CardInfo>{languageSelect.pageInfo}</S.CardInfo>

                <S.AreaInput>
                    <S.Label>{languageSelect.inputTitle}<S.Required> *</S.Required> </S.Label>

                    <Input value={title} onChange={(event) => setTitle(event.target.value)} maxLength="100" />

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <S.Label style={{ fontSize: 13 }}>
                            <strong style={{ color: (title.length > 70) && (title.length !== 100) ? "#ff8c00" : title.length === 100 ? '#FF6766' : '#9c9a9a' }}>Número de caracteres: [{`${title.length}/100`}]</strong>
                        </S.Label>
                    </div>

                </S.AreaInput>

                <S.Row>
                    <S.AreaInput width="49%">
                        <S.Label>{languageSelect.inputSpecialty} <S.Required>*</S.Required> </S.Label>
                        <Input type="select" name="select" id="exampleSelect" onChange={e => { setSpecialtie(e.target.value) }} disabled={loading}>
                            <option value="" disabled selected hidden>{languageSelect.inputSpecialty}</option>
                            {specialties.map((item, index) => {
                                return (
                                    <option key={index} value={item.id} selected={specialtie === item.id}>{item.name}</option>
                                );
                            })}
                        </Input>
                    </S.AreaInput>

                    <S.AreaInput width="49%">
                        <S.Label>{languageSelect.inputCategory} <S.Required>*</S.Required> </S.Label>
                        <Input type="select" name="select" id="exampleSelect" onChange={e => { setSubArea(e.target.value) }} disabled={loading}>
                            <option value="" disabled selected hidden>{languageSelect.inputCategory}</option>
                            {subAreas.map((item, index) => {
                                return (
                                    <option key={index} value={item.id} selected={subArea === item.id}>{item.name}</option>
                                );
                            })}
                        </Input>
                    </S.AreaInput>
                </S.Row>

                <S.AreaInput>
                    <S.Label>{languageSelect.inputDescription} <S.Required>*</S.Required> </S.Label>
                    <Editor values={description} setValues={setDescription} />
                </S.AreaInput>

                <S.AreaInput>
                    <S.Label>{languageSelect.inputTag} <S.Required>*</S.Required></S.Label>
                    <ReactTagInput tags={tags} onChange={(newTags) => { setTags(newTags) }} placeholder="Tags" />
                </S.AreaInput>

                <S.Label>{languageSelect.inputImages} <S.Required>*</S.Required> </S.Label>

                <S.AreaInputImage className='scroll'>
                    <S.RowImage>
                        <S.ImageBtn >
                            <AiOutlineCamera size={24} />
                            <Input
                                id="addImagesBr"
                                type='file'
                                multiple
                                value=""
                                style={{ display: 'none' }}
                                accept="image/*"

                                onChange={handlerSections}
                            />
                            <S.AddImgBtn>
                                <AiOutlinePlus size={25} color="#FFF" />
                                <Input
                                    id="addImagesEn"
                                    type='file'
                                    value=""
                                    multiple
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handlerSections}
                                />
                            </S.AddImgBtn>
                        </S.ImageBtn>
                        <S.CardInfo style={{ marginLeft: 20 }}>
                            {languageSelect.imageInfo}
                        </S.CardInfo>
                    </S.RowImage>
                    {
                        sections.map((section, index) => {
                            return (
                                <S.RowImageContainer key={index}>

                                    <S.AreaInput width="160px" style={{ marginBottom: 30 }}>
                                        <S.Label style={{ fontSize: 12, width: 150 }}>{languageSelect.titleImageSpotlight} <S.Required> *</S.Required></S.Label>
                                        <S.InputImageSpotlight src={sections[index].filesUrl.length > 0 ? sections[index].filesUrl[0] : null} />
                                    </S.AreaInput>


                                    <S.AreaInput width="100%" style={{ marginTop: 20 }}>
                                        <S.AreaInput width="75%">
                                            <S.Label>{languageSelect.inputTitle}<S.Required> *</S.Required> </S.Label>

                                            <Input value={sections[index].title} onChange={(event) => {
                                                sections[index].title = event.target.value;
                                                setSections([...sections])
                                            }} maxLength="100" />

                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                                <S.Label style={{ fontSize: 13 }}>
                                                    <strong style={{ color: (sections[index].title.length > 70) && (sections[index].title.length !== 100) ? "#ff8c00" : sections[index].title.length === 100 ? '#FF6766' : '#9c9a9a' }}>Número de caracteres: [{`${sections[index].title.length}/100`}]</strong>
                                                </S.Label>
                                            </div>
                                        </S.AreaInput>

                                        <S.RowImage>
                                            <S.ImageBtn >
                                                <AiOutlineCamera size={24} />
                                                <Input
                                                    id="addImagesBr"
                                                    type='file'
                                                    multiple
                                                    value=""
                                                    style={{ display: 'none' }}
                                                    accept="image/*"

                                                    onChange={(e) => handlerSectionImages(e, index)}
                                                />
                                                <S.AddImgBtn>
                                                    <AiOutlinePlus size={25} color="#FFF" />
                                                    <Input
                                                        id="addImagesEn"
                                                        type='file'
                                                        value=""
                                                        multiple
                                                        style={{ display: 'none' }}
                                                        accept="image/*"
                                                        onChange={(e) => handlerSectionImages(e, index)}
                                                    />
                                                </S.AddImgBtn>
                                            </S.ImageBtn>
                                            <S.CardInfo style={{ marginLeft: 20 }}>
                                                Adicione mais imagens e videos da mesma seleção do titulo.
                                            </S.CardInfo>
                                        </S.RowImage>

                                        <S.RowArray className='scroll'>
                                            {
                                                sections[index].filesUrl?.map((fileUrl, indexFile) => {
                                                    return (
                                                        <S.ItemImage key={indexFile}>
                                                            <S.InputImage src={fileUrl} />
                                                            <S.DeleteImgBtn onClick={() => { RemoveFile(indexFile, index) }}>
                                                                <AiOutlineClose size={15} color="#202020" />
                                                            </S.DeleteImgBtn>

                                                            <S.DeleteImgBtn hoverBg={"#CCC"} style={{ top: '25%' }} onClick={() => {setOpenModalViewImage(!openModalViewImage); handlerSelectImageView(fileUrl, sections[index].title )}}>
                                                                <AiOutlineExpand size={15} color="#202020" />
                                                            </S.DeleteImgBtn>

                                                            <S.DeleteImgBtn disabled={indexFile === (sections[index].filesUrl.length - 1)} hoverBg={"#31AF89"} onClick={() => { UpPositionFile(indexFile, indexFile + 1, index) }} style={{ top: '50%' }}>
                                                                <AiFillCaretRight size={15} color="#202020" />
                                                            </S.DeleteImgBtn>

                                                            <S.DeleteImgBtn disabled={indexFile === 0} hoverBg={"#31AF89"} onClick={() => { UpPositionFile(indexFile, indexFile - 1, index) }} style={{ top: '75%' }}>
                                                                <AiFillCaretLeft size={15} color="#202020" />
                                                            </S.DeleteImgBtn>

                                                            <S.DeleteImgBtn hoverBg={"#DDD"} onClick={() => { handlerSelectThumbnail(indexFile, index) }} style={{ top: '50%', left: '45%' }}>
                                                                <AiOutlineSelect size={15} color="#202020" />
                                                            </S.DeleteImgBtn>

                                                        </S.ItemImage>
                                                    )
                                                })
                                            }
                                        </S.RowArray>
                                    </S.AreaInput >

                                    <S.DeleteImgBtn onClick={() => { RemoveSection(index) }} style={{ top: -5, right: -5 }}>
                                        <AiOutlineClose size={20} color="#202020" />
                                    </S.DeleteImgBtn>

                                    <S.DeleteImgBtn hoverBg={"#31AF89"} disabled={index === 0} onClick={() => { UpPositionSection(index, index - 1) }} style={{ top: '90%', left: 20 }}>
                                        <AiFillCaretUp size={15} color="#202020" />
                                    </S.DeleteImgBtn>

                                    <S.DeleteImgBtn hoverBg={"#31AF89"} disabled={ index === (sections.length - 1)} onClick={() => { UpPositionSection(index, index + 1) }} style={{ top: '90%', left: 50 }}>
                                        <AiFillCaretDown size={15} color="#202020" />
                                    </S.DeleteImgBtn>

                                </S.RowImageContainer>
                            )
                        })
                    }


                </S.AreaInputImage>
                {ModalImageView()}
            </CardBody>
        </Fragment>
    )
}


