import React, { Fragment, useEffect } from 'react';
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import './styles.scss';
import * as S from './style/approve';

import CelusApi from '../../server/celusApi';

const ApproveSideBar = () => {
  const api = CelusApi()
  const [isOpen, setIsOpen] = React.useState(false);
  const [status, setStatus] = React.useState('approved');
  const [author, setAuthor] = React.useState('');
  const [authorId, setAuthorId] = React.useState('');
  const { laudusId } = useParams();
  const [collapseOne, collapseOneOpen] = React.useState(false);
  const [collapseOneDis, collapseOneOpenDis] = React.useState(false);
  const [data, setData] = React.useState('');

  const cancel = () => {
    window.location.href = '/list/table/laudus';
  }

  const ModalStatusApproved = () => {
    return (
      <Fragment>
        <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)}>
          <ModalHeader>Publicar LaudUS?</ModalHeader>
          <ModalBody>
          Você tem certeza de que gostaria de publicar esse resumUS? Ao clicar em sim, ele ficará disponível no aplicativo para todos os usuários.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => collapseOneOpen(!collapseOne)}>
              Não
            </Button>
            <Button color="primary" onClick={PutStatusLaudus}>Sim</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
  const ModalStatusDisapproved = () => {
    return (
      <Fragment>
        <Modal isOpen={collapseOneDis} toggle={() => collapseOneOpenDis(!collapseOneDis)}>
          <ModalHeader>Reprovar LaudUS?</ModalHeader>
          <ModalBody>
          Você tem certeza de que gostaria de reprovar este laudUS? Ao clicar em sim o resumUS não será publicado e o autor receberá uma notificação.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => collapseOneOpenDis(!collapseOneDis)}>
              Não
            </Button>
            <Button color="primary" onClick={PutStatusLaudus}>Sim</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }

  useEffect(() => {
    GetLaudusByIdLaudus();
     }, []);


  const GetLaudusByIdLaudus = async () => {
    try {
      const result = await api.getLaudusByIdLaudus(laudusId);
      setAuthor(result.result[0].client.name);
      setAuthorId(result.result[0].client.id);
      setStatus(result.result[0].status);
      const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago","Set","Out","Nov","Dez"];
      let auxData = new Date(result.result[0].createdAt);
      let dataFormatada = ((auxData.getDate() + " " + meses[(auxData.getMonth())] + " " + auxData.getFullYear()));
      setData(dataFormatada);

    } catch (error) {
      console.log(error);
    }
  }

  const PutStatusLaudus = async () => {
    try {
      const values = {
        status: status,
        id: laudusId,
        clientId: authorId
      }
      await api.putStatusLaudus(values);
      window.location.href = '/list/table/laudus';
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <S.Container>
      <S.HeaderArea>
        <S.HeaderTitle>Publicar / Reprovar</S.HeaderTitle>
      </S.HeaderArea>
      <S.Body>
        <S.Line>
          <S.DefaultBold >Status: </S.DefaultBold>
          <S.StatusSelect type="select" name="select" onChange={e => { setStatus(e.target.value) }} >
          <option value="" disabled selected hidden>Status</option>
            <option value="approved" >Aprovado</option>
            <option value="disapproved" >Reprovado</option>
          </S.StatusSelect>
        </S.Line>
        <S.Line>
          <S.DefaultBold >Enviado em: </S.DefaultBold>
          <S.DefaultText>{data}</S.DefaultText>
        </S.Line>
        <S.Line>
          <S.DefaultBold>Autor: </S.DefaultBold>
          <S.DefaultText>{author}</S.DefaultText>
        </S.Line>
      </S.Body>
      <S.Bottom>
        <S.CancelBtn onClick={cancel}>Cancelar</S.CancelBtn>

        {
          status ==='approved' ? <S.PublishBtn style={{ background: '#31AF89' }} onClick={() => collapseOneOpen(!collapseOne)}>Publicar</S.PublishBtn>
          : status ==='disapproved' ?
          <S.PublishBtn style={{ background: '#FF6766' }} onClick={() => collapseOneOpenDis(!collapseOneDis)}>Reprovar</S.PublishBtn> : null
        }

      </S.Bottom>
      {ModalStatusApproved()}
      {ModalStatusDisapproved()}
    </S.Container>
  )
};

export default ApproveSideBar;
