import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardFooter, Modal, ModalBody } from "reactstrap";
import { Br, En, Es } from "../../assets/img/icons/celus";
import celusApi from "../../server/celusApi";
import * as S from "./styled";
import {
  AiFillCaretRight,
  AiFillCaretLeft,
  AiOutlineDelete,
} from "react-icons/ai";
import {
  FaBookmark as SaveFilled,
  FaRegBookmark as SaveOutline,
  FaShareAlt,
} from "react-icons/fa";

import { BiPencil, BiChevronDown } from "react-icons/bi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { ModalTitle } from "react-bootstrap";
import { ChangeIsFreeContent } from "../ChangeIsFreeContent/ChangeIsFreeContent";

export const ViewProtoculus = () => {
  const [showModalLanguade, setShowModalLaguage] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [isSave, setIsSave] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [isFreeContent, setIsFreeContent] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalSection, setIsOpenModalSection] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [values, setValues] = useState();
  const [images, setImages] = useState([]);
  const [titleSection, setTitleSection] = useState();
  const imgsContainer = document.getElementById("imgs-container");

  const [scrollX, setScrollX] = useState(0);

  const [area, setArea] = useState({
    icon: "",
    name: "",
  });

  const [currentImage, setCurrentImage] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  const { id } = useParams();

  const api = celusApi();

  useEffect(() => {
    handlerGetProtoculusById();
  }, [id, currentLanguage]);

  useEffect(() => {
    setTimeout(() => {
      handlerCheckedFavoriteProtoculus();
    }, 10);
  }, []);

  const handlerCheckedFavoriteProtoculus = async () => {
    try {
      let values = { id: id, clientId: Cookies.get("clientId") };
      const result = await api.CheckedFavoriteProtoculus(values);
      setIsSave(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handlerDeleteProtoculus = async () => {
    try {
      await api.DeleteProtoculus({ id: id });
      toast.success("ProtocolUS deletado com seucesso!");
    } catch (error) {
      console.log(error);
    } finally {
      setIsOpenModal(!isOpenModal);
      history.push("/protocolus");
    }
  };
  const handlerFavoriteProtoculus = async () => {
    try {
      let values = { id: id, clientId: Cookies.get("clientId") };
      await api.FavoriteProtoculus(values);
      setIsSave(!isSave);
    } catch (error) {
      console.log(error);
    }
  };

  const handlerGetProtoculusById = async () => {
    try {
      setLoading(true);
      setValues({});
      setTitleSection(null);
      setCurrentSection(null);
      setCurrentImage(0);
      let arrayImages = [];

      let data = { id: id, languageId: currentLanguage };
      const result = await api.ShowProtoculus(data);

      setIsFreeContent(result.isFreeContent);

      setValues(result.contents[0]);
      setTitleSection(result.contents[0].sections[0].title);
      setCurrentSection(result.contents[0].sections[0].id);

      result.contents[0].sections.map((section) => {
        section.images.map((image) => {
          arrayImages.push(image);
        });
      });

      setImages([...arrayImages]);

      area.icon = result.area.iconBlack;
      area.name = result.area.name;
      setArea({ ...area });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentSection && !loading) {
      setTitleSection(
        values.sections.filter((item) => item.id === currentSection)[0].title
      );
    }
  }, [currentSection]);

  const ModalDeleteProtoculus = () => {
    return (
      <Fragment>
        <Modal isOpen={isOpenModal} toggle={() => setIsOpenModal(!isOpenModal)}>
          <S.HeaderModal bg="#FF6766">
            <ModalTitle>
              <S.ModalTitle>Excluir ProtocolUS</S.ModalTitle>{" "}
            </ModalTitle>
          </S.HeaderModal>
          <ModalBody>
            <S.InfoModal>
              Você tem certeza de que gostaria de excluir este ProtocolUS? Ao
              clicar em sim o ProtocolUS será excluido permanentemente.
            </S.InfoModal>
          </ModalBody>
          <S.FooterModal>
            <S.Bottom
              color="#484848"
              hoverBg="#31AF89"
              hoverColor="#F5f5f5"
              onClick={() => setIsOpenModal(!isOpenModal)}
            >
              Não
            </S.Bottom>
            <S.Bottom
              hoverBg="#FF6766"
              color="#484848"
              hoverColor="#F5f5f5"
              onClick={handlerDeleteProtoculus}
            >
              Sim
            </S.Bottom>
          </S.FooterModal>
        </Modal>
      </Fragment>
    );
  };

  const ModalSelectSection = () => {
    return (
      <Fragment>
        <Modal
          isOpen={isOpenModalSection}
          toggle={() => setIsOpenModalSection(!isOpenModalSection)}
        >
          <S.HeaderModal bg="#31AF89">
            <ModalTitle>
              <S.ModalTitle>Sessões</S.ModalTitle>{" "}
            </ModalTitle>
          </S.HeaderModal>
          <ModalBody>
            {values.sections.map((section, index) => {
              return (
                <S.BottomSetSection
                  key={index}
                  select={section.id === currentSection}
                  onClick={() => {
                    setCurrentSection(section.id);
                    setIsOpenModalSection(!isOpenModalSection);
                    setCurrentImage(
                      images.indexOf(
                        images.filter(
                          (item) => item.infograficoSectionId === section.id
                        )[0],
                        0
                      )
                    );
                  }}
                >
                  ● {section.title}
                </S.BottomSetSection>
              );
            })}
          </ModalBody>
          <S.FooterModal>
            <S.Bottom
              color="#484848"
              hoverBg="#31AF89"
              hoverColor="#F5f5f5"
              onClick={() => setIsOpenModalSection(!isOpenModalSection)}
            >
              Cancelar
            </S.Bottom>
          </S.FooterModal>
        </Modal>
      </Fragment>
    );
  };

  useEffect(() => {
    if (!loading) {
      setCurrentSection(images[currentImage]?.infograficoSectionId);
    }
  }, [currentImage]);

  const handlerScrollLeft = () => {
    let x = scrollX + Math.round(imgsContainer.offsetWidth / 2);
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };

  const handlerScrollRight = () => {
    console.log(imgsContainer.offsetWidth);

    // let x = scrollX - Math.round(imgsContainer.offsetWidth / 2);
    // let listW = images.length * 60;
    // if ((imgsContainer.offsetWidth - listW) > x) {
    //     x = imgsContainer.offsetWidth - listW - 60
    // }
    // setScrollX(x)
  };

  return (
    <Fragment>
      {loading ? null : (
        <Card>
          <S.Header>
            <S.ContentHeader>
              <S.IconProtoculus src={area.icon} />
              <S.HeaderTitle>{area.name}</S.HeaderTitle>
            </S.ContentHeader>

            <S.ContentHeader>
              <S.HeaderButtonSelectLanguage
                onClick={() => setShowModalLaguage(!showModalLanguade)}
              >
                Idiomas da publicação <BiChevronDown />{" "}
              </S.HeaderButtonSelectLanguage>

              <S.BottomEdit
                onClick={() => {
                  history.push(`/edit/protocolus/${id}`);
                }}
              >
                {" "}
                <BiPencil /> Editar{" "}
              </S.BottomEdit>
            </S.ContentHeader>
          </S.Header>
          <S.Body>
            <S.ModalLanguages display={showModalLanguade ? "flex" : "none"}>
              <S.ItemLanguage
                onClick={() => {
                  setCurrentLanguage(1);
                  setShowModalLaguage(!showModalLanguade);
                }}
              >
                <S.IconLanguage src={Br} />
                <S.LanguageLabel>Português</S.LanguageLabel>
              </S.ItemLanguage>

              <S.ItemLanguage
                onClick={() => {
                  setCurrentLanguage(2);
                  setShowModalLaguage(!showModalLanguade);
                }}
              >
                <S.IconLanguage src={En} />
                <S.LanguageLabel>English</S.LanguageLabel>
              </S.ItemLanguage>

              <S.ItemLanguage
                disabled
                onClick={() => {
                  setCurrentLanguage(3);
                  setShowModalLaguage(!showModalLanguade);
                }}
              >
                <S.IconLanguage src={Es} />
                <S.LanguageLabel>Español</S.LanguageLabel>
              </S.ItemLanguage>
            </S.ModalLanguages>
            <S.Title
              style={{ fontSize: 24, marginLeft: 5, fontWeight: "bold" }}
            >
              {values.title}
            </S.Title>
            <S.Content>
              <S.Description>
                <S.BottomSectionSelect
                  onClick={() => {
                    setIsOpenModalSection(!isOpenModalSection);
                  }}
                >
                  ● Indice(s) {titleSection ? `: ${titleSection}` : null}
                </S.BottomSectionSelect>
                <br />
                <S.Label>
                  {currentLanguage === 1 ? "Descrição" : "Description"}
                </S.Label>
                <S.ContentDescription
                  className="scroll__protoculus"
                  dangerouslySetInnerHTML={{ __html: values.description }}
                />

                <S.Options>
                  {/* {isLike ? (<AiTwotoneLike size={25} onClick={() => setIsLike(!isLike)} color="#31AF89" />) : (<AiOutlineLike size={25} onClick={() => setIsLike(!isLike)} color="#010101CC" />)} */}
                  <div></div>
                  <S.CardFunctions>
                    {isSave ? (
                      <SaveFilled
                        onClick={() => handlerFavoriteProtoculus()}
                        size={20}
                        color="#31AF89"
                      />
                    ) : (
                      <SaveOutline
                        onClick={() => handlerFavoriteProtoculus()}
                        size={20}
                        color="#010101CC"
                      />
                    )}
                    <FaShareAlt
                      size={20}
                      color="#010101CC"
                      onClick={async (event) => {
                        if (navigator.share !== undefined) {
                          event.preventDefault();
                          await navigator.share({
                            title: "Celus - ProtocolUS",
                            text: values.title,
                            url: `https://salus-celus.com.br/view/protocolus/${values.title}/${values.id}`,
                          });
                        } else {
                          navigator.clipboard.writeText(
                            `https://salus-celus.com.br/view/protocolus/${values.title}/${values.id}`
                          );
                          toast.success(
                            "Link copiado para a área de transferência."
                          );
                        }
                      }}
                    />
                  </S.CardFunctions>
                </S.Options>

                <S.DeleteBottom onClick={() => setIsOpenModal(!isOpenModal)}>
                  <AiOutlineDelete size={25} color="#FFF" />
                </S.DeleteBottom>
              </S.Description>

              <S.ImageConteinerView>
                <S.ImageConteiner>
                  <S.ImageView src={images[currentImage]?.src} />
                  <S.LeftBottom
                    onClick={() => {
                      setCurrentImage(currentImage - 1);
                    }}
                    disabled={currentImage === 0}
                    hidden={currentImage === 0}
                  >
                    <AiFillCaretLeft size={20} />
                  </S.LeftBottom>

                  <S.RightBottom
                    onClick={() => {
                      setCurrentImage(currentImage + 1);
                    }}
                    hidden={currentImage === images.length - 1}
                    disabled={currentImage === images.length - 1}
                  >
                    <AiFillCaretRight size={20} />
                  </S.RightBottom>

                  {/* <S.CurrentImageRow>
                                            {
                                                images?.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <S.CurrentImage onClick={() => {setCurrentImage(index); setCurrentSection(item.infograficoSectionId)}} current={index === currentImage} />
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </S.CurrentImageRow> */}
                </S.ImageConteiner>
                <S.Title style={{ marginTop: 15 }}>● {titleSection}</S.Title>

                <S.CurrentImageViewRow className="scroll__protoculus">
                  {images?.map((item, index) => {
                    return (
                      <S.AreaImageSection
                        currentSection={
                          item.infograficoSectionId === currentSection
                        }
                      >
                        <S.PrevImage
                          src={item.src}
                          onClick={() => {
                            setCurrentImage(index);
                            setCurrentSection(item.infograficoSectionId);
                          }}
                          current={index === currentImage}
                        />
                      </S.AreaImageSection>
                    );
                  })}
                </S.CurrentImageViewRow>
              </S.ImageConteinerView>
            </S.Content>
          </S.Body>
          <CardFooter></CardFooter>
          {ModalDeleteProtoculus()}
          {ModalSelectSection()}
        </Card>
      )}
      <ChangeIsFreeContent
        id={id}
        type={"protocolus"}
        originalValue={isFreeContent}
      />
    </Fragment>
  );
};
