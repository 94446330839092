import React from 'react';
import { Row, Col} from 'reactstrap';
import PostCreateLaudus from '../feed/PostCreateLaudus';
import FeedSideBar from '../feedUS/FeedSideBar';

const FormsLaudus = () => {

  return (
    <>
      <Row>
        <Col lg={8} className="pl-lg-2">
          
          <PostCreateLaudus />
        </Col>
        <Col lg={4} className="pr-lg-2 mb-3 mb-lg-0">
          <FeedSideBar />
        </Col>
      </Row>

    </>
  );
};

export default FormsLaudus;
