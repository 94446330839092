import React, { Fragment } from 'react';
import { CardLaudus } from '../cardLaudus/CardLaudus';
import { EmptyContent } from '../EmptyContent';
import { emptyCards } from '../../assets/img/illustrations'
import { LoadingCard } from '../LoadingCard';

const FeedCard = ({ values = [], loading }) => {

  return (
    <Fragment>

      {
        loading ? (
          <Fragment>
            <LoadingCard/>
          </Fragment>
        ) :

          (values.length <= 0) ?
            <EmptyContent
              src={emptyCards}
              msg="Essa Especialidade ainda não tem nenhum LaudUS, crie um conteúdo para publicação!"
              goTo="/laudus/new"
              title="Clique aqui"
            />
            :
            values.map((item, index) => {
              return (
                <CardLaudus key={index} values={item} to={`/to-see/Laudus`} status={false} />
              )
            })

      }
    </Fragment>
  );
};


export default FeedCard;
