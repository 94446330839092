import React, { Fragment, useEffect, useState } from "react";
import { Card } from "reactstrap";
import *as S from './styles';
import CelusApi from "../../server/celusApi";
import { FaCheck } from "react-icons/fa";
import ReactPaginate from 'react-paginate';
import {LoadingTableAllUser} from "../LoadingTableUser";

export const AllUsers = () => {

    const api = CelusApi();
    const [values, setValues] = useState([]);
    const [currentPage, setCurrentPage] = useState(0)
    const perPage = 10;
    const [totalPage, setTotalPage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getClients();
    }, [currentPage]);


    const getClients = async () => {
        try {
            setLoading(true);
            const result = await api.getClients(currentPage);
            setValues(result.clients.rows);
            setTotalPage(Math.ceil(result.clients.count / perPage));
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } catch (error) {
            console.log(error);
        }
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1);
    };

    return (
        <Fragment>
            <Card>
                <S.Header>
                    <S.Title>Usuários</S.Title>
                </S.Header>

                <S.Body>
                    <S.TableNew responsive={true}>
                        <S.Thead>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Status</th>
                                <th>Função</th>
                                <th>Ação</th>
                            </tr>
                        </S.Thead>

                        {
                            loading ? (
                                <Fragment>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                   <LoadingTableAllUser/>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <tbody>
                                        {
                                            values.map((item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <tr>
                                                            <S.Td>{item.id}</S.Td>
                                                            <S.Td>{item.name}</S.Td>
                                                            <S.Td>{item.email}</S.Td>
                                                            <S.Td>
                                                                <S.Select>
                                                                    <S.Option value={true}>Ativo</S.Option>
                                                                    <S.Option value={false}>Bloqueado</S.Option>
                                                                </S.Select>
                                                            </S.Td>
                                                            <S.Td>Cliente</S.Td>
                                                            <S.Td>
                                                                <S.BtnSave>
                                                                    <FaCheck size={20} color="#FFF" />
                                                                </S.BtnSave>
                                                            </S.Td>
                                                        </tr>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Fragment>
                            )
                        }


                    </S.TableNew>
                </S.Body>

                <S.Footer>
                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </S.Footer>
            </Card>
        </Fragment>
    );
}