import React, { Fragment, useEffect } from "react";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as S from "./ChangeIsFreeContent.styles";

import CelusApi from "../../server/celusApi";

export function ChangeIsFreeContent({ type, originalValue, id }) {
  console.log({
    type,
    originalValue,
    id,
  });
  const [isFreeContent, setIsFreeContent] = React.useState(originalValue);
  useEffect(() => {
    setIsFreeContent(originalValue);
  }, [originalValue]);

  const api = CelusApi();
  const [openModal, setOpenModal] = React.useState(0);

  const cancel = () => {
    window.location.href = "/list/table/laudus";
  };

  const ModalStatusApproved = () => {
    return (
      <Fragment>
        <Modal isOpen={openModal === 1} toggle={() => setOpenModal(0)}>
          <ModalHeader>Deseja tornar esse conteúdo gratuito?</ModalHeader>
          <ModalBody>
            Você tem certeza de que gostaria de tornar esse conteúdo gratuito?
            Ao clicar em sim, ele ficará disponível no aplicativo para todos os
            usuários pagantes ou não.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setOpenModal(0)}>Não</Button>
            <Button color="primary" onClick={onSubmit}>
              Sim
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };
  const ModalStatusDisapproved = () => {
    return (
      <Fragment>
        <Modal isOpen={openModal === 2} toggle={() => setOpenModal(0)}>
          <ModalHeader>Deseja tornar esse conteúdo bloqueado?</ModalHeader>
          <ModalBody>
            Você tem certeza de que gostaria de tornar esse conteúdo bloqueado?
            Ao clicar em sim o conteúdo estará disponível apenas para usuários
            pagantes.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setOpenModal(0)}>Não</Button>
            <Button color="primary" onClick={onSubmit}>
              Sim
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };

  const onSubmit = async () => {
    try {
      await api.changeContentFree({
        id,
        isFreeContent,
        type,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <S.Container>
      <S.HeaderArea>
        <S.HeaderTitle>Liberar acesso Gratuito</S.HeaderTitle>
      </S.HeaderArea>
      <S.Body>
        <S.Line>
          <S.DefaultBold>É Conteúdo Gratuito?: </S.DefaultBold>
          <S.StatusSelect
            type="select"
            name="select"
            onChange={(e) => {
              setIsFreeContent(e.target.value === "true");
            }}
            value={isFreeContent ? "true" : "false"}
          >
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </S.StatusSelect>
        </S.Line>
      </S.Body>
      <S.Bottom>
        <S.CancelBtn onClick={cancel}>Cancelar</S.CancelBtn>
        <S.PublishBtn
          style={{ background: "#FF6766" }}
          onClick={() => setOpenModal(isFreeContent ? 1 : 2)}
        >
          Salvar
        </S.PublishBtn>
      </S.Bottom>
      <ModalStatusApproved />
      <ModalStatusDisapproved />
    </S.Container>
  );
}
