import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from "reactstrap";
import * as S from "./styles";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import CelusApi from "../../server/celusApi";
import { AreasTable } from "../AreasTable/AreasTable";

export const Tools = () => {
  const api = CelusApi();
  const [areas, setAreas] = useState([]);
  const [select, setSelect] = useState(true);
  const [welcomeTexts, setWelcomeTexts] = useState({});
  const isAllActive = false;

  useEffect(() => {
    getArea();
    getWelcomeTexts();
  }, [api]);

  const getArea = async () => {
    const result = await api.listAreas("admin");
    setAreas(result);
  };

  const getWelcomeTexts = async () => {
    const result = await api.listWelcomeTexts("admin");
    setWelcomeTexts(result);
  };

  const onChangeWelcomeText = async (key, value) => {
    const copy = { ...welcomeTexts };
    copy[key] = value;
    setWelcomeTexts(copy);
  };

  const saveWelcomeTexts = async () => {
    await api.saveWelcomeText(welcomeTexts);
    window.location.reload();
  };

  console.log(welcomeTexts);

  const updAreaShow = async (id, status, type) => {
    await api.putAreaShow({ id, status, type });
    getArea();
  };

  return (
    <Fragment>
      <Card>
        <CardHeader style={{ background: "#F9FAFD" }}>
          <h5>Configurações</h5>
        </CardHeader>
        <CardBody>
          <h6>Areas</h6>
          <S.ContentArea>
            <Input
              type="select"
              name="select"
              id="select"
              onChange={(e) => {
                setSelect(e.target.value);
              }}
            >
              <option value="" disabled selected hidden>
                Areas
              </option>
              {areas.map((item, index) => {
                return (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <S.ButtonArea
              show={areas[select]?.show}
              active={isAllActive}
              onClick={() => {
                updAreaShow(areas[select]?.id, !areas[select]?.show, "");
              }}
            >
              Ver em Todos
            </S.ButtonArea>

            <S.ButtonArea
              show={areas[select]?.showLaudus}
              onClick={() => {
                updAreaShow(
                  areas[select]?.id,
                  !areas[select]?.showLaudus,
                  "laudus"
                );
              }}
            >
              {areas[select]?.showLaudus ? (
                <RiEyeLine size={22} />
              ) : (
                <RiEyeCloseLine size={22} />
              )}
              Laudus
            </S.ButtonArea>

            <S.ButtonArea
              show={areas[select]?.showResumus}
              onClick={() => {
                updAreaShow(
                  areas[select]?.id,
                  !areas[select]?.showResumus,
                  "resumus"
                );
              }}
            >
              {areas[select]?.showResumus ? (
                <RiEyeLine size={22} />
              ) : (
                <RiEyeCloseLine size={22} />
              )}
              Resumus
            </S.ButtonArea>

            <S.ButtonArea
              show={areas[select]?.showAnatomus}
              onClick={() => {
                updAreaShow(
                  areas[select]?.id,
                  !areas[select]?.showAnatomus,
                  "anatomus"
                );
              }}
            >
              {areas[select]?.showAnatomus ? (
                <RiEyeLine size={22} />
              ) : (
                <RiEyeCloseLine size={22} />
              )}
              Anatomus
            </S.ButtonArea>
          </S.ContentArea>

          <S.Divider />

          <h6>Textos da Tela Inicial</h6>
          <h6>1.</h6>
          <S.ContentArea>
            <S.TextInput
              type={"textarea"}
              value={welcomeTexts["welcome_text_1"]}
              onChange={(e) =>
                onChangeWelcomeText("welcome_text_1", e.target.value)
              }
            />
          </S.ContentArea>
          <h6>2.</h6>
          <S.ContentArea>
            <S.TextInput
              type={"textarea"}
              value={welcomeTexts["welcome_text_2"]}
              onChange={(e) =>
                onChangeWelcomeText("welcome_text_2", e.target.value)
              }
            />
          </S.ContentArea>
          <h6>3.</h6>
          <S.ContentArea>
            <S.TextInput
              type={"textarea"}
              value={welcomeTexts["welcome_text_3"]}
              onChange={(e) =>
                onChangeWelcomeText("welcome_text_3", e.target.value)
              }
            />
          </S.ContentArea>
          <Button onClick={saveWelcomeTexts}>Salvar</Button>

          <S.Divider />

          <h6>Configuraçâo das Areas</h6>
          <AreasTable />
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </Fragment>
  );
};
