import React, { useContext } from 'react';
import { Nav, NavItem, Collapse } from 'reactstrap';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import SaveLaudusResumus from './SaveLaudusResumus';
import Saved from './Saved';
import CartNotification from './CartNotification';
import AppContext from '../../context/Context';
import {  topNavbarBreakpoint } from '../../config';

const TopNavRightSideNavItem = () => {
  const { isTopNav } = useContext(AppContext);
  const {
    navbarCollapsed,
    setNavbarCollapsed
  } = useContext(AppContext);

  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center" 
      style={{
        gap: '10px'
      }}
    >
      <CartNotification />
      {isTopNav ? (
        <Collapse navbar isOpen={navbarCollapsed} className="scrollbar ">
          <Nav navbar>
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
        </Collapse>
      ) : (
        <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}>
          <NavItem>
          </NavItem>
        </Nav>
      )}

      <SaveLaudusResumus/>
      <Saved />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
