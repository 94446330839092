import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  Table,
} from "reactstrap";
import ButtonIcon from "../common/ButtonIcon";
import { FaAlignLeft, FaBan, FaCheck, FaRegClock } from "react-icons/fa";
import CelusApi from "../../server/celusApi";
import { User, View } from "../../assets/img/icons/celus/index";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LoadingTable } from "../LoadingTable";

export const ListTableResumus = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("all");
  const [resumus, setResumus] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10;
  const api = CelusApi();

  useEffect(() => {
    GetResumusFilter();
  }, [status, currentPage]);

  useCallback(() => {
    setCurrentPage(0);
  }, [status]);

  const GetResumusFilter = async () => {
    setLoading(true);
    let values = { status: status, page: currentPage, limit: 10 };
    let results = await api.getResumusFilter(values);
    console.log({
      resumus: results.result,
    });
    setResumus(results.result);
    setTotalPage(Math.ceil(results.count / perPage));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const statusView = {
    app(status) {
      return (
        <>
          <div className="app">
            <p>{status}</p>
            <FaCheck size="14" color="#FFF" />
          </div>
        </>
      );
    },

    rep(status) {
      return (
        <>
          <div className="rep">
            <p>{status}</p>
            <FaBan size="14" color="#FFF" />
          </div>
        </>
      );
    },

    lood(status) {
      return (
        <>
          <div className="lood">
            <p>{status}</p>
            <FaRegClock size="14" color="#FFF" />
          </div>
        </>
      );
    },

    edit(status) {
      return (
        <>
          <div className="edit">
            <p>{status}</p>
            <FaAlignLeft size="14" color="#FFF" />
          </div>
        </>
      );
    },
  };

  const handlePageChange = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const ViewResumus = (doc) => {
    history.push(`/to-see/ResumUS/${doc}`);
  };
  return (
    <>
      <Card className="mb-3">
        <CardHeader title="ResumUS Recebidos" light={false} className="m-none">
          <Fragment>
            <div className="filter-input">
              <InputGroup style={{ marginRight: "10px" }}>
                <Input
                  type="select"
                  placeholder="Filtrar Pro"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Filtrar
                  </option>
                  <option value="approved">Aprovados</option>
                  <option value="waiting">Aguardando Aprovação</option>
                  <option value="loading">Em Revisão</option>
                  <option value="disapproved">Reprovados</option>
                  <option value="all">Todos</option>
                </Input>
              </InputGroup>
              <ButtonIcon
                transform="shrink-3 down-2"
                color="falcon-default"
                size="sm"
                className="filter"
                onClick={() => {
                  GetResumusFilter();
                }}
              >
                Filtrar
              </ButtonIcon>
            </div>
          </Fragment>
        </CardHeader>

        <CardBody className="p-0">
          <div className="content-table">
            <Table responsive="lg">
              <thead>
                <tr className="header-table">
                  <th>Autor</th>
                  <th>Título</th>
                  <th>Editor</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {loading ? (
                <Fragment>
                  <tbody>
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                    <LoadingTable />
                  </tbody>
                </Fragment>
              ) : (
                <Fragment>
                  <tbody>
                    {resumus
                      ? resumus.map((i) => {
                          return (
                            <tr>
                              <td className="content-col">
                                <img
                                  src={
                                    !!i.author.avatar ? i.author.avatar : User
                                  }
                                  className="init"
                                />
                                <p>{i.author?.name}</p>
                              </td>
                              <td
                                className="Link"
                                onClick={() => {
                                  history.push(`/resumus/review/${i.id}`);
                                }}
                              >
                                {i?.title}
                              </td>
                              <td>{i?.editor ? i?.editor : "--"}</td>
                              <td>
                                {i?.status === "approved"
                                  ? statusView.app("Aprovado")
                                  : i?.status === "disapproved"
                                  ? statusView.rep("Reprovado")
                                  : i?.status === "waiting"
                                  ? statusView.lood("Aguardando aprovação")
                                  : statusView.edit("Em revisão")}
                              </td>
                              <td>
                                <button
                                  className="view"
                                  onClick={() => {
                                    ViewResumus(i.id);
                                  }}
                                >
                                  <img src={View} />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : "  "}
                  </tbody>
                </Fragment>
              )}
            </Table>
          </div>

          <div className="pag-table-admin">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageChange}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              forcePage={currentPage === 0 ? 0 : currentPage - 1}
              pageCount={totalPage}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};
