import styled from 'styled-components';
import { CardBody, CardHeader, CardTitle, ModalFooter, ModalHeader } from 'reactstrap';

export const IconProtoculus = styled.img`
    width: 35px;
    height: 35px;
    object-fit: scale-down;
    margin-right: 15px;
`;

export const Header = styled(CardHeader)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 60px;
    background-color: #F3F3F3;
`;

export const HeaderTitle = styled(CardTitle)`
    margin: 0;
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Body = styled(CardBody)`
    position: relative;
    @media (max-width: 1200px) {
        height: auto;
    }
`;

export const PrevImage = styled.img`
    min-width: 60px;
    height: 60px;

    filter: ${({current}) => current ? 'grayscale(0%)' : 'grayscale(100%)' };
    transform: ${({current}) => current ? 'scale(1.1)' : 'scale(1)' };
    border: ${({current}) => current ? '2px solid #31AF89' : 'none' };
    z-index: ${({current}) => current ? '999' : '1' };

    margin-right: 5px;

    object-fit: cover;

    cursor: pointer;
`;

export const ModalLanguages = styled.div`
    width: 180px;
    height: 130px;

    display: ${({ display }) => display ? display : 'flex'};
    flex-direction: column !important;

    justify-content: center;
    align-items: center;

    background-color: #F5f5f5 !important;

    position: absolute;
    border-radius: 5px;

    top: 0;
    right: 0;

    padding: 5px;

    margin-right: 10px;
    margin-top: 10px;

    z-index: 999;
`;

export const ItemLanguage = styled.button`
    display: flex;
    flex-direction: row;

    border: none;

    width: 100%;

    border-bottom-color: #DDD;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 5px 0;

    background-color: #F5f5f5 !important;


    margin-bottom: 5px;

    transition: 0.3s;

    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }
`;

export const IconLanguage = styled.img`
    border-radius: 20px;
    width: 20px;
    height: 20px;

    margin-right: 5px;
    object-fit: cover;
`;

export const LanguageLabel = styled.span`
    color: #636363;
    font-size: 14px;
`;


export const HeaderButtonSelectLanguage = styled.button`
    color: #636363;
    border: none;
    background-color: transparent;
    font-size: 14px;
    transition: 0.3s;

    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        opacity: 0.8;
    }
`;

export const ContentDescription = styled.div`
    overflow:auto;

    max-height: 500px !important;

    p{
        padding: 0;
        width: 100%;
    }
`;

export const Title = styled.h1`
    width: 100%;
    font-size: 20px;
    text-transform: none;
    margin-bottom: 25px !important;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;


    @media (max-width: 1200px) {
        flex-direction: column;
    }

`;

export const BottomSectionSelect = styled.button`

    width: 100%;
    height: 40px;

    border: none;
    background-color: #f5f5f5;
    border-radius: 5px;

    transition: 300ms;
   
    margin-top: -10px;
   
    text-align: start;
    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        background-color: #ddd;
    }

`;

export const BottomSetSection = styled.button`

    width: 100%;
    height: 40px;

    border: none;
    background-color: #f5f5f5;
    border-radius: 5px;
    color: ${({select}) => select ? '#31AF89' : '#202020'};
    transition: 300ms;
   
    margin-top: 10px;
    text-align: start;

    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        background-color: #ddd;
    }

`;

export const ImageConteiner = styled.div`
    width: 100%;
    min-width: 610px;
   
    min-height: 700px;
    max-height: 700px;

    @media (max-width: 1200px) {
        min-width: 100%;
    }

    background-color: #f5f5f5;
    border-radius: 5px;
    position: relative;
`;

export const ImageConteinerView = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 610px;
   
    @media (max-width: 1200px) {
        min-width: 100%;
    }

    position: relative;
`;

export const ImageView = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;

    transition: 300ms;
`;

export const LeftBottom = styled.button`
    position: absolute;
    left: 0;
    top: 50%;

    width: 40px;
    height: 40px;

    border-radius: 40px;
    border: none;
    transition: 0.3s;

    margin-left: 5px;
    box-shadow: 3px 3px 6px #00000040;

    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        opacity: 0.8;
    }
`;

export const Bottom = styled.button`
   
    width: 180px;
    height: 40px;

    border-radius: 5px;
    border: none;
    transition: 0.3s;

    box-shadow: 3px 3px 6px #00000040;
    color:${({color}) => color } ;
    background-color: ${({bg}) => bg ? bg : '#EBEBEB'};
    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        background-color: ${({hoverBg}) => hoverBg ? hoverBg : "#EBEBEB"};
        color: ${({hoverColor}) => hoverColor ? hoverColor : "#484848"};
    }
`;

export const RightBottom = styled.button`
    position: absolute;
    right: 0;
    top: 50%;

    width: 40px;
    height: 40px;
    transition: 0.3s;

    margin-right: 5px;
    border-radius: 40px;
    border: none;
    box-shadow: 3px 3px 6px #00000040;

   
    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        opacity: 0.8;
    }

`;

export const DeleteBottom = styled.button`
    position: absolute;
    left: 25px;
    bottom: 0;

    width: 40px;
    height: 40px;

    background-color: #FF6766;
    margin-right: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 3px 3px 6px #00000040;

    transition: 0.3s;
    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        opacity: 0.8;
    }

`;

export const BottomEdit = styled.button`
   
    color: #31AF89;
    border: none;
    transition: 0.3s;

    background-color: transparent;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        opacity: 0.8;
    }

`;

export const CurrentImageRow = styled.div`
    width: 100%;
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    bottom: 0;

    padding: 5px;
`;

export const CurrentImageViewRow = styled.div`
    max-width: 100%;
    min-width: 100%;

    height: 100px;

    overflow:auto;

    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    bottom: 0;

    padding: 5px;

    position: relative;

    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
`;

export const AreaImageSection = styled.div`

    scroll-snap-align: start;

    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    background-color: ${({currentSection}) => currentSection ? '#31AF894D' : 'transparent'};
`;

export const CurrentImage = styled.div`
    width: ${({current}) => current ? '15px' : '10px'};
    height: ${({current}) => current ? '15px' : '10px'};
    border-radius: 15px;

    margin-right: 5px;
    background-color: ${({current}) => current ? '#50D19A' : '#BFBFBF'};

    cursor: pointer;
`;

export const Label = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #31AF89;

`;

export const CardFunctions = styled.div`
    min-width: 60px;
    padding: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    cursor: pointer !important;
    margin-right: 5px;
    justify-content: space-between;
`;

export const Options = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    margin-top: 10px;
`;
 
export const Description = styled.div`
    width: 100%;
    height: 610px;

    padding: 10px;
`;

export const FooterModal = styled(ModalFooter)`
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
`;

export const HeaderModal = styled(ModalHeader)`
    display: flex;
    background: ${({bg}) => bg ? bg : "#31AF89"};
    flex-direction: row;
    justify-content: center;
`;

export const InfoModal = styled.p`
    width: 100%;
    text-align: center;
`;

export const ModalTitle = styled.span`
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
`;
