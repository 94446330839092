import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss'

export const EmptyContent = ({ goTo, src, msg, title }) => {
    const history = useHistory();
    const goNav = () => {
        history.push(goTo);
    }
    return (
        <Fragment>
            <div className="contentEmpty">
                <img src={src} />
                <p className="descriptionText">{msg}<button className="buttonEmpty" onClick={() => goNav()}>{title}</button></p>
            </div>
        </Fragment>
    )
}
