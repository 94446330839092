import React, { Fragment } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./styles.scss";
export const LoadingViewLaudus = () => {
    return (
        <Fragment>
            <Skeleton height={40} className="mb-4" />
            <Skeleton height={300} />
            <h5 style={{ fontSize: 19, color: '#50D19A', marginBottom: 15, marginTop: 15 }}>Descrição</h5>
            <Skeleton height={25} count={5} className="margin__line" />

            <h5 style={{ fontSize: 19, color: '#50D19A', marginBottom: 15, marginTop: 15 }}>Conclusão</h5>
            <Skeleton height={25} count={5} className="margin__line" />
            <Skeleton height={60} className="margin__options" />
            <div className="author">
                <Skeleton width={100} height={100} circle={true} />
                <div style={{marginLeft: 15}}>
                    <Skeleton width={150} height={25} className="margin__line" count={2} />
                </div>

            </div>
        </Fragment>
    )
}