import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, InputGroup, Input, Table, CardHeader } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { FaAlignLeft, FaBan, FaCheck, FaRegClock } from "react-icons/fa";
import CelusApi from '../../server/celusApi';
import { User, View } from '../../assets/img/icons/celus/index';
import './styles.scss'
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { LoadingTable } from '../LoadingTable';

export const ListTable = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('all');

    const [laudus, setLaudus] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [currentPage, setCurrentPage] = useState(0)
    const perPage = 10;


    const api = CelusApi();

    useEffect(() => {
        GetLaudus();
    }, [status, currentPage]);


    useEffect(() => {
        setCurrentPage(0);
    }, [status])


    const GetLaudus = async () => {
        setLoading(true);
        const values = { status: status, page: currentPage, limit: 10 }
        let results = await api.getLaudus(values);
        setLaudus(results.result.laudus.rows);
        setTotalPage(Math.ceil(results.result.laudus.count / perPage));
        setTimeout(() => {
            setLoading(false);
        },1000);
    }

    const GetLaudusFilter = async () => {
        setLoading(true);
        const values = { status: status, page: currentPage, limit: 10 }
        let results = await api.getLaudusFilter(values);
        setLaudus(results.result.laudus.rows);
        setTotalPage(Math.ceil(results.result.laudus.count / perPage));
        setTimeout(() => {
            setLoading(false);
        },1000);
    }



    const statusView = {

        app(status) {
            return (
                <>
                    <div className="app">
                        <p>{status}</p>
                        <FaCheck size="14" color="#FFF" />
                    </div>
                </>
            )
        },

        rep(status) {
            return (
                <>
                    <div className="rep">
                        <p>{status}</p>
                        <FaBan size="14" color="#FFF" />
                    </div>
                </>
            );
        },

        lood(status) {
            return (
                <>
                    <div className="lood">
                        <p>{status}</p>
                        <FaRegClock size="14" color="#FFF" />
                    </div>
                </>
            );
        },

        edit(status) {
            return (
                <>
                    <div className="edit">
                        <p>{status}</p>
                        <FaAlignLeft size="14" color="#FFF" />
                    </div>
                </>
            );
        },
    }


    const ViewLaudus = (id, subarea, title, area) => {
        history.push(`/to-see/LaudUS/${id}/${subarea}/${title}/${area}`)
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1);
    };

    return (
        <>


            <Card className="mb-3">
                <CardHeader title="LaudUS Recebidos" light={false} className="m-none">

                    <Fragment>
                        <div className="filter-input">
                            <InputGroup style={{ marginRight: '10px' }}>
                                <Input type="select" placeholder="Filtrar Pro" onChange={e => { setStatus(e.target.value) }}>
                                    <option value="" disabled selected hidden>Filtrar</option >
                                    <option value="approved" >Aprovados</option>
                                    <option value="waiting">Aguardando Aprovação</option>
                                    <option value="loading">Em Revisão</option>
                                    <option value="disapproved">Reprovados</option>
                                    <option value="all" >Todos</option>
                                </Input>
                            </InputGroup>
                            <ButtonIcon transform="shrink-3 down-2" color="falcon-default" size="sm" className="filter"
                                onClick={() => {
                                    GetLaudusFilter();
                                }}
                            >
                                Filtrar
                            </ButtonIcon>
                        </div>
                    </Fragment>
                </CardHeader>

                <CardBody className="p-0">
                    <div className="content-table">
                        <Table responsive="lg">
                            <thead>
                                <tr className="header-table">
                                    <th>Autor</th>
                                    <th>Título</th>
                                    <th>Editor</th>
                                    <th>Status</th>
                                    <th> </th>
                                </tr>
                            </thead>

                            {loading ? (
                                <Fragment>
                                    <tbody>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                        <LoadingTable/>
                                    </tbody>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <tbody>
                                        {

                                            laudus ?
                                                laudus.map((i) => {
                                                    return (
                                                        <tr>
                                                            <td className="content-col">
                                                                <img src={!!i.client.avatar ? i.client.avatar : User} className="init" />
                                                                <p>{!!i.client.name ? i.client.name : 'Usuário sem nome'}</p>
                                                            </td>
                                                            <td className="Link" onClick={() => { history.push(`/laudus/review/${i.id}`) }}>
                                                                {i.title}
                                                            </td>
                                                            <td>{i.admin ? i.admin.name : '--'}</td>
                                                            <td>{
                                                                i.status === 'approved' ? statusView.app('Aprovado') :

                                                                    i.status === 'disapproved' ? statusView.rep('Reprovado') :

                                                                        i.status === 'waiting' ? statusView.lood('Aguardando aprovação') :
                                                                            statusView.edit('Em revisão')
                                                            }</td>
                                                            <td><button className="view" onClick={() => { ViewLaudus(i.id, i.subareaId, i.title, i.areaId) }}><img src={View} /></button></td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                        }

                                    </tbody>
                                </Fragment>
                            )
                            }

                        </Table>
                    </div>
                    <div className="pag-table-admin">
                        <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageChange}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            forcePage={currentPage === 0 ? 0 : currentPage - 1}
                            pageCount={totalPage}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
