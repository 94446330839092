import { Table, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import styled from "styled-components";


export const Header = styled(CardHeader)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    height: 70px;

    padding: 0 10px;
    margin: 0;

    border-bottom: 1px solid #DDD;
`;

export const Body = styled(CardBody)`
    padding: 0;
`;

export const Footer = styled(CardFooter)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const Title = styled(CardTitle)`
    font-size: 20px;
    color: #3C3C3C;

    margin: 0;
`;

export const Thead = styled.thead`
    align-items: center;
    >tr{
        background-color: #EDF2F9;
    }
    th{
        font-size: 14px;
    }

`;

export const TableNew = styled(Table)`
    border-spacing: 0;
    border-collapse: collapse;

    td{
        padding: 5px; 
        margin: auto;
        align-items: center;
        vertical-align: middle;
        color: #3C3C3C;
    }

    th{
        padding: 5px; 
        margin: auto;
        align-items: center;
        vertical-align: middle;
        color: #3C3C3C;

    }
`

export const Select = styled.select`
    position: relative;
    outline: none;
    width: 200px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #CCC;
    border-radius: 5px;
    height: 34px;
    background-color: transparent;

    align-items: center;
    margin: 0;

`

export const Option = styled.option`

`;

export const Tbody = styled.tbody`

`

export const Td = styled.td`

`

export const BtnSave = styled.button`
    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;
    border: none;
    background-color: #31AF89;
    border-radius: 5px;
    transition: 0.5s;
    :focus{
        border: none;
        opacity: 0.8;
        outline: none;
    }
    :hover{
        border: none;
        opacity: 0.8;
    }


`;