import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ImgNull } from '../../assets/img/icons/celus/index';
import { FaBookmark as SaveFilled, FaRegBookmark as SaveOutline } from "react-icons/fa";

import celusApi from '../../server/celusApi';
import Cookies from 'js-cookie';
import './styles.scss';

export const CardLaudus = ({ values, to, status = false, isDraft = false, Children }) => {

    const [checkFavorite, setCheckFavorite] = useState(false);
    const api = celusApi();

    useEffect(() => {
        setCheckFavorite(false);
    }, [values]);

    const getFavoriteReport = async () => {
        const json = {
            idReport: values.id,
            idClient: Cookies.get('clientId')
        };
        const result = await api.checkFavoriteReport(json);
        setCheckFavorite(result);
    };

    const saveReport = async () => {
        const json = {
            reportId: values.id,
            clientId: Cookies.get('clientId'),
        }

        try {
            await api.postFavoriteLaudus(json);
            getFavoriteReport()
        } catch (error) {
            console.log(error)
            return;
        }
    }

    useEffect(() => {
        getFavoriteReport();
    }, [getFavoriteReport]);


    String.prototype.stripHTML = function () { return this.replace(/<.*?>/g, ''); }

    return (
        <Fragment>
            <div className="container-card" >
                <div className="content-card">
                    <div className="row-card">
                        <Link to={`${to}/${values.id}`}>
                            <div className="content-values">
                                <div className="content-title-card">
                                    <h3 className="title-card">{values.title}</h3>
                                </div>
                                <p className="content-abistract" dangerouslySetInnerHTML={{ __html: values.description.stripHTML() }}>

                                </p>
                            </div>
                        </Link>
                        <div className="footer-card">

                            <div className="content-name">
                                <p>Autor: {values.client.name} </p>

                                {status ?
                                    <p className="status">
                                        {values.status === 'approved' ? 'Aprovado' :
                                            values.status === 'waiting' ? 'Aguardando Aprovação' :
                                                values.status === 'disapproved' ? 'Reprovado' : 'Em Revisão'}
                                    </p>
                                    : null
                                }

                            </div>

                            <div className="item-acao">
                                {isDraft ?
                                    Children
                                    :
                                    <>
                                        <button className="item-button" onClick={() => saveReport()} style={{ outline: 'none' }}>
                                            {
                                                checkFavorite ? (
                                                    <SaveFilled className="save-icon save-filled" />
                                                ) : (
                                                    <SaveOutline className="save-icon" />
                                                )
                                            }
                                        </button>
                                        <button className="item-button" style={{ outline: 'none' }}
                                            onClick={
                                                () => {
                                                    navigator.share({
                                                        title: 'Share',
                                                        text: 'whatevs',
                                                        url: `/to-see/laudus/${values.id}/${values.title}`
                                                    }
                                                    )
                                                }
                                            }
                                        >
                                            <FaShareAlt transform="shrink-4" className="fs-2 footer-icon" color='#344050' />
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="thumb">
                        <img src={values.reportImages[0]?.src ? values.reportImages[0].src : ImgNull} />
                    </div>
                </div>
            </div>

        </Fragment>
    )
}