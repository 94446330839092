import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { CardLaudus } from '../cardLaudus/CardLaudus';
import { EmptyContent } from '../EmptyContent';
import { emptyCards } from '../../assets/img/illustrations'
import { LoadingCard } from '../LoadingCard';

const FeedCard = ({ values, loading }) => {
  return (
    <Fragment>
      {
        loading ? (
          <Fragment>
            <LoadingCard/>
          </Fragment>
        ) : (values.length <= 0) ?
          <EmptyContent
            src={emptyCards}
            msg="Você ainda não tem nenhum LaudUS, crie um conteúdo para publicação!"
            goTo='/laudus/new'
            title="Clique aqui"
          />
          :
          values.map((item, index) => {
            return (
              <CardLaudus key={index} values={item} to={`/me-laudus/Laudus`} status={true} />
            )
          })
      }
    </Fragment>
  );
};

FeedCard.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  user: PropTypes.object,
  className: PropTypes.string
};

export default FeedCard;
