export const ProtoculusText = {
    en:{
        headerTitle: 'Create ProtocolUS',
        pageInfo: 'Share your protoculUS here, create a format on how to describe your infographic of an ultrasound of a pathology.',
        imageInfo: 'Add images to better exemplify your protoculUS for the reader.',
        titleImageSpotlight: 'Featured image',
        labelImageSpotlight: 'Add a featured image to your article by dragging to this area',
        inputTitle: 'Title',
        inputDescription: 'Description',
        inputSpecialty: 'Specialty',
        inputCategory: 'Category',
        inputTag: 'Tags',
        inputImages: 'Images',
        submit: 'Publish',
        requiredInfo: 'Mandatory field.'
    },
    br:{
        headerTitle: 'Criar ProtocolUS',
        pageInfo: 'Compartilhe aqui seu protoculUS, crie um formato de como descrever seu infografico de um ultrassonográfico de uma patologia.',
        imageInfo: 'Adicione imagens para melhor exemplificar o seu protoculUS para o leitor.',
        titleImageSpotlight: 'Imagem em destaque',
        labelImageSpotlight: 'Adicione uma imagem de destaque para o seu artigo arrastando para esse area',
        inputTitle: 'Título',
        inputDescription: 'Descrição',
        inputSpecialty: 'Especialidade',
        inputCategory: 'Categoria',
        inputTag: 'Tags',
        inputImages: 'Imagens',
        submit: 'Publicar',
        requiredInfo: 'Campos obrigatorio.'
    }
}
