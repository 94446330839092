import React from 'react';
import { Row, Col} from 'reactstrap';
import EditResumus from '../feed/EditResumus';
import FeedSideBarResumus from '../feedUS/FeedSideBarResumus';

const FormsResumusEdit = () => {

  return (
    <>
      <Row>
        <Col lg={9} className="pl-lg-2">
          <EditResumus/>
        </Col>
        <Col lg={3} className="pr-lg-2 mb-3 mb-lg-0">
          <FeedSideBarResumus />
        </Col>
      </Row>

    </>
  );
};

export default FormsResumusEdit;
