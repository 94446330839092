import React, { Fragment, useEffect, useState } from 'react';
import { Card, InputGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, CardHeader } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import CelusApi from '../../server/celusApi';
import { toast } from 'react-toastify';
import './styles.scss';

import * as S from './styles.js';
import { AnatomUS } from '../../assets/img/icons/celus';

export const ClearTests = () => {
    const [values, setValues] = useState([]);
    const [type, setType] = useState('all');
    const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const api = CelusApi();

    useEffect(() => {
        getTrash(type)
    }, [type]);

    const deleteTests = async () => {
        try {
            setIsLoading(true)
            await api.deleteTrashTests(type)
        } catch (error) {
            console.warn(error)
        } finally {
            setIsLoading(false)
            setIsDeleteModalShowing(false)
            getTrash()
        }
    } 

    const getTrash = async () => {
        try {
            const result = await api.getTrashTests(type);
            setValues(result);

        } catch (error) {
            toast.error('Não foi possivel listar os conteúdos de testes!');
            console.log(error)
        }
    }

    const DeleteResumusModal = () => {
        return (
          <Modal isOpen={isDeleteModalShowing} toggle={() => setIsDeleteModalShowing(!isDeleteModalShowing)}>
            <ModalHeader >
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }} >
                <div>Aviso!</div>
              </div>
            </ModalHeader>
            <ModalBody>
              <p style={{ fontSize: 17, fontWeight: 'bold', width: '100%' }}>Deseja mesmo apagar todos os conteúdos de teste?</p>
            </ModalBody>
            <ModalFooter style={{ width: '100%', justifyContent: 'space-between' }}>
              <Button onClick={() => setIsDeleteModalShowing(!isDeleteModalShowing)}>
                Cancelar
              </Button>
              <Button style={{ background: '#50D19A', border: 0 }} onClick={() =>  deleteTests() }>
                  { isLoading ? 'loading...' : 'Apagar' }
                  {/* Apagar */}
              </Button>
            </ModalFooter>
          </Modal>
        );
      }

    return (
        <Fragment>
            <Card>
                <CardHeader title="Limpar Testes" color="#484848">
                    <Fragment>
                        <div className="filter-input">
                            <InputGroup style={{ marginRight: '10px' }}>
                                <Input type="select" placeholder="Filtrar Pro" onChange={e => { setType(e.target.value.toLocaleLowerCase()) }}>
                                    <option value="" disabled selected hidden>Filtrar</option >
                                    <option value="laudus" >LaudUS</option>
                                    <option value="resumus">ResumUS</option>
                                    <option value="anatomus">AnatomUS</option>
                                    <option value="all">Todos</option>
                                </Input>
                            </InputGroup>
                            <ButtonIcon transform="shrink-3 down-2" color="falcon-default" size="sm" className="filter"
                                onClick={()=>{
                                    setIsDeleteModalShowing(!isDeleteModalShowing);
                                }}
                            >
                                Apagar
                            </ButtonIcon>
                        </div>
                    </Fragment>
                </CardHeader>

                {
                    values.map((item, index) => {
                        if(item.content.length > 0){
                            return (
                                <>
                                    <S.TypeTitle>{item.type}</S.TypeTitle>
                                    {
                                        item.content.map((content) => {
                                            return (
                                                <S.TestContenthCard>
                                                    <S.ThumbNail 
                                                        src={
                                                            item.type === 'LaudUS' ? content?.reportImages[0]?.src
                                                            : item.type === 'ResumUS' ? content?.doc?.thumbnail
                                                            : AnatomUS
                                                        }
                                                    />
                                                    <S.DetailsArea>
                                                        <h5>
                                                            {item.type === 'ResumUS' ? content.doc.title : content.title}
                                                        </h5>
                                                        <span>Author: <S.Author> { item.type === 'ResumUS' ? content.doc?.author?.name : item.type === 'AnatomUS' ? content?.admin?.name : content.client?.name }</S.Author> </span>
                                                    </S.DetailsArea>
                                                </S.TestContenthCard>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                    })
                }
                {DeleteResumusModal()}
            </Card>
        </Fragment>
    )
}
