import React, { useState, Fragment, useEffect, useRef } from "react";
import EditBtn from "./EditBtn";
import SaveBtn from "./SaveBtn";
import { Editor } from "../Editor";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  Card,
  CardBody,
  Media,
  Input,
  Form,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import ImageUploading from "react-images-uploading";
import CelusApi from "../../server/celusApi";
import FalconCardHeader from "../common/FalconCardHeader";
import { useForm } from "react-hook-form";

import * as S from "./css/components";
import "./css/editLaudus.css";

import { AiFillDelete } from "react-icons/ai";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { Up } from "../../assets/img/icons/celus/index";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { EmptyContent } from "../EmptyContent";
import { notNotification } from "../../assets/img/illustrations";
import { ChangeIsFreeContent } from "../ChangeIsFreeContent/ChangeIsFreeContent";
const ReviewLaudus = () => {
  const api = CelusApi();
  const editorRef = useRef(null);

  const [isTitleDisabled, setIsTitleDisabled] = useState(true);
  const [isDescriptionDisabled, setIsDescriptionDisabled] = useState(true);
  const [isConclusionDisabled, setIsConclusionDisabled] = useState(true);
  const [isAreaDisabled, setIsAreaDisabled] = useState(true);
  const [isSubAreaDisabled, setIsSubAreaDisabled] = useState(true);

  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);

  const [loading, setLoading] = useState(true);

  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [specialtie, setSpecialtie] = useState();
  const [subArea, setSubArea] = useState();
  const [title, setTitle] = useState("");
  const [images, setImages] = React.useState([]);
  const [rImages, setRImages] = React.useState([]);
  const [description, setDescription] = useState();
  const [conclusion, setConclusion] = useState();
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [error, setError] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [scrollX, setScrollX] = useState(0);

  const [valuesLaudus, setValuesLaudus] = useState();

  const [collapseOne, collapseOneOpen] = React.useState(false);

  const [errs, setErrs] = useState({
    title: "",
    description: "",
    conclusion: "",
    images: "",
    subArea: "",
    specialtie: "",
  });

  const { register, errors } = useForm();

  const { laudusId } = useParams();

  useEffect(() => {
    GetSpecialties();
  }, []);

  useEffect(() => {
    GetSubAreas();
  }, [specialtie]);

  useEffect(() => {
    GetLaudusByIdLaudus();
  }, []);

  const onChange = (imageList, addUpvaluesIndex) => {
    setImages(imageList);
    setIsSaveBtnVisible(true);
  };

  const handleEditLaudo = async () => {
    const values = new FormData();

    values.append("title", title);
    values.append("description", description);
    values.append("conclusion", conclusion);
    values.append("areaId", Number(specialtie));
    values.append("subAreaId", Number(subArea));
    values.append("id", Number(laudusId));
    values.append("clientId", Number(valuesLaudus.client.id));
    values.append("editorId", Number(Cookies.get("adminId")));

    if (images.length > 0) {
      for (let index = 0; index < images.length; index++) {
        values.append("file", images[index].file);
      }
    }

    try {
      await api.editLaudusByAdmin(values);
      // collapseOneOpen(!collapseOne);
      DeleteImage();
      window.location.href = "/list/table/laudus";
    } catch (error) {
      console.log(error);
    }
  };

  const GetSpecialties = async () => {
    try {
      const res = await api.listAreas("laudus");
      setSpecialties(res);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSubAreas = async () => {
    try {
      const res = await api.getListSubAreas({
        specialtie: specialtie,
        type: "laudus",
      });
      setSubAreas(res);
    } catch (error) {
      console.log(error);
    }
  };

  const GetLaudusByIdLaudus = async () => {
    try {
      setLoading(true);

      await api.getLaudusByIdLaudus(laudusId).then((result) => {
        setValuesLaudus(result.result[0]);
        setRImages(result.result[0].reportImages);
        setTitle(result.result[0].title);
        setDescription(result.result[0].description);
        setConclusion(result.result[0].conclusion);
        setSpecialtie(Number(result.result[0].areaId));
        setSubArea(result.result[0].subareaId);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleEditField = (field) => {
    if (field === "title") setIsTitleDisabled(!isTitleDisabled);
    if (field === "description")
      setIsDescriptionDisabled(!isDescriptionDisabled);
    if (field === "conclusion") setIsConclusionDisabled(!isConclusionDisabled);
    if (field === "area") setIsAreaDisabled(!isAreaDisabled);
    if (field === "subArea") setIsSubAreaDisabled(!isSubAreaDisabled);
  };

  //IMAGES

  const deleteImageFromArray = (id, index) => {
    const aux = rImages;
    aux.splice(index, 1);
    setRImages(aux);
    setImagesToDelete((oldArray) => [...oldArray, id]);
    setIsSaveBtnVisible(true);
  };

  const DeleteImage = async () => {
    try {
      await api.deleteReportImage(imagesToDelete);
    } catch (error) {
      console.log(error);
    }
  };

  const fileField = useRef();

  const moveB = document.getElementById("move-back");
  const moveN = document.getElementById("move-next");
  const imgsContainer = document.getElementById("imgs-container");
  const listWidth = rImages.length * 325;
  const deleteBtn = document.getElementById("deleteBtn");

  const showImagesButtons = () => {
    moveB.classList.remove("hidden");
    moveN.classList.remove("hidden");
  };

  const CloseImagesButton = () => {
    moveB.classList.add("hidden");
    moveN.classList.add("hidden");
  };

  const showDeleteBtn = () => {
    setIsHidden(!isHidden);
  };

  const closeDeleteBtn = () => {
    setIsHidden(!isHidden);
  };

  const handleLeftArrow = () => {
    let x = scrollX + Math.round(imgsContainer.offsetWidth / 2);
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };

  const handleRightArrow = () => {
    let x = scrollX - Math.round(imgsContainer.offsetWidth / 2);
    let listW = rImages.length * 235;
    if (imgsContainer.offsetWidth - listW > x) {
      x = imgsContainer.offsetWidth - listW - 60;
    }
    setScrollX(x);
  };

  const showSaveBtn = () => {
    if (
      !isTitleDisabled ||
      !isDescriptionDisabled ||
      !isConclusionDisabled ||
      !isAreaDisabled ||
      !isSubAreaDisabled
    ) {
      setIsSaveBtnVisible(true);
    }
  };

  useEffect(() => {
    showSaveBtn();
  }, [
    isTitleDisabled,
    isDescriptionDisabled,
    isConclusionDisabled,
    isAreaDisabled,
    isSubAreaDisabled,
  ]);

  const ModalLaudoEdit = () => {
    return (
      <Fragment>
        <Modal
          isOpen={collapseOne}
          toggle={() => collapseOneOpen(!collapseOne)}
        >
          <ModalHeader>Editar LaudUS?</ModalHeader>
          <ModalBody>
            Você tem certeza de que gostaria de editar esse LaudUS? Ao clicar em
            sim, as alterações serão salvas permanentemente.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => collapseOneOpen(!collapseOne)}
              color="danger"
            >
              Cancelar
            </Button>
            <Button color="success" onClick={handleEditLaudo}>
              Salvar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={
            <Fragment>
              <Media body className="ml-2">
                <h5 className="mb-0 fs-0" style={{ fontSize: 19 }}>
                  Revisar LaudUS
                </h5>
              </Media>
            </Fragment>
          }
          titleTag={Media}
          titleClass="align-items-center"
        />

        {valuesLaudus || !loading ? (
          <div style={{ padding: 30 }}>
            <p
              style={{
                fontSize: 17,
                fontWeight: "normal",
                color: "#3C3C3C",
                width: "100%",
              }}
            >
              Compartilhe conosco sua experiência, crie um formato de como lauda
              de uma achado ultrassonográfico de uma patologia.
            </p>

            <CardBody className="p-0" style={{ overflowX: "hidden" }}>
              <label
                style={{
                  height: "270px",
                  border: "1px dashed #739AD1",
                  borderRadius: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  background: "#EDF2F9",
                  margin: "15px 0",
                  padding: "15px",
                  cursor: "pointer",
                }}
              >
                {images.length === 0 ? (
                  <>
                    <img src={Up} style={{ width: "50px" }} />
                    <p
                      style={{
                        color: "#9F9F9F",
                        fontSize: "17px",
                        fontWeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Adicione imagens para melhor exemplificar a patologia para
                      o leitor.
                    </p>
                  </>
                ) : (
                  ""
                )}

                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  dataURLKey="data_url"
                  ref={fileField}
                >
                  {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={{
                          color: "#9F9F9F",
                          fontWeight: "normal",
                          border: "1px solid #9F9F9F",
                          padding: "5px",
                          borderRadius: "5px",
                          fontSize: "15px",
                          textAlign: "center",
                          maxWidth: 205,
                          backgroundColor: "transparent",
                        }}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Selecionar arquivos
                      </button>
                      <div
                        className="imgs-col"
                        style={{
                          justifyContent:
                            images.length > 3 ? "flex-start" : "center",
                        }}
                      >
                        {imageList.map((image, index) => (
                          <div key={index} className="drop-images">
                            <div className="image-item__btn-wrapper">
                              {/* <button  onClick={() => onImageRemove(index)}>x</button> */}
                              <div
                                className="trash-btn"
                                onClick={() => onImageRemove(index)}
                              >
                                <AiFillDelete />
                              </div>
                            </div>
                            <img
                              src={image.data_url}
                              alt=""
                              className="drop-image"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </label>
              {error === true && images.length <= 0 ? (
                <S.errorMessage>{errs.images} </S.errorMessage>
              ) : null}
              <div
                onMouseOver={() => showImagesButtons()}
                onMouseOut={() => CloseImagesButton()}
                id="imgs-container"
                style={{
                  position: "relative",
                }}
              >
                <div
                  className="move-back move hidden"
                  id="move-back"
                  onClick={() => handleLeftArrow()}
                >
                  <IoIosArrowBack className="icon-arrow" />
                </div>
                <div
                  className="move-next move hidden"
                  id="move-next"
                  onClick={() => handleRightArrow()}
                >
                  <IoIosArrowForward className="icon-arrow" />
                </div>
                <div
                  style={{
                    maxWidth: listWidth,
                    marginBottom: 20,
                    overflowX: "hidden",
                    marginLeft: scrollX,
                  }}
                  className="list"
                >
                  {rImages.map((item, index) => {
                    return (
                      <div
                        className="image-item"
                        onMouseOver={() => showDeleteBtn(index)}
                        onMouseOut={() => closeDeleteBtn()}
                      >
                        {/* <div className={`left-side side ${index ===0 && 'hidden'}`} onClick={() => changeImagePos('back', item.id, index)}>
                          <AiOutlineCaretLeft color="white" fontSize="22"/>
                        </div> */}
                        <AiFillDelete
                          className={`delete-btn ${!isHidden && "hidden"}`}
                          onClick={() => deleteImageFromArray(item.id, index)}
                        />
                        <img
                          key={index}
                          src={item.src}
                          style={{ width: "100%", height: "100%" }}
                        />

                        {/* <div className={`right-side side ${index ===(rImages.length - 1) && 'hidden'}`} onClick={() => changeImagePos('next', item.id, index)}>
                          <AiOutlineCaretRight color="white" fontSize="22"/>
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
              <Form>
                <FormGroup>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Título <strong style={{ color: "#FF6766" }}>*</strong>{" "}
                    </Label>
                    <div onClick={() => handleEditField("title")}>
                      <EditBtn />
                    </div>
                  </div>
                  <Input
                    disabled={!!isTitleDisabled}
                    className={!!isTitleDisabled && "disabled"}
                    type="text"
                    name="name"
                    id="exampleName"
                    placeholder="Título"
                    maxLength="100"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Label>Adicione o nome da patologia</Label>
                    <S.LabelForm style={{ fontSize: 13 }}>
                      <strong
                        style={{
                          color:
                            title.length > 70 && title.length !== 100
                              ? "#ff8c00"
                              : title.length === 100
                              ? "#FF6766"
                              : "#9c9a9a",
                        }}
                      >
                        Número de caracteres: [{`${title.length}/100`}]
                      </strong>
                    </S.LabelForm>
                  </div>
                </FormGroup>

                <FormGroup>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Descrição <strong style={{ color: "#FF6766" }}>*</strong>{" "}
                    </Label>
                    <div onClick={() => handleEditField("description")}>
                      <EditBtn />
                    </div>
                  </div>
                  <div style={{ border: "1px solid #D8E2EF", borderRadius: 8 }}>
                    <Editor
                      disabled={!!isDescriptionDisabled}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      values={description}
                      setValues={setDescription}
                      className={!!isDescriptionDisabled && "disabled"}
                    />
                  </div>

                  <Label>Descreva acima como lauda a patologia</Label>
                </FormGroup>

                <FormGroup>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Conclusão <strong style={{ color: "#FF6766" }}>*</strong>{" "}
                    </Label>
                    <div onClick={() => handleEditField("conclusion")}>
                      <EditBtn />
                    </div>
                  </div>
                  <div style={{ border: "1px solid #D8E2EF", borderRadius: 8 }}>
                    <Editor
                      disabled={!!isConclusionDisabled}
                      className={!!isConclusionDisabled && "disabled"}
                      values={conclusion}
                      setValues={setConclusion}
                    />
                  </div>
                </FormGroup>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "40px",
                  }}
                >
                  <FormGroup style={{ width: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        for="exampleName"
                        style={{ fontSize: 16, color: "#3C3C3C" }}
                      >
                        Especialidade
                      </Label>
                      <div onClick={() => handleEditField("area")}>
                        <EditBtn />
                      </div>
                    </div>
                    <Input
                      disabled={!!isAreaDisabled}
                      className={!!isAreaDisabled && "disabled"}
                      style={{ width: "100%" }}
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={(e) => {
                        setSpecialtie(e.target.value);
                      }}
                    >
                      <option> </option>
                      {specialties.map((item) => {
                        return (
                          <option
                            value={item.id}
                            selected={specialtie === item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>

                  <FormGroup style={{ width: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        for="exampleName"
                        style={{ fontSize: 16, color: "#3C3C3C" }}
                      >
                        Categorias
                      </Label>
                      <div onClick={() => handleEditField("subArea")}>
                        <EditBtn />
                      </div>
                    </div>
                    <Input
                      disabled={!!isSubAreaDisabled}
                      className={!!isSubAreaDisabled && "disabled"}
                      style={{ width: "100%" }}
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={(e) => {
                        setSubArea(e.target.value);
                      }}
                    >
                      <option> </option>
                      {subAreas.map((item) => {
                        return (
                          <option
                            value={item.id}
                            selected={subArea === item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </div>
                {isSaveBtnVisible && (
                  <div onClick={() => collapseOneOpen(!collapseOne)}>
                    <SaveBtn />
                  </div>
                )}
              </Form>
              {ModalLaudoEdit()}
            </CardBody>
          </div>
        ) : !loading ? (
          <EmptyContent
            src={notNotification}
            msg="Esse conteúdo não está mais disponível!"
            goTo="/list/table/laudus"
            title="Revisar LaudUS"
          />
        ) : null}
      </Card>
      <ChangeIsFreeContent
        id={laudusId}
        type={"laudus"}
        originalValue={valuesLaudus?.isFreeContent}
      />
    </>
  );
};

export default ReviewLaudus;
