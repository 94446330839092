import React, { Fragment, useCallback, useEffect, useState } from "react";
import FeedCard from "./FeedCard";
import CelusApi from "../../server/celusApi";

import Cookies from "js-cookie";
import { FormGroup, Input, Label } from "reactstrap";
import ReactPaginate from "react-paginate";

const FeedContent = ({ draft }) => {
  const api = CelusApi();
  const [status, setStatus] = useState("all");
  const [values, setValues] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLResumusList();
  }, [status, currentPage, draft]);

  useCallback(() => {
    setCurrentPage(0);
  }, [status]);

  const getLResumusList = async () => {
    try {
      setLoading(true);
      const values = {
        page: currentPage,
        limit: 10,
        uid: Cookies.get("clientId"),
        status: status,
      };
      let res;
      console.log({
        draft,
      });
      if (draft) {
        res = await api.getResumusDraft(values);
      } else {
        res = await api.getResumusByAdmin(values);
      }
      setValues(res.result);
      setTotalPage(Math.ceil(res.count / perPage));
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <Fragment>
      <FormGroup style={{ width: "40%" }}>
        <Label
          for="exampleName"
          style={{
            fontSize: 16,
            color: "#3C3C3C",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filtro
        </Label>
        <Input
          type="select"
          name="select"
          id="exampleSelect"
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <option value="" disabled selected hidden>
            Filtrar
          </option>
          <option value="all">Todos</option>
          <option value="approved">Publicados</option>
          <option value="disapproved">Reprovados</option>
        </Input>
      </FormGroup>

      <FeedCard values={values} loading={loading} />

      <div className="pag-table">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          forcePage={currentPage === 0 ? 0 : currentPage - 1}
          pageCount={totalPage}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </Fragment>
  );
};

export default FeedContent;
