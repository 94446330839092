import React, { useState, Fragment, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Media,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import * as S from "./css/components/index";
import { Button } from "react-bootstrap";
import { Editor } from "../Editor";
import CelusApi from "../../server/celusApi";
import FalconCardHeader from "../common/FalconCardHeader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import "react-tagsinput/react-tagsinput.css";
import { Up, ImgNull } from "../../assets/img/icons/celus/index";
import ModalImg from "../../assets/img/celus/resumus/resumus-modal-img.png";
import Cookies from "js-cookie";
import { Link, useParams, useHistory } from "react-router-dom";
import { Reference } from "../Reference";

const EditResumus = () => {
  const { doc } = useParams();

  const api = CelusApi();
  const [content, setContent] = useState("");
  const [specialtie, setSpecialtie] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [subArea, setSubArea] = useState("");
  const [tags, setTags] = useState([]);
  const [ref, setRef] = useState([]);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [isEditedModalOpen, setIsEditedModalOpen] = useState(false);
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [images, setImages] = React.useState(null);
  const [error, setError] = useState(false);
  const [collapseOne1, collapseOneOpen1] = useState(false);

  const history = useHistory();
  const [errs, setErrs] = useState({
    title: "",
    description: "",
    conclusion: "",
    images: "",
    subArea: "",
    specialtie: "",
  });

  const preview = useMemo(() => {
    return images ? URL.createObjectURL(images) : null;
  }, [images]);

  useEffect(() => {
    const GetSpecialties = async () => {
      try {
        const res = await api.listAreas("resumus");
        setSpecialties(res);
      } catch (error) {
        console.log(error);
      }
    };
    GetSpecialties();
  }, [api]);

  useEffect(() => {
    const GetSubAreas = async () => {
      try {
        const res = await api.getListSubAreas({
          specialtie: specialtie,
          type: "resumus",
        });
        setSubAreas(res);
      } catch (error) {
        console.log(error);
      }
    };
    GetSubAreas();
  }, [api, specialtie]);

  useEffect(() => {
    const GetResumusByDoc = async () => {
      try {
        const result = await api.getResumusByDoc(doc);
        setTitle(result.result[0].title);
        setSubTitle(result.result[0].subtitle);
        setContent(result.result[0].content);
        setSubArea(Number(result.result[0].subAreaId));
        setSpecialtie(Number(result.result[0].areaId));
        setThumbnail(result.result[0].thumbnail);
        setRef(result.result[0].references);
        setTags(result.result[0].keywords);
      } catch (error) {
        console.log(error);
      }
    };
    GetResumusByDoc();
  }, [api, doc]);

  const saveAsDraft = async (status) => {
    setIsDraftModalOpen(!isDraftModalOpen);
    // setTimeout(function(){ setIsDraftModalOpen(false); }, 3000);
    setTimeout(function () {
      document.getElementById("draft-alert").classList.add("draft-alert-out");
      document
        .getElementById("draft-alert-span")
        .classList.add("draft-alert-out");
    }, 4000);
  };

  const CreateModal = () => {
    return (
      <Modal
        isOpen={isEditedModalOpen}
        toggle={() => setIsEditedModalOpen(!isEditedModalOpen)}
      >
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              ResumUS editado e publicado com sucesso!
            </span>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "15px 45px",
          }}
        >
          <img
            src={ModalImg}
            style={{ maxWidth: "200px", height: "auto", marginBottom: "20px" }}
          />

          <div>
            {/* <p style={{width: '100%', textAlign: 'center'}}>Seu resumUS foi enviado para validação, o tempo de análise dura entre 3 a 8 dias. Em breve enviaremos um feedback do seu resumUS, fique atento.</p> */}
            <p style={{ width: "100%", textAlign: "center" }}>
              Seu resumUS foi editado e já está disponível para a visualização.
            </p>
          </div>
        </ModalBody>
        <ModalFooter
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            borderTop: "none",
            paddingBottom: "20px",
          }}
        >
          <Link
            style={{
              background: "#fff",
              border: "1px solid #50D19A",
              color: "#50D19A",
              borderRadius: "5px",
              padding: "10px 15px",
            }}
            to={"/resumus/list"}
          >
            Ver resumUS
          </Link>
          <Link
            style={{
              background: "#50D19A",
              border: 0,
              borderRadius: "5px",
              padding: "10px 15px",
            }}
            to={"/resumus/new"}
          >
            Criar novo resumUS
          </Link>
        </ModalFooter>
      </Modal>
    );
  };

  const postArtigo = async (status) => {
    if (title && content && tags.length > 0 && specialtie && subAreas) {
      if (status === true) {
        saveAsDraft();
      } else {
        setIsEditedModalOpen(!isEditedModalOpen);
      }
      const data = new FormData();

      data.append("doc", doc);
      data.append("file_url", thumbnail ? thumbnail : null);
      data.append("areaId", specialtie);
      data.append("content", content);
      data.append("isDraft", status);
      data.append("subAreaId", subArea);
      data.append("subtitle", subTitle);
      data.append("title", title);
      data.append("clientId", Number(Cookies.get("clientId")));
      data.append("references", ref ? JSON.stringify(ref) : null);
      data.append("keywords", JSON.stringify(tags));
      data.append("file", images);

      try {
        await api.putResumus(data);
        if (status === true) {
          setIsDraftModalOpen(false);
          window.location.href = "/resumus/list";
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (!title) {
        errs.title = "O campo titulo é obrigatorio!";
      } else {
        errs.title = null;
      }
      // if (!subTitle) {
      //   errs.subtitle = 'O campo subtitulo é obrigatorio!';
      // } else {
      //   errs.subtitle = null
      // };
      if (content === "<p><br></p>") {
        errs.description = "O campo descrição é obrigatorio!";
      } else {
        errs.description = null;
      }
      // if (!images) {
      //   errs.images = 'O campo de imagem é obrigatorio!';
      // } else {
      //   errs.images = null
      // };
      if (tags.length <= 0) {
        errs.tags = "O campo de tags é obrigatorio!";
      } else {
        errs.tags = null;
      }
      if (!specialtie) {
        errs.specialtie = "O campo de especialidade é obrigatorio!";
      } else {
        errs.specialtie = null;
      }
      if (!subArea) {
        errs.subarea = "O campo de categoria é obrigatorio!";
      } else {
        errs.subarea = null;
      }
      // if (!ref) {
      //   errs.ref = 'O campo de referências é obrigatorio!';
      // } else {
      //   errs.ref = null
      // };
      setErrs({ ...errs });
      setError(true);
      setIsValidationModalOpen(true);
    }
  };

  const DeleteResumus = async () => {
    try {
      await api.deleteResumus(doc);
      history.push("/resumus/list");
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteResumusModal = () => {
    return (
      <Modal
        isOpen={collapseOne1}
        toggle={() => collapseOneOpen1(!collapseOne1)}
      >
        <ModalHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div>Aviso!</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: 17, fontWeight: "bold", width: "100%" }}>
            Você está prestes a excluir um documento!
          </p>
        </ModalBody>
        <ModalFooter style={{ width: "100%", justifyContent: "space-between" }}>
          <Button onClick={() => collapseOneOpen1(!collapseOne1)}>
            Cancelar
          </Button>
          <Button
            style={{ background: "#50D19A", border: 0 }}
            onClick={() => {
              DeleteResumus();
            }}
          >
            Continuar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const ValidationModal = () => {
    return (
      <Modal
        isOpen={isValidationModalOpen}
        toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}
      >
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              Ops! Alguns campos estão incompletos
            </span>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "200px",
            }}
          >
            <S.ValidateModalRow>
              <span>
                {!errs.title ? <S.CheckIcon checked /> : <S.CheckIcon />} Titulo
              </span>
            </S.ValidateModalRow>

            {/* <S.ValidateModalRow>
              <span>{!errs.subtitle ? <S.CheckIcon checked /> : <S.CheckIcon />} Subtitulo</span>
            </S.ValidateModalRow> */}

            <S.ValidateModalRow>
              <span>
                {!errs.description ? <S.CheckIcon checked /> : <S.CheckIcon />}{" "}
                Descrição
              </span>
            </S.ValidateModalRow>

            {/* <S.ValidateModalRow>
              <span>{!errs.images ? <S.CheckIcon checked /> : <S.CheckIcon />} Imagem destaque</span>
            </S.ValidateModalRow> */}

            <S.ValidateModalRow>
              <span>
                {!errs.tags ? <S.CheckIcon checked /> : <S.CheckIcon />} Tags
              </span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>
                {!errs.specialtie ? <S.CheckIcon checked /> : <S.CheckIcon />}{" "}
                Especialidade
              </span>
            </S.ValidateModalRow>

            <S.ValidateModalRow>
              <span>
                {!errs.subarea ? <S.CheckIcon checked /> : <S.CheckIcon />}{" "}
                Categoria
              </span>
            </S.ValidateModalRow>
            {/* 
            <S.ValidateModalRow>
              <span>{!errs.ref ? <S.CheckIcon checked /> : <S.CheckIcon />} Referências</span>
            </S.ValidateModalRow> */}
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button
            block
            className="modal-save"
            onClick={() => setIsValidationModalOpen(!isValidationModalOpen)}
          >
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={
          <Fragment>
            <Media body className="ml-2">
              <h5 className="mb-0 fs-0" style={{ fontSize: 19 }}>
                Editar ResumUS
              </h5>
            </Media>
          </Fragment>
        }
        titleTag={Media}
        titleClass="align-items-center"
      />

      <div style={{ padding: 30 }}>
        <CardBody className="p-0">
          <Form>
            <FormGroup>
              <Label
                for="exampleName"
                style={{
                  fontSize: 16,
                  color: "#3C3C3C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Título <strong style={{ color: "#FF6766" }}> *</strong>
              </Label>
              <Input
                type="text"
                name="name"
                id="exampleName"
                placeholder="Título"
                value={title}
                maxlength="100"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <S.LabelForm
                style={{ fontSize: 13, marginTop: 15, position: "relative" }}
              >
                <strong
                  style={{
                    position: "absolute",
                    right: 0,
                    color:
                      title.length > 70 && title.length !== 100
                        ? "#ff8c00"
                        : title.length === 100
                        ? "#FF6766"
                        : "#9c9a9a",
                  }}
                >
                  Número de caracteres: [{`${title.length}/100`}]
                </strong>
              </S.LabelForm>
              {error === true && !title ? (
                <S.errorMessage>{errs.title}</S.errorMessage>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label
                for="exampleName"
                style={{
                  fontSize: 16,
                  color: "#3C3C3C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Subtítulo
              </Label>
              <Input
                type="text"
                name="name"
                id="exampleName"
                placeholder="Subtítulo"
                maxlength="100"
                value={subTitle}
                onChange={(e) => {
                  setSubTitle(e.target.value);
                }}
              />
              <S.LabelForm
                style={{ fontSize: 13, marginTop: 15, position: "relative" }}
              >
                <strong
                  style={{
                    position: "absolute",
                    right: 0,
                    color:
                      subTitle.length > 70 && subTitle.length !== 100
                        ? "#ff8c00"
                        : subTitle.length === 100
                        ? "#FF6766"
                        : "#9c9a9a",
                  }}
                >
                  Número de caracteres: [{`${subTitle.length}/100`}]
                </strong>
              </S.LabelForm>
              {error === true && !subTitle ? (
                <S.errorMessage>{errs.subtitle}</S.errorMessage>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label
                for="exampleName"
                style={{
                  fontSize: 16,
                  color: "#3C3C3C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Descrição <strong style={{ color: "#FF6766" }}> *</strong>
              </Label>

              <Editor setValues={setContent} values={content} />
            </FormGroup>
            {error === true && content === "<p><br></p>" ? (
              <S.errorMessage>{errs.description}</S.errorMessage>
            ) : null}
            <FormGroup>
              <Label
                for="exampleName"
                style={{
                  fontSize: 16,
                  color: "#3C3C3C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Foto destaque
                <strong style={{ color: "#FF6766" }}> *</strong>
              </Label>

              <S.PreviwArea>
                <S.SelectImage>
                  <S.Up src={Up} />
                  {preview
                    ? null
                    : !thumbnail
                    ? "Escolha uma imagem"
                    : "Atualizar imagem"}
                  <Input
                    type="file"
                    name="filefield"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => setImages(e.target.files[0])}
                  />
                </S.SelectImage>

                <S.PreviwAreaImage>
                  <S.ImagemPreviw
                    src={preview ? preview : thumbnail ? thumbnail : ImgNull}
                  />
                </S.PreviwAreaImage>
              </S.PreviwArea>

              <S.LabelForm style={{ fontSize: 14 }}>
                Adicione uma imagem de destaque para o seu artigo.
              </S.LabelForm>

              {/* <img src={preview ? preview : thumbnail ? thumbnail : Up} style={{ width: '250px', height: '120px', marginBottom: '10px', objectFit: 'cover' }} />

                {preview ? null : !thumbnail ? <p style={{ color: '#9F9F9F', fontSize: '17px', fontWeight: 'normal', textAlign: 'center' }} >Adicione uma foto de destaque para o seu artigo.</p> : null}
                <p style={{ color: '#9F9F9F', fontSize: '17px', fontWeight: 'normal', border: '1px solid #9F9F9F', padding: '5px', borderRadius: '5px', fontSize: '15px', maxWidth: 205, textAlign: 'center' }}> {preview ? 'Atualizar Arquivo' : 'Selecionar Arquivo'}</p>
                <Input type="file" name="filefield" accept="image/*"
                  style={{ display: 'none' }} onChange={e => setImages(e.target.files[0])}
                /> */}
            </FormGroup>

            <FormGroup>
              <Label
                for="exampleName"
                style={{
                  fontSize: 16,
                  color: "#3C3C3C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Tags
              </Label>
              <ReactTagInput
                tags={tags}
                onChange={(newTags) => setTags(newTags)}
                placeholder="Tags"
              />
              <S.LabelForm style={{ fontSize: 14, marginTop: 5 }}>
                Pressione a tecla Enter a cada tag informada!
              </S.LabelForm>
            </FormGroup>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormGroup style={{ width: "49%" }}>
                <Label
                  for="exampleName"
                  style={{
                    fontSize: 16,
                    color: "#3C3C3C",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Especialidade
                </Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => {
                    setSpecialtie(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Especialidade
                  </option>
                  {specialties.map((item) => {
                    return (
                      <option value={item.id} selected={specialtie === item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup style={{ width: "49%" }}>
                <Label
                  for="exampleName"
                  style={{
                    fontSize: 16,
                    color: "#3C3C3C",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Categoria
                </Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => {
                    setSubArea(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    Categoria
                  </option>
                  {subAreas.map((item) => {
                    return (
                      <option value={item.id} selected={subArea === item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </div>

            <FormGroup>
              <Label
                for="exampleName"
                style={{
                  fontSize: 16,
                  color: "#3C3C3C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Referências
              </Label>

              <Reference disabled={false} arrayRef={ref} setArreyRef={setRef} />
            </FormGroup>
            <Row noGutters className="justify-content-between ">
              <Col
                className="col"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <S.DraftBtn
                  onClick={() => {
                    postArtigo(true);
                  }}
                >
                  Salvar como rascunho
                </S.DraftBtn>

                <S.PublishBtn
                  onClick={() => {
                    postArtigo(false);
                  }}
                >
                  Publicar alterações
                </S.PublishBtn>

                <S.DeleteBtn
                  onClick={() => {
                    collapseOneOpen1(!collapseOne1);
                  }}
                >
                  Excluir resumUS
                </S.DeleteBtn>
              </Col>
            </Row>
          </Form>

          {isDraftModalOpen && (
            <div
              className="draft-alert"
              id="draft-alert"
              style={{
                width: "100%",
                padding: "8px 30px",
                background: "#31AF89",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                marginTop: "30px",
              }}
            >
              <IoIosCheckmarkCircle
                style={{ color: "#fff", fontSize: "21px" }}
              />
              <span
                id="draft-alert-span"
                style={{
                  color: "#FFF",
                  fontSize: "17px",
                  fontWeight: "lighter",
                }}
              >
                Rascunho salvo com sucesso!
              </span>
            </div>
          )}
        </CardBody>
      </div>
      {CreateModal()}
      {ValidationModal()}
      {DeleteResumusModal()}
    </Card>
  );
};

export default EditResumus;
