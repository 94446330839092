import React from 'react';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import FeedContent from './FeedContent';
import FeedSideBar from '../feedUS/FeedSideBar';
import FeedProvider from './FeedProvider';
import NavFeed from './NavFeed';

export const MyLaudusDraft = () => {
  return (
    <FeedProvider style={{ margin: 0 }}>
      <ContentWithAsideLayout aside={<FeedSideBar />} isStickyAside={false}>
        <NavFeed/>
        <FeedContent/>
      </ContentWithAsideLayout>
    </FeedProvider>
  )
};

