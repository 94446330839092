import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { FaBookmark, FaRegBookmark, FaShareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ImgNull } from "../../assets/img/icons/celus/index";

import celusApi from "../../server/celusApi";
import Cookies from "js-cookie";

import "./styles.scss";

export const CardResumus = ({
  values,
  to,
  status = false,
  isDraft = false,
  Children,
}) => {
  const [checkFavorite, setCheckFavorite] = useState(false);
  const api = celusApi();

  const getFavoriteResumus = async (id) => {
    const values = {
      docId: id,
      uid: Cookies.get("clientId"),
    };
    const result = await api.checkFavoriteResumus(values);
    setCheckFavorite(result.result.toFavorite);
  };

  const saveResumus = async (id) => {
    const values = {
      docId: id,
      uid: Cookies.get("clientId"),
    };
    try {
      await api.postFavorite(values);
      getFavoriteResumus(values.docId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFavoriteResumus(values?.id);
  }, [setCheckFavorite, values]);

  return (
    <Fragment>
      <div className="container-card">
        <div className="content-card">
          <div className="row-card">
            <Link to={`${to}/${values?.id}`}>
              <div className="content-item">
                <div className="content-title-card">
                  <h3 className="title-card">{values.title}</h3>
                </div>
                {/* <p className="content-abistract">{item.content.stripHTML()}</p> */}
                <p className="content-abistract">{values.subtitle}</p>
              </div>
            </Link>
            <div className="footer-card">
              <p>Autor: {values.author.name} </p>

              <div>
                {isDraft ? (
                  Children
                ) : (
                  <>
                    <button
                      className="item-button"
                      onClick={() => {
                        saveResumus(values?.id);
                      }}
                      style={{ outline: "none" }}
                    >
                      {checkFavorite ? (
                        <FaBookmark
                          transform="shrink-4"
                          className="fs-2 footer-icon"
                          color="#31AF89"
                          style={{ marginRight: 17 }}
                        />
                      ) : (
                        <FaRegBookmark
                          transform="shrink-4"
                          className="fs-2 footer-icon"
                          color="#484848"
                          style={{ marginRight: 17 }}
                        />
                      )}
                    </button>

                    <FaShareAlt
                      transform="shrink-4"
                      className="fs-2 footer-icon"
                      color="#344050"
                      onClick={() => {
                        navigator.share({
                          title: "Share",
                          text: "whatevs",
                          url: `/to-see/resumus/${values.id}`,
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="thumb">
            <img src={values.thumbnail ? values.thumbnail : ImgNull} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
