import React from 'react';

import FormReviewResumus from '../widgets/FormReviewResumus';

const index = () => {
    return(
        <>
            <FormReviewResumus/>
        </>
    );
}

export default index;