import React, { useState, useEffect } from "react";
import * as S from "./style/resumus.js";
import Cookies from "js-cookie";
import celusApi from "../../server/celusApi";
import { useCommentRefresh } from "../../hooks/useCommentUpdate";
import { Dots } from "react-activity";

const PostCommentCard = ({ docId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [content, setContent] = useState("");
  const { setRefresh, refresh } = useCommentRefresh();

  const defaultAvatar =
    "https://firebasestorage.googleapis.com/v0/b/celus-d9ab7.appspot.com/o/client%2Fdefault-profile-img.png?alt=media&token=c16e7289-b613-4014-a9f1-41def62c78e0";

  const handleComment = async () => {
    try {
      if (!!content) {
        setIsLoading(true);
        const values = {
          docId: docId,
          content: content,
          clientId: parseInt(Cookies.get("clientId")),
        };

        await celusApi().postCommentResumus(values);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      if (!!content) {
        setIsLoading(false);
        setRefresh(!refresh);
        setContent("");
      }
    }
  };

  useEffect(() => {
    setUserName(Cookies.get("name"));
    setUserAvatar(Cookies.get("avatar"));
  }, []);

  return (
    <S.Container>
      <S.AuthorRow>
        <S.Avatar src={userAvatar || defaultAvatar} />
        <S.PostDetailsArea>
          <S.AuthorName>{userName}</S.AuthorName>
        </S.PostDetailsArea>
      </S.AuthorRow>

      <S.ContentRow>
        {/* {comment.content} */}
        <S.PostCommentInput
          placeholder="Digite seu comentário aqui"
          onChange={(e) => setContent(e.target.value)}
          value={content}
        />
      </S.ContentRow>

      <S.PostInteractionRow>
        <S.PostSubmitBtn onClick={() => handleComment()}>
          {isLoading ? <Dots /> : "Publicar"}
        </S.PostSubmitBtn>
      </S.PostInteractionRow>
    </S.Container>
  );
};

export default PostCommentCard;
