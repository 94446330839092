import qs from "qs";
import Cookies from "js-cookie";

const BASEAPI = process.env.REACT_APP_BASEURL;
const BASEAPI_PUBLIC = process.env.REACT_APP_BASEAPI_PUBLIC;

export const apiFetchPost = async (endpoint, body) => {
  if (!body.token) {
    let token = Cookies.get("token");
    if (token) {
      body.token = token;
    }
  }

  const res = await fetch(BASEAPI + endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify(body),
  });

  const json = await res.json();

  if (json.notallowed) {
    window.location.href = "/authentication/login";
    return;
  }

  return json;
};

export const apiFetchDelete = async (endpoint) => {
  let token = Cookies.get("token");

  const res = await fetch(`${BASEAPI + endpoint}?${qs.stringify({ token })}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const json = await res.json();

  if (json.notallowed) {
    window.location.href = "/authentication/login";
    return;
  }

  return json;
};

export const apiFetchGet = async (endpoint, body = {}) => {
  if (!body.token) {
    let token = Cookies.get("token");
    if (token) {
      body.token = token;
    }
  }

  const res = await fetch(`${BASEAPI + endpoint}?${qs.stringify(body)}`);
  const json = await res.json();

  if (json.notallowed) {
    window.location.href = "/authentication/login";
    return;
  }

  return json;
};

export const apiFetchPublicGet = async (endpoint, body = []) => {
  if (!body.token) {
    let token = Cookies.get("token");
    if (token) {
      body.token = token;
    }
  }

  const res = await fetch(`${BASEAPI_PUBLIC + endpoint}?${qs.stringify(body)}`);
  const json = await res.json();

  if (json.notallowed) {
    window.location.href = "/authentication/login";
    return;
  }

  return json;
};

export const apiFetchFile = async (endpoint, body) => {
  let token = Cookies.get("token");

  // if (!body.token && token) {
  //   body.append("token", token);
  // }

  const res = await fetch(
    BASEAPI +
      endpoint +
      "?" +
      new URLSearchParams({
        token: token,
      }),
    {
      method: "POST",
      body,
    }
  );

  const json = await res.json();

  // if (json.notallowed) {
  //   window.location.href = "/authentication/login";
  //   return;
  // }
  return json;
};
