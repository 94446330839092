import React, { Fragment } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./styles.scss";

export const LoadingTableAnatomus = () => {
    return (
        <Fragment>
            <tr>
                <td style={{ margin: 0 }}>
                    <div className="row__itens">
                        <Skeleton width={50} height={25} />
                    </div>
                </td>
                <td>
                    <div className="item">
                        <Skeleton width={300} height={25} />
                    </div>
                </td>
                <td>
                    <div className="item">
                        <Skeleton width={300} height={25} />
                    </div>
                </td>

                <td>
                    <div className="row__itens">
                        <Skeleton width={50} height={25} style={{marginRight: 10}} />
                        <Skeleton width={50} height={25} />
                    </div>
                </td>

                
            </tr>
        </Fragment>
    )
}
