import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CelusApi from '../../server/celusApi';
import { auth, firebase } from '../../server/firebase';
import * as S from './styles';

export const NewUser = () => {

    const api = CelusApi();
    const [email, setEmail] = useState();
    const [checkEmail, setCheckEmail] = useState();
    const [name, setName] = useState()
    const [password, setPassword] = useState();
    const [checkPassword, setCheckPassword] = useState();
    const [func, setFunc] = useState();
    const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [errs, setErrs] = useState({
        name: undefined,
        checkEmail: undefined,
        checkPassword: undefined,
        email: undefined,
        password: undefined,
        func: undefined,
        passwordDifferent: undefined,
        emailDifferent: undefined,
    });

    useEffect(() => {
        CheckPassword()
    }, [password, checkPassword]);

    useEffect(() => {
        CheckEmail()
    }, [email, checkEmail]);

    const CheckEmail = () => {
        return email === checkEmail;
    }
    const CheckPassword = () => {
        return password ===checkPassword;
    }

    const Submit = async () => {
        if (name && email && checkEmail && (email === checkEmail) && (password ===checkPassword) && password && checkPassword && func && (password.length >= 6)) {
            setLoading(true);
            try {
                const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
                await user.updateProfile({ displayName: name });

                const values = {
                    name: name,
                    email: email,
                    password: password,
                    firebaseUid: user.uid,
                    occupation: func
                }
                const result = await api.PostAdmin(values);

                if (result.errorMsg) {
                    toast.error(`${result.errorMsg}: ${email}`);

                } else {
                    toast.success(`Usuário cadastrado: ${email}`);
                    setLoading(false);
                }

            } catch (error) {
                if (error.code) {
                    toast.error(`${error.message}: ${email}`);
                } else {
                    toast.error(`Não foi possivel cadastrar o novo usuário: ${email}`);
                }
                setLoading(false);
            }

        } else {

            if (!name) {
                errs.name = 'O campo Nome é obrigatorio!';
            } else {
                errs.name = null;
            }
            if (!password) {
                errs.password = 'O campo Senha é obrigatorio!';
            } else {
                if (password.length <= 6) {
                    errs.password = 'A senha precisa ter pelo menos 6 (seis) caracteres!';
                } else {
                    errs.password = null;
                }
            }
            if (!checkPassword) {
                errs.checkPassword = 'O campo Confirmar Senha é obrigatorio!';
            } else {
                
                if (!CheckPassword() || checkPassword.length <= 6) {                    
                    errs.checkPassword = 'As senhas não são iguais!';
                } else {
                    errs.checkPassword = null;
                }
            }
            if (!email) {
                errs.email = 'O campo E-mail é obrigatorio!';
            } else {
                errs.email = null;
            }
            if (!checkEmail) {
                errs.checkEmail = 'O campo Confirmar E-mail é obrigatorio!';
            } else {
                if(!CheckEmail()){
                    errs.checkEmail = 'Os E-mails não são iguais!';
                }else{
                    errs.checkEmail = null;
                }
            }
            if (!func) {
                errs.func = 'O campo Função é obrigatorio!';
            } else {
                errs.func = null;
            }


            setErrs({ ...errs });
            setError(true);
            setIsValidationModalOpen(true);
            setLoading(false);
        }



    }

    const texts = {
        info: 'Crie um novo usuário preenchendo as informações abaixo. Usuários editores podem criar, editar, aprovar e reprovar laudUS e resumUS, usuários administradores possuem todos os privilégios de editor e ainda podem bloquear ou ativar usuários e cadastrar novos usuários.'
    }

    const ValidationModal = () => {
        return (
            <Modal isOpen={isValidationModalOpen} toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}>
                <ModalHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div>
                        <span style={{ fontSize: '22px', fontWeight: 'bold' }}>Ops! Alguns campos estão incompletos</span>
                    </div>
                </ModalHeader>
                <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '200px' }}>
                        <S.ValidateModalRow>
                            <span>{!errs.name ? <S.CheckIcon checked /> : <S.CheckIcon />} Nome Completo</span>
                        </S.ValidateModalRow>

                        <S.ValidateModalRow>
                            <span>{!errs.email ? <S.CheckIcon checked /> : <S.CheckIcon />} E-mail</span>
                        </S.ValidateModalRow>

                        <S.ValidateModalRow>
                            <span>{!errs.checkEmail ? <S.CheckIcon checked /> : <S.CheckIcon />} Confirmar e-mail</span>
                        </S.ValidateModalRow>

                        <S.ValidateModalRow>
                            <span>{!errs.password ? <S.CheckIcon checked /> : <S.CheckIcon />} Senha</span>
                        </S.ValidateModalRow>

                        <S.ValidateModalRow>
                            <span>{!errs.checkPassword ? <S.CheckIcon checked /> : <S.CheckIcon />} Confirmar senha</span>
                        </S.ValidateModalRow>

                        <S.ValidateModalRow>
                            <span>{!errs.func ? <S.CheckIcon checked /> : <S.CheckIcon />} Função</span>
                        </S.ValidateModalRow>
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <S.IButton block className="modal-save" onClick={() =>
                        setIsValidationModalOpen(!isValidationModalOpen)}
                    >
                        Voltar
                    </S.IButton>
                </ModalFooter>
            </Modal>
        );
    }

    const Form = () => {
        return (
            <Card className="mb-3">
                <S.ICardHeader>Cadastrar um novo usuário</S.ICardHeader>
                <CardBody>
                    <S.P>{texts.info}</S.P>

                    <S.IFormGroup>
                        <S.ILabel for="exampleName">Nome Completo <S.IRequired>*</S.IRequired> </S.ILabel>
                        <S.IInput
                            checked={name ? true : false}
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Nome completo"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                        {(error === true && !name) ? <S.errorMessage >{errs.name}</S.errorMessage > : null}
                    </S.IFormGroup>
                    <S.IFormGroup>
                        <S.ILabel for="exampleName">E-mail <S.IRequired>*</S.IRequired></S.ILabel>
                        <S.IInput
                            checked={CheckEmail()}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                        {(error ===true && !email) ? <S.errorMessage >{errs.email}</S.errorMessage > : null}
                    </S.IFormGroup>

                    <S.IFormGroup>
                        <S.ILabel for="exampleName">Confirmar e-mail <S.IRequired>*</S.IRequired></S.ILabel>
                        <S.IInput
                            checked={CheckEmail()}
                            type="email"
                            name="checkEmail"
                            id="checkEmail"
                            placeholder="Confirmar e-mail"
                            value={checkEmail}
                            onChange={(e) => { setCheckEmail(e.target.value) }}
                        />
                        {((error === true && !checkEmail) || errs.checkEmail) ? <S.errorMessage >{errs.checkEmail}</S.errorMessage > : null}
                    </S.IFormGroup>

                    <S.Row>
                        <S.IFormGroup style={{ width: '48%' }}>
                            <S.ILabel for="exampleName">Senha <S.IRequired>*</S.IRequired></S.ILabel>
                            <S.IInput
                                checked={CheckPassword()}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                            {(error ===true && !password || errs.checkPassword) ? <S.errorMessage >{errs.password}</S.errorMessage > : null}
                            {(error ===true && errs.password && password) ? <S.errorMessage >{errs.password}</S.errorMessage > : null}
                        </S.IFormGroup>

                        <S.IFormGroup style={{ width: '48%' }}>
                            <S.ILabel for="exampleName">Confirmar senha <S.IRequired>*</S.IRequired></S.ILabel>
                            <S.IInput
                                checked={CheckPassword()}
                                type="password"
                                name="checkPassword"
                                id="checkPassword"
                                placeholder="Confirmar senha"
                                value={checkPassword}
                                onChange={(e) => { setCheckPassword(e.target.value) }}
                            />
                            {(error ===true && !checkPassword || errs.checkPassword) ? <S.errorMessage >{errs.checkPassword}</S.errorMessage > : null}
                            {(error ===true && errs.password && password) ? <S.errorMessage >{errs.password}</S.errorMessage > : null}
                        </S.IFormGroup>

                    </S.Row>
                    <S.IFormGroup style={{ width: '48%' }}>
                        <S.ILabel for="exampleName">Função <S.IRequired>*</S.IRequired></S.ILabel>
                        <S.IInput
                            checked={func ? true : false}
                            type="select"
                            name="function"
                            id="function"
                            onChange={(e) => { setFunc(e.target.value) }}
                        >
                            <option value="" disabled selected hidden> Função</option>
                            <option value="admin" selected={func ==='admin'} > Administrador</option>
                            <option value="edit" selected={func ==='edit'} > Editor</option>
                        </S.IInput>
                        {(error ===true && !func) ? <S.errorMessage >{errs.func}</S.errorMessage > : null}
                    </S.IFormGroup>

                    <S.Row >
                        <S.IButton style={{ marginTop: 35 }} onClick={Submit} disabled={loading}>Cadastrar</S.IButton>
                    </S.Row>
                </CardBody>
            </Card>
        )
    }
    return (
        <Fragment>
            <Col lg="8">
                <ContentWithAsideLayout banner={Form()}>
                </ContentWithAsideLayout>
            </Col>
            {ValidationModal()}
        </Fragment>
    )
}