import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import "./styles.scss";

export const LoadingCardRanking = () => {
    return(
        <Fragment>
            <Skeleton className="loading__card" count={1}/>
        </Fragment>
    )
}