import styled from "styled-components";

export const NotificationCard = styled.div`
    width: 100%;
    padding: 5px;

    background-color: ${(props) => (props.backgroundColor)};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    border-bottom: 1px #ccc solid;

    margin-bottom: 10px;
    border-radius: 5px;

    box-shadow: 0px 0px 5px #00000029;
    border-radius: 5px;
    opacity: 1;

    transition: 0.5s;
    :hover{
        cursor: pointer;
        background-color: #F4F4F4;
    }
    
`;

export const ContentNotification = styled.div`
    margin-top: 10px;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 10px;
`;

export const Description = styled.div`
    padding: 5px;
    margin-left: 20px;

    overflow: hidden; // Removendo barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 2; // Quantidade de linhas
    -webkit-box-orient: vertical; 
    
`;

export const NotoficationTrue = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;

    width: 15px;
    height: 15px;

    border-radius: 20px;
    background-color: #31AF89;

    animation-name: stretch;
    animation-duration: 2.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    
    @keyframes stretch {
            0% {
                transform: scale(1);
                background-color: #CCC;
                border-radius: 100%;
            }
  
            50% {
                background-color: #31AF89;
            }
  
            100% {
                transform: scale(1.3);
                background-color: #31AF89;
            }
        }
`;

export const NotoficationCircle = styled.div `
    width: 5px;
    height: 5px;

    border-radius: 20px;
    background-color: #FFFF;
`;
export const TypeDate = styled.p`
    font-size: 14px;
    color: #484848;
    font-weight: 600;

    margin-top: 15px;
    margin-bottom: 0;
    padding: 0;

    :hover{
        color: #000;
    }
`;
