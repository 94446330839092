import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const AuthorRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const ContentRow = styled.div`
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  /* background: blue; */
  color: #334444;
  margin-top: 10px;
`;

export const InteractionRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 10px;
`;

export const PostInteractionRow = styled(InteractionRow)`
  justify-content: flex-end;
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #7BE495;
`;

export const DetailsArea = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: #fff;
`;

export const PostDetailsArea = styled(DetailsArea)`
  justify-content: flex-start;
  padding-top: 5px;
`;

export const AuthorName = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #334444;
`;

export const Date = styled.span`
  color: #99AAAA;
  font-size: 14px;
  color: #334444;
`;

export const PostCommentInput = styled.textarea`
  width: 100%;
  border: none;
`;

export const PostSubmitBtn = styled.button`
  width: auto;
  padding: 10px 20px;
  border: 1px solid #56C596;
  border-radius: 4px;
  color: #56C596;
  background: transparent;
  transition: 0.2s all ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  .disabled{
    opacity: 0.4;
  }

  :hover{
    background: #56C596;
    color: #fff;
  }

  :focus{
    outline: none;
  }
  
`;