import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import celusApi from '../../server/celusApi';
import Cookies from 'js-cookie';
import { CardLaudus } from '../cardLaudus/CardLaudus';

import './styles.scss';

const FeedCard = ({ values, loading }) => {

  const [collapseOne, collapseOneOpen] = useState(false);
  const [id, setId] = useState();

  const ModalAlert = () => {
    return (
      <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)}>
        <ModalHeader >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }} >
            <div>Aviso!</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: 17, fontWeight: 'bold' }}>você tem certeza que quer excluir esse LaudUS?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={cls}>
            Cancelar
          </Button>
          <Button style={{ background: '#50D19A', border: 0 }} onClick={DeleteLaudus}>Continuar</Button>
        </ModalFooter>
      </Modal>
    );
  }

  const cls = () => {
    collapseOneOpen(!collapseOne);
  }

  const DeleteLaudus = async () => {
    try {
      const values = { id: id, clientId: Cookies.get('clientId') }
      await celusApi().deleteLaudus(values);
      collapseOneOpen(!collapseOne)
      window.location.href = '/my-laudus/draft';
    } catch (error) {
      console.log(error);
    }
  }

  const action = (id) => {
    return (
      <div className="item-acao">

        <Link className="item-button"
          to={`/laudus/edit/${id}`}
          style={{
            outline: 'none'
          }}
        >
          <AiOutlineEdit transform="shrink-4" className="fs-2" color='#344050' />
        </Link>

        <button className="item-button"
          style={{
            outline: 'none'
          }}
          onClick={() => { 
            collapseOneOpen(!collapseOne)
            setId(id)
          }}
        >
          <AiOutlineDelete transform="shrink-4" className="fs-2" color='#FF6766' />
        </button>
      </div>
    )
  }

  return (
    <Fragment>
      {values.map((item, index) => {
        return (
          <CardLaudus key={index} values={item} to="/laudus/edit" status={false} isDraft={true} Children={action(item.id)} />
        )
      })}
      {ModalAlert()}
    </Fragment>
  );
};

FeedCard.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  user: PropTypes.object,
  className: PropTypes.string
};

export default FeedCard;
