import React from 'react';
import { MdModeEdit } from "react-icons/md";

const SaveBtn = () => {

  return (
    <div style={{
      maxWidth: '180px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      background: '#31AF89',
      padding: '5px 10px',
      borderRadius: 5
    }}>
      <span style={{fontSize: '16px', fontWeight: 'bold', cursor: 'pointer'}}>Salvar alterações</span>
    </div>
  )
}

export default SaveBtn;
