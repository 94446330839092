import React, { useEffect } from "react";
import CelusApi from "../../server/celusApi";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "reactstrap";

export function AreasTable() {
  const api = CelusApi();

  // DATA FETCHING
  const [allAreas, setAllAreas] = useState([]);
  const getAllAreas = async () => {
    const result = await api.listAllAreas();
    return result;
  };
  useEffect(() => {
    getAllAreas().then(r => setAllAreas(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // END DATA FETCHING

  //EDITING/CREATING
  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      image: undefined
    },
    shouldUnregister: true
  });
  const [show, setShow] = useState(false);
  const [editingAreaId, setEditingAreaId] = useState();
  const [isSubArea, setIsSubArea] = useState(false);
  const [subAreaParentId, setSubAreaParentId] = useState();
  const openCreateAreaModal = () => {
    setEditingAreaId(undefined);
    setIsSubArea(false);
    setShow(true);
  };
  const openEditAreaModal = areaId => {
    setEditingAreaId(areaId);
    setIsSubArea(false);
    setShow(true);
  };
  const openCreateSubAreaModal = parentId => {
    setEditingAreaId(undefined);
    setIsSubArea(true);
    setSubAreaParentId(parentId);
    setShow(true);
  };
  const openEditSubAreaModal = (subAreaId, parentId) => {
    setIsSubArea(true);
    setSubAreaParentId(parentId);
    setEditingAreaId(subAreaId);
    setShow(true);
  };
  const onSubmit = async data => {
    if (isSubArea) {
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.image) formData.append("image", data.image[0]);

      if (editingAreaId) {
        await api.updateSubArea(formData, editingAreaId);
      } else {
        await api.createSubArea(formData, subAreaParentId);
      }
    } else {
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.image) formData.append("image", data.image[0]);
      if (editingAreaId) {
        await api.updateArea(editingAreaId, formData);
      } else {
        await api.createArea(formData);
      }
    }

    getAllAreas().then(r => setAllAreas(r));
    setShow(false);
  };
  useEffect(() => {
    if (!show) return;

    if (!editingAreaId) {
      reset();
    }

    let editingArea;

    if (!isSubArea) {
      editingArea = allAreas.find(a => a.id === editingAreaId);
    } else {
      editingArea = allAreas
        .find(a => a.id === subAreaParentId)
        .subareas.find(a => a.id === editingAreaId);
    }

    console.log({
      editingArea
    });

    reset({
      name: editingArea?.name
    });
  }, [editingAreaId, show]);
  //END EDITING/CREATING

  //DELETING
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingAreaId, setDeletingAreaId] = useState();
  const [blockDelete, setBlockDelete] = useState();
  const [loadingCanDelete, setLoadingCanDelete] = useState(false);
  const [isSubAreaDelete, setIsSubAreaDelete] = useState(false);
  const openDeleteAreaModeal = areaId => {
    setShowDeleteModal(true);
    setIsSubAreaDelete(false);
    setDeletingAreaId(areaId);
  };
  const openDeleteSubAreaModeal = subAreaId => {
    setShowDeleteModal(true);
    setIsSubAreaDelete(true);
    setDeletingAreaId(subAreaId);
  };
  const onDelete = async () => {
    if (isSubAreaDelete) {
      await api.deleteSubArea(deletingAreaId);
    } else {
      await api.deleteArea(deletingAreaId);
    }
    getAllAreas().then(r => setAllAreas(r));
    setShowDeleteModal(false);
    setDeletingAreaId(undefined);
    setIsSubAreaDelete(false);
    setBlockDelete(undefined);
    setLoadingCanDelete(false);
  };
  useEffect(() => {
    const getCanDelete = async id => {
      setLoadingCanDelete(true);
      let response;

      if (isSubAreaDelete) {
        response = await api.getQuantitySubareas(id);
      } else {
        response = await api.getQuantityAreas(id);
      }

      const quantity = {
        laudus: response.result.laudus,
        resumus: response.result.resumus,
        anatomus: response.result.anatomus,
        riskscore: response.result.riskscore,
        infograficos: response.result.infograficos,
        medultra: response.result.medultra
      };

      let canDelete = true;
      for (const key in quantity) {
        if (quantity[key] > 0) {
          canDelete = false;
        }
      }

      console.log({ canDelete });

      if (!canDelete) {
        setBlockDelete(quantity);
      } else setBlockDelete(undefined);

      setLoadingCanDelete(false);
    };

    if (showDeleteModal) {
      getCanDelete(deletingAreaId);
    }
  }, [deletingAreaId]);
  //END DELETING

  return (
    <div>
      <Button
        style={{ float: "right", marginBottom: 5 }}
        variant={"outline-success"}
        onClick={openCreateAreaModal}
      >
        Criar área
      </Button>
      <BootstrapTable
        keyField="id"
        data={allAreas}
        columns={[
          {
            dataField: "id",
            text: "ID"
          },
          {
            dataField: "icon",
            text: "Ícone",
            formatter: (cell, row) => {
              return (
                <img
                  style={{
                    width: 30,
                    height: 30
                  }}
                  src={cell}
                />
              );
            }
          },
          {
            dataField: "name",
            text: "Nome"
          },
          {
            formatter: (cell, row) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 5
                  }}
                >
                  <Button
                    variant={"info"}
                    onClick={() => {
                      openEditAreaModal(row.id);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    variant={"danger"}
                    onClick={() => {
                      openDeleteAreaModeal(row.id);
                    }}
                  >
                    Deletar
                  </Button>
                </div>
              );
            }
          }
        ]}
        expandRow={{
          renderer: row => (
            <>
              <Button
                variant={"outline-success"}
                onClick={() => openCreateSubAreaModal(row.id)}
                style={{ float: "right", marginBottom: 5 }}
              >
                Criar subárea
              </Button>
              <BootstrapTable
                keyField="id"
                data={row.subareas}
                columns={[
                  {
                    dataField: "id",
                    text: "ID"
                  },
                  {
                    dataField: "icon",
                    text: "Ícone",
                    formatter: (cell, row) => {
                      return (
                        <img
                          style={{
                            width: 30,
                            height: 30
                          }}
                          src={cell}
                        />
                      );
                    }
                  },
                  {
                    dataField: "name",
                    text: "Nome"
                  },
                  {
                    formatter: (cell, subrow) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: 5
                          }}
                        >
                          <Button
                            variant={"info"}
                            onClick={() => {
                              openEditSubAreaModal(subrow.id, row.id);
                            }}
                          >
                            Editar
                          </Button>
                          <Button
                            variant={"danger"}
                            onClick={() => {
                              openDeleteSubAreaModeal(subrow.id);
                            }}
                          >
                            Deletar
                          </Button>
                        </div>
                      );
                    }
                  }
                ]}
              />
            </>
          )
        }}
      />
      <Modal show={show} onHide={() => setShow(false)}>
        <div className={"p-4"}>
          <h3>
            {editingAreaId != null ? "Editar" : "Criar"}{" "}
            {isSubArea ? "Subárea" : "Área"}
          </h3>
          {isSubArea && (
            <div>
              <h4>
                Área pai: {allAreas.find(a => a.id === subAreaParentId).name}
              </h4>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"pt-4 pb-2"}>
              <label>Nome</label>
              <input
                name="name"
                ref={register}
                style={{
                  display: "block",
                  width: "100%",
                  padding: 5,
                  borderRadius: 5,
                  border: "1px solid #ccc"
                }}
              />
            </div>
            <div className={"pb-4"}>
              <label>Ícone</label>
              <input
                name="image"
                ref={register}
                type="file"
                style={{
                  display: "block",
                  width: "100%",
                  padding: 5,
                  borderRadius: 5,
                  border: "1px solid #ccc"
                }}
              />
            </div>

            <Button type="submit">Salvar</Button>
          </form>
        </div>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        {loadingCanDelete ? (
          <div>Carregando...</div>
        ) : blockDelete == null ? (
          <div className={"p-4"}>
            <h4>Deletar área</h4>
            <div className={"pt-3 pb-3"}>
              <h5>
                Tem certeza que deseja deletar a{" "}
                {isSubAreaDelete ? "subárea" : "área"}?
              </h5>
            </div>
            <Button onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
            <Button variant={"danger"} onClick={onDelete}>
              Deletar
            </Button>
          </div>
        ) : (
          <div className={"p-5"}>
            <span>Não é possivel deletar essa área.</span>
            <div>
              <span>Essa área está sendo usada em:</span>
              <ul>
                {blockDelete?.laudus > 0 && (
                  <li>Laudus: {blockDelete?.laudus} vezes</li>
                )}
                {blockDelete?.resumus > 0 && (
                  <li>Resumus: {blockDelete?.resumus} vezes</li>
                )}
                {blockDelete?.anatomus > 0 && (
                  <li>Anatomus: {blockDelete?.anatomus} vezes</li>
                )}
                {blockDelete?.riskscore > 0 && (
                  <li>Riskscore: {blockDelete?.riskscore} vezes</li>
                )}
                {blockDelete?.infograficos > 0 && (
                  <li>Infográficos: {blockDelete?.infograficos} vezes</li>
                )}
                {blockDelete?.medultra > 0 && (
                  <li>Medultra: {blockDelete?.medultra} vezes</li>
                )}
              </ul>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
