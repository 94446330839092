import React, { Fragment } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export const LoadingProtocolus = () => {
    return (
        <Fragment>
            <Skeleton height={15} width={'45%'} style={{ marginTop: 15 }} />
            <Skeleton height={15} width={'32%'} />

            <Skeleton height={20} width={100} style={{ marginTop: 30 }} />
            <Skeleton height={40} width={'70%'} />

            <Skeleton height={20} width={100} style={{ marginTop: 30 }} />
            <Skeleton height={40} width={'70%'} />

            <Skeleton height={20} width={100} style={{ marginTop: 30 }} />
            <Skeleton height={300} width={'70%'} />

            <Skeleton height={20} width={100} style={{ marginTop: 30 }} />
            <Skeleton height={40} width={'70%'} />

            <Skeleton height={20} width={100} style={{ marginTop: 30 }} />
            <Skeleton height={300} width={'70%'} />
        </Fragment>
    )
}
