import styled from "styled-components";

export const TestContenthCard = styled.div`
    width: 100%;
    height: 100px;

    padding: 0px 0px 0px 20px;
    background-color: transparent;

    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px #ccc solid;

    transition: 0.5s;
    :hover{
        cursor: pointer;
        background-color: #F4F4F4;
    }
`;

export const ThumbNail = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #31AF89;
`;

export const DetailsArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
`;

export const Author = styled.span`
    font-weight: 600;
    font-size: 14px; 
`;

export const Area = styled.div`
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    display: flex;
`;

export const CardContent = styled.div`
    width: 97%;
    height: 80px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    transition: all ease 0.2s;
    :hover{
        opacity: 0.8;
        transform: scale(1.02);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`;

export const Title = styled.h5`
    color: #31AF89;
`;

export const TypeTitle = styled.span`

`;

export const PositionNumber = styled.span`
    position: absolute;
    right: 10px;
    top: 10px;
    color: #31AF89;
    font-weight: bold;
    font-size: ${props => props.position < 2 ? '24px' : props.position < 3 ? '22px' : props.position < 4 ? '20px' : '16px'};
`;