import React, { useState, Fragment, useEffect, useMemo } from "react";
import { Editor } from "../Editor";
import {
  Card,
  CardBody,
  Media,
  Input,
  Form,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import Cookies from "js-cookie";
import EditBtn from "./EditBtn";
import SaveBtn from "./SaveBtn";
import "./css/editResumus.css";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import CelusApi from "../../server/celusApi";
import FalconCardHeader from "../common/FalconCardHeader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import "react-tagsinput/react-tagsinput.css";
import { ImgNull, Up } from "../../assets/img/icons/celus/index";
import { useParams } from "react-router-dom";
import { EmptyContent } from "../EmptyContent";
import { notNotification } from "../../assets/img/illustrations";
import * as S from "./css/components";
import { Reference } from "../Reference";
import { ChangeIsFreeContent } from "../ChangeIsFreeContent/ChangeIsFreeContent";

const ReviewResumus = () => {
  const api = CelusApi();

  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
  const [specialtie, setSpecialtie] = useState("");

  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [docId, setDocId] = useState("");
  const [tags, setTags] = useState([]);
  const [disabledTitle, setDisabledTitle] = useState(true);
  const [disabledSubTitle, setDisabledSubTitle] = useState(true);
  const [disabledDescription, setDisabledDescription] = useState(true);
  const [disabledImage, setDisabledImage] = useState(true);
  const [disabledCategory, setDisabledCategory] = useState(true);
  const [disabledSpecialty, setDisabledSpecialty] = useState(true);
  const [disabledReferences, setDisabledReferences] = useState(true);
  const [disabledTags, setDisabledTags] = useState(true);
  const [loading, setLoading] = useState(true);

  const [collapseOne, collapseOneOpen] = React.useState(false);
  const [subtitle, setSubtitle] = useState("");
  const [clientId, setClientId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [subAreaId, setSubAreaId] = useState("");
  const [references, setReferences] = useState([]);
  const { resumusDoc } = useParams();
  const [thumbnail, setThumbnail] = useState("");
  const [images, setImages] = useState("");
  const [isFreeContent, setIsFreeContent] = React.useState(false);

  const showSaveBtn = () => {
    if (
      !disabledTitle ||
      !disabledSubTitle ||
      !disabledDescription ||
      !disabledImage ||
      !disabledCategory ||
      !disabledSpecialty ||
      !disabledReferences ||
      !disabledTags
    ) {
      setIsSaveBtnVisible(true);
    }
  };

  useEffect(() => {
    showSaveBtn();
  }, [
    disabledTitle,
    disabledSubTitle,
    disabledDescription,
    disabledImage,
    disabledCategory,
    disabledSpecialty,
    disabledReferences,
    disabledTags,
  ]);

  useEffect(() => {
    GetSpecialties();
  }, []);

  useEffect(() => {
    GetSubAreas();
  }, [specialtie]);

  useEffect(() => {
    getResumusByDoc();
  }, []);

  const preview = useMemo(() => {
    return images ? URL.createObjectURL(images) : null;
  }, [images]);

  const GetSpecialties = async () => {
    try {
      const res = await api.listAreas("resumus");
      setSpecialties(res);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSubAreas = async () => {
    try {
      const res = await api.getListSubAreas({
        specialtie: specialtie,
        type: "resumus",
      });
      setSubAreas(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditField = (field) => {
    if (field === "title") setDisabledTitle(false);
    if (field === "subtitle") setDisabledSubTitle(false);
    if (field === "tags") setDisabledTags(false);
    if (field === "image") setDisabledImage(false);
    if (field === "category") setDisabledCategory(false);
    if (field === "specialty") setDisabledSpecialty(false);
    if (field === "references") setDisabledReferences(false);
    if (field === "description") setDisabledDescription(false);
  };

  const handleEditResumus = async () => {
    const values = new FormData();
    values.append("title", title);
    values.append("subtitle", subtitle);
    values.append("content", content);
    values.append("areaId", Number(specialtie));
    values.append("subAreaId", Number(subAreaId));
    values.append("references", JSON.stringify(references));
    values.append("keywords", JSON.stringify(tags));
    values.append("docId", docId);
    values.append("doc", resumusDoc);
    values.append("file", images);
    values.append("uid", clientId);
    values.append("editorId", parseInt(Cookies.get("adminId")));

    try {
      await api.editResumusByAdmin(values);
      window.location.href = "/list/table/resumus";
    } catch (error) {
      console.log(error);
    }
  };

  const getResumusByDoc = async () => {
    try {
      setLoading(true);
      await api.getResumusByDoc(resumusDoc).then((res) => {
        let aux = res.result;
        console.log({
          aux,
        });
        setTitle(aux.title);
        setSubtitle(aux.subtitle);
        setSpecialtie(Number(aux.areaId));
        setSubAreaId(Number(aux.subAreaId));
        setContent(aux.content);
        setTags(aux.keywords);
        setReferences(aux.references ? aux.references : []);
        setDocId(aux.id);
        setThumbnail(aux.thumbnail);
        setClientId(aux.author.id);
        setLoading(false);
        setIsFreeContent(aux.isFreeContent);
      });
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  console.log({
    subAreas,
  });

  const ModalResumusEdit = () => {
    return (
      <Fragment>
        <Modal
          isOpen={collapseOne}
          toggle={() => collapseOneOpen(!collapseOne)}
        >
          <ModalHeader>Editar ResumUS?</ModalHeader>
          <ModalBody>
            Você tem certeza de que gostaria de editar esse ResumUS? Ao clicar
            em sim, as alterações serão salvas permanentemente.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => collapseOneOpen(!collapseOne)}
              color="danger"
            >
              Cancelar
            </Button>
            <Button color="success" onClick={handleEditResumus}>
              Salvar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={
            <Fragment>
              <Media body className="ml-2">
                <h5 className="mb-0 fs-0" style={{ fontSize: 19 }}>
                  Revisar ResumUS
                </h5>
              </Media>
            </Fragment>
          }
          titleTag={Media}
          titleClass="align-items-center"
        />

        {docId || !loading ? (
          <div style={{ padding: 30 }}>
            <CardBody className="p-0">
              <Form>
                <FormGroup style={{ marginBottom: "30px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Título <strong style={{ color: "#FF6766" }}>*</strong>{" "}
                    </Label>
                    <div onClick={() => handleEditField("title")}>
                      <EditBtn />
                    </div>
                  </div>

                  <Input
                    disabled={!!disabledTitle}
                    className={!!disabledTitle && "disabled"}
                    type="text"
                    name="name"
                    id="exampleName"
                    placeholder="Título"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                  <S.LabelForm
                    style={{
                      fontSize: 13,
                      marginTop: 15,
                      position: "relative",
                    }}
                  >
                    <strong
                      style={{
                        position: "absolute",
                        right: 0,
                        color:
                          title.length > 70 && title.length !== 100
                            ? "#ff8c00"
                            : title.length === 100
                            ? "#FF6766"
                            : "#9c9a9a",
                      }}
                    >
                      Número de caracteres: [{`${title.length}/100`}]
                    </strong>
                  </S.LabelForm>
                </FormGroup>

                <FormGroup style={{ marginBottom: "30px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Subtítulo
                    </Label>
                    <div onClick={() => handleEditField("subtitle")}>
                      <EditBtn />
                    </div>
                  </div>

                  <Input
                    disabled={!!disabledSubTitle}
                    className={!!disabledSubTitle && "disabled"}
                    type="text"
                    name="name"
                    id="exampleName"
                    placeholder="Subtítulo"
                    value={subtitle}
                    onChange={(e) => {
                      setSubtitle(e.target.value);
                    }}
                  />
                  <S.LabelForm
                    style={{
                      fontSize: 13,
                      marginTop: 15,
                      position: "relative",
                    }}
                  >
                    <strong
                      style={{
                        position: "absolute",
                        right: 0,
                        color:
                          subtitle.length > 70 && subtitle.length !== 100
                            ? "#ff8c00"
                            : subtitle.length === 100
                            ? "#FF6766"
                            : "#9c9a9a",
                      }}
                    >
                      Número de caracteres: [{`${subtitle.length}/100`}]
                    </strong>
                  </S.LabelForm>
                </FormGroup>

                <FormGroup>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Descrição <strong style={{ color: "#FF6766" }}>*</strong>{" "}
                    </Label>
                    <div onClick={() => handleEditField("description")}>
                      <EditBtn />
                    </div>
                  </div>

                  <div style={{ border: "1px solid #D8E2EF", borderRadius: 8 }}>
                    <Editor
                      disabled={!!disabledDescription}
                      className={!!disabledDescription && "disabled"}
                      values={content}
                      setValues={setContent}
                    />
                  </div>
                </FormGroup>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Label
                    for="exampleName"
                    style={{ fontSize: 16, color: "#3C3C3C" }}
                  >
                    Foto Destaque{" "}
                    <strong style={{ color: "#FF6766" }}>*</strong>{" "}
                  </Label>
                  <div onClick={() => handleEditField("image")}>
                    <EditBtn />
                  </div>
                </div>

                <S.PreviwArea>
                  <S.SelectImage
                    style={{
                      cursor: !!disabledImage ? "not-allowed" : "pointer",
                    }}
                  >
                    <S.Up src={Up} />
                    {preview
                      ? null
                      : !thumbnail
                      ? "Escolha uma imagem"
                      : "Atualizar imagem"}
                    <Input
                      disabled={!!disabledImage}
                      type="file"
                      name="filefield"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => setImages(e.target.files[0])}
                    />
                  </S.SelectImage>

                  <S.PreviwAreaImage>
                    <S.ImagemPreviw
                      src={preview ? preview : thumbnail ? thumbnail : ImgNull}
                    />
                  </S.PreviwAreaImage>
                </S.PreviwArea>

                <S.LabelForm style={{ fontSize: 14 }}>
                  Adicione uma imagem de destaque para o seu artigo.
                </S.LabelForm>

                <FormGroup style={{ marginBottom: "30px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Tags
                    </Label>
                    <div onClick={() => handleEditField("tags")}>
                      <EditBtn />
                    </div>
                  </div>
                  <ReactTagInput
                    readOnly={!!disabledTags}
                    className={!!disabledTags && "disabled"}
                    editable={true}
                    tags={tags}
                    value={tags}
                    onChange={(newTags) => setTags(newTags)}
                    placeholder="Tags"
                  />
                </FormGroup>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormGroup style={{ marginBottom: "30px", width: "49%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        for="exampleName"
                        style={{ fontSize: 16, color: "#3C3C3C" }}
                      >
                        Especialidade
                      </Label>
                      <div onClick={() => handleEditField("specialty")}>
                        <EditBtn />
                      </div>
                    </div>
                    <Input
                      disabled={!!disabledSpecialty}
                      className={!!disabledSpecialty && "disabled"}
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={(e) => {
                        setSpecialtie(e.target.value);
                      }}
                    >
                      <option> </option>
                      {specialties.map((item) => {
                        return (
                          <option
                            value={item.id}
                            selected={specialtie === item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: "30px", width: "49%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label
                        for="exampleName"
                        style={{ fontSize: 16, color: "#3C3C3C" }}
                      >
                        Categorias
                      </Label>
                      <div onClick={() => handleEditField("category")}>
                        <EditBtn />
                      </div>
                    </div>
                    <Input
                      disabled={!!disabledCategory}
                      className={!!disabledCategory && "disabled"}
                      type="select"
                      name="select"
                      id="exampleSelect"
                      onChange={(e) => {
                        setSubAreaId(e.target.value);
                      }}
                    >
                      <option> </option>
                      {subAreas.map((item) => {
                        return (
                          <option
                            value={item.id}
                            selected={subAreaId === item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </div>
                <FormGroup style={{ marginBottom: "30px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label
                      for="exampleName"
                      style={{ fontSize: 16, color: "#3C3C3C" }}
                    >
                      Referências{" "}
                    </Label>
                    <div onClick={() => handleEditField("references")}>
                      <EditBtn />
                    </div>
                  </div>

                  <Reference
                    disabled={disabledReferences}
                    arrayRef={references}
                    setArreyRef={setReferences}
                  />

                  {/* <Input disabled={!!disabledReferences} className={!!disabledReferences && 'disabled'} d type="textarea" name="name" id="exampleName" onChange={(e) => { setReferences(e.target.value) }} value={references} /> */}
                </FormGroup>
                {isSaveBtnVisible && (
                  <div onClick={() => collapseOneOpen(!collapseOne)}>
                    <SaveBtn />
                  </div>
                )}
                {/* <Row noGutters className="justify-content-between ">
              <Col className="col">
                <Button
                  onClick={() => {
                    setIsDraft(true);
                    PostLaudus();
                  }}
                  className="d-md-inline-block"
                  style={{ marginRight: 10, background: '#EBEBEB', border: '1px solid #EBEBEB' }}>Salvar</Button>
                <Button
                  onClick={() => {
                    PostLaudus();
                  }}
                  className="d-md-inline-block" style={{ background: '#50D19A', border: '1px solid #31AF89' }}>Solicitar Publicação</Button>
              </Col>
            </Row> */}
              </Form>
              <ModalResumusEdit />
            </CardBody>
          </div>
        ) : !loading ? (
          <EmptyContent
            src={notNotification}
            msg="Esse conteúdo não está mais disponível!"
            goTo="/list/table/resumus"
            title="Revisar ResumUS"
          />
        ) : null}
      </Card>
      <ChangeIsFreeContent
        id={resumusDoc}
        type={"resumus"}
        originalValue={isFreeContent}
      />
    </>
  );
};

export default ReviewResumus;
