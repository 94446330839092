import React, { useState } from 'react';
import { Fragment } from 'react';

import './styles.scss'

import { CardLaudus } from './CardLaudus';
import { CardResumus } from './CardResumus';
import { FeedProtoculusSave } from '../FeedProtoculusSave';

export const SaveUS = () => {

    const [typeSaves, setTypeSaves] = useState('LaudUS');

    const ViewResumUS = () => {
        return (
            <CardResumus />
        )
    }

    const ViewRLaudUS = () => {
        return (
            <CardLaudus />
        )
    }

    const ViewProtoculUS = () => {
        return (
            <FeedProtoculusSave />
        )
    }

    return (
        <Fragment>
            <h2>Salvos</h2>

            <div className={"tabs-opc-focus"}>

                <button
                    style={typeSaves === 'LaudUS' ? { borderBottom: '2px solid #31AF89', color: '#31AF89' } : { color: '#484848' }}
                    onClick={() => {
                        setTypeSaves('LaudUS');
                    }}
                >
                    LaudUS
                </button>

                <button
                    style={typeSaves === 'ResumUS' ? { borderBottom: '2px solid #31AF89', color: '#31AF89', marginLeft: 20 } : { color: '#484848', marginLeft: 20 }}
                    onClick={() => {
                        setTypeSaves('ResumUS');
                    }}
                >
                    ResumUS
                </button>

                <button
                    style={typeSaves === 'ProtocolUS' ? { borderBottom: '2px solid #31AF89', color: '#31AF89', marginLeft: 20 } : { color: '#484848', marginLeft: 20 }}
                    onClick={() => {
                        setTypeSaves('ProtocolUS');
                    }}
                >
                    ProtocolUS
                </button>
            </div>
            <hr />
            <div>

                {
                    typeSaves === 'LaudUS' ?

                        ViewRLaudUS()
                        : typeSaves === "ResumUS" ?
                            ViewResumUS()
                            : ViewProtoculUS()
                }

            </div>
        </Fragment>
    )
}
