import { CardFooter, CardHeader, CardTitle, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

export const Required = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #ff6766;
    margin-right: 5px;
`;

export const RequiredText = styled.span`
    font-size: 12px;
    color: #636363;
`;

export const CardHeaderStyled = styled(CardHeader)`
    background-color: #F3F3F3;
    height: 60px;
`;

export const CardFooterStyled = styled(CardFooter)`
    background-color: #F3F3F3;
    height: 45px;
`;

export const CardTitleStyled = styled(CardTitle)`
    font-size: 20px;
    font-weight: bold;
`;

export const CardInfo = styled.p`
    font-size: 12px;
    color: #636363;
    max-width: 500px;
`;

export const Label = styled.p`
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #202020;
`;

export const AreaInput = styled.div`

    width: ${({ width }) => width ? width : '690px'};
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 20px;
`;

export const AreaInputImage = styled.div`

    width: ${({ width }) => width ? width : '690px'};
    height: 270px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    border: 1px dashed #739AD1;
    background-color: #EDF2F9;

    border-radius: 5px;
    padding: 10px;
`;

export const DeleteImgBtn = styled.button`
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
    border-radius: 24px;
    background-color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
   
    outline: none;
    border: none;

    :focus{
        border: none;
        outline: none;
    }
`;


export const AddImgBtn = styled.label`
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 24px;
    background-color: #202020;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;

    :focus{
        border: none;
        outline: none;
    }
`
export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 690px;
`;

export const RowArray = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 470px;

    overflow-x: scroll;
    overflow-y: hidden;
   
`;

export const RowImage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 690px;
`;

export const RowImageContainer = styled.div`

    margin-top: 25px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 690px;
`;

export const InputImageSpotlight = styled.label`

    width: 130px;
    height: 130px;
    border: 1px dashed #739AD1;
    background-image: ${({ bgImage }) => bgImage ? `u rl(${bgImage})` : 'none'} ;
    background-size: cover;
    border-radius: 5px;

    margin-top: 10px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover{
        cursor: pointer;
    }
`;

export const InputImage = styled.div`

    min-width: 130px;
    min-height: 130px;
    background-image: ${({ bgImage }) => bgImage ? `u rl(${bgImage})` : 'none'} ;
    background-size: cover;
    border-radius: 5px;
    margin-right: 25px;
    border: 1px dashed #739AD1;
    position: relative;
    :hover{
        cursor: pointer;
    }
`;

export const SpanLabelSpotlight = styled.span`
    color: #9F9F9F;
    font-size: 11px;
    text-align: center;
    margin: 5px;

    z-index: 1;;
`;


export const ImageBtn = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    outline: none;
    border: none;
    background-color: #FFF;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    opacity: 1;

    position: relative;

    :hover{
        cursor: pointer;
    }
`;

export const FooterModal = styled(ModalFooter)`
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
`;

export const HeaderModal = styled(ModalHeader)`
    display: flex;
    background: ${({bg}) => bg ? bg : "#31AF89"};
    flex-direction: row;
    justify-content: center;
`;

export const HeaderTitle = styled.span`
    color: ${({color}) => color ? color : "#FFF"};
    font-size: 20px;
    font-weight: bold;
`;

export const InfoModal = styled.p`
    width: 100%;
    text-align: center !important;
`;

export const Bottom = styled.button`
   
    min-width: 180px;
    height: 40px;

    border-radius: 5px;
    border: none;
    transition: 0.3s;
    box-shadow: 3px 3px 6px #00000040;
    color:${({color}) => color } ;
    background-color: ${({bg}) => bg ? bg : '#EBEBEB'};
    :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
    }

    :hover{
        opacity: 0.8;
    }
`;

export const PostBtn = styled.button`
    width: 200px;
    height: 45px;
    border-radius: 5px;
    border: none;
    outline: none;
    background: transparent linear-gradient(113deg, #31AF89 0%, #50D19A 100%) 0% 0% no-repeat padding-box;
    transition: 0.5s;

    color: #FFF;

    :focus{
        border: none;
    outline: none;
    }

    :hover{
        opacity: 0.8;
    }

`;

export const Headerlanguage = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    background-color: #FFF;
`;

export const HeaderCurrentLanguage = styled.button`
    flex: 1;

    border: none;

    transition: 0.3s;

    :focus{
        border: none;
        outline: none;
    }

    :hover{
        opacity: 0.9;
    }

    justify-content: center;
    align-items: center;
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
    width: 33.3333333333%;
    height: 45px;
    background-color: ${({current}) => current ? '#C9F1E1' : '#E5F8F0'};
`;

export const Icon = styled.img`
    width: 30px;
    height: 30px;

    object-fit: cover;

    border-radius: 30px;

    margin-right: 25px;
`;

export const ItemError = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
`;
