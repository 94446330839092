import React, { Fragment, useEffect, useState } from 'react';
import FeedCard from './FeedCard';
import CelusApi from '../../server/celusApi';

import Cookies from 'js-cookie';
import { emptyCardsDraft } from '../../assets/img/illustrations';
import { EmptyContent } from '../EmptyContent';
import ReactPaginate from 'react-paginate';
import { LoadingCard } from '../LoadingCard';

const FeedContent = () => {

  const api = CelusApi();
  const [values, setValues] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const perPage = 10;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLaudusList = async () => {
      setLoading(true);
      let res = await api.getLaudusDraft({ id: Number(Cookies.get('clientId')), page: currentPage, limit: 10 });
      setValues(res.result.laudus.rows);
      setTotalPage(Math.ceil(res.result.laudus.count / perPage))
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
    getLaudusList()
  }, [currentPage]);



  const handlePageChange = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <Fragment>
      {
        loading ? (
          <Fragment>
            <LoadingCard />
          </Fragment>
        ) : (values.length <= 0) ?
          <EmptyContent
            src={emptyCardsDraft}
            msg="Você ainda não tem nenhum rascunho, crie um conteúdo para publicação!"
            goTo="/laudus/new"
            title="Clique aqui"
          />
          : (
            <Fragment>

              <FeedCard values={values} loading={loading} />

              <div className="pag-table">
                <ReactPaginate
                  nextLabel=">"
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={totalPage}
                  previousLabel="<"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </Fragment>

          )
      }



    </Fragment>
  );
};

export default FeedContent;
