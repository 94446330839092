
import React, { useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

useGLTF.preload('/4_2.glb');
export default function Scanner(props) {
  const { scene, animations } = useGLTF('/4_2.glb');
  const { actions } = useAnimations(animations, scene)
  useEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
  }, [actions, scene])
  return <primitive object={scene} {...props} />
}
