import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 0px 5px #00000029;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

export const HeaderArea = styled.div`
  width: 90%;
  background-color: #EDF2F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Line = styled.div`
  margin-bottom: 10px;
`;

export const HeaderTitle = styled.span`
  font-size: 19px;
  font-weight: 600;
  color: #3C3C3C;
`;

export const Body = styled.div`
  padding: 20px 20px;
  background-color: #FAFAFA;
  flex-wrap: wrap;
`;

export const DefaultText = styled.span`
  font-size: 16px;
  color: #3C3C3C;
`;

export const DefaultBold = styled(DefaultText)`
  font-weight: 600;
`;

export const StatusSelect = styled.select`
  border: 1px solid #D8E2EF;
  padding: 5px 10px;
  color: #3C3C3C;
  font-size: 15px;
  border-radius: 5px;
  outline: none;
  width: 100%;
`;

export const Bottom = styled(Body)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #00000029;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

export const CancelBtn = styled.span`
  color: #31AF89;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const PublishBtn = styled.span`
  color: #FFFFFF;
  font-size: 17px;
  background: #9F9F9F80;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  cursor: pointer;

  :hover{
    background: #31AF89;
  }
`;