import React, { Fragment, useEffect, useState } from 'react';
import FeedCard from './FeedCard';
import CelusApi from '../../server/celusApi';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const FeedContent = () => {

  const api = CelusApi();
  const { id } = useParams();
  const [values, setValues] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const perPage = 10;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getArticlesList = async () => {
      setLoading(true);
      let values = { id: parseInt(id), page: currentPage, limit: 10 }
      let res = await api.getArticles(values);
      setValues(res.result);
      setTotalPage(Math.ceil(res.count / perPage));
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
    getArticlesList();
  }, [currentPage]);



  const handlePageChange = (event) => {
    setCurrentPage(event.selected + 1);
  };


  return (
    <Fragment>
      <FeedCard values={values} loading={loading} />
      <div className="pag-table">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          forcePage={currentPage === 0 ? 0 : currentPage - 1}
          pageCount={totalPage}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>

    </Fragment>
  );
};

export default FeedContent;
