import React, { Fragment } from 'react';
import { EmptyContent } from '../EmptyContent';
import { emptyCards } from '../../assets/img/illustrations'
import { CardResumus } from '../cardResumus/CardResumus';
import { LoadingCard } from '../LoadingCard';

const FeedCard = ({ values, loading }) => {
  return (
    <Fragment>

      {
        loading ? (
          <Fragment>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </Fragment>
        ) : (values.length <= 0) ?
          <EmptyContent
            src={emptyCards}
            msg="Essa Especialidade ainda não tem nenhum ResumUS, crie um conteúdo para publicação!"
            goTo="/resumus/new"
            title="Clique aqui"
          />
          :
          values.map((item, index) => {
            return (
              <CardResumus key={index} values={item} to={`/to-see/resumus`} status={false} />
            )
          })}
    </Fragment>
  );
};


export default FeedCard;
