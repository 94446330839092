import React, { createContext, useState, useContext } from 'react';

const CommentRefreshContext = createContext();

const CommentUpdateProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <CommentRefreshContext.Provider
      value={{
        refresh,
        setRefresh,
      }}
    >
      {children}
    </CommentRefreshContext.Provider>
  )
}

const useCommentRefresh = () => {
  const context = useContext(CommentRefreshContext);

  if (!context) {
    throw new Error('useCommentRefresh must be used within an CommentUpdateProvider');
  }

  return context;
};

export { useCommentRefresh, CommentUpdateProvider };


