import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { FaBookmark, FaShareAlt } from "react-icons/fa";
import { ImgNull } from "../../assets/img/icons/celus";
import ReactPaginate from "react-paginate";

import CelusApi from "../../server/celusApi";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { saveEmpytCards } from "../../assets/img/illustrations";
import { EmptyContent } from "../EmptyContent";
import { LoadingCard } from "../LoadingCard";

export const CardResumus = () => {
  const api = CelusApi();
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10;

  useEffect(() => {
    GetResumus();
  }, [currentPage]);

  const saveResumus = async (id) => {
    const json = {
      docId: id,
      uid: Cookies.get("clientId"),
    };

    try {
      await api.postFavoriteResumus(json);
      GetResumus();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const GetResumus = async () => {
    setLoading(true);
    let values = { uid: Cookies.get("clientId"), page: currentPage, limit: 10 };
    const result = await api.getResumusFavorite(values);
    setValues(result.result);
    setTotalPage(Math.ceil(result.count / perPage));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handlePageChange = (event) => {
    setCurrentPage(event.selected + 1);
  };

  String.prototype.stripHTML = async function () {
    return this.replace(/<.*?>/g, "");
  };
  return (
    <Fragment>
      {loading ? (
        <Fragment>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </Fragment>
      ) : values.length <= 0 ? (
        <EmptyContent
          src={saveEmpytCards}
          msg="Você ainda não tem nenhum ResumUS salvo!"
          goTo="/especialidades/resumus"
          title="Ver ResumUS"
        />
      ) : (
        values?.map((item, index) => {
          return (
            <>
              <div className="container-card" key={index}>
                <div className="content-card">
                  <div className="row-card">
                    <Link to={`/to-see/resumus/${item.idDoc}`}>
                      <div className="content-item">
                        <div className="content-title-card">
                          <h3 className="title-card">{item.doc.title}</h3>
                        </div>
                        {/* <p className="content-abistract">{item.doc.content.stripHTML()}</p> */}
                        <p className="content-abistract">{item.doc.subtitle}</p>
                      </div>
                    </Link>
                    <div className="footer-card">
                      <p>Autor: {item.doc.author.name} </p>
                      <div>
                        <FaBookmark
                          transform="shrink-4"
                          className="fs-2 footer-icon"
                          color="#31AF89"
                          style={{ marginRight: 17 }}
                          onClick={() => {
                            saveResumus(item.idDoc);
                          }}
                        />
                        <FaShareAlt
                          transform="shrink-4"
                          className="fs-2 footer-icon"
                          color="#344050"
                          onClick={() => {
                            navigator.share({
                              title: "Share",
                              text: "whatevs",
                              url: `/to-see/resumus/${item.id}`,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="thumb">
                    <img
                      async="on"
                      loading="lazy"
                      src={item.doc.thumbnail ? item.doc.thumbnail : ImgNull}
                    />
                  </div>
                </div>
              </div>
            </>
          );
        })
      )}

      <div className="pag-table-admin">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          forcePage={currentPage === 0 ? 0 : currentPage - 1}
          pageCount={totalPage}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </Fragment>
  );
};
