import React, { useState, useMemo, useEffect } from 'react';
import { Card, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import * as S from './css/anatomus';
import * as C from './css/components';
import CelusApi from '../../server/celusApi';
import { Canvas } from '@react-three/fiber';
import { ContactShadows, OrbitControls, PresentationControls } from '@react-three/drei';
import Scanner from '../scanner/index';
import Scanner_2 from '../scanner/index_2';
import Scanner_3 from '../scanner/index_3';
import Scanner_4 from '../scanner/index_4';
import Scanner_5 from '../scanner/index_5';
import './styles.scss';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import { FormAnatomus } from './FormAnatomus';
import { AreasImagensMark } from './AreaImagensMark';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';
import { CircleSlider } from 'react-circle-slider';
import LoadingPost from '../../assets/img/loading-anatomus.svg';

export default function PostCreateAnatomus() {
  const history = useHistory();
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const api = CelusApi();
  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [specialtie, setSpecialtie] = useState();
  const [scanner, setScanner] = useState('transversal');
  const [subArea, setSubArea] = useState();
  const [title, setTitle] = useState('');

  const [description, setDescription] = useState([]);
  const [descriptionL, setDescriptionL] = useState([]);
  const [descriptionO, setDescriptionO] = useState([]);
  const [descriptionC, setDescriptionC] = useState([]);

  const [content, setContent] = useState('');
  const [contentL, setContentL] = useState('');
  const [contentO, setContentO] = useState('');
  const [contentC, setContentC] = useState('');

  const [isTransversal, setIsTransversal] = useState(true);
  const [isLongitudinal, setIsLongitudinal] = useState(false);
  const [isObliqua, setIsObliqua] = useState(false);
  const [isCoronal, setIsCoronal] = useState(false);
  const [selectImage, setSelectImage] = useState(null);

  const [modal, setModal] = useState(false);

  const [arrayImagens, setArrayImages] = useState([]);
  const [arrayImagensL, setArrayImagesL] = useState([]);
  const [arrayImagensO, setArrayImagesO] = useState([]);
  const [arrayImagensC, setArrayImagesC] = useState([]);

  const [arrayImagensMark, setArrayImagesMark] = useState([]);
  const [arrayImagensMarkL, setArrayImagesMarkL] = useState([]);
  const [arrayImagensMarkO, setArrayImagesMarkO] = useState([]);
  const [arrayImagensMarkC, setArrayImagesMarkC] = useState([]);

  const [arrayImagensNoMark, setArrayImagesNoMark] = useState([]);
  const [arrayImagensNoMarkL, setArrayImagesNoMarkL] = useState([]);
  const [arrayImagensNoMarkO, setArrayImagesNoMarkO] = useState([]);
  const [arrayImagensNoMarkC, setArrayImagesNoMarkC] = useState([]);

  const [imageToMark, setImageToMark] = useState(null);
  const [imageToMarkL, setImageToMarkL] = useState(null);
  const [imageToMarkO, setImageToMarkO] = useState(null);
  const [imageToMarkC, setImageToMarkC] = useState(null);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);
  const [width, setWidth] = useState(80);
  const [height, setHeight] = useState(100);
  const [showArea, setShowArea] = useState(false);
  const [alt, setAlt] = useState();
  const [larg, setLarg] = useState();
  const [showPrevImg, setShowPrevImg] = useState(true);

  const [cont, setCont] = useState(1);
  const [contL, setContL] = useState(1);
  const [contO, setContO] = useState(1);
  const [contC, setContC] = useState(1);

  const [arrayLabels, setArrayLabels] = useState([]);
  const [arrayLabelsL, setArrayLabelsL] = useState([]);
  const [arrayLabelsO, setArrayLabelsO] = useState([]);
  const [arrayLabelsC, setArrayLabelsC] = useState([]);

  const [labelIndex, setLabelIndex] = useState();
  const [labelIndexL, setLabelIndexL] = useState();
  const [labelIndexO, setLabelIndexO] = useState();
  const [labelIndexC, setLabelIndexC] = useState();

  const [labelDescription, setLabelDescription] = useState();
  const [labelDescriptionL, setLabelDescriptionL] = useState();
  const [labelDescriptionO, setLabelDescriptionO] = useState();
  const [labelDescriptionC, setLabelDescriptionC] = useState();

  const [labelsImagens, setLabelsImagens] = useState([]);
  const [labelsImagensL, setLabelsImagensL] = useState([]);
  const [labelsImagensO, setLabelsImagensO] = useState([]);
  const [labelsImagensC, setLabelsImagensC] = useState([]);

  const [error, setError] = useState(false);

  const [errs, setErrs] = useState({
    section: 'Geral',
    msg: '',
    title: '',
    description: '',
    specialtie: '',
    subArea: '',
    arrayImagens: '',
    arrayImagensMark: '',
    arrayImagensNoMark: ''
  });

  useEffect(() => {
    GetSpecialties();
     }, []);


  useEffect(() => {
    GetSubAreas();
  }, [specialtie]);

  const GetSpecialties = async () => {
    try {
      const res = await api.listAreas();
      setSpecialties(res);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSubAreas = async () => {
    try {
      const res = await api.getListSubAreas({ specialtie: specialtie, type: 'anatomus' });
      setSubAreas(res);
    } catch (error) {
      console.log(error);
    }
  };

  const [modalPostLoading, setModalPostLoading] = useState(false);
  const LoadingPostModal = () => {
    return (
      <Fragment>
        <Modal isOpen={modalPostLoading}>
          <ModalHeader>Estamos quase lá!</ModalHeader>
          <ModalBody>
            <img src={LoadingPost} />
          </ModalBody>
          <ModalFooter>
            <Label>Estamos concluindo ...</Label>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };

  const ValidationModal = () => {
    return (
      <Modal isOpen={isValidationModalOpen} toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}>
        <ModalHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div>
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              Ops! Alguns campos estão incompletos [{`${errs.section}`}]
            </span>
            <span>{errs.section === 'Geral' ? <S.errorMessage>{errs.msg}</S.errorMessage> : null}</span>
          </div>
        </ModalHeader>
        <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px' }}>
            <C.ValidateModalRow>
              <span>{!errs.title ? <C.CheckIcon checked /> : <C.CheckIcon />} Título</span>
            </C.ValidateModalRow>

            <C.ValidateModalRow>
              <span>{!errs.specialtie ? <C.CheckIcon checked /> : <C.CheckIcon />} Especialidade</span>
            </C.ValidateModalRow>

            <C.ValidateModalRow>
              <span>{!errs.subArea ? <C.CheckIcon checked /> : <C.CheckIcon />} Categoria</span>
            </C.ValidateModalRow>

            {errs.section === 'Geral' ? null : (
              <>
                <C.ValidateModalRow>
                  <span>{!errs.arrayImagens ? <C.CheckIcon checked /> : <C.CheckIcon />} Varredura(s)</span>
                </C.ValidateModalRow>

                <C.ValidateModalRow>
                  <span>{!errs.description ? <C.CheckIcon checked /> : <C.CheckIcon />} Descrição</span>
                </C.ValidateModalRow>
                <C.ValidateModalRow>
                  <span>{!errs.arrayImagensNoMark ? <C.CheckIcon checked /> : <C.CheckIcon />}Ref. Marcação</span>
                </C.ValidateModalRow>
                <C.ValidateModalRow>
                  <span>{!errs.arrayImagensMark ? <C.CheckIcon checked /> : <C.CheckIcon />} Marcação</span>
                </C.ValidateModalRow>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button block className="modal-save" onClick={() => setIsValidationModalOpen(!isValidationModalOpen)}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const selectVarredura = varredura => {
    if (varredura === 1) {
      setIsTransversal(true);
      setIsLongitudinal(false);
      setIsObliqua(false);
      setIsCoronal(false);
    } else if (varredura === 2) {
      setIsTransversal(false);
      setIsLongitudinal(true);
      setIsObliqua(false);
      setIsCoronal(false);
    } else if (varredura === 3) {
      setIsTransversal(false);
      setIsLongitudinal(false);
      setIsCoronal(false);
      setIsObliqua(true);
    } else if (varredura === 4) {
      setIsTransversal(false);
      setIsLongitudinal(false);
      setIsCoronal(true);
      setIsObliqua(false);
    }
  };

  useEffect(() => {
    getAltLarg();
  }, [selectImage]);

  const preview = useMemo(() => {
    return selectImage ? URL.createObjectURL(selectImage) : null;
  }, [selectImage]);

  const getAltLarg = () => {
    var img = document.getElementById('hotspot_image');
    setAlt(img?.height);
    setLarg(img?.width);
  };

  function clickHotspotImage(event) {
    var img = document.getElementById('hotspot_image');
    var canvas = document.createElement('canvas');
    setX(event.nativeEvent.offsetX);
    setY(event.nativeEvent.offsetY);
    setAlt(img?.height + 1);
    setLarg(img?.width + 1);
    canvas.width = img?.width;
    canvas.height = img?.height;
    canvas.getContext('2d').drawImage(img, 0, 0, img?.width, img?.height);
    setShowPrevImg(false);
  }

  const ModalEditFile = () => {
    return (
      <S.IModal isOpen={modal} toggle={() => setModal(!modal)}>
        <S.IModalHeader>
          <S.Title>AnatomUS Editor</S.Title>
        </S.IModalHeader>
        <S.IModalBody>
          {
            showPrevImg ? (
              <S.Label style={{ textAlign: 'center', width: '87%', padding: 20, border: 'dashed 1px #DDD' }}>Após fazer o upload, clique na imagem para acionar a movimentação do transdutor.</S.Label>
            ) : null
          }

          <S.FramesArea>
            <S.ContentArea>
              <div
                id="btnScreenshot"
                style={{
                  width: larg + 1,
                  height: alt + 1,
                  border: 'solid 1px #0005',
                  backgroundImage: `url(${preview})`,
                  backgroundSize: 'cover',
                  maxWidth: 890,
                  maxHeight: 510,
                  display: !showPrevImg ? 'flex' : 'none'
                }}
              >
                <div
                  style={{
                    backgroundColor: showArea ? 'rgba(44, 44, 44, 0.38)' : null,
                    width: Number(width),
                    height: Number(height),
                    transform: `translate(${x + 'px'}, ${y + 'px'}) rotateZ(${rotateZ + 'deg'}) rotateY(${rotateY +
                      'deg'}) rotateX(${rotateX + 'deg'})`
                  }}
                >
                  <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0, 5], fov: 50 }}>
                    <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                    <OrbitControls zoomSpeed={0.5} />
                    <ambientLight intensity={0.5} />
                    <spotLight
                      position={[10, 10, 10]}
                      angle={0.15}
                      penumbra={1}
                      shadow-mapSize={[512, 512]}
                      castShadow
                    />
                    <PresentationControls>
                      {scanner === 'endocavitario' ? (
                        <Scanner />
                      ) : scanner === 'linear' ? (
                        <Scanner_2 />
                      ) : scanner === 'convexo' ? (
                        <Scanner_3 />
                      ) : scanner === 'microconvexo' ? (
                        <Scanner_5 />
                      ) : (
                        <Scanner_4 />
                      )}
                    </PresentationControls>
                    <ContactShadows position={[0, -1.4, 0]} opacity={0.75} width={10} height={10} blur={2.6} far={2} />
                  </Canvas>
                </div>
              </div>

              <div style={{ display: showPrevImg ? 'flex' : 'none' }}>
                <img
                  id="hotspot_image"
                  style={{ maxWidth: 890, maxHeight: 510, filter: 'grayscale(100%)', cursor: 'pointer' }}
                  name="hotspot_image"
                  src={preview}
                  onClick={e => clickHotspotImage(e)}
                />
              </div>

            </S.ContentArea>

            <div>
              <S.FormGroup style={{ marginBottom: 15 }}>
                <S.Label>Selecione um Transdutor </S.Label>
                <S.Select
                  onChange={e => {
                    setScanner(e.target.value);
                  }}
                >
                  <S.Option value="" disabled selected hidden>
                    Transdutor
                  </S.Option>
                  <S.Option value="convexo"> Convexo </S.Option>
                  <S.Option value="linear">Linear </S.Option>
                  <S.Option value="endocavitario">Endocavitario </S.Option>
                  <S.Option value="setorial">Setorial </S.Option>
                  <S.Option value="microconvexo">Microconvexo </S.Option>
                </S.Select>
                {error === true && !specialtie ? <S.errorMessage>{errs.specialtie}</S.errorMessage> : null}
              </S.FormGroup>

              <label className="file-upload">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  onChange={e => {
                    setSelectImage(e.target.files[0]);
                    setShowPrevImg(true);
                  }}
                />
                Selecionar Imagem
              </label>

              <div>
                <label>Movimentação:</label>
                <br />
                <div>
                  <label>X: [{x}]</label>
                  <br />
                  <input
                    type="range"
                    max={larg - width / 2}
                    value={x}
                    onChange={e => {
                      setX(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Y: [{y}]</label>
                  <br />
                  <input
                    type="range"
                    max={alt - height / 2}
                    value={y}
                    onChange={e => {
                      setY(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <label>Rotação Z:</label>
                <br />
                <div style={{ padding: '10px' }}>
                  <CircleSlider
                    min={-360}
                    max={360}
                    value={rotateZ}
                    showTooltip={true}
                    fontSize={20}
                    size={140}
                    knobRadius={10}
                    progressWidth={10}
                    circleWidth={10}
                    progressColor="#31AF89"
                    onChange={e => {
                      setRotateZ(e);
                    }}
                  />
                  <br />
                </div>
              </div>

              <div>
                <label>Área:</label>
                <br />
                <div>
                  <label>Largura: [{width}]</label>
                  <br />
                  <input
                    type="range"
                    min={80}
                    max={500}
                    value={width}
                    onChange={e => {
                      setWidth(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Altura: [{height}]</label>
                  <br />
                  <input
                    type="range"
                    min={100}
                    max={500}
                    value={height}
                    onChange={e => {
                      setHeight(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Ver Área: [{showArea.toString()}]</label>
                  <br />
                  <input
                    type="checkbox"
                    value={showArea}
                    onChange={e => {
                      setShowArea(e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </S.FramesArea>
        </S.IModalBody>
        <S.IModalFooter />
      </S.IModal>
    );
  };

  const PostAnatomus = async () => {
    // setModalPostLoading(true);
    const Transversal = {
      descriptions: description,
      labelsImagens: labelsImagens
    };

    const Obliqua = {
      descriptions: descriptionO,
      labelsImagens: labelsImagensO
    };

    const Longitudinal = {
      descriptions: descriptionL,
      labelsImagens: labelsImagensL
    };

    const Coronal = {
      descriptions: descriptionC,
      labelsImagens: labelsImagensC
    };

    if (title && specialtie && subArea) {
      errs.title = '';
      errs.specialtie = '';
      errs.subArea = '';
      setErrs({ ...errs });

      let auxError = 0;

      if (
        description.length === 0 &&
        labelsImagens.length === 0 &&
        arrayImagens.length === 0 &&
        arrayImagensMark.length === 0 &&
        arrayImagensNoMark.length === 0
      ) {
        auxError++;
      }

      if (
        descriptionO.length === 0 &&
        labelsImagensO.length === 0 &&
        arrayImagensO.length === 0 &&
        arrayImagensMarkO.length === 0 &&
        arrayImagensNoMarkO.length === 0
      ) {
        auxError++;
      }

      if (
        descriptionL.length === 0 &&
        labelsImagensL.length === 0 &&
        arrayImagensL.length === 0 &&
        arrayImagensMarkL.length === 0 &&
        arrayImagensNoMarkL.length === 0
      ) {
        auxError++;
      }

      if (
        descriptionC.length === 0 &&
        labelsImagensC.length === 0 &&
        arrayImagensC.length === 0 &&
        arrayImagensMarkC.length === 0 &&
        arrayImagensNoMarkC.length === 0
      ) {
        auxError++;
      }

      if (auxError === 4) {
        setModalPostLoading(false);
        errs.section = 'Geral';
        errs.msg = 'Nenhuma varredura iniciada!';
        setError(true);
        setErrs({ ...errs });
        setIsValidationModalOpen(true);
        auxError = 0;
      } else {

        auxError = 0;
        if ((description.length === 0 && labelsImagens.length === 0 && arrayImagens.length === 0 && arrayImagensMark.length === 0 && arrayImagensNoMark.length === 0) || (description.length > 0 && labelsImagens.length > 0 && arrayImagens.length > 0 && arrayImagensMark.length > 0 && arrayImagensNoMark.length > 0 && (arrayImagens.length === arrayImagensMark.length && arrayImagens.length === arrayImagensNoMark.length))) {
          if ((descriptionO.length === 0 && labelsImagensO.length === 0 && arrayImagensO.length === 0 && arrayImagensMarkO.length === 0 && arrayImagensNoMarkO.length === 0) || (descriptionO.length > 0 && labelsImagensO.length > 0 && arrayImagensO.length > 0 && arrayImagensMarkO.length > 0 && arrayImagensNoMarkO.length > 0 && (arrayImagensO.length === arrayImagensMarkO.length && arrayImagensO.length === arrayImagensNoMarkO.length))) {
            if ((descriptionL.length === 0 && labelsImagensL.length === 0 && arrayImagensL.length === 0 && arrayImagensMarkL.length === 0 && arrayImagensNoMarkL.length === 0) || (descriptionL.length > 0 && labelsImagensL.length > 0 && arrayImagensL.length > 0 && arrayImagensMarkL.length > 0 && arrayImagensNoMarkL.length > 0 && (arrayImagensL.length === arrayImagensMarkL.length && arrayImagensL.length === arrayImagensNoMarkL.length))) {
              if ((descriptionC.length === 0 && labelsImagensC.length === 0 && arrayImagensC.length === 0 && arrayImagensMarkC.length === 0 && arrayImagensNoMarkC.length === 0) || (descriptionC.length > 0 && labelsImagensC.length > 0 && arrayImagensC.length > 0 && arrayImagensMarkC.length > 0 && arrayImagensNoMarkC.length > 0 && (arrayImagensC.length === arrayImagensMarkC.length && arrayImagensC.length === arrayImagensNoMarkC.length))) {

                setModalPostLoading(true);
                const Data = new FormData();
                Data.append('title', title);
                Data.append('adminId', Number(Cookies.get('adminId')));
                Data.append('areaId', specialtie);
                Data.append('subareaId', subArea);
                Data.append('description', description);
                Data.append('transversal', JSON.stringify(Transversal));
                Data.append('obliqua', JSON.stringify(Obliqua));
                Data.append('longitudinal', JSON.stringify(Longitudinal));
                Data.append('coronal', JSON.stringify(Coronal));

                if (arrayImagens.length > 0) {
                  for (let index = 0; index < arrayImagens.length; index++) {
                    Data.append('files', arrayImagens[index]);
                  }
                }

                if (arrayImagensNoMark.length > 0) {
                  for (let index = 0; index < arrayImagensNoMark.length; index++) {
                    Data.append('filesnomark', arrayImagensNoMark[index]);
                  }
                }

                if (arrayImagensMark.length > 0) {
                  for (let index = 0; index < arrayImagensMark.length; index++) {
                    Data.append('filesmark', arrayImagensMark[index]);
                  }
                }
                //Obliqua
                if (arrayImagensO.length > 0) {
                  for (let index = 0; index < arrayImagensO.length; index++) {
                    Data.append('filesObliqua', arrayImagensO[index]);
                  }
                }

                if (arrayImagensNoMarkO.length > 0) {
                  for (let index = 0; index < arrayImagensNoMarkO.length; index++) {
                    Data.append('filesnomarkObliqua', arrayImagensNoMarkO[index]);
                  }
                }

                if (arrayImagensMarkO.length > 0) {
                  for (let index = 0; index < arrayImagensMarkO.length; index++) {
                    Data.append('filesmarkObliqua', arrayImagensMarkO[index]);
                  }
                }
                //Longitudinal
                if (arrayImagensL.length > 0) {
                  for (let index = 0; index < arrayImagensL.length; index++) {
                    Data.append('filesLongitudinal', arrayImagensL[index]);
                  }
                }

                if (arrayImagensNoMarkL.length > 0) {
                  for (let index = 0; index < arrayImagensNoMarkL.length; index++) {
                    Data.append('filesnomarkLongitudinal', arrayImagensNoMarkL[index]);
                  }
                }

                if (arrayImagensMarkL.length > 0) {
                  for (let index = 0; index < arrayImagensMarkL.length; index++) {
                    Data.append('filesmarkLongitudinal', arrayImagensMarkL[index]);
                  }
                }

                //Coronal
                if (arrayImagensC.length > 0) {
                  for (let index = 0; index < arrayImagensC.length; index++) {
                    Data.append('filesCoronal', arrayImagensC[index]);
                  }
                }

                if (arrayImagensNoMarkC.length > 0) {
                  for (let index = 0; index < arrayImagensNoMarkC.length; index++) {
                    Data.append('filesnomarkCoronal', arrayImagensNoMarkC[index]);
                  }
                }

                if (arrayImagensMarkC.length > 0) {
                  for (let index = 0; index < arrayImagensMarkC.length; index++) {
                    Data.append('filesmarkCoronal', arrayImagensMarkC[index]);
                  }
                }

                try {
                  const result = await api.postAnatomus(Data);
                  if (result.result.status) {
                    toast.success(`Anatomus Cadastrado!`);
                    history.push('/anatomus');
                    setModalPostLoading(false);
                  } else {
                    toast.error(`Opa! Não foi possível cadastrar um novo AnatomUS!`);
                    setModalPostLoading(false);
                  }
                } catch (error) {
                  toast.error(`Opa! Não foi possível cadastrar um novo AnatomUS!`);
                  console.log(error);
                  setModalPostLoading(false);
                }
              } else {
                errs.section = 'Coronal';

                if (descriptionC.length <= 0) {
                  errs.description = 'O campo Descrição é Obrigatorio!';
                } else {
                  errs.description = null;
                }

                if (arrayImagensC.length <= 0) {
                  errs.arrayImagens = 'Nenhuma Varredura Adicionada!';
                } else {
                  errs.arrayImagens = null;
                }

                if (arrayImagensMarkC.length <= 0) {
                  errs.arrayImagensMark = 'Nenhuma Imagen com marcação Informada!';
                } else {
                  errs.arrayImagensMark = null;
                }

                if (arrayImagensNoMarkC.length <= 0) {
                  errs.arrayImagensNoMark = 'Nenhuma Imagen Informada!';
                } else {
                  errs.arrayImagensNoMark = null;
                }

                if (arrayImagensMarkC.length !== arrayImagensC.length) {
                  errs.arrayImagensMark = 'Opa! Quantia invalida';
                } else {
                  errs.arrayImagensMark = null;
                }

                if (arrayImagensNoMarkC.length !== arrayImagensC.length) {
                  errs.arrayImagensNoMark = 'Opa! Quantia invalida';
                } else {
                  errs.arrayImagensNoMark = null;
                }

                setModalPostLoading(false);
                setError(true);
                setErrs({ ...errs });
                setIsValidationModalOpen(true);
              }


            } else {
              errs.section = 'Logitudinal';

              if (descriptionL.length <= 0) {
                errs.description = 'O campo Descrição é Obrigatorio!';
              } else {
                errs.description = null;
              }

              if (arrayImagensL.length <= 0) {
                errs.arrayImagens = 'Nenhuma Varredura Adicionada!';
              } else {
                errs.arrayImagens = null;
              }

              if (arrayImagensMarkL.length <= 0) {
                errs.arrayImagensMark = 'Nenhuma Imagen com marcação Informada!';
              } else {
                errs.arrayImagensMark = null;
              }

              if (arrayImagensNoMarkL.length <= 0) {
                errs.arrayImagensNoMark = 'Nenhuma Imagen Informada!';
              } else {
                errs.arrayImagensNoMark = null;
              }

              if (arrayImagensMarkL.length !== arrayImagensL.length) {
                errs.arrayImagensMark = 'Opa! Quantia invalida';
              } else {
                errs.arrayImagensMark = null;
              }

              if (arrayImagensNoMarkL.length !== arrayImagensL.length) {
                errs.arrayImagensNoMark = 'Opa! Quantia invalida';
              } else {
                errs.arrayImagensNoMark = null;
              }

              setModalPostLoading(false);
              setError(true);
              setErrs({ ...errs });
              setIsValidationModalOpen(true);
            }
          } else {
            errs.section = 'Oblíqua';

            if (descriptionO.length <= 0) {
              errs.description = 'O campo Descrição é Obrigatorio!';
            } else {
              errs.description = null;
            }

            if (arrayImagensO.length <= 0) {
              errs.arrayImagens = 'Nenhuma Varredura Adicionada!';
            } else {
              errs.arrayImagens = null;
            }

            if (arrayImagensMarkO.length <= 0) {
              errs.arrayImagensMark = 'Nenhuma Imagen com marcação Informada!';
            } else {
              errs.arrayImagensMark = null;
            }

            if (arrayImagensNoMarkO.length <= 0) {
              errs.arrayImagensNoMark = 'Nenhuma Imagen Informada!';
            } else {
              errs.arrayImagensNoMark = null;
            }

            if (arrayImagensMarkO.length !== arrayImagensO.length) {
              errs.arrayImagensMark = 'Opa! Quantia invalida';
            } else {
              errs.arrayImagensMark = null;
            }

            if (arrayImagensNoMarkO.length !== arrayImagensO.length) {
              errs.arrayImagensNoMark = 'Opa! Quantia invalida';
            } else {
              errs.arrayImagensNoMark = null;
            }

            setModalPostLoading(false);
            setError(true);
            setErrs({ ...errs });
            setIsValidationModalOpen(true);
          }
        } else {
          errs.section = 'Transversal';

          if (description.length <= 0) {
            errs.description = 'O campo Descrição é Obrigatorio!';
          } else {
            errs.description = null;
          }

          if (arrayImagens.length <= 0) {
            errs.arrayImagens = 'Nenhuma Varredura Adicionada!';
          } else {
            errs.arrayImagens = null;
          }

          if (arrayImagensMark.length <= 0) {
            errs.arrayImagensMark = 'Nenhuma Imagen com marcação Informada!';
          } else {
            if (arrayImagensMark.length !== arrayImagens.length) {
              errs.arrayImagensMark = 'Opa! Quantia invalida';
            } else {
              errs.arrayImagensMark = null;
            }
            errs.arrayImagensMark = null;
          }

          if (arrayImagensNoMark.length <= 0) {
            errs.arrayImagensNoMark = 'Nenhuma Imagen Informada!';
          } else {
            if (arrayImagensNoMark.length !== arrayImagens.length) {
              errs.arrayImagensNoMark = 'Opa! Quantia invalida';
            } else {
              errs.arrayImagensNoMark = null;
            }
            errs.arrayImagensNoMark = null;
          }

          setModalPostLoading(false);
          setError(true);
          setErrs({ ...errs });
          setIsValidationModalOpen(true);
        }
      }
    } else {
      if (!title) {
        errs.title = 'O campo titulo é Obrigatorio!';
      } else {
        errs.title = null;
      }

      if (!subArea) {
        errs.subArea = 'O campo Categorias é Obrigatorio!';
      } else {
        errs.subArea = null;
      }

      if (!specialtie) {
        errs.specialtie = 'O campo Especialidade é Obrigatorio!';
      } else {
        errs.specialtie = null;
      }

      setModalPostLoading(false);
      setError(true);
      setErrs({ ...errs });
      setIsValidationModalOpen(true);
    }
  };

  const AreaImageMark = () => {
    return (
      <Fragment>
        <AreasImagensMark
          arrayImagensMark={arrayImagensMark}
          setArrayImagesMark={setArrayImagesMark}
          arrayImagensNoMark={arrayImagensNoMark}
          setArrayImagesNoMark={setArrayImagesNoMark}
          imageToMark={imageToMark}
          setImageToMark={setImageToMark}
          idMark="area-content-mark"
          classMark="draggable"
          labelsImagens={labelsImagens}
          setLabelsImagens={setLabelsImagens}
          labelIndex={labelIndex}
          setLabelIndex={setLabelIndex}
          setLabelDescription={setLabelDescription}
          labelDescription={labelDescription}
          setCont={setCont}
          cont={cont}
          arrayLabels={arrayLabels}
          setArrayLabels={setArrayLabels}
        />
      </Fragment>
    );
  };

  const AreaImageMarkL = () => {
    return (
      <Fragment>
        <AreasImagensMark
          arrayImagensMark={arrayImagensMarkL}
          setArrayImagesMark={setArrayImagesMarkL}
          arrayImagensNoMark={arrayImagensNoMarkL}
          setArrayImagesNoMark={setArrayImagesNoMarkL}
          imageToMark={imageToMarkL}
          setImageToMark={setImageToMarkL}
          idMark="area-content-mark-L"
          classMark="draggable-L"
          labelsImagens={labelsImagensL}
          setLabelsImagens={setLabelsImagensL}
          labelIndex={labelIndexL}
          setLabelIndex={setLabelIndexL}
          setLabelDescription={setLabelDescriptionL}
          labelDescription={labelDescriptionL}
          setCont={setContL}
          cont={contL}
          arrayLabels={arrayLabelsL}
          setArrayLabels={setArrayLabelsL}
        />
      </Fragment>
    );
  };

  const AreaImageMarkC = () => {
    return (
      <Fragment>
        <AreasImagensMark
          arrayImagensMark={arrayImagensMarkC}
          setArrayImagesMark={setArrayImagesMarkC}
          arrayImagensNoMark={arrayImagensNoMarkC}
          setArrayImagesNoMark={setArrayImagesNoMarkC}
          imageToMark={imageToMarkC}
          setImageToMark={setImageToMarkC}
          idMark="area-content-mark-C"
          classMark="draggable-C"
          labelsImagens={labelsImagensC}
          setLabelsImagens={setLabelsImagensC}
          labelIndex={labelIndexC}
          setLabelIndex={setLabelIndexC}
          setLabelDescription={setLabelDescriptionC}
          labelDescription={labelDescriptionC}
          setCont={setContC}
          cont={contC}
          arrayLabels={arrayLabelsC}
          setArrayLabels={setArrayLabelsC}
        />
      </Fragment>
    );
  };

  const AreaImageMarkO = () => {
    return (
      <Fragment>
        <AreasImagensMark
          arrayImagensMark={arrayImagensMarkO}
          setArrayImagesMark={setArrayImagesMarkO}
          arrayImagensNoMark={arrayImagensNoMarkO}
          setArrayImagesNoMark={setArrayImagesNoMarkO}
          imageToMark={imageToMarkO}
          setImageToMark={setImageToMarkO}
          idMark="area-content-mark-O"
          classMark="draggable-O"
          labelsImagens={labelsImagensO}
          setLabelsImagens={setLabelsImagensO}
          labelIndex={labelIndexO}
          setLabelIndex={setLabelIndexO}
          setLabelDescription={setLabelDescriptionO}
          labelDescription={labelDescriptionO}
          setCont={setContO}
          cont={contO}
          arrayLabels={arrayLabelsO}
          setArrayLabels={setArrayLabelsO}
        />
      </Fragment>
    );
  };

  return (
    <Card className="mb-3">
      <S.Header>
        <S.Title>Criar novo AnatomUS</S.Title>
      </S.Header>

      <div style={{ padding: '15px', width: `100%` }}>
        <S.FormGroup style={{ marginBottom: '15px', maxWidth: '578px' }}>
          <S.Label>
            Título <S.Required>*</S.Required>
          </S.Label>
          <S.Input
            type="text"
            onChange={e => {
              setTitle(e.target.value);
            }}
            placeholder="Informe um título"
            maxLength="100"
          />
          <C.LabelForm style={{ fontSize: 13, marginTop: 15, position: 'relative' }}>
            <strong
              style={{
                position: 'absolute',
                right: 0,
                color:
                  title.length > 70 && title.length !== 100 ? '#ff8c00' : title.length===100 ? '#FF6766' : '#9c9a9a'
              }}
            >
              Número de caracteres: [{`${title.length}/100`}]
            </strong>
          </C.LabelForm>
          {error === true && !title ? <S.errorMessage>{errs.title}</S.errorMessage> : null}
        </S.FormGroup>

        <S.AreasSubAreas style={{ marginBottom: 30 }}>
          <S.FormGroup>
            <S.Label>
              Selecione uma especialidade <S.Required>*</S.Required>
            </S.Label>
            <S.Select
              onChange={e => {
                setSpecialtie(e.target.value);
              }}
            >
              <S.Option value="" disabled selected hidden>
                Especialidade
              </S.Option>
              {specialties.map((item, index) => {
                return (
                  <S.Option key={index} value={item.id}>
                    {item.name}
                  </S.Option>
                );
              })}
            </S.Select>
            {error === true && !specialtie ? <S.errorMessage>{errs.specialtie}</S.errorMessage> : null}
          </S.FormGroup>

          <S.FormGroup>
            <S.Label>
              Selecione a categoria <S.Required>*</S.Required>
            </S.Label>
            <S.Select
              onChange={e => {
                setSubArea(e.target.value);
              }}
            >
              <S.Option value="" disabled selected hidden>
                Categoria
              </S.Option>
              {subAreas.map((item, index) => {
                return (
                  <S.Option key={index} value={item.id}>
                    {item.name}
                  </S.Option>
                );
              })}
            </S.Select>
            {error === true && !subArea ? <S.errorMessage>{errs.subArea}</S.errorMessage> : null}
          </S.FormGroup>
        </S.AreasSubAreas>

        <Button
          style={{ backgroundColor: '#31AF89', marginBottom: 15 }}
          onClick={() => {
            setModal(!modal);
          }}
        >
          AnatomUS Editor
        </Button>

        <S.VarredurasArea>
          <S.Label>
            Adicione as varreduras <S.Required>*</S.Required>
          </S.Label>

          <S.VarredurasOptionsArea>
            <S.VarreduraOptionBtn
              style={{ backgroundColor: isTransversal ? '#31AF89' : '#DDD', color: isTransversal ? '#FFF' : '#484848' }}
              onClick={() => selectVarredura(1)}
              isActive={isTransversal}
            >
              Transversal
            </S.VarreduraOptionBtn>

            <S.VarreduraOptionBtn
              style={{
                backgroundColor: isLongitudinal ? '#31AF89' : '#DDD',
                color: isLongitudinal ? '#FFF' : '#484848'
              }}
              onClick={() => selectVarredura(2)}
              isActive={isLongitudinal}
            >
              Longitudinal
            </S.VarreduraOptionBtn>

            <S.VarreduraOptionBtn
              style={{ backgroundColor: isObliqua ? '#31AF89' : '#DDD', color: isObliqua ? '#FFF' : '#484848' }}
              onClick={() => selectVarredura(3)}
              isActive={isObliqua}
            >
              Oblíqua
            </S.VarreduraOptionBtn>

            <S.VarreduraOptionBtn
              style={{
                backgroundColor: isCoronal ? '#31AF89' : '#DDD',
                color: isCoronal ? '#FFF' : '#484848'
              }}
              onClick={() => selectVarredura(4)}
              isActive={isCoronal}
            >
              Coronal
            </S.VarreduraOptionBtn>
          </S.VarredurasOptionsArea>
        </S.VarredurasArea>

        {isTransversal ? (
          <FormAnatomus
            title="Transversal"
            arrayImagens={arrayImagens}
            setArrayImages={setArrayImages}
            content={content}
            setContent={setContent}
            description={description}
            setDescriptio={setDescription}
            areaImagensMark={AreaImageMark}
          />
        ) : isLongitudinal ? (
          <FormAnatomus
            title="Longitudinal"
            arrayImagens={arrayImagensL}
            setArrayImages={setArrayImagesL}
            content={contentL}
            setContent={setContentL}
            description={descriptionL}
            setDescriptio={setDescriptionL}
            areaImagensMark={AreaImageMarkL}
          />
        ) : isObliqua ? (
          <FormAnatomus
            title="Oblíqua"
            arrayImagens={arrayImagensO}
            setArrayImages={setArrayImagesO}
            content={contentO}
            setContent={setContentO}
            description={descriptionO}
            setDescriptio={setDescriptionO}
            areaImagensMark={AreaImageMarkO}
          />
        ) : <FormAnatomus
          title="Coronal"
          arrayImagens={arrayImagensC}
          setArrayImages={setArrayImagesC}
          content={contentC}
          setContent={setContentC}
          description={descriptionC}
          setDescriptio={setDescriptionC}
          areaImagensMark={AreaImageMarkC}
        />}

        <S.BottomArea>
          <S.CancelBtn
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Cancelar
          </S.CancelBtn>
          <S.PublishBtn onClick={PostAnatomus}>Publicar</S.PublishBtn>
        </S.BottomArea>
      </div>
      {ModalEditFile()}
      {ValidationModal()}
      {LoadingPostModal()}
    </Card>
  );
}
