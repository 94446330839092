import React from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
       
      </Col>
      <Col sm="auto">
      </Col>
    </Row>
  </footer>
);

export default Footer;
