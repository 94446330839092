import styled from "styled-components";

export const TestContenthCard = styled.div`
    width: 100%;
    height: 100px;

    padding: 0px 0px 0px 20px;
    background-color: transparent;

    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px #ccc solid;

    transition: 0.5s;
    :hover{
        cursor: pointer;
        background-color: #F4F4F4;
    }
`;

export const TypeTitle = styled.h5`
    padding: 5px;
    margin-left: 15px;
`;

export const ThumbNail = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #31AF89;
`;

export const DetailsArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
`;

export const Author = styled.span`
    font-weight: 600;
    font-size: 14px; 
`;

export const WordsArea = styled.div`
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 30px;
`;

export const WordArea = styled.div`
    width: 100%;
    height: 100%;
`;

export const BarAmount = styled.div`
    height: 10px;
    width: 100%;
    background: #31AF89;
`;