import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Card, CardBody, InputGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Tooltip, CardFooter, CardHeader } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { FaTrashAlt, FaPen } from "react-icons/fa";

import CelusApi from '../../server/celusApi';
import { ImgNull } from '../../assets/img/icons/celus/index';

import './styles.scss'

import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { LoadingTableAnatomus } from '../LoadingTableAnatomus';

export const TableAnatomus = () => {

    const api = CelusApi();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [specialties, setSpecialties] = useState([]);
    const [specialtie, setSpecialtie] = useState('all');
    const [subArea, setSubArea] = useState();
    const [values, setValues] = useState({});
    const [totalPage, setTotalPage] = useState();
    const [currentPage, setCurrentPage] = useState(0)
    const perPage = 10;
    const [collapseOne1, collapseOneOpen1] = useState(false);
    const [anatomus, setAnatomus] = useState([]);

    useEffect(() => {
        GetAnatomus();
    }, [specialtie, currentPage]);

    useCallback(() => {
        setCurrentPage(0);
    }, [specialtie]);

    useEffect(() => {
        GetSpecialties();
       }, []);


    const GetSpecialties = async () => {
        try {
            const res = await api.listAreas();
            setSpecialties(res);
        } catch (error) {
            console.log(error);
        }
    }

    const GetAnatomus = async () => {
        setLoading(true);

        const params = {
            area: specialtie,
            subarea: subArea,
            page: currentPage,
            limit: 10
        }
        let results = await api.getAnatomus(params);
        setAnatomus(results.result.anatomus.rows);
        setTotalPage(Math.ceil(results.result.anatomus.count / perPage));
        setTimeout(() => {
            setLoading(false);
        }, 500);

    }

    const DeleteAnatomus = async (anatomusId) => {
        collapseOneOpen1(!collapseOne1);

        const body = {
            adminId: Cookies.get('adminId'),
            anatomusId: anatomusId,
        }

        setValues({ ...body });

    }

    const DeleteAnatomusChecked = async (values) => {
        await api.deleteAnatomus(values);
        GetAnatomus();
        collapseOneOpen1(!collapseOne1);
        toast.success('Anatomus Excluido!');
    }

    const DeleteLaudusModal = () => {
        return (
            <Modal isOpen={collapseOne1} toggle={() => collapseOneOpen1(!collapseOne1)}>
                <ModalHeader >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }} >
                        <div>Aviso!</div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <p style={{ fontSize: 17, fontWeight: 'bold', width: '100%' }}>Você está prestes a excluir um documento?</p>
                </ModalBody>
                <ModalFooter style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button onClick={() => collapseOneOpen1(!collapseOne1)}>
                        Cancelar
                    </Button>
                    <Button style={{ background: '#50D19A', border: 0 }} onClick={() => { DeleteAnatomusChecked(values) }}>Continuar</Button>
                </ModalFooter>
            </Modal>
        );
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1);
    };


    return (
        <>

            <Card className="mb-3">
                <CardHeader title="AnatomUS publicados" light={false} className="m-none">

                    <Fragment>
                        <div className="filter-input">
                            <InputGroup style={{ marginRight: '10px' }}>
                                <Input type="select" placeholder="Filtrar Pro" onChange={e => { setSpecialtie(e.target.value) }}>
                                    <option value="" disabled selected hidden>Especialidades</option >
                                    {specialties.map((item, index) => {
                                        return (
                                            <option value={item.id} key={index} >{item.name}</option>
                                        )
                                    })}

                                    <option value="all" >Todos</option>

                                </Input>
                            </InputGroup>
                            <ButtonIcon transform="shrink-3 down-2" color="falcon-default" size="sm" className="filter"
                                onClick={() => { GetAnatomus() }}
                            >
                                Filtrar
                            </ButtonIcon>
                        </div>
                    </Fragment>
                </CardHeader>

                <CardBody className="p-0">
                    <div className="content-table">
                        <Table responsive="lg">
                            <thead>
                                <tr className="header-table">
                                    <th>#</th>
                                    <th>Autor</th>
                                    <th>Título</th>
                                    <th> </th>
                                </tr>
                            </thead>

                            {
                                loading ? (
                                    <Fragment>
                                        <tbody>

                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                            <LoadingTableAnatomus />
                                        </tbody>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <tbody>
                                            {
                                                anatomus?.map((i) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <p>{i?.id}</p>
                                                            </td>
                                                            <td className="content-col">
                                                                <img src={ImgNull} className="init" />
                                                                <p>{i.admin?.name ? i.admin?.name : 'Usuário sem nome'}</p>
                                                            </td>
                                                            <td className="Link" onClick={() => { history.push(`/anatomus-edit/${i?.id}`) }}>
                                                                <Link to={`/anatomus-edit/${i?.id}`}>{i?.title}</Link>
                                                            </td>
                                                            <td>
                                                                <button className="view" onClick={() => { history.push(`/anatomus-edit/${i?.id}`) }} > <FaPen size={18} /></button>
                                                                <button className="view" onClick={() => { DeleteAnatomus(i?.id) }} disabled={i?.admin?.id !== Number(Cookies.get('adminId'))}> <FaTrashAlt size={18} /></button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Fragment>
                                )
                            }

                        </Table>
                    </div>
                </CardBody>

                <CardFooter>
                    <div className="pag-table-admin">
                        <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageChange}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            forcePage={currentPage === 0 ? 0 : currentPage - 1}
                            pageCount={totalPage}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </CardFooter>
                {DeleteLaudusModal()}
            </Card>
        </>
    );
}
