import React, { useState, useMemo, useEffect } from 'react';
import { Card, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as S from './css/anatomus';
import * as C from './css/components';
import CelusApi from '../../server/celusApi';
import { Canvas } from '@react-three/fiber';
import { ContactShadows, OrbitControls, PresentationControls } from '@react-three/drei';
import './styles.scss';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import { FormAnatomusEdit } from './FormAnatomusEdit';
import Cookies from 'js-cookie';
import { useParams, useHistory } from 'react-router';
import { AreaImagensMarkEdit } from './AreaImagensMarkEdit';
import Scanner from '../scanner/index';
import Scanner_2 from '../scanner/index_2';
import Scanner_3 from '../scanner/index_3';
import Scanner_4 from '../scanner/index_4';
import Scanner_5 from '../scanner/index_5';
import { CircleSlider } from 'react-circle-slider';

export default function PutCreateAnatomus() {
  const history = useHistory();
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const api = CelusApi();
  const { anatomusId } = useParams();

  const [values, setValues] = useState([]);

  const [specialties, setSpecialties] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const [specialtie, setSpecialtie] = useState();
  const [subArea, setSubArea] = useState();
  const [title, setTitle] = useState('');

  const [description, setDescription] = useState([]);
  const [descriptionL, setDescriptionL] = useState([]);
  const [descriptionO, setDescriptionO] = useState([]);
  const [descriptionC, setDescriptionC] = useState([]);

  const [content, setContent] = useState('');
  const [contentL, setContentL] = useState('');
  const [contentO, setContentO] = useState('');
  const [contentC, setContentC] = useState('');

  const [arrayImagensNew, setArrayImagesNew] = useState([]);
  const [arrayImagensNewL, setArrayImagesNewL] = useState([]);
  const [arrayImagensNewO, setArrayImagesNewO] = useState([]);
  const [arrayImagensNewC, setArrayImagesNewC] = useState([]);

  const [isTransversal, setIsTransversal] = useState(true);
  const [isLongitudinal, setIsLongitudinal] = useState(false);
  const [isObliqua, setIsObliqua] = useState(false);
  const [isCoronal, setIsCoronal] = useState(false);
  const [selectImage, setSelectImage] = useState(null);

  const [modal, setModal] = useState(false);

  const [arrayImagens, setArrayImages] = useState([]);
  const [arrayImagensL, setArrayImagesL] = useState([]);
  const [arrayImagensO, setArrayImagesO] = useState([]);
  const [arrayImagensC, setArrayImagesC] = useState([]);

  const [arrayImagensMark, setArrayImagesMark] = useState([]);
  const [arrayImagensMarkL, setArrayImagesMarkL] = useState([]);
  const [arrayImagensMarkO, setArrayImagesMarkO] = useState([]);
  const [arrayImagensMarkC, setArrayImagesMarkC] = useState([]);

  const [arrayImagensNoMark, setArrayImagesNoMark] = useState([]);
  const [arrayImagensNoMarkL, setArrayImagesNoMarkL] = useState([]);
  const [arrayImagensNoMarkO, setArrayImagesNoMarkO] = useState([]);
  const [arrayImagensNoMarkC, setArrayImagesNoMarkC] = useState([]);

  const [imageToMark, setImageToMark] = useState(null);
  const [imageToMarkL, setImageToMarkL] = useState(null);
  const [imageToMarkO, setImageToMarkO] = useState(null);
  const [imageToMarkC, setImageToMarkC] = useState(null);

  const [scanner, setScanner] = useState('transversal');

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);
  const [width, setWidth] = useState(80);
  const [height, setHeight] = useState(100);
  const [showArea, setShowArea] = useState(false);
  const [alt, setAlt] = useState();
  const [larg, setLarg] = useState();
  const [showPrevImg, setShowPrevImg] = useState(true);

  const [cont, setCont] = useState(1);
  const [contL, setContL] = useState(1);
  const [contO, setContO] = useState(1);
  const [contC, setContC] = useState(1);

  const [arrayLabels, setArrayLabels] = useState([]);
  const [arrayLabelsL, setArrayLabelsL] = useState([]);
  const [arrayLabelsO, setArrayLabelsO] = useState([]);
  const [arrayLabelsC, setArrayLabelsC] = useState([]);

  const [labelIndex, setLabelIndex] = useState();
  const [labelIndexL, setLabelIndexL] = useState();
  const [labelIndexO, setLabelIndexO] = useState();
  const [labelIndexC, setLabelIndexC] = useState();

  const [labelDescription, setLabelDescription] = useState();
  const [labelDescriptionL, setLabelDescriptionL] = useState();
  const [labelDescriptionO, setLabelDescriptionO] = useState();
  const [labelDescriptionC, setLabelDescriptionC] = useState();

  const [labelsImagens, setLabelsImagens] = useState([]);
  const [labelsImagensL, setLabelsImagensL] = useState([]);
  const [labelsImagensO, setLabelsImagensO] = useState([]);
  const [labelsImagensC, setLabelsImagensC] = useState([]);

  const [error, setError] = useState(false);

  const [arrayImagensMarkNew, setArrayImagesMarkNew] = useState([]);
  const [arrayImagensMarkNewO, setArrayImagesMarkNewO] = useState([]);
  const [arrayImagensMarkNewL, setArrayImagesMarkNewL] = useState([]);
  const [arrayImagensMarkNewC, setArrayImagesMarkNewC] = useState([]);

  const [arrayImagensNoMarkNew, setArrayImagesNoMarkNew] = useState([]);
  const [arrayImagensNoMarkNewO, setArrayImagesNoMarkNewO] = useState([]);
  const [arrayImagensNoMarkNewL, setArrayImagesNoMarkNewL] = useState([]);
  const [arrayImagensNoMarkNewC, setArrayImagesNoMarkNewC] = useState([]);

  const [id, setId] = useState('');
  const [idL, setIdL] = useState('');
  const [idO, setIdO] = useState('');
  const [idC, setIdC] = useState('');

  const [errs, setErrs] = useState({
    title: '',
    description: '',
    specialtie: '',
    subArea: '',
    arrayImagens: '',
    arrayImagensMark: '',
    arrayImagensNoMark: ''
  });

  useEffect(() => {
    GetSpecialties();
     }, []);


  useEffect(() => {
    GetSubAreas();
  }, [specialtie]);

  const GetSpecialties = async () => {
    try {
      const res = await api.listAreas();
      setSpecialties(res);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSubAreas = async () => {
    try {
      const res = await api.getListSubAreas({ specialtie: specialtie, type: 'anatomus' });
      setSubAreas(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAnatomusById();
     }, []);


  const GetAnatomusById = async () => {
    const result = await api.getAnatomusbyId(anatomusId);
    setValues(result.result[0]);
    setTitle(result.result[0].title);
    setSpecialtie(result.result[0].areaId);
    setSubArea(result.result[0].subareaId);

    result.result[0].anatomuContents.map(item => {
      if (item?.type === 'transversal') {
        setDescription(JSON.parse(item?.description));
        setArrayImages(JSON.parse(item?.varreduras));
        setArrayImagesNoMark(JSON.parse(item?.imagesNoMark));
        setArrayImagesMark(JSON.parse(item?.imagesMark));
        setLabelsImagens(JSON.parse(item?.labelsImagens));
        setId(item?.id);
      } else {
        if (item?.type === 'obliqua') {
          setDescriptionO(JSON.parse(item?.description));
          setArrayImagesO(JSON.parse(item?.varreduras));
          setArrayImagesNoMarkO(JSON.parse(item?.imagesNoMark));
          setArrayImagesMarkO(JSON.parse(item?.imagesMark));
          setLabelsImagensO(JSON.parse(item?.labelsImagens));
          setIdO(item?.id);
        } else {
          if (item?.type === 'coronal') {
            setDescriptionC(JSON.parse(item?.description));
            setArrayImagesC(JSON.parse(item?.varreduras));
            setArrayImagesNoMarkC(JSON.parse(item?.imagesNoMark));
            setArrayImagesMarkC(JSON.parse(item?.imagesMark));
            setLabelsImagensC(JSON.parse(item?.labelsImagens));
            setIdC(item?.id);
          } else {
            setDescriptionL(JSON.parse(item?.description));
            setArrayImagesL(JSON.parse(item?.varreduras));
            setArrayImagesNoMarkL(JSON.parse(item?.imagesNoMark));
            setArrayImagesMarkL(JSON.parse(item?.imagesMark));
            setLabelsImagensL(JSON.parse(item?.labelsImagens));
            setIdL(item?.id);
          }
        }
      }
    });
  };

  const ValidationModal = () => {
    return (
      <Modal isOpen={isValidationModalOpen} toggle={() => setIsValidationModalOpen(!isValidationModalOpen)}>
        <ModalHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div>
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>Ops! Alguns campos estão incompletos</span>
          </div>
        </ModalHeader>
        <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '150px' }}>
            <C.ValidateModalRow>
              <span>{!errs.title ? <C.CheckIcon checked /> : <C.CheckIcon />} Título</span>
            </C.ValidateModalRow>

            <C.ValidateModalRow>
              <span>{!errs.specialtie ? <C.CheckIcon checked /> : <C.CheckIcon />} Especialidade</span>
            </C.ValidateModalRow>

            <C.ValidateModalRow>
              <span>{!errs.subArea ? <C.CheckIcon checked /> : <C.CheckIcon />} Categoria</span>
            </C.ValidateModalRow>

            <C.ValidateModalRow>
              <span>{!errs.arrayImagens ? <C.CheckIcon checked /> : <C.CheckIcon />} Varredura</span>
            </C.ValidateModalRow>

            <C.ValidateModalRow>
              <span>{!errs.description ? <C.CheckIcon checked /> : <C.CheckIcon />} Descrição</span>
            </C.ValidateModalRow>
            <C.ValidateModalRow>
              <span>{!errs.arrayImagensNoMark ? <C.CheckIcon checked /> : <C.CheckIcon />}Ref. Marcação</span>
            </C.ValidateModalRow>
            <C.ValidateModalRow>
              <span>{!errs.arrayImagensMark ? <C.CheckIcon checked /> : <C.CheckIcon />} Marcação</span>
            </C.ValidateModalRow>
          </div>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button block className="modal-save" onClick={() => setIsValidationModalOpen(!isValidationModalOpen)}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const selectVarredura = varredura => {
    if (varredura === 1) {
      setIsTransversal(true);
      setIsLongitudinal(false);
      setIsObliqua(false);
      setIsCoronal(false);
    } else if (varredura === 2) {
      setIsTransversal(false);
      setIsLongitudinal(true);
      setIsObliqua(false);
      setIsCoronal(false);
    } else if (varredura === 3) {
      setIsTransversal(false);
      setIsLongitudinal(false);
      setIsCoronal(false);
      setIsObliqua(true);
    } else if (varredura === 4) {
      setIsTransversal(false);
      setIsLongitudinal(false);
      setIsCoronal(true);
      setIsObliqua(false);
    }
  };

  useEffect(() => {
    getAltLarg();
  }, [selectImage]);

  const preview = useMemo(() => {
    return selectImage ? URL.createObjectURL(selectImage) : null;
  }, [selectImage]);

  const getAltLarg = () => {
    var img = document.getElementById('hotspot_image');
    setAlt(img?.height);
    setLarg(img?.width);
  };

  function clickHotspotImage(event) {
    var img = document.getElementById('hotspot_image');
    var canvas = document.createElement('canvas');
    setX(event.nativeEvent.offsetX);
    setY(event.nativeEvent.offsetY);
    setAlt(img?.height + 1);
    setLarg(img?.width + 1);
    canvas.width = img?.width;
    canvas.height = img?.height;
    canvas.getContext('2d').drawImage(img, 0, 0, img?.width, img?.height);
    setShowPrevImg(false);
  }

  const ModalEditFile = () => {
    return (
      <S.IModal isOpen={modal} toggle={() => setModal(!modal)}>
        <S.IModalHeader>
          <S.Title>AnatomUS Editor</S.Title>
        </S.IModalHeader>
        <S.IModalBody>
          <S.FramesArea>
            <S.ContentArea>
              <div
                id="btnScreenshot"
                style={{
                  width: larg + 1,
                  height: alt + 1,
                  border: 'solid 1px #0005',
                  backgroundImage: `url(${preview})`,
                  backgroundSize: 'cover',
                  maxWidth: 890,
                  maxHeight: 510
                }}
              >
                <div
                  style={{
                    backgroundColor: showArea ? 'rgba(44, 44, 44, 0.38)' : null,
                    width: Number(width),
                    height: Number(height),
                    transform: `translate(${x + 'px'}, ${y + 'px'}) rotateZ(${rotateZ + 'deg'}) rotateY(${rotateY +
                      'deg'}) rotateX(${rotateX + 'deg'})`
                  }}
                >
                  <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0, 5], fov: 50 }}>
                    <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
                    <OrbitControls zoomSpeed={0.5} />
                    <ambientLight intensity={0.5} />
                    <spotLight
                      position={[10, 10, 10]}
                      angle={0.15}
                      penumbra={1}
                      shadow-mapSize={[512, 512]}
                      castShadow
                    />
                    <PresentationControls>
                      {scanner === 'endocavitario' ? (
                        <Scanner />
                      ) : scanner === 'linear' ? (
                        <Scanner_2 />
                      ) : scanner === 'convexo' ? (
                        <Scanner_3 />
                      ) : scanner === 'microconvexo' ? (
                        <Scanner_5 />
                      ) : (
                        <Scanner_4 />
                      )}
                    </PresentationControls>
                    <ContactShadows position={[0, -1.4, 0]} opacity={0.75} width={10} height={10} blur={2.6} far={2} />
                  </Canvas>
                </div>
              </div>
              <img
                id="hotspot_image"
                style={{ display: showPrevImg ? 'flex' : 'none', maxWidth: 890, maxHeight: 510 }}
                name="hotspot_image"
                src={preview}
                onClick={e => clickHotspotImage(e)}
              />
            </S.ContentArea>

            <div>
              <S.FormGroup style={{ marginBottom: 15 }}>
                <S.Label>Selecione um Transdutor </S.Label>
                <S.Select
                  onChange={e => {
                    setScanner(e.target.value);
                  }}
                >
                  <S.Option value="" disabled selected hidden>
                    Transdutor
                  </S.Option>
                  <S.Option value="convexo"> Convexo </S.Option>
                  <S.Option value="linear">Linear </S.Option>
                  <S.Option value="endocavitario">Endocavitario </S.Option>
                  <S.Option value="setorial">Setorial </S.Option>
                  <S.Option value="microconvexo">Microconvexo </S.Option>
                </S.Select>
                {error === true && !specialtie ? <S.errorMessage>{errs.specialtie}</S.errorMessage> : null}
              </S.FormGroup>

              <label className="file-upload">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  onChange={e => {
                    setSelectImage(e.target.files[0]);
                    setShowPrevImg(true);
                  }}
                />
                Selecionar Imagem
              </label>

              <div>
                <label>Movimentação:</label>
                <br />
                <div>
                  <label>X: [{x}]</label>
                  <br />
                  <input
                    type="range"
                    max={larg - width / 2}
                    value={x}
                    onChange={e => {
                      setX(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Y: [{y}]</label>
                  <br />
                  <input
                    type="range"
                    max={alt - height / 2}
                    value={y}
                    onChange={e => {
                      setY(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <label>Rotação Z:</label>
                <br />
                <div style={{ padding: '10px' }}>
                  <CircleSlider
                    min={-360}
                    max={360}
                    value={rotateZ}
                    showTooltip={true}
                    fontSize={20}
                    size={140}
                    knobRadius={10}
                    progressWidth={10}
                    circleWidth={10}
                    progressColor="#31AF89"
                    onChange={e => {
                      setRotateZ(e);
                    }}
                  />
                  <br />
                </div>
              </div>

              <div>
                <label>Área:</label>
                <br />
                <div>
                  <label>Largura: [{width}]</label>
                  <br />
                  <input
                    type="range"
                    min={80}
                    max={500}
                    value={width}
                    onChange={e => {
                      setWidth(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Altura: [{height}]</label>
                  <br />
                  <input
                    type="range"
                    min={100}
                    max={500}
                    value={height}
                    onChange={e => {
                      setHeight(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Ver Área: [{showArea.toString()}]</label>
                  <br />
                  <input
                    type="checkbox"
                    value={showArea}
                    onChange={e => {
                      setShowArea(e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </S.FramesArea>
        </S.IModalBody>
        <S.IModalFooter />
      </S.IModal>
    );
  };

  const PostAnatomus = async () => {
    const Transversal = {
      descriptions: description,
      labelsImagens: labelsImagens,
      id: id,
      varredureas: arrayImagens,
      imagesMark: arrayImagensMark,
      imagesNoMark: arrayImagensNoMark
    };

    const Obliqua = {
      descriptions: descriptionO,
      labelsImagens: labelsImagensO,
      id: idO,
      varredureas: arrayImagensO,
      imagesMark: arrayImagensMarkO,
      imagesNoMark: arrayImagensNoMarkO
    };

    const Longitudinal = {
      descriptions: descriptionL,
      labelsImagens: labelsImagensL,
      id: idL,
      varredureas: arrayImagensL,
      imagesMark: arrayImagensMarkL,
      imagesNoMark: arrayImagensNoMarkL
    };

    const Coronal = {
      descriptions: descriptionC,
      labelsImagens: labelsImagensC,
      id: idC,
      varredureas: arrayImagensC,
      imagesMark: arrayImagensMarkC,
      imagesNoMark: arrayImagensNoMarkC
    };

    if (title && specialtie && subArea) {
      const Data = new FormData();
      Data.append('title', title);
      Data.append('adminId', Number(Cookies.get('adminId')));
      Data.append('anatomusId', anatomusId);
      Data.append('areaId', specialtie);
      Data.append('subareaId', subArea);
      Data.append('description', description);
      Data.append('transversal', JSON.stringify(Transversal));
      Data.append('obliqua', JSON.stringify(Obliqua));
      Data.append('longitudinal', JSON.stringify(Longitudinal));
      Data.append('coronal', JSON.stringify(Coronal));

      try {
        await api
          .putAnatomus(Data)
          .then(() => {
            history.push('/anatomus');
            toast.success(`Anatomus Cadastrado!`);
          })
          .catch(() => {
            toast.error(`Opa! Não foi possivel cadastrar o anatomus!`);
          });
      } catch (error) {
        toast.error(`Opa! Não foi possivel cadastrar o anatomus!`);
        console.log(error);
      }
    } else {
      if (!title) {
        errs.title = 'O campo titulo é Obrigatorio!';
      } else {
        errs.title = null;
      }

      if (!subArea) {
        errs.subArea = 'O campo Categorias é Obrigatorio!';
      } else {
        errs.subArea = null;
      }
      if (!specialtie) {
        errs.specialtie = 'O campo Especialidade é Obrigatorio!';
      } else {
        errs.specialtie = null;
      }
      setError(true);
      setErrs({ ...errs });
      setIsValidationModalOpen(true);
    }
  };

  const AreaImageMark = () => {
    return (
      <Fragment>
        <AreaImagensMarkEdit
          arrayImagensMarkNew={arrayImagensMarkNew}
          setArrayImagesMarkNew={setArrayImagesMarkNew}
          arrayImagensNoMarkNew={arrayImagensNoMarkNew}
          setArrayImagesNoMarkNew={setArrayImagesNoMarkNew}
          arrayImagensMark={arrayImagensMark}
          setArrayImagesMark={setArrayImagesMark}
          arrayImagensNoMark={arrayImagensNoMark}
          setArrayImagesNoMark={setArrayImagesNoMark}
          imageToMark={imageToMark}
          setImageToMark={setImageToMark}
          idMark="area-content-mark"
          classMark="draggable"
          labelsImagens={labelsImagens}
          setLabelsImagens={setLabelsImagens}
          labelIndex={labelIndex}
          setLabelIndex={setLabelIndex}
          setLabelDescription={setLabelDescription}
          labelDescription={labelDescription}
          setCont={setCont}
          cont={cont}
          arrayLabels={arrayLabels}
          setArrayLabels={setArrayLabels}
        />
      </Fragment>
    );
  };

  const AreaImageMarkL = () => {
    return (
      <Fragment>
        <AreaImagensMarkEdit
          arrayImagensMarkNew={arrayImagensMarkNewL}
          setArrayImagesMarkNew={setArrayImagesMarkNewL}
          arrayImagensNoMarkNew={arrayImagensNoMarkNewL}
          setArrayImagesNoMarkNew={setArrayImagesNoMarkNewL}
          arrayImagensMark={arrayImagensMarkL}
          setArrayImagesMark={setArrayImagesMarkL}
          arrayImagensNoMark={arrayImagensNoMarkL}
          setArrayImagesNoMark={setArrayImagesNoMarkL}
          imageToMark={imageToMarkL}
          setImageToMark={setImageToMarkL}
          idMark="area-content-mark-L"
          classMark="draggable-L"
          labelsImagens={labelsImagensL}
          setLabelsImagens={setLabelsImagensL}
          labelIndex={labelIndexL}
          setLabelIndex={setLabelIndexL}
          setLabelDescription={setLabelDescriptionL}
          labelDescription={labelDescriptionL}
          setCont={setContL}
          cont={contL}
          arrayLabels={arrayLabelsL}
          setArrayLabels={setArrayLabelsL}
        />
      </Fragment>
    );
  };

  const AreaImageMarkC = () => {
    return (
      <Fragment>
        <AreaImagensMarkEdit
          arrayImagensMarkNew={arrayImagensMarkNewC}
          setArrayImagesMarkNew={setArrayImagesMarkNewC}
          arrayImagensNoMarkNew={arrayImagensNoMarkNewC}
          setArrayImagesNoMarkNew={setArrayImagesNoMarkNewC}
          arrayImagensMark={arrayImagensMarkC}
          setArrayImagesMark={setArrayImagesMarkC}
          arrayImagensNoMark={arrayImagensNoMarkC}
          setArrayImagesNoMark={setArrayImagesNoMarkC}
          imageToMark={imageToMarkC}
          setImageToMark={setImageToMarkC}
          idMark="area-content-mark-C"
          classMark="draggable-C"
          labelsImagens={labelsImagensC}
          setLabelsImagens={setLabelsImagensC}
          labelIndex={labelIndexC}
          setLabelIndex={setLabelIndexC}
          setLabelDescription={setLabelDescriptionC}
          labelDescription={labelDescriptionC}
          setCont={setContC}
          cont={contC}
          arrayLabels={arrayLabelsC}
          setArrayLabels={setArrayLabelsC}
        />
      </Fragment>
    );
  };

  const AreaImageMarkO = () => {
    return (
      <Fragment>
        <AreaImagensMarkEdit
          arrayImagensMarkNew={arrayImagensMarkNewO}
          setArrayImagesMarkNew={setArrayImagesMarkNewO}
          arrayImagensNoMarkNew={arrayImagensNoMarkNewO}
          setArrayImagesNoMarkNew={setArrayImagesNoMarkNewO}
          arrayImagensMark={arrayImagensMarkO}
          setArrayImagesMark={setArrayImagesMarkO}
          arrayImagensNoMark={arrayImagensNoMarkO}
          setArrayImagesNoMark={setArrayImagesNoMarkO}
          imageToMark={imageToMarkO}
          setImageToMark={setImageToMarkO}
          idMark="area-content-mark-O"
          classMark="draggable-O"
          labelsImagens={labelsImagensO}
          setLabelsImagens={setLabelsImagensO}
          labelIndex={labelIndexO}
          setLabelIndex={setLabelIndexO}
          setLabelDescription={setLabelDescriptionO}
          labelDescription={labelDescriptionO}
          setCont={setContO}
          cont={contO}
          arrayLabels={arrayLabelsO}
          setArrayLabels={setArrayLabelsO}
        />
      </Fragment>
    );
  };

  return (
    <Card className="mb-3">
      <S.Header>
        <S.Title>Editar AnatomUS</S.Title>
      </S.Header>

      <div style={{ padding: '30px' }}>
        <S.FormGroup style={{ marginBottom: '15px', maxWidth: '578px' }}>
          <S.Label>
            Título <S.Required>*</S.Required>
          </S.Label>
          <S.Input
            type="text"
            maxLength="100"
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
            placeholder="Informe um título"
          />
          <C.LabelForm style={{ fontSize: 13, marginTop: 15, position: 'relative' }}>
            <strong
              style={{
                position: 'absolute',
                right: 0,
                color:
                  title.length > 70 && title.length !== 100 ? '#ff8c00' : title.length===100 ? '#FF6766' : '#9c9a9a'
              }}
            >
              Número de caracteres: [{`${title.length}/100`}]
            </strong>
          </C.LabelForm>
          {error === true && !title ? <S.errorMessage>{errs.title}</S.errorMessage> : null}
        </S.FormGroup>

        <S.AreasSubAreas style={{ marginBottom: 30 }}>
          <S.FormGroup>
            <S.Label>
              Selecione uma especialidade <S.Required>*</S.Required>
            </S.Label>
            <S.Select
              onChange={e => {
                setSpecialtie(e.target.value);
              }}
            >
              {/* <S.Option value="" disabled selected hidden>Especialidade</S.Option> */}
              {specialties.map((item, index) => {
                return (
                  <S.Option key={index} value={item.id} selected={specialtie === item.id}>
                    {item.name}
                  </S.Option>
                );
              })}
            </S.Select>
            {error === true && !specialtie ? <S.errorMessage>{errs.specialtie}</S.errorMessage> : null}
          </S.FormGroup>

          <S.FormGroup>
            <S.Label>
              Selecione a categoria <S.Required>*</S.Required>
            </S.Label>
            <S.Select
              onChange={e => {
                setSubArea(e.target.value);
              }}
            >
              {subAreas.map((item, index) => {
                return (
                  <S.Option key={index} value={item.id} selected={subArea === item.id}>
                    {item.name}
                  </S.Option>
                );
              })}
            </S.Select>
            {error === true && !subArea ? <S.errorMessage>{errs.subArea}</S.errorMessage> : null}
          </S.FormGroup>
        </S.AreasSubAreas>
        <Button
          style={{ backgroundColor: '#31AF89', marginBottom: 15 }}
          onClick={() => {
            setModal(!modal);
          }}
        >
          AnatomUS Editor
        </Button>
        <S.VarredurasArea>
          <S.Label>
            Adicione as varreduras <S.Required>*</S.Required>
          </S.Label>

          <S.VarredurasOptionsArea>
            <S.VarreduraOptionBtn
              style={{ backgroundColor: isTransversal ? '#31AF89' : '#DDD', color: isTransversal ? '#FFF' : '#484848' }}
              onClick={() => selectVarredura(1)}
              isActive={isTransversal}
            >
              Transversal
            </S.VarreduraOptionBtn>

            <S.VarreduraOptionBtn
              style={{
                backgroundColor: isLongitudinal ? '#31AF89' : '#DDD',
                color: isLongitudinal ? '#FFF' : '#484848'
              }}
              onClick={() => selectVarredura(2)}
              isActive={isLongitudinal}
            >
              Longitudinal
            </S.VarreduraOptionBtn>

            <S.VarreduraOptionBtn
              style={{ backgroundColor: isObliqua ? '#31AF89' : '#DDD', color: isObliqua ? '#FFF' : '#484848' }}
              onClick={() => selectVarredura(3)}
              isActive={isObliqua}
            >
              Oblíqua
            </S.VarreduraOptionBtn>

            <S.VarreduraOptionBtn
              style={{ backgroundColor: isCoronal ? '#31AF89' : '#DDD', color: isCoronal ? '#FFF' : '#484848' }}
              onClick={() => selectVarredura(4)}
              isActive={isCoronal}
            >
              Coronal
            </S.VarreduraOptionBtn>
          </S.VarredurasOptionsArea>
        </S.VarredurasArea>

        {isTransversal ? (
          <FormAnatomusEdit
            title="Transversal"
            arrayImagens={arrayImagens}
            arrayImagensNew={arrayImagensNew}
            setArrayImagesNew={setArrayImagesNew}
            setArrayImages={setArrayImages}
            content={content}
            setContent={setContent}
            description={description}
            setDescriptio={setDescription}
            areaImagensMark={AreaImageMark}
          />
        ) : isLongitudinal ? (
          <FormAnatomusEdit
            title="Longitudinal"
            arrayImagens={arrayImagensL}
            arrayImagensNew={arrayImagensNewL}
            setArrayImagesNew={setArrayImagesNewL}
            setArrayImages={setArrayImagesL}
            content={contentL}
            setContent={setContentL}
            description={descriptionL}
            setDescriptio={setDescriptionL}
            areaImagensMark={AreaImageMarkL}
          />
        ) : isCoronal ? (
          <FormAnatomusEdit
            title="Coronal"
            arrayImagens={arrayImagensC}
            arrayImagensNew={arrayImagensNewC}
            setArrayImagesNew={setArrayImagesNewC}
            setArrayImages={setArrayImagesC}
            content={contentC}
            setContent={setContentC}
            description={descriptionC}
            setDescriptio={setDescriptionC}
            areaImagensMark={AreaImageMarkC}
          />
        ) : (
          <FormAnatomusEdit
            title="Oblíqua"
            arrayImagens={arrayImagensO}
            arrayImagensNew={arrayImagensNewO}
            setArrayImagesNew={setArrayImagesNewO}
            setArrayImages={setArrayImagesO}
            content={contentO}
            setContent={setContentO}
            description={descriptionO}
            setDescriptio={setDescriptionO}
            areaImagensMark={AreaImageMarkO}
          />
        )}

        <S.BottomArea>
          <S.CancelBtn>Cancelar</S.CancelBtn>
          <S.PublishBtn onClick={PostAnatomus}>Publicar</S.PublishBtn>
        </S.BottomArea>
      </div>
      {ModalEditFile()}
      {ValidationModal()}
    </Card>
  );
}
