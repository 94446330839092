import React, { Fragment, useEffect, useState } from 'react';
import { Card, InputGroup, Input, CardHeader } from 'reactstrap';
import CelusApi from '../../server/celusApi';
import './styles.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { Doughnut, Bar } from 'react-chartjs-2';
import * as S from './styles.js';
import celusApi from '../../server/celusApi';
import { SearchesNotFound } from '../SearchesNotFound';
import { FilterAreasAmount } from '../FilterAreasAmount';
import { Functionalities } from '../Functionalities';
import { ContentRanking } from '../ContentRanking';

export const Analytics = () => {
    const [option, setOption] = useState('searches');
    return (
        <Fragment>
            <Card>
                <CardHeader style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5 style={{ marginBottom: '20px' }} className="mb-0">Analytics</h5>
                    <InputGroup style={{ width: '25%' }}>
                        <Input type="select" placeholder="Filtrar" onChange={e => { setOption(e.target.value.toLocaleLowerCase()) }}>
                            <option className='option' value="" disabled selected hidden>Filtrar</option >
                            <option className='option' value="searches">Pesquisas</option>
                            <option className='option' value="filters">Filtros</option>
                            <option className='option' value="functionalities">Funcionalidades</option>
                            <option className='option' value="content">Conteúdos</option>
                        </Input>
                    </InputGroup>
                </CardHeader>

                <S.ContentArea>
                    {
                        option === 'filters' ? (
                            <FilterAreasAmount />
                        ) : option === 'searches' ? (
                            <SearchesNotFound />
                        ) : option === 'functionalities' ? (
                            <Functionalities />
                        ) : option === 'content' ? (
                            <ContentRanking />
                        ) : null
                    }
                </S.ContentArea>
            </Card>
        </Fragment>
    )
}
