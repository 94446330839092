import React from 'react';
import { MdModeEdit } from "react-icons/md";

const EditBtn = () => {
  const handleEdit = () => {
    
  }

  return (
    <div onClick={handleEdit} style={{
      display: 'flex',
      alignItems: 'center',
      color: '#31AF89',
      gap: '3px',
    }}>
      <MdModeEdit size={23} />
      <span style={{fontSize: '16px', fontWeight: 'bold', cursor: 'pointer'}}>Editar</span>
    </div>
  )
}

export default EditBtn;
