import React from 'react';

import FormsLaudus from '../widgets/FormsLaudus';

const Index = () => {
    return(
        <>
            <FormsLaudus/>
        </>
    );
}

export default Index;