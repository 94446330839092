import {
  apiFetchDelete,
  apiFetchFile,
  apiFetchGet,
  apiFetchPost,
  apiFetchPublicGet
} from "./fetch";

const CelusApi = {
  listWelcomeTexts: async () => {
    const res = await apiFetchGet("welcome-text");
    return res;
  },

  listAreas: async type => {
    const json = await apiFetchGet(`areas/list/${type}`, {});
    return json.result;
  },

  listAllAreas: async () => {
    const json = await apiFetchGet(`areas/all`, {});
    return json.data;
  },

  createSubArea: async (data, parentId) => {
    const json = await apiFetchFile(`areas/subarea/create/${parentId}`, data);
    return json;
  },

  updateSubArea: async (data, areaId) => {
    const json = await apiFetchFile(`areas/subarea/update/${areaId}`, data);
    return json;
  },

  deleteSubArea: async areaId => {
    const json = await apiFetchDelete(`areas/subarea/delete/${areaId}`);
    return json;
  },

  createArea: async data => {
    const json = await apiFetchFile(`areas/create`, data);
    return json;
  },

  deleteArea: async id => {
    const json = await apiFetchDelete(`areas/delete/${id}`);
    return json;
  },

  updateArea: async (id, data) => {
    const json = await apiFetchFile(`areas/update/${id}`, data);
    return json;
  },

  getQuantityAreas: async areaId => {
    const json = await apiFetchGet(`areas/quantity/${areaId}`, {});
    return json;
  },

  getQuantitySubareas: async subareaId => {
    const json = await apiFetchGet(`areas/subarea/quantity/${subareaId}`, {});
    return json;
  },

  listLanguage: async () => {
    const json = await apiFetchPublicGet(`languages`, {});
    return json;
  },

  listProtoculus: async () => {
    const json = await apiFetchGet(`infograficos/all`, {});
    return json.result;
  },

  listProtoculusFavorite: async values => {
    const json = await apiFetchGet(
      `infograficos/list-favorites/${values?.clientId}`,
      {}
    );
    return json.result;
  },

  ///infograficos/show/:id
  ShowProtoculus: async values => {
    const json = await apiFetchGet(
      `infograficos/show/${values.id}/${values.languageId}`,
      {}
    );
    return json.result;
  },

  ///infograficos/favorite/:id/:clientId
  FavoriteProtoculus: async values => {
    const json = await apiFetchPost(
      `infograficos/favorite/${values.id}/${values.clientId}`,
      {}
    );
    return json;
  },

  DeleteProtoculus: async values => {
    const json = await apiFetchDelete(`infograficos/delete/${values.id}`, {});
    return json;
  },

  ///infograficos/store
  PostProtoculus: async values => {
    const json = await apiFetchFile("infograficos/store", values);
    return json;
  },

  PutProtoculus: async values => {
    const json = await apiFetchPost("infograficos/edit/:id", { data: values });
    return json;
  },

  UpNewImage: async values => {
    const json = await apiFetchFile(
      `protocolus/up/images/${values.sectionId}`,
      values
    );

    return json;
  },

  //checked
  CheckedFavoriteProtoculus: async values => {
    const json = await apiFetchGet(
      `infograficos/favorite/checked/${values?.id}/${values?.clientId}`,
      {}
    );
    return json.result;
  },

  groupedReports: async () => {
    const json = await apiFetchGet("laudus/grouped", {});
    return json;
  },
  groupedReportsUser: async values => {
    const json = await apiFetchGet(`grouped-laudus-user/${values}`, {});
    return json.result;
  },

  postLaudus: async values => {
    const json = await apiFetchFile("laudus/create", values);
    return json;
  },

  putAreaShow: async values => {
    const json = await apiFetchPost("areas/upd", values);
    return json;
  },

  getLaudusClient: async values => {
    const json = await apiFetchGet(
      `laudus/admin/${values.id}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  getNotifications: async () => {
    const json = await apiFetchGet(`notification/get`, {});
    return json;
  },

  getNotificationsAll: async values => {
    const json = await apiFetchGet(
      `/notification/all/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  putStatusNotification: async values => {
    const json = await apiFetchGet(`/notification/status/${values}`, {});

    return json;
  },
  getLaudusReview: async () => {
    const json = await apiFetchGet("laudus/count/review/dashboard", {});
    return json;
  },

  postUsersFirebase: async values => {
    const json = await apiFetchPost("/user", values);
    return json;
  },

  getArticles: async values => {
    const json = await apiFetchGet(
      `/resumus/esp/${values.id}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  getResumusByAdmin: async values => {
    const json = await apiFetchGet(
      `/articles-uid/${values.uid}/${values.page}/${values.status}/${
        values.limit
      }`,
      {}
    );
    return json;
  },

  postFavoriteResumus: async values => {
    const json = await apiFetchPost(`/favorite-article`, values);
    return json;
  },

  getLaudusArea: async values => {
    const json = await apiFetchGet(
      `/laudus/area/${values.id}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },
  getArticlesSubAreas: async values => {
    const json = await apiFetchPost(`/articles/subareas`, values);
    return json;
  },

  getListSubAreas: async values => {
    const json = await apiFetchGet(
      `/sub-areas/${values.specialtie}/${values.type}`,
      {}
    );
    return json[0].subareas;
  },

  putLaudus: async values => {
    const json = await apiFetchFile("/laudus/upd", values);
    return json;
  },

  putResumus: async values => {
    const json = await apiFetchFile("/resumus-edit", values);
    return json;
  },

  deleteLaudus: async values => {
    const json = await apiFetchPost("/laudus/delete", values);
    return json;
  },

  getLaudus: async ({ status, page, limit }) => {
    const json = await apiFetchGet(
      `/laudus/pagination/${status}/${page}/${limit}`,
      {}
    );
    return json;
  },

  getLaudusFilter: async ({ status, page, limit }) => {
    const json = await apiFetchGet(`/laudus/${status}/${page}/${limit}`, {});
    return json;
  },

  getResumusReview: async () => {
    const json = await apiFetchGet("/resumus-review", {});
    return json;
  },

  getResumus: async () => {
    const json = await apiFetchGet("/resumus", {});
    return json;
  },

  getResumusFilter: async values => {
    const json = await apiFetchGet(
      `/resumus/review/pagination/${values.page}/${values.status}/${
        values.limit
      }`,
      {}
    );
    return json;
  },

  getAnatomus: async values => {
    const json = await apiFetchGet(
      `/anatomus/list/${values.area}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  getAnatomusbyId: async values => {
    const json = await apiFetchGet(`/anatomus/get-by-id/${values}`, {});
    return json;
  },

  deleteAnatomus: async values => {
    const json = await apiFetchGet(
      `/anatomus/delete/${values.adminId}/${values.anatomusId}`,
      {}
    );
    return json;
  },
  getLaudusById: async valuesData => {
    const json = await apiFetchGet(
      `/laudus/select/${Number(valuesData.id)}/${Number(valuesData.clientId)}`,
      valuesData
    );
    return json;
  },

  getByReportIdLike: async values => {
    const json = await apiFetchGet(`/like-report/${values}`, {});
    return json;
  },

  postLikesReports: async values => {
    const json = await apiFetchPost(`/like-report/`, values);
    return json;
  },

  getLaudusByClient: async values => {
    const json = await apiFetchGet(
      `/laudus/edit/${values.id}/${values.clientId}`,
      {}
    );
    return json;
  },

  checkLike: async values => {
    const json = await apiFetchGet(
      `/check-like/${values.clientId}/${values.reportId}`,
      {}
    );
    return json;
  },

  deleteReportImage: async ids => {
    const json = await apiFetchPost(`/delete-image/`, ids);
    return json;
  },

  postResumus: async values => {
    const json = await apiFetchFile("/resumus", values);
    return json;
  },
  postLike: async values => {
    const json = await apiFetchPost("/like/resumus", values);
    return json;
  },
  checkLikeResumus: async values => {
    const json = await apiFetchPost("/check-like/resumus", values);
    return json;
  },

  putAnatomus: async values => {
    const json = await apiFetchFile("/anatomus/put", values);
    return json;
  },

  postAnatomus: async values => {
    const json = await apiFetchFile("/anatomus/post", values);
    return json;
  },

  postNewVarreduras: async values => {
    const json = await apiFetchFile("/anatomus/new-varreduras", values);
    return json;
  },

  postNewImagesMark: async values => {
    const json = await apiFetchFile("/anatomus/new-images-mark", values);
    return json;
  },

  postNewImagesNoMark: async values => {
    const json = await apiFetchFile("/anatomus/new-images-no-mark", values);
    return json;
  },
  // postLike: async (values) => {
  //     const json = await apiFetchPost(
  //         '/like',
  //         values
  //     );
  //     return json;
  // },

  postFavorite: async values => {
    const json = await apiFetchPost("/favorite-article", values);
    return json;
  },
  checkFavoriteResumus: async values => {
    const json = await apiFetchPost("/check-favorite", values);
    return json;
  },

  checkFavoriteReport: async values => {
    const json = await apiFetchPost("/check-favorite-report", values);
    return json;
  },

  postFavoriteLaudus: async values => {
    const json = await apiFetchPost(`/favorite-report/`, values);
    return json;
  },

  deleteResumus: async values => {
    const json = await apiFetchGet(`/resumus-dele/${values}`);
    return json;
  },

  /// Admin routes

  login: async values => {
    const json = await apiFetchPost(`/login`, values);
    return json;
  },
  // Get Laudus By idLaudus
  getLaudusByIdLaudus: async values => {
    const json = await apiFetchGet(`/laudus/view/by/${values}`, {});
    return json;
  },

  getClients: async page => {
    const json = await apiFetchGet(`/clients/${page}`, {});
    return json;
  },

  // Put Status laudus
  putStatusLaudus: async values => {
    const json = await apiFetchPost("/laudus/status/put", values);
    return json;
  },

  changeContentFree: async values => {
    const json = await apiFetchPost("/change-content-free", values);
    return json;
  },

  postNotificationPush: async values => {
    const json = await apiFetchPost("/push", values);
    return json;
  },
  getResumusByDoc: async values => {
    const json = await apiFetchGet(`/article-doc/${values}`, {});
    return json;
  },

  PutStatusResumus: async values => {
    const json = await apiFetchPost("/resumus/status/put", values);
    return json;
  },

  PostAdmin: async values => {
    const json = await apiFetchPost("/user-admin", values);
    return json;
  },

  PutAdmin: async values => {
    const json = await apiFetchPost("/user-admin/edit", values);
    return json;
  },

  getLaudusDraft: async values => {
    const json = await apiFetchGet(
      `/laudus/laudus-draft/${values.id}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  getResumusDraft: async values => {
    const json = await apiFetchGet(
      `/resumus-isdraft/${values.uid}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  // done
  groupedResumus: async () => {
    const json = await apiFetchGet("/grouped-resumus", {});
    return json.result;
  },

  // done
  getResumusGrouped: async () => {
    const json = await apiFetchGet("/get-resumus", {});
    return json.result;
  },

  // done
  getResumusByClient: async uid => {
    const json = await apiFetchGet(`/get-resumus-client/${uid}`, {});
    return json.result;
  },

  search: async search => {
    const json = await apiFetchGet(`/search/${search}`);
    return json;
  },

  getLaudusFavorite: async values => {
    const json = await apiFetchGet(
      `/laudus-saves/${values.id}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  getResumusFavorite: async values => {
    const json = await apiFetchGet(
      `/favorite-resumus/${values.uid}/${values.page}/${values.limit}`,
      {}
    );
    return json;
  },

  editResumusByAdmin: async values => {
    const json = await apiFetchFile(`/edit-admin-resumus`, values);
    return json;
  },

  editLaudusByAdmin: async values => {
    const json = await apiFetchFile(`/laudus/edit-admin`, values);
    return json;
  },

  getTrashTests: async type => {
    const json = await apiFetchGet(`/trash-tests/${type}`, {});
    return json;
  },

  deleteTrashTests: async type => {
    const json = await apiFetchPost(`/trash-tests/${type}`, {});
    return json;
  },

  toggleCommentLike: async values => {
    const json = await apiFetchPost(`/resumus/comment-toggle-like`, values);
    return json;
  },

  IsCommentAlreadyLiekd: async values => {
    const json = await apiFetchPost(`/resumus/is-comment-liked`, values);
    return json;
  },

  postCommentResumus: async values => {
    const json = await apiFetchPost(`/resumus/post-comment`, values);
    return json;
  },

  getSearchesNotFound: async filter => {
    const json = await apiFetchGet(`/searches-not-found/${filter}`);
    return json;
  },

  getAreasFilterAmount: async values => {
    const json = await apiFetchGet(`/filter-areas-amount`, {});
    return json;
  },

  getFunctionsAmount: async values => {
    const json = await apiFetchGet(`/analytics/functionalities`, {});
    return json;
  },

  getContentRanking: async filter => {
    const json = await apiFetchGet(`/analytics/content/${filter}`, {});
    return json;
  },

  saveWelcomeText: async values => {
    const json = await apiFetchPost(`/welcome-text`, values);
    return json;
  }
};

export default () => CelusApi;
