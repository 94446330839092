import React, { useState, useEffect, Fragment } from "react";
import * as S from "./style/resumus.js";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import Cookies from "js-cookie";
import celusApi from "../../server/celusApi";

const CommentCard = ({ comment, docId, reloadResumu }) => {
  const date = new Date(comment.createdAt).toLocaleDateString("pt-br");

  console.log({
    comment,
  });

  const defaultAvatar =
    "https://firebasestorage.googleapis.com/v0/b/celus-d9ab7.appspot.com/o/client%2Fdefault-profile-img.png?alt=media&token=c16e7289-b613-4014-a9f1-41def62c78e0";

  const handleLike = async () => {
    const values = {
      docId: docId,
      commentId: comment.id,
      uid: Cookies.get("clientId"),
    };
    await celusApi().toggleCommentLike(values);
    await reloadResumu();
  };

  const isLiked = comment?.likes?.includes(parseInt(Cookies.get("clientId")));
  const likesNumber = comment?.likes?.length ?? 0;

  return (
    <Fragment>
      <S.Container>
        <S.AuthorRow>
          <S.Avatar src={comment.client.avatar || defaultAvatar} />
          <S.DetailsArea>
            <S.AuthorName>{comment.client.name}</S.AuthorName>
            <S.Date>{date}</S.Date>
          </S.DetailsArea>
        </S.AuthorRow>

        <S.ContentRow>{comment.content}</S.ContentRow>

        <S.InteractionRow>
          {isLiked ? (
            <AiFillLike
              size={24}
              style={{ cursor: "pointer" }}
              onClick={() => handleLike()}
            />
          ) : (
            <AiOutlineLike
              size={24}
              style={{ cursor: "pointer" }}
              onClick={() => handleLike()}
            />
          )}

          {likesNumber}
        </S.InteractionRow>
      </S.Container>
    </Fragment>
  );
};

export default CommentCard;
