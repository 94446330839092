import React, { Fragment, useEffect } from "react";
import {
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import "./styles.scss";
import * as S from "./style/approve";

import CelusApi from "../../server/celusApi";

const ApproveSideBarResumus = () => {
  const api = CelusApi();
  const [status, setStatus] = React.useState("approved");
  const [author, setAuthor] = React.useState("");
  const [authorUid, setAuthorUid] = React.useState("");
  const { resumusDoc } = useParams();
  const [collapseOne, collapseOneOpen] = React.useState(false);
  const [collapseOneDis, collapseOneOpenDis] = React.useState(false);
  const [data, setData] = React.useState("");

  const cancel = () => {
    window.location.href = "/list/table/resumus";
  };

  const ModalStatusApproved = () => {
    return (
      <Fragment>
        <Modal
          isOpen={collapseOne}
          toggle={() => collapseOneOpen(!collapseOne)}
        >
          <ModalHeader>Publicar ResumUS?</ModalHeader>
          <ModalBody>
            Você tem certeza de que gostaria de publicar esse ResumUS? Ao clicar
            em sim, ele ficará disponível no aplicativo para todos os usuários.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => collapseOneOpen(!collapseOne)}>Não</Button>
            <Button color="primary" onClick={PutStatusResumus}>
              Sim
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };
  const ModalStatusDisapproved = () => {
    return (
      <Fragment>
        <Modal
          isOpen={collapseOneDis}
          toggle={() => collapseOneOpenDis(!collapseOneDis)}
        >
          <ModalHeader>Reprovar ResumUS?</ModalHeader>
          <ModalBody>
            Você tem certeza de que gostaria de reprovar este ResumUS? Ao clicar
            em sim o resumUS não será publicado e o autor receberá uma
            notificação.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => collapseOneOpenDis(!collapseOneDis)}>
              Não
            </Button>
            <Button color="primary" onClick={PutStatusResumus}>
              Sim
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };

  useEffect(() => {
    getResumusByDoc();
  }, []);

  const getResumusByDoc = async () => {
    try {
      const res = await api.getResumusByDoc(resumusDoc);
      let aux = res.result;
      setAuthor(aux.author.name);
      setAuthorUid(aux.author.id);
      setStatus(aux.status);

      console.log({
        aux,
      });

      const meses = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const unixTime = aux.createdAt;
      const auxData = new Date(unixTime);
      let dataFormatada =
        auxData.getDate() +
        " " +
        meses[auxData.getMonth()] +
        " " +
        auxData.getFullYear();
      setData(dataFormatada);
    } catch (error) {
      console.log(error);
    }
  };

  const PutStatusResumus = async () => {
    try {
      const values = {
        status: status,
        doc: resumusDoc,
        uid: authorUid,
      };
      await api.PutStatusResumus(values);
      window.location.href = "/list/table/resumus";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <S.Container>
      <S.HeaderArea>
        <S.HeaderTitle>Publicar / Reprovar</S.HeaderTitle>
      </S.HeaderArea>
      <S.Body>
        <S.Line>
          <S.DefaultText>Status: </S.DefaultText>
          <S.StatusSelect
            type="select"
            name="select"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            value={status}
          >
            <option value="" disabled selected hidden>
              Status
            </option>
            <option value="approved">Aprovado</option>
            <option value="disapproved">Reprovado</option>
          </S.StatusSelect>
        </S.Line>
        <S.Line>
          <S.DefaultText>Enviado em: </S.DefaultText>
          <S.DefaultBold>{data}</S.DefaultBold>
        </S.Line>
        <S.Line>
          <S.DefaultText>Autor: </S.DefaultText>
          <S.DefaultBold>{author}</S.DefaultBold>
        </S.Line>
      </S.Body>
      <S.Bottom>
        <S.CancelBtn onClick={cancel}>Cancelar</S.CancelBtn>

        {status === "approved" ? (
          <S.PublishBtn
            style={{ background: "#31AF89" }}
            onClick={() => collapseOneOpen(!collapseOne)}
          >
            Publicar
          </S.PublishBtn>
        ) : status === "disapproved" ? (
          <S.PublishBtn
            style={{ background: "#FF6766" }}
            onClick={() => collapseOneOpenDis(!collapseOneDis)}
          >
            Reprovar
          </S.PublishBtn>
        ) : null}
      </S.Bottom>
      {ModalStatusApproved()}
      {ModalStatusDisapproved()}
    </S.Container>
  );
};

export default ApproveSideBarResumus;
