import React, { useReducer } from 'react';
import { FeedContext } from '../../context/Context';
import { arrayReducer } from '../../reducers/arrayReducer';
 
const FeedProvider = ({ children }) => {
   const [feeds, feedDispatch] = useReducer(arrayReducer);


  return <FeedContext.Provider value={{ feeds, feedDispatch }}>{children}</FeedContext.Provider>;
};

export default FeedProvider;
