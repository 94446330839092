import styled from "styled-components";

export const AreaRef = styled.div`
    width: 100%;

    border: 1px solid #DDD;
    border-radius: 5px;

    padding: 5px;

`;

export const InputArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const Label = styled.label`
    margin-top: 8px;
    margin-bottom: 10px;
`;

export const Btn = styled.button`
    border-radius: 5px;
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};

    border: none;

    background-color:#31AF89;

    transition: 0.5s;

    :focus{
       outline:none;
       overflow: none;
    }

    :hover{
        opacity: 0.8;
    }
`;

export const IndexRef = styled.span`

    background-color: #31AF89;
    color: #FFF;

    border-radius: 5px;

    margin-right: 5px;
    height: 30px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SpanItem = styled.input`
     width: 100%;

border: 1px solid #DDD;

padding: 2px 10px;

:focus{
   outline:none;
   overflow: none;
}

margin-right: 5px;
`;



export const InputRef = styled.input`
    width: 100%;

    border: 1px solid #DDD;

    padding: 2px 10px;

    :focus{
       outline:none;
       overflow: none;
    }

    margin-right: 5px;


`;

export const RefContent = styled.div`

`;

export const RefItem = styled.div`
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
