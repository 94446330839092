import React, { Fragment, useEffect, useState } from 'react';
import DashboardItens from './DashboardItens';
import CelusApi from '../../server/celusApi';
import { useParams } from 'react-router-dom';

const DashboardSpecialties = () => {
    // State

    const api = CelusApi();
    const [results, setResults] = useState([]);

    const { type } = useParams();

    useEffect(() => {
        const handlerAreas = async () => {
            var res = await api.listAreas(type.toLowerCase());
            setResults(res);
        }
        handlerAreas();
    }, [api]);

    return (
        <Fragment>
            <h1 style={{ marginLeft: 12, fontSize: 19, marginBottom: 30, fontWeight: 'bold' }}>Especilidades</h1>
            <div className="d-flex flex-wrap">
                {
                    results.map((item) => {
                        return (<DashboardItens
                            toRoutes={`/us/${type.toLowerCase()}/${item.name}/${item.id}`}
                            icon={`${item.icon}`}
                            title={`${item.name}`}
                        />
                        )
                    })
                }
            </div>
        </Fragment>
    );
};

export default DashboardSpecialties;
