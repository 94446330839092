import React from 'react';
import { Row, Col} from 'reactstrap';
import EditLaudus from '../feed/EditLaudus';
import FeedSideBar from '../feedUS/FeedSideBar';

const FormsLaudusEdit = () => {

  return (
    <>
      <Row>
        <Col lg={9} className="pl-lg-2">
          <EditLaudus />
        </Col>
        <Col lg={3} className="pr-lg-2 mb-3 mb-lg-0">
          <FeedSideBar />
        </Col>
      </Row>

    </>
  );
};

export default FormsLaudusEdit;
