import React, { Fragment, useEffect, useState } from "react";
import DashboardItens from "./DashboardItens";
import {
  LaudUS,
  ResumUS,
  AnatomUS,
  Protoculus,
} from "../../assets/img/icons/celus";
import DashboardItensReview from "./DashboardItensReview";

import CelusApi from "../../server/celusApi";

const Dashboard = () => {
  const api = CelusApi();

  const [reviewLaudus, setReviewLaudus] = useState([]);
  const [reviewResumus, setReviewResumus] = useState([]);

  useEffect(() => {
    const GetLaudusReview = async () => {
      let result = await api.getLaudusReview();
      setReviewLaudus(result.result);
    };
    GetLaudusReview();
  }, [api]);

  useEffect(() => {
    const GetResumusReview = async () => {
      let result = await api.getResumusReview();
      setReviewResumus(result.result);
    };
    GetResumusReview();
  }, [api]);

  return (
    <Fragment>
      <h5 className="mb-3">Revisar</h5>
      <div className="d-flex flex-wrap mb-4">
        <DashboardItensReview
          toRoutes="/list/table/laudus"
          title="LaudUS"
          icon={LaudUS}
          cont={
            reviewLaudus.laudus > 9
              ? reviewLaudus.laudus
              : "0" + reviewLaudus.laudus
          }
          approved={
            reviewLaudus.approved > 9
              ? reviewLaudus.approved
              : "0" + reviewLaudus.approved
          }
          disapproved={
            reviewLaudus.disapproved > 9
              ? reviewLaudus.disapproved
              : "0" + reviewLaudus.disapproved
          }
          waiting={
            reviewLaudus.waiting > 9
              ? reviewLaudus.waiting
              : "0" + reviewLaudus.waiting
          }
        />
        <DashboardItensReview
          toRoutes="/list/table/resumus"
          title="ResumUS"
          icon={ResumUS}
          cont={
            reviewResumus.resumus > 9
              ? reviewResumus.resumus
              : "0" + reviewResumus.resumus
          }
          approved={
            reviewResumus.approved > 9
              ? reviewResumus.approved
              : "0" + reviewResumus.approved
          }
          disapproved={
            reviewResumus.disapproved > 9
              ? reviewResumus.disapproved
              : "0" + reviewResumus.disapproved
          }
          waiting={
            reviewResumus.waiting > 9
              ? reviewResumus.waiting
              : "0" + reviewResumus.waiting
          }
        />
      </div>

      <h5 className="mb-3">Visualizar</h5>
      <div className="d-flex flex-wrap">
        <DashboardItens
          toRoutes="/especialidades/laudus"
          icon={LaudUS}
          title="LaudUS"
        />

        <DashboardItens
          toRoutes={`/especialidades/resumus`}
          icon={ResumUS}
          title="ResumUS"
        />

        {/*<DashboardItens*/}
        {/*  toRoutes={`/anatomus/new`}*/}
        {/*  icon={AnatomUS}*/}
        {/*  title='AnatomUS'*/}
        {/*/>*/}

        <DashboardItens
          toRoutes={`/protocolus`}
          icon={Protoculus}
          title="ProtocolUS"
        />
      </div>
    </Fragment>
  );
};

export default Dashboard;
