import React, { useState } from 'react';
import {FaRegBookmark} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Dropdown } from 'reactstrap';

const Saved = () => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <Link to="/salvos"> 
        <FaRegBookmark transform="shrink-4" className="fs-2 footer-icon" color='#3C3C3C'/>
      </Link>
    </Dropdown>
  );
};

export default Saved;
