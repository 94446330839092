import React, { Fragment, useEffect, useState } from 'react';
import FeedCard from './FeedCard';
import CelusApi from '../../server/celusApi';
import { useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const FeedContent = () => {

  const api = CelusApi();
  const { id } = useParams();
  const [values, setValues] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const perPage = 10;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLaudusList = async () => {
      setLoading(true);
      let values = { id: parseInt(id), page: currentPage, limit: 10 }
      let res = await api.getLaudusArea(values);
      setValues(res.result.laudus.rows);
      setTotalPage(Math.ceil(res.result.laudus.count / perPage));
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }

    getLaudusList()
  }, [currentPage]);


  const handlePageChange = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <Fragment>

      <FeedCard values={values} loading={loading} />

      {
        values ? (
          <div className="pag-table">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageChange}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        ) : null
      }


    </Fragment>
  );
};

export default FeedContent;
