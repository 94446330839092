import React, { Fragment, useEffect, useState } from 'react';
import * as S from './styled';
import { FaBookmark as SaveFilled, FaRegBookmark as SaveOutline, FaShareAlt } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import celusApi from '../../server/celusApi';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const ProtoculusItem = ({ values, admin, id }) => {

    console.log(values, admin, id)
    const history = useHistory()
    const [isSave, setIsSave] = useState(false);
   
    const api = celusApi();

    const goToProtoculus = () => {
        history.push(`/view/protocolus/${id}/${encodeURI(values.contents[0].title)}`);
    }

    useEffect(() => {
        const handlerCheckedFavoriteProtoculus = async () => {
            try {
                let data = { id: id, clientId: Cookies.get('clientId') }
                const result = await api.CheckedFavoriteProtoculus(data);
                setIsSave(result);
   
            } catch (error) {
                console.log(error);
            }
        }
        handlerCheckedFavoriteProtoculus();
    }, [values]);

   

    const handlerFavoriteProtoculus = async () => {
        try {
            let data = { id: id, clientId: Cookies.get('clientId') }
            await api.FavoriteProtoculus(data);
            setIsSave(!isSave);

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Fragment>
            <S.CardItem>
                <S.ImagePreview src={ values.contents[0]?.sections[0]?.images[0]?.src} onClick={() => goToProtoculus()} />

                <S.CardInfo>
                    <S.Title onClick={() => goToProtoculus()} >{values.contents[0].title}</S.Title>
                    <S.RowInfo>

                        <S.Author>{admin.name}</S.Author>

                        <S.CardFunctions>
                            {
                                isSave ? (<SaveFilled onClick={() => handlerFavoriteProtoculus()} size={20} color="#FFF" />) : (<SaveOutline onClick={() => handlerFavoriteProtoculus()} size={20} color="#FFF" />)
                            }
                            <FaShareAlt size={20} color="#FFF"
                                onClick={async (event) => {
                                    if (navigator.share !== undefined) {
                                        event.preventDefault();
                                        await navigator.share({
                                            title: 'Celus - ProtocolUS',
                                            text: values.contents[0].title,
                                            url: `https://salus-celus.com.br/view/protocolus/${values.contents[0].title}/${id}`
                                        })
                                    } else {
                                        navigator.clipboard.writeText(`https://salus-celus.com.br/view/protocolus/${values.contents[0].title}/${id}`)
                                        toast.success('Link copiado para a área de transferência.');
                                    }
                                }} />
                        </S.CardFunctions>

                    </S.RowInfo>
                </S.CardInfo>

            </S.CardItem>
        </Fragment>
    )
}
