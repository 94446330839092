import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import '../custon/custonDashboard.css';
import './dashboard.scss';
import CountUp from 'react-countup';

const DashboardItensReview = ({ toRoutes, icon, title, cont, approved, disapproved, waiting }) => {

    return (
        <>
            <div className="p-2 mr-3  review-card">
                <div style={{ width: '80%', height: '100%' }}>

                    <div className="review-title">
                        <h5>{title}</h5>
                        <strong>
                            <CountUp
                                end={parseInt(cont)}
                                duration={2}
                                separator=","
                                decimal="."
                            />
                        </strong>
                    </div>

                    <div>
                        <div className="review-status ">
                            <strong className="approved">
                                <CountUp
                                    end={parseInt(approved)}
                                    duration={2}
                                    separator=","
                                    decimal="."
                                />
                            </strong>
                            <p>Aprovados</p>
                        </div>

                        <div className="review-status ">
                            <strong className="disapproved">
                                <CountUp
                                    end={parseInt(disapproved)}
                                    duration={2}
                                    separator=","
                                    decimal="."
                                />
                            </strong>
                            <p>Reprovados</p>
                        </div>
                        <div className="review-status">
                            <strong className="waiting">
                                <CountUp
                                    end={parseInt(waiting)}
                                    duration={2}
                                    separator=","
                                    decimal="."
                                />
                            </strong>
                            <p>Aguardando Aprovação</p>
                        </div>

                        <div className="review-link">
                            <Link to={toRoutes}>Ver todos {title} <FaAngleRight style={{ marginLeft: 5 }} size="20" /> </Link>
                        </div>

                    </div>
                </div>

                <div className="content-img">
                    <img src={icon} className="icon_card" />
                </div>

            </div>
        </>
    )
}

export default DashboardItensReview;