import styled from 'styled-components';
import QuillEditor from '../../../common/QuillEditor';
import { FaPlus } from 'react-icons/fa';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

export const AreaLabels = styled.div`
  width: 81%;
  height: 150px;
  max-height: 500px;

  background-color: #ffff;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  border: 1px dashed #ddd;
`;

export const ListLabels = styled.div`
  padding: 0 10px;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const H1 = styled.p`
  margin: 0;
  padding: 0;

  font-size: 20px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 30px;
  border-bottom: solid 1px #ccc;
`;

export const Title = styled.h3`
  font-size: 19px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 0;
`;

export const AreasSubAreas = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IFormGroup = styled.div`
  width: 81%;
`;
export const errorMessage = styled.p`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #ff6766;
  font-size: 14px;
  padding: 0;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  color: #3c3c3c;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 5px;
`;

export const Required = styled.span`
  color: #ff6766;
  font-weight: 600;
`;

export const Select = styled.select`
  width: 273px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 5px;
`;

export const Option = styled.option``;

export const VarredurasArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const VarredurasOptionsArea = styled.div`
  display: flex;
  align-items: flex-end;
  height: 45px;
`;

export const VarreduraOptionBtn = styled.button`
  border: 1px solid #d9d9d9;
  border-bottom: 0px;
  width: 133px;
  height: 38px;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  outline: none !important;
  transition: all ease 0.2s;

  background-color: ${props => props.isActive && '#fff'};
  height: ${props => props.isActive && '45px'};

  :hover {
    height: 45px;
    background-color: #fff;
  }
`;

export const BorderDiv = styled.div`
  border: 1px solid #d9d9d9;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const Instruction = styled.span`
  color: #3c3c3c;
  font-size: 16px;
  font-weight: normal;
`;

export const FramesArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const ContentArea = styled.div`
  margin-right: 10px;
  width: 100%;
  height: 100%;
  background-color: #edf2f9;
  border: 1px solid #739ad1;
  border-style: dashed;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 5px;

`;

export const ContentAreaBottom = styled.div`
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3px;
`;

export const AddNewFrame = styled.div`
  width: 105px;
  height: 94px;
  border: 1px solid #739ad1;
  border-style: dashed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PlusIcon = styled(FaPlus)`
  color: #3e9cc7;
  font-size: 35px;
`;

export const PickerArea = styled.div`
  width: 25%;
  height: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
`;

export const TransdutorArea = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #00000029;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TransdutorImg = styled.img`
  height: auto;
  max-width: 130px;
  max-height: 130px;
`;

export const TextEditor = styled(QuillEditor)`
  height: 300px;
  max-width: 894px;
  border: 1px solid #d9d9d9;
`;

export const BottomArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 15px;
`;

export const CancelBtn = styled.button`
  width: 113px;
  padding: 5px 0px;
  background: #ebebeb;
  border: 1px solid #9f9f9f;
  color: #9f9f9f;
  border-radius: 5px;
  cursor: pointer;
`;

export const PublishBtn = styled.button`
  width: 113px;
  padding: 5px 0px;
  background: #50d19a;
  border: 1px solid #31af89;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;

  transition: 0.5s;

  :hover {
    opacity: 0.8;
  }
`;

export const MarkArea = styled(TransdutorArea)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IModal = styled(Modal)`
  max-width: 90%;
  min-height: 500px;
`;
export const IModalBody = styled(ModalBody)`
  width: 100%;
`;
export const IModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
export const IModalHeader = styled(ModalHeader)``;

export const IButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 0;
  width: 50px;
  height: 100%;
  transition: 0.5s;

  border-radius: ${({ radius }) =>
    radius === 'left' ? '5px 0 0 5px' : radius === 'top' ? '0 5px 0 0' : radius === 'botton' ? '0 0 5px 0' : '0 0 0 0'};
  :hover {
    background-color: rgba(6, 193, 129, 1);
  }
`;

export const ImageList = styled.img`
  width: 100px;
  height: 120px;

  filter: grayscale(100%);
  transition: 0.5s;
  :hover {
    cursor: pointer;
    filter: grayscale(0);
  }
`;

export const HeaderLabels = styled.div`
  width: 100%;
  height: 45px;
  background-color: #f0f0f0;
  padding: 5px 10px;

  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const MarkPoint = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: ${props => (props.color ? props.color : '#31AF89')};
  border-radius: 50% 50% 50% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: '#FFF';
  font-weight: 600;
  cursor: pointer;
  border: 1px solid rgba(126, 126, 126, 1);
`;

export const Hr = styled.div`
  width: 81%;
  height: 1px;
  margin-top: 10px;
  background-color: #ccc;
`;
