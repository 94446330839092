import React, { Fragment, useRef } from "react";
import { Editor as EditorLib } from '@tinymce/tinymce-react';

export const Editor = ({ setValues = Function(), values }) => {
    const editorRef = useRef(null);

    return (
        <Fragment>
            <input id="my-file" type="file" name="my-file" accept="image/png, image/jpeg, image/jpg" style={{ display: 'none' }} onChange="" />
            <EditorLib
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                    height: 500,
                    menubar: 'file edit view format tools tc',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code',
                        'image imagetools',
                        'image',
                        'importcss',
                        'preview',
                        'print',
                        'media'
                    ],
                    language: 'pt_BR',
                    selector: 'textarea',
                    branding: false,
                    image_uploadtab: false,
                    file_picker_types: 'file image media',
                    image_uploadtab: false,
                    toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | image |' +
                        'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
                        'removeformat | help | print | preview | importcss | ',
                    content_css: '//www.tinymce.com/css/codepen.min.css',
                    file_browser_callback_types: 'image',
                    file_picker_callback: function (callback, value, meta) {

                        if (meta.filetype === 'image') {
                            var input = document.getElementById('my-file');
                            input.click();
                            input.onchange = function (e) {

                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    callback(e.target.result, {
                                        alt: file.name
                                    });
                                };
                                reader.readAsDataURL(file);
                                e.preventDefault();
                            };
                        }

                    },
                    paste_data_images: true,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={values}
                onEditorChange={newText => setValues(newText)}
            />
        </Fragment>
    )
}