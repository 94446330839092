import React, { Fragment, useState } from 'react';
import ReactDevicePreview from 'react-device-preview';
import { DeviceFrameset } from 'react-device-frameset';
import * as S from './styled';
import ReactHtmlParser from 'react-html-parser';

const PreviewMobileLaudus = ({images, title, description, conclusion}) => {
  const [currentIndexImage, setCurrentIndexImage] = useState(0);

  const descFormat = description.replaceAll("<img ", "<img class='img' ");

  const descFormat2 = descFormat.replaceAll("<p>", "<p class='p'>");

  const descFormat3 = descFormat2.replaceAll("<p ", "<p class='p' ");

  const conclusionFormat = conclusion.replaceAll("<img ", "<img class='img' ");

  const conclusionFormat2 = conclusionFormat.replaceAll("<p>", "<p class='p'>");

  const conclusionFormat3 = conclusionFormat2.replaceAll("<p ", "<p class='p' ");


  return (
    <DeviceFrameset device="iPhone X" color="black">
      <S.Container>
        <S.StatusBar />
        <S.Header>
          LaudUS
        </S.Header>

        <S.Content>
          {
            images.length > 0 && (
              <Fragment>
                <S.currentImage src={images[currentIndexImage]?.data_url} />
                <S.ImagesCarouselArea>
                  {
                    images.map((image, index) => (
                      <S.CaroulselImage src={image?.data_url} onClick={() => setCurrentIndexImage(index)} />
                    ))
                  }
                </S.ImagesCarouselArea>
              </Fragment>
            )
          }

          <S.Title>{title || 'SEU TÍTULO AQUI'}</S.Title>

          <S.ContentTitle>Descrição</S.ContentTitle>
          <S.ContentHtml>
            { descFormat3.length > 0 ? ReactHtmlParser(descFormat3) : 'SUA DESCRIÇÃO AQUI' }
          </S.ContentHtml>
          
          <S.ContentTitle>Conclusão</S.ContentTitle>
          <S.ContentHtml style={{ paddingBottom: '30px' }}>
            { conclusionFormat3.length > 0 ? ReactHtmlParser(conclusionFormat3) : 'SUA CONCLUSÃO AQUI' }
          </S.ContentHtml>
        </S.Content>
      </S.Container>
    </DeviceFrameset>
  )
}

export default PreviewMobileLaudus;
