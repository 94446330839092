import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import { CommentUpdateProvider } from './hooks/useCommentUpdate';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { socket } from './server/socket';

const App = () => {

  useEffect(() => {
    iniSocket();
  },[]);

  const ws = useRef(null);

  const iniSocket = () => {
    ws.current = socket();
    ws.current.on('connection', () => { });
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <CommentUpdateProvider>
        <Layout />
      </CommentUpdateProvider>
    </Router>
  );
};

export default App;
