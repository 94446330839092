import React, { Fragment } from 'react';
import FeedCard from './FeedCard';

const FeedContent = () => {
  return (
    <Fragment>
      <FeedCard className="mb-3"/>
    </Fragment>
  );
};

export default FeedContent;
