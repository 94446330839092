import React, { Fragment, useEffect, useState } from 'react';
import { Card, InputGroup, Input, CardHeader } from 'reactstrap';
import CelusApi from '../../server/celusApi';
import './styles.scss';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import * as S from './styles.js';

export const SearchesNotFound = () => {
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [words, setWords] = useState();
    const api = CelusApi();

    useEffect(() => {
        const getWords = async () => {
            try {
                const response = await api.getSearchesNotFound(filter);

                console.log(response.result)
                setWords(response.result)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
            
        }
        getWords();
    }, [filter]);

    const options = {
        rotations: 2,
        rotationAngles: [0, 0],
        scale: "sqrt",
        fontFamily: "impact",
        fontSizes: [30, 80],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 10,
    };

    return (
        !loading ? (
            <Fragment>
                <Card>
                    <CardHeader style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h5 style={{ marginBottom: '20px' }} className="mb-0">Termos sem resposta mais pesquisados</h5>
                        <InputGroup style={{ width: '15%' }}>
                            <Input type="select" placeholder="Filtrar" onChange={e => { setFilter(e.target.value.toLocaleLowerCase()) }}>
                                <option className='option' value="all">Todos</option>
                                <option className='option' value="last-month">Ultimo mês</option>
                            </Input>
                        </InputGroup>
                    </CardHeader>


                    <S.WordsArea>
                        {
                            <ReactWordcloud words={words} options={options} />
                        }
                    </S.WordsArea>

                </Card>
            </Fragment>
        ) : null
    )
}
