import styled from 'styled-components';
import { Button, Label } from 'reactstrap';
import { ImCheckmark } from "react-icons/im";
import { AiOutlineQuestionCircle as InfoIcon } from "react-icons/ai";

export const DraftBtn = styled(Button)`
  margin-right: 15px;
  background: #EBEBEB;
  border: none;
  color: #9F9F9F;

  :hover{
    background: #EBEBEB !important;
    opacity: 0.7;
    color: #9F9F9F !important;
  }
`;

export const InfoButton = styled(InfoIcon)`
  color: #9F9F9F;
  margin-left: 3px;
  /* margin-left: 8px;
  background: #EBEBEB;
  border: 1px solid #9F9F9F;
  color: #9F9F9F;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 2px;

  :hover{
    background: #EBEBEB !important;
    opacity: 0.7;
    color: #9F9F9F !important;
  } */
`;

export const PublishBtn = styled(Button)`
  background: #50D19A;
  border: none;
  :hover{
    background: #50D19A !important;
    opacity: 0.7;
    color: #fff !important;
  }
`;

export const DeleteBtn = styled.div`
  margin-right: 10; 
  color: #9F9F9F; 
  background: #FF6766; 
  color: #FAFAFA; 
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 15px;
  cursor: pointer;
  :hover{
    opacity: 0.7;
    color: #fff !important;
  }
`;

export const PreviewMobileBtn = styled.div`
  margin-right: 10; 
  color: #fff; 
  background: #2878e0; 
  color: #FAFAFA; 
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 15px;
  cursor: pointer;
  transition: all ease 0.3s;
  opacity: ${props => props.isActive ? 1 : 0.3};
  :hover{
    opacity: 1;
  }
`;

export const PreviwArea = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

export const SelectImage = styled.label`
  width: 50%;
  height: 100%;
  border-radius: 5px 0 0 10px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px dashed #739AD1;
  cursor: pointer;
  margin: 0;
  
`;

export const Up = styled.img`
  width: 50px;
  object-fit: cover;
`;

export const PreviwAreaImage = styled.div`
  width: 50%;
  height: 100%;

  border-radius: 0 10px 10px 0;
  border: 1px dashed #739AD1;
`;

export const UpLabel = styled.p`
  margin: 0;
  padding: 0;

  font-size: 14px;
  color: #000;


`;

export const ImagemPreviw = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  border: none;
  object-fit: cover;
`;


export const LabelForm = styled(Label)`
  font-size: 16px;
  color: #3C3C3C;
  display: flex;
  align-items: center;
`

export const ValidateModalRow = styled.div`
  height: 20px;
  display: flex;
  margin-bottom: 3px;
  justify-content: flex-start;
  align-items: center;
`;

export const CheckIcon = styled(ImCheckmark)`
  color: ${props => props.checked ? '#31AF89' : '#ccc'};
  margin-right: 5px;
`;

export const errorMessage = styled.p`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #FF6766;
  font-size: 14px;
  padding: 0;
`;

export const Mandatory = styled.samp`
  color: #FF6766;
  font-weight: 600;
`;

export const ImageItem = styled.div`
  background-color: 'red;
`;
