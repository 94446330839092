import React from "react";
import ContentWithAsideLayout from "../../layouts/ContentWithAsideLayout";
import FeedContent from "./FeedContent";
import FeedSideBarResumus from "../feedUS/FeedSideBarResumus";
import FeedProvider from "./FeedProvider";
import NavFeed from "./NavFeed";

export const MyResumUS = ({ draft }) => {
  console.log({ draft });
  return (
    <FeedProvider style={{ margin: 0 }}>
      <ContentWithAsideLayout
        aside={<FeedSideBarResumus />}
        isStickyAside={false}
      >
        <NavFeed />
        <FeedContent draft={draft} />
      </ContentWithAsideLayout>
    </FeedProvider>
  );
};
