import styled from "styled-components";


export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const StatusBar = styled.div`
  width: 100%;
  height: 20px;
  background: #000;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to right, #31af89 , #50d199);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fcfcfc;
  padding: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h3`
  width: 100%;
  color: #010101;
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
`;

export const ContentTitle = styled.h3`
  width: 100%;
  color: #50d199;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const currentImage = styled.img`
  width: 100%;
  height: 180px;
  border-radius: 5px;
`;

export const ContentHtml = styled.div`
  width: 100%;
  color: #010101;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .img{
    max-height: 250px;
  }

  .p{
    width: 100%;
  }
`;

export const ImagesCarouselArea = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CaroulselImage = styled.img`
  width: 80px;
  height: 40px;
  margin-right: 15px;
  cursor: pointer;
`;
