import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import Avatar from '../common/Avatar';
import CelusApi from '../../server/celusApi';
import { toast } from 'react-toastify';

import * as S from './styles.js';
import { User } from '../../assets/img/icons/celus';
import ReactPaginate from 'react-paginate';
import { LoadingCard } from '../LoadingCard';

export const ViewNotifications = () => {

    const [values, setVelus] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [currentPage, setCurrentPage] = useState(0)
    const perPage = 10;
    const [loading, setLoading] = useState(true);

    const api = CelusApi();

    useEffect(() => {
        GetNotifications()
    }, [currentPage]);

    const putStatusNotification = async (values) => {
        try {
            await api.putStatusNotification(values);
        } catch (error) {
            console.log(error);
        }
    }

    const GetNotifications = async () => {
        try {
            setLoading(true);
            let values = { page: currentPage, limit: 10 }
            const result = await api.getNotificationsAll(values);
            setVelus(result.result.notification.rows);
            setTotalPage(Math.ceil(result.result.notification.count / perPage));
            setTimeout(() => {
                setLoading(false);
            }, 500);

        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            toast.error('Não foi possivel listar as notificações!');
            console.log(error)
        }
    }

    const DateNotification = (date) => {
        const altData = new Date();
        const Data = new Date(date);
        return Math.round((Math.abs(altData - Data) / (1000 * 3600 * 24)));
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1);
    };

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Notificações
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    {

                        loading ? (
                            <Fragment>
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                                <LoadingCard />
                            </Fragment>
                        ) : (

                            <Fragment>
                                {
                                    values.map((item, index) => {
                                        return (
                                            <S.NotificationCard backgroundColor={item.status === 0 ? '#fafafa' : '#FFF'} onClick={() => {
                                                putStatusNotification(item.id)
                                                window.location.href = item.notificationAdminLaudus.length > 0 ? `/laudus/review/${item.notificationAdminLaudus[0].idLaudus}` : item.notificationAdminResumus.length > 0 ? `/resumus/review/${item.notificationAdminResumus[0].idResumus}` : null
                                            }} >
                                                {
                                                    item.status === 0 ? (
                                                        <Fragment>
                                                            <S.NotoficationTrue>
                                                                <S.NotoficationCircle />
                                                            </S.NotoficationTrue>
                                                        </Fragment>
                                                    ) : null
                                                }
                                                <S.ContentNotification>
                                                    <div>
                                                        <Avatar src={item?.client?.avatar ? item?.client?.avatar : User} />
                                                    </div>

                                                    <S.Description dangerouslySetInnerHTML={{ __html: item.content + " Clique para revisar." }} />
                                                </S.ContentNotification>

                                                <S.TypeDate>
                                                    {DateNotification(item.createdAt) <= 1 ? "Hoje - " : "Há " + DateNotification(item.createdAt) + " dias - "}  {item.notificationAdminLaudus.length > 0 ? 'LaudUS' : item.notificationAdminResumus.length > 0 ? 'ResumUS' : null}
                                                </S.TypeDate>
                                            </S.NotificationCard>
                                        )
                                    })
                                }
                            </Fragment>
                        )

                    }
                </CardBody>
                <CardFooter>
                    <div className="pag-table">
                        <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageChange}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={totalPage}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </CardFooter>
            </Card>
        </Fragment>
    )
}
