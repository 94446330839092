import React, { Fragment, useEffect, useState } from 'react';
import { Card, InputGroup, Input, CardHeader } from 'reactstrap';
import CelusApi from '../../server/celusApi';
import './styles.scss';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { Doughnut, Bar } from 'react-chartjs-2';

import * as S from './styles.js';
import celusApi from '../../server/celusApi';

export const Functionalities = () => {
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [functionalities, setFunctionalities] = useState();
    const api = CelusApi();

    useEffect(() => {
        getFunctionsAmount()
    }, []);

    const getFunctionsAmount = async () => {
        try {
            const response = await celusApi().getFunctionsAmount({});
            setFunctionalities(response.result);
            console.log('FUNCIONALIDADES -> ', response.result)
         } catch (error) {
             console.log(error)
         } finally {
             setLoading(false);
         }
    }

    const backgrounds = [
        '#845EC2', 
        '#D65DB1', 
        '#FF6F91', 
        '#FF9671', 
        '#FFC75F', 
        '#F9F871', 
        '#0097B2', 
        '#0087C2', 
        '#0074C7', 
        '#005CBD', 
        '#CAFBE7', 
        '#6E7698',
        '#1D1D35',
        '#DEB887',
        '#3E5B42'
    ];

    return (
        !loading ? (
            <Fragment>
                <Card>
                    <CardHeader style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h5 style={{ marginBottom: '20px' }} className="mb-0">Funcionalidades mais usadas no app</h5>
                        {/* <InputGroup style={{ width: '10%' }}>
                            <Input type="select" placeholder="Filtrar" onChange={e => { setFilter(e.target.value.toLocaleLowerCase()) }}>
                                <option className='option' value="" disabled selected hidden>Filtrar</option >
                                <option className='option' value="all">Todas</option>
                                <option className='option' value="last-month">Ultimo mês</option>
                            </Input>
                        </InputGroup> */}
                    </CardHeader>


                    <S.Area>
                        <Doughnut 
                            data={{
                                labels:  functionalities.map(item => item?.name),
                                datasets: [
                                {
                                    backgroundColor: backgrounds.slice(0, functionalities.length),
                                    label: 'Funcionalidades',
                                    data: functionalities.map(item => item?.lives),
                                },
                                ]
                            }} 
                            width={100}
                            height={50}
                        />
                    </S.Area>

                </Card>
            </Fragment>
        ) : null
    )
}
