import React, { Fragment } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./styles.scss";

export const LoadingTable = () => {
    return (
        <Fragment>
                <tr>
                    <td style={{ margin: 0 }}>
                        <div className="row__itens">
                            <Skeleton width={30} height={30} circle style={{ marginRight: 20 }} />
                            <Skeleton width={200} height={25} />
                        </div>
                    </td>
                    <td>
                        <div className="item">
                            <Skeleton width={200} height={25} />
                        </div>
                    </td>
                    <td>
                        <div className="item">
                            <Skeleton width={200} height={25} />
                        </div>
                    </td>
                    <td>  <div className="item">
                        <Skeleton width={200} height={25} />
                    </div>
                    </td>
                    <td>
                        <div className="item">
                            <Skeleton width={40} height={25} />
                        </div>
                    </td>
                </tr>
        </Fragment>
    )
}
