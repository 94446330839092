import React, { Fragment, useEffect, useState } from 'react';
import { Card, InputGroup, Input, CardHeader } from 'reactstrap';
import CelusApi from '../../server/celusApi';
import './styles.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { Doughnut, Bar } from 'react-chartjs-2';
import * as S from './styles.js';
import celusApi from '../../server/celusApi';

export const FilterAreasAmount = () => {
    const [loading, setLoading] = useState(true);
    const [areas, setAreas] = useState([]);
    // const [filter, setFilter] = useState('all');
    // const [words, setWords] = useState();
    const api = CelusApi();

    useEffect(() => {
        getAreasAmount()
    }, []);

    const getAreasAmount = async () => {
        try {
            const response = await celusApi().getAreasFilterAmount({});
            setAreas(response.result)
            console.log('AREEEAAAS -> ', response.result.filter(item => item.area))
         } catch (error) {
             console.log(error)
         } finally {
             setLoading(false);
         }
    }

    const backgrounds = [
        '#31AF89', 
        '#009B8D', 
        '#008689', 
        '#0D717F', 
        '#265C6E', 
        '#2F4858', 
        '#0097B2', 
        '#0087C2', 
        '#0074C7', 
        '#005CBD', 
        '#CAFBE7', 
        '#6E7698',
        '#1D1D35',
        '#DEB887',
        '#3E5B42'
    ];

    return (
        !loading ? (
            <Fragment>
                <Card>
                    <CardHeader>
                        <Fragment>
                            <h5 style={{ marginBottom: '20px' }} className="mb-0">Ranking de especialidades mais filtradas</h5>
                        </Fragment>
                    </CardHeader>

                    <S.ContentArea>
                        <Doughnut 
                            data={{
                               labels:  areas.map(item => item?.area?.name),
                               datasets: [
                                {
                                    backgroundColor: backgrounds.slice(0, areas.length),
                                    label: 'Filtros',
                                    data: areas.map(item => item?.value),
                                },
                               ]
                            }} 
                            width={100}
                            height={50}
                        />
                    </S.ContentArea>
                </Card>
            </Fragment>
        ) : null
    )
}
