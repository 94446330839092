
import Cookies from 'js-cookie';

export const isLogged = () => {
    let token = Cookies.get('token');
    return (token) ? true : false;
}

export const doLogin = (token, values, rememderPassword = false) => {
    if (rememderPassword) {
        Cookies.set('token', token, { expires: 999 });
        Cookies.set('clientId', values.clientId, { expires: 999 });
        Cookies.set('adminId', values.adminId, { expires: 999 });
        Cookies.set('email', values.email, { expires: 999 });
        Cookies.set('name', values.name, { expires: 999 });
        Cookies.set('uid', values.uid, { expires: 999 });
    } else {
        Cookies.set('token', token);
        Cookies.set('clientId', values.clientId);
        Cookies.set('adminId', values.adminId);
        Cookies.set('email', values.email);
        Cookies.set('name', values.name);
        Cookies.set('uid', values.uid);


    }
}
