import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { FaBookmark, FaShareAlt } from 'react-icons/fa';
import { ImgNull } from '../../assets/img/icons/celus';

import CelusApi from '../../server/celusApi';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { EmptyContent } from '../EmptyContent';
import { saveEmpytCards } from '../../assets/img/illustrations';
import ReactPaginate from 'react-paginate';
import { LoadingCard } from '../LoadingCard';

export const CardLaudus = () => {

    const api = CelusApi();
    const [values, setValues] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [currentPage, setCurrentPage] = useState(0)
    const perPage = 10;
    const [loading, setLoading] = useState(true);

    const GetLaudus = async () => {
        setLoading(true);
        let values = { id: Number(Cookies.get('clientId')), page: currentPage, limit: 10 }
        const result = await api.getLaudusFavorite(values);
        setValues(result.result.laudus.rows);
        setTotalPage(Math.ceil(result.result.laudus.count / perPage));
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        GetLaudus();
    }, [currentPage]);

    const saveReport = async (id) => {
        const json = {
            reportId: id,
            clientId: Cookies.get('clientId'),
        }

        try {
            await api.postFavoriteLaudus(json);
            GetLaudus();
        } catch (error) {
            console.log(error)
            return;
        }
    }

    const handlePageChange = (event) => {
        setCurrentPage(event.selected + 1);
    };

    String.prototype.stripHTML = function () { return this.replace(/<.*?>/g, ''); }
    return (
        <Fragment>

            {

                loading ? (
                    <Fragment>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                        <LoadingCard/>
                    </Fragment>
                ) : (values.length <= 0) ?
                    <EmptyContent
                        src={saveEmpytCards}
                        msg="Você ainda não tem nenhum LaudUS salvo!"
                        goTo="/especialidades/laudus"
                        title="Ver LaudUS"
                    />
                    :
                    (
                        <Fragment>
                            {
                                values?.map((item, index) => {
                                    return (
                                        <>
                                            <div className="container-card" key={index}>
                                                <div className="content-card">
                                                    <div className="row-card">
                                                        <Link to={`/to-see/laudus/${item.reportId}`}>
                                                            <div className="content-item">
                                                                <div className="content-title-card">
                                                                    <h3 className="title-card">{item.report?.title}</h3>
                                                                </div>
                                                                <p className="content-abistract" dangerouslySetInnerHTML={{ __html: item.report?.description.stripHTML() }}>
                                                                </p>
                                                            </div>
                                                        </Link>
                                                        <div className="footer-card">
                                                            <p>Autor: {item.report?.client?.name} </p>
                                                            <div>
                                                                <FaBookmark transform="shrink-4" className="fs-2 footer-icon" color='#31AF89'
                                                                    style={{ marginRight: 17 }}
                                                                    onClick={() => {
                                                                        saveReport(item.reportId)
                                                                    }}
                                                                />
                                                                <FaShareAlt transform="shrink-4" className="fs-2 footer-icon" color='#344050'
                                                                    onClick={
                                                                        () => {
                                                                            navigator.share({
                                                                                title: 'Share',
                                                                                text: 'whatevs',
                                                                                url: `/to-see/laudus/${item.reportId}/${values.title}`
                                                                            }
                                                                            )
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="thumb">
                                                        <img src={item.report?.reportImages[0]?.src ? item?.report.reportImages[0].src : ImgNull} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}

                            <div className="pag-table">
                                <ReactPaginate
                                    nextLabel=">"
                                    onPageChange={handlePageChange}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPage}
                                    previousLabel="<"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </Fragment>

                    )}

        </Fragment>
    )
}