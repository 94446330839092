import { CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';

export const IconProtoculus = styled.img`
    width: 35px;
    height: 35px;

    object-fit: scale-down;

    margin-right: 15px;
`;

export const Header = styled(CardHeader)`
    flex: 1;
    display: flex;

    max-height: 60px;
    background-color: #F3F3F3;
`;

export const Body = styled(CardBody)`
    display: flex;
    flex-direction: row;

    overflow:auto; 
`;
