import React, { Fragment, useState } from "react";
import *as S from './styles';
import { FaPlus, FaPen, FaRegTrashAlt } from "react-icons/fa";


export const Reference = ({
    disabled,
    arrayRef = [],
    setArreyRef = Function()
}) => {

    const [ref, setRef] = useState();
    const [refIndex, setRefIndex] = useState();

    const RemoverLabels = from => {
        arrayRef.splice(from, 1);
        setArreyRef([...arrayRef]);
    };

    return (
        <Fragment>
            <S.AreaRef disabled={disabled}>
                <S.InputArea>
                    <S.InputRef placeholder="Referência Bibliográfica" value={ref} onChange={(e) => { setRef(e.target.value) }} />
                    <S.Btn onClick={(e) => {
                        e.preventDefault()
                        arrayRef.push(ref);
                        setArreyRef([...arrayRef]);
                        setRef('');
                    }}>
                        <FaPlus color="#FFF" />
                    </S.Btn>
                </S.InputArea>
                {
                    <S.Label>Referências Bibliográficas:</S.Label>
                }
                <S.RefContent>
                    {
                        arrayRef.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <S.RefItem>
                                        <S.IndexRef>{index + 1}</S.IndexRef>
                                        <S.SpanItem

                                            style={{
                                                border: index === refIndex ? '1px solid #31AF89' : 'none'
                                            }}

                                            disabled={index !== refIndex}
                                            value={arrayRef[index]} onChange={e => {
                                                arrayRef[index] = e.target.value;
                                                setArreyRef([...arrayRef]);
                                            }} />
                                        <S.Btn
                                            style={{ marginRight: 5 }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                if (refIndex === index) {
                                                    setRefIndex()
                                                } else {
                                                    setRefIndex(index);
                                                }

                                            }}>
                                            <FaPen color="#FFF" />
                                        </S.Btn>
                                        <S.Btn

                                            onClick={(e) => {
                                                e.preventDefault()
                                                RemoverLabels(index)
                                            }}>
                                            <FaRegTrashAlt color="#FFF" />
                                        </S.Btn>
                                    </S.RefItem>
                                </Fragment>
                            )
                        })
                    }
                </S.RefContent>
            </S.AreaRef>
        </Fragment>
    );
}