import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';
import { FaBuffer, FaComment } from "react-icons/fa";
import { IoMdLogOut, IoMdSettings } from "react-icons/io";
import { BsFillFileEarmarkRichtextFill, BsFillBarChartFill } from "react-icons/bs";
//Icon menu edit

const NavbarVerticalMenuItem = ({ route }) => ( 
  <Flex align="center">
    {route.icon && (
      <span className="nav-link-icon">
        {
          route.icon ==='buffer' ?
            (
              <FaBuffer size={20} style={{ marginLeft: -3 }} />
            )
            : route.icon ==='logout' ?
              (
                <IoMdLogOut size={20} style={{ marginLeft: -2 }} />
              )
              : route.icon ==='tools' ?
                (
                  <IoMdSettings size={20} style={{ marginLeft: -2 }} />
                )
                : route.icon ==='push' ?
                (
                  <FaComment size={20} />
                )
                : route.icon === 'protoculus'?(
                  <BsFillFileEarmarkRichtextFill size={20}  style={{ marginLeft: -2 }} />
                ): route.icon === 'chart' ? (
                  <BsFillBarChartFill size={20}  style={{ marginLeft: -2, marginTop: -5 }} />
                ) : (
                  <FontAwesomeIcon icon={route.icon} />
                )
        }
      </span>
    )}
    <span className="nav-link-text">{route.name}</span>
    {!!route.badge && (
      <Badge color={route.badge.color || '#31AF89'} pill className="ml-2">
        {route.badge.text}
      </Badge>
    )}
  </Flex>
);

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired
  }).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
