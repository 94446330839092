import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Switch } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import AppContext from "../context/Context";
import { getPageName } from "../helpers/utils";

import "../components/custon/custonDashboard.css";
import RouteHandler from "../components/RouteHandler/RouteHandler";
import DashboardSpecialties from "../components/dashboard/DashboardSpecialties";
import FeedUS from "../components/feedUS/Feed";
import { FeedResumus } from "../components/feedResumus/Feed";
import Index from "../components/laudus";
import ReviewLaudus from "../components/reviewLaudus";
import IndexRusumus from "../components/resumus/IndexRusumus";
import ReviewResumus from "../components/reviewResumus";
import ViewUs from "../components/ViewUS/Feed";
import ViewResumus from "../components/ViewResumus/Feed";
import { ListTable } from "../components/ListTable";
import { ListTableResumus } from "../components/ListTableResumus";
import Anatomus from "../components/feed/PostCreateAnatomus";
import { NewUser } from "../components/newUser";
import { User } from "../components/user";
import { MyLaudUS } from "../components/MyLaudUS/Feed";
import { MyResumUS } from "../components/MyResumUS/Feed";
import FormsResumusEdit from "../components/widgets/FormsResumusEdit";
import FormsLaudusEdit from "../components/widgets/FormsLaudusEdit";
import { MyLaudusDraft } from "../components/MyLaudusDraft/Feed";
import { TableAnatomus } from "../components/TableAnatomus";
import PutCreateAnatomus from "../components/feed/PutCreateAnatomus";
import { ViewNotifications } from "../components/ViewNotifications";
import ViewUsMe from "../components/ViewUSMe/Feed";
import { SaveUS } from "../components/SaveUS";
import { Tools } from "../components/tools";
import { Push } from "../components/push";
import { ClearTests } from "../components/ClearTests";
import { SearchesNotFound } from "../components/SearchesNotFound";
import { FilterAreasAmount } from "../components/FilterAreasAmount";
import { Analytics } from "../components/analytics";
import { AllUsers } from "../components/AllUsers";
import { Protoculus } from "../components/Protoculus";
import { FeedProtoculus } from "../components/FeedProtoculus";
import { ProtoculusEdit } from "../components/ProtoculusEdit";
import { ViewProtoculus } from "../components/ViewProtoculus";

const DashboardLayout = ({ location }) => {
  const { isFluid } = useContext(AppContext);

  const isKanban = getPageName("kanban");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? "container-fluid" : "container"}>
      <NavbarVertical isKanban={isKanban} navbarStyle={`#31AF89`} />
      <div className="content mt-3">
        <NavbarTop />
        <Switch>
          <RouteHandler private path="/dashboard" exact>
            <Dashboard />
          </RouteHandler>
          <RouteHandler private path="/" exact>
            <Dashboard />
          </RouteHandler>
          <RouteHandler private path="/especialidades/:type">
            <DashboardSpecialties />
          </RouteHandler>

          <RouteHandler private path="/us/laudus/:name/:id">
            <FeedUS />
          </RouteHandler>

          <RouteHandler private path="/us/resumus/:name/:id">
            <FeedResumus />
          </RouteHandler>

          <RouteHandler private path="/laudus/new">
            <Index />
          </RouteHandler>

          <RouteHandler private path="/laudus/review/:laudusId">
            <ReviewLaudus />
          </RouteHandler>

          <RouteHandler private path="/resumus/new">
            <IndexRusumus />
          </RouteHandler>

          <RouteHandler private path="/resumus/review/:resumusDoc">
            <ReviewResumus />
          </RouteHandler>

          <RouteHandler private path="/to-see/laudus/:id">
            <ViewUs />
          </RouteHandler>

          <RouteHandler private path="/to-see/resumus/:id">
            <ViewResumus />
          </RouteHandler>

          <RouteHandler private path="/list/table/laudus">
            <ListTable />
          </RouteHandler>

          <RouteHandler private path="/list/table/resumus">
            <ListTableResumus />
          </RouteHandler>

          <RouteHandler private path="/anatomus/new">
            <Anatomus />
          </RouteHandler>

          <RouteHandler private path="/add/user">
            <NewUser />
          </RouteHandler>

          <RouteHandler exact private path="/profile/user">
            <User />
          </RouteHandler>

          <RouteHandler private path="/laudus/list">
            <MyLaudUS />
          </RouteHandler>

          <RouteHandler private path="/resumus/list/">
            <MyResumUS />
          </RouteHandler>

          <RouteHandler private path="/resumus/edit/:doc">
            <FormsResumusEdit />
          </RouteHandler>

          <RouteHandler exact private path="/laudus/edit/:id">
            <FormsLaudusEdit />
          </RouteHandler>

          <RouteHandler exact private path="/my-resumus/draft">
            <MyResumUS draft={true} />
          </RouteHandler>

          <RouteHandler private path="/my-laudus/draft">
            <MyLaudusDraft />
          </RouteHandler>

          <RouteHandler private path="/anatomus">
            <TableAnatomus />
          </RouteHandler>

          <RouteHandler private path="/anatomus-edit/:anatomusId">
            <PutCreateAnatomus />
          </RouteHandler>

          <RouteHandler private path="/admin/notifications">
            <ViewNotifications />
          </RouteHandler>

          <RouteHandler private path="/me-laudus/laudus/:id">
            <ViewUsMe />
          </RouteHandler>

          <RouteHandler private path="/me-resumus/resumus/:id">
            <ViewResumus />
          </RouteHandler>

          <RouteHandler private path="/salvos/:type?">
            <SaveUS />
          </RouteHandler>

          <RouteHandler private path="/tools">
            <Tools />
          </RouteHandler>
          <RouteHandler private path="/notifications/push">
            <Push />
          </RouteHandler>

          <RouteHandler private path="/clear-tests">
            <ClearTests />
          </RouteHandler>

          <RouteHandler private path="/searches-not-found">
            <SearchesNotFound />
          </RouteHandler>

          <RouteHandler private path="/filter-areas-amount">
            <FilterAreasAmount />
          </RouteHandler>

          <RouteHandler private path="/analytics">
            <Analytics />
          </RouteHandler>

          <RouteHandler private path="/all/users">
            <AllUsers />
          </RouteHandler>

          <RouteHandler private path="/create/protocolus">
            <Protoculus />
          </RouteHandler>

          <RouteHandler private path="/protocolus">
            <FeedProtoculus />
          </RouteHandler>

          <RouteHandler private path="/edit/protocolus/:id">
            <ProtoculusEdit />
          </RouteHandler>

          <RouteHandler private path="/view/protocolus/:id/:title">
            <ViewProtoculus />
          </RouteHandler>

          <RouteHandler private path="/create/protocolus">
            <Protoculus />
          </RouteHandler>

          <RouteHandler private path="/protocolus">
            <FeedProtoculus />
          </RouteHandler>

          <RouteHandler private path="/view/protocolus/:id/:title">
            <ViewProtoculus />
          </RouteHandler>

          {/*Redirect*/}
          <Redirect to="/errors/404" />
        </Switch>
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
