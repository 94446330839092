import React from 'react';
import { Row, Col } from 'reactstrap';
import { PostCreateResumus } from '../feed/PostCreateResumus';
import FeedSideBarResumus from '../feedUS/FeedSideBarResumus';

const FormsLaudus = () => {

  return (
    <>
      <Row>
        <Col lg={8} className="pl-lg-2">
          <PostCreateResumus/>
        </Col>
        <Col lg={4} className="pr-lg-2 mb-3 mb-lg-0">
          <FeedSideBarResumus />
        </Col>
      </Row>

    </>
  );
};

export default FormsLaudus;
