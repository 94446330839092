import React, { Fragment, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Form, Label } from "reactstrap";
import CelusApi from "../../server/celusApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Picker } from "emoji-mart";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

export const Push = () => {
    const history = useHistory();
    const api = CelusApi();
    const [previewEmojiTextarea, setPreviewEmojiTextarea] = useState(false);
    const [message, setMessage] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [disabled, setDisabled] = useState(false);

    const addEmoji = e => {
        let emoji = e.native;
        setMessage(message + emoji);
    };
    const UpdAreaShow = async () => {
        setDisabled(true)
        try {
            if(message && messageTitle){
                await api.postNotificationPush({ messageTitle, message });
                setDisabled(false)
                toast.success('Notificação enviada!');
                history.push('/dashboard')

            }else{
                setDisabled(false)
                toast.error('Campos não informados!');
            }
            
        } catch (error) {
            setDisabled(false)
            toast.error('Tente novamente mais tarde!');

        }

    }
    return (
        <Fragment>
            <Card>
                <CardHeader style={{ background: '#F9FAFD' }}>
                    <h5>Criar Notificação</h5>
                </CardHeader>
                <CardBody>
                    <Form className="position-relative" style={{ marginBottom: 20 }}>
                        <Label>Título</Label>
                        <Input
                            style={{ marginBottom: 15 }}
                            className="resize-none"
                            rows="6"
                            placeholder="Título"
                            value={messageTitle}
                            onChange={({ target }) => setMessageTitle(target.value)}
                            disabled={disabled}
                        />
                        <Label>Mensagem</Label>
                        <Input
                            className="resize-none"
                            type="textarea"
                            rows="6"
                            placeholder="Mensagem de Notificação"
                            value={message}
                            onChange={({ target }) => setMessage(target.value)}
                            onClick={() => setPreviewEmojiTextarea(false)}
                            disabled={disabled}

                        />
                        <FontAwesomeIcon
                            icon={['far', 'laugh-beam']}
                            transform="grow-5"
                            style={{
                                position: 'absolute',
                                bottom: '6%',
                                right: '1%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setPreviewEmojiTextarea(!previewEmojiTextarea)}
                        />
                        {previewEmojiTextarea && (
                            <Picker
                                set="google"
                                onSelect={addEmoji}
                                sheetSize={20}
                                style={{
                                    position: 'absolute',
                                    top: '40%',
                                    bottom: '24%',
                                    right: '1%',
                                    padding: 0,
                                    zIndex: 1,
                                    background: '#FFF',

                                }}
                                theme={'light'}
                                showPreview={false}
                                showSkinTones={false}
                            />
                        )}
                    </Form>
                    <Button disabled={disabled} onClick={UpdAreaShow}>Enviar</Button>
                </CardBody>
                <CardFooter></CardFooter>
            </Card>
        </Fragment>
    )
}