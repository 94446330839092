import React from 'react';
import { Row, Col} from 'reactstrap';
import ReviewLaudus from '../feed/ReviewLaudus';
import ApproveSideBar from '../feedUS/ApproveSideBar';

const FormsReviewLaudus = () => {
  return (
    <>
      <Row>
        <Col lg={8} className="pl-lg-2">
          <ReviewLaudus />
        </Col>
        <Col lg={4} className="pr-lg-2 mb-3 mb-lg-0">
          <ApproveSideBar />
        </Col>
      </Row>

    </>
  );
};

export default FormsReviewLaudus;
